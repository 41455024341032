import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import { Redirect } from "react-router-dom";
import Admintable from "./Admintable";
import Adminmainnav from "./Adminmainnav";
import Sidenav from "../../TableUi/components/Navbar/Sidenav";
import { useDispatch } from "react-redux";
import { handle_store_url_location } from "../../actions/filterAction";

function Adminusers({ admin_reducer }) {
  const dispatch = useDispatch();
  if (localStorage.getItem("Token") == null) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <div className="wrapper">
      <Grid container>
        <Grid item sm={0.7}>
          <Sidenav />
        </Grid>
        <Grid item sm={11.2}>
          <div
            id="content"
            className={admin_reducer.boot_active ? "active" : null}
          >
            <section>
              <Adminmainnav />
              <Admintable />
            </section>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
Adminusers.propTypes = {
  admin_reducer: PropTypes.object,
};
const mapStateToProps = (state) => ({
  admin_reducer: state.admin_reducer,
});
export default connect(mapStateToProps)(Adminusers);

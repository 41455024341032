import React from "react";
import "../../../../css/Sections/Divider/horizontaldivider.css";

function HorizontalDivider() {
  return (
    <div className="divider_wrapper">
      <div className="divider_line div-transparent div-dot"></div>
    </div>
  );
}

export default HorizontalDivider;

import React, { useRef } from "react";
import PropTypes from "prop-types";
// import { LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
// import dayjs from "dayjs";
import "../../../../css/Filters/filters.css";

function Datepicker({ date_from, set_date_from, date_to, set_date_to }) {
  // const maxDate = dayjs();
  const date_start_ref = useRef();
  const date_to_ref = useRef();
  return (
    <div>
      {/* <div>
        <p className="date_text_endpoint">start</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" sx={{ width: "90%" }}>
            <DatePicker
              className="mui_datepicker_input"
              maxDate={date_to !== "None" ? date_to : maxDate}
              value={date_from}
              onChange={(event) => set_date_from(event ? event : "None")}
              slotProps={{
                textField: {
                  variant: "standard",
                  style: { border: "none", outline: "none" },
                },
              }}
            />
            {date_from !== "None" ? (
              <button
                className="mui_datepicker_closebtn"
                onClick={() => set_date_from("None")}
              >
                <CloseIcon fontSize="smaller" style={{ color: "white" }} />
              </button>
            ) : null}
          </Stack>
        </LocalizationProvider>
      </div>
      <div>
        <p className="date_text_endpoint">end</p>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack direction="row" sx={{ width: "90%" }}>
            <DatePicker
              className="mui_datepicker_input"
              minDate={date_from !== "None" ? date_from : null}
              maxDate={maxDate}
              value={date_to}
              onChange={(event) => set_date_to(event ? event : "None")}
              slotProps={{
                textField: { variant: "standard", style: { border: "none" } },
              }}
            />
            {date_to !== "None" ? (
              <button
                className="mui_datepicker_closebtn"
                onClick={() => set_date_to("None")}
              >
                <CloseIcon fontSize="smaller" style={{ color: "white" }} />
              </button>
            ) : null}
          </Stack>
        </LocalizationProvider>
      </div> */}

      <div className="dateRangePicker_input">
        <input
          type="text"
          placeholder="start"
          value={date_from !== "None" ? date_from : "start"}
          ref={date_start_ref}
          onClick={() => (date_start_ref.current.type = "date")}
          onChange={(event) =>
            set_date_from(event.target.value ? event.target.value : "None")
          }
          max={date_to !== "None" ? date_to : null}
        />
        <Box sx={{ mx: 1 }}> to </Box>
        <input
          type="text"
          placeholder="end"
          value={date_to !== "None" ? date_to : "end"}
          ref={date_to_ref}
          onClick={() => (date_to_ref.current.type = "date")}
          onChange={(event) =>
            set_date_to(event.target.value ? event.target.value : "None")
          }
          min={date_from !== "None" ? date_from : null}
        />
      </div>
    </div>
  );
}
Datepicker.propTypes = {
  date_from: PropTypes.string,
  date_to: PropTypes.string,
  set_date_from: PropTypes.func,
  set_date_to: PropTypes.func,
};

export default Datepicker;

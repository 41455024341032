import PropTypes from "prop-types";
import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { connect } from "react-redux";
import {
  date_space_fm,
  admin_chart,
  arr_calc,
  email_sent,
} from "../actions/adminAction";

function Dashboard({
  users,
  admin_reducer,
  date_space_fm,
  admin_chart,
  arr_calc,
  email_sent,
}) {
  useEffect(() => {
    admin_chart();
    email_sent();
  }, []);

  const user_id_filter = (value) => {
    const val = value.split(".csv")[0].split("uid_");
    const new_val = val[val.length - 1];
    return new_val;
  };
  const dt_fm = (dt) => {
    const new_day = new Date(dt).toDateString();
    return new_day;
  };

  return (
    <div>
      <section
        id="boxes"
        className={users.dark_theme ? "py-5 dk-mode" : "py-5 bg-white"}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="quick_activity_wrap">
                <div className="single_quick_activity">
                  <h4>New Registered Users</h4>
                  <h3>
                    <span className="counter">{admin_reducer.today_users}</span>
                  </h3>
                </div>
                <div className="single_quick_activity">
                  <h4>Total Logins</h4>
                  <h3>
                    <span className="counter">
                      {admin_reducer.data.length !== 0
                        ? arr_calc(admin_reducer.data)
                        : "..."}
                    </span>
                  </h3>
                </div>
                <div className="single_quick_activity">
                  <h4>Total Users</h4>
                  <h3>
                    <span className="counter">
                      {admin_reducer.all_data.length !== 0
                        ? admin_reducer.all_data.length
                        : "..."}
                    </span>
                  </h3>
                </div>
                <div className="single_quick_activity">
                  <h4>Unauthorized Users</h4>
                  <h3>
                    <span className="counter">
                      {admin_reducer.all_data.length !== 0
                        ? admin_reducer.unauth_users
                        : "..."}
                    </span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-8 shadow-lg">
              <div className="white_card mb_30 card_height_100">
                <div className="white_card_header">
                  <div className="row align-items-center justify-content-between flex-wrap">
                    <div className="col-lg-4">
                      <div className="main-title">
                        <div
                          className="m-0"
                          style={{ float: "left", color: "white" }}
                        >
                          Total Logins
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="white_card_body"
                  style={{ position: "relative" }}
                >
                  <div style={{ minHeight: "354px" }}>
                    <Chart
                      options={admin_reducer.options}
                      series={admin_reducer.series}
                      type="bar"
                      width="100%"
                      height={320}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4">
              <div className="white_card card_height_100 mb_30 user_crm_wrapper">
                <div className="row">
                  <div className="col-lg-6 ">
                    <div className="single_crm shadow-lg">
                      <div className="crm_head d-flex align-items-center justify-content-between">
                        <div
                          className="thumb"
                          style={{ boxSizing: "border-box" }}
                        >
                          <img
                            alt="profile_pic"
                            src="https://demo.dashboardpack.com/user-management-html/img/crm/businessman.svg"
                          />
                          <p style={{ fontSize: "large" }}>Total Logins</p>
                        </div>
                      </div>
                      <div
                        className="crm_body"
                        style={{ boxSizing: "border-box", minHeight: "160px" }}
                      >
                        {admin_reducer.all_data.length > 0 ? (
                          <>
                            <h4>{admin_reducer.data[0]}</h4>
                            <p>{admin_reducer.key[0]}</p>
                          </>
                        ) : (
                          <p>Loading ...</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="single_crm shadow-lg">
                      <div className="crm_head crm_bg_1 d-flex align-items-center justify-content-between">
                        <div
                          className="thumb"
                          style={{ boxSizing: "border-box" }}
                        >
                          <img
                            alt="prof_poic_2"
                            src="https://demo.dashboardpack.com/user-management-html/img/crm/businessman.svg"
                          />
                          <p style={{ fontSize: "large" }}>Total Logins</p>
                        </div>
                      </div>
                      <div
                        className="crm_body"
                        style={{ boxSizing: "border-box", minHeight: "160px" }}
                      >
                        {admin_reducer.all_data.length > 0 ? (
                          <>
                            <h4>{admin_reducer.data[1]}</h4>
                            <p>{admin_reducer.key[1]}</p>
                          </>
                        ) : (
                          <p>Loading ...</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 ">
                    <div className="single_crm shadow-lg">
                      <div className="crm_head crm_bg_2 d-flex align-items-center justify-content-between">
                        <div
                          className="thumb"
                          style={{ boxSizing: "border-box" }}
                        >
                          <img
                            alt="prof_pic_3"
                            src="https://demo.dashboardpack.com/user-management-html/img/crm/businessman.svg"
                          />
                          <p style={{ fontSize: "large" }}>Total Logins</p>
                        </div>
                      </div>
                      <div
                        className="crm_body"
                        style={{ boxSizing: "border-box", minHeight: "160px" }}
                      >
                        {admin_reducer.all_data.length > 0 ? (
                          <>
                            <h4>{admin_reducer.data[2]}</h4>
                            <p>{admin_reducer.key[2]}</p>
                          </>
                        ) : (
                          <p>Loading ...</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 ">
                    <div className="single_crm shadow-lg">
                      <div className="crm_head crm_bg_3 d-flex align-items-center justify-content-between">
                        <div
                          className="thumb"
                          style={{ boxSizing: "border-box" }}
                        >
                          <img
                            alt="prof_pic_4"
                            src="https://demo.dashboardpack.com/user-management-html/img/crm/businessman.svg"
                          />
                          <p style={{ fontSize: "large" }}>Total Logins</p>
                        </div>
                      </div>
                      <div
                        className="crm_body"
                        style={{ boxSizing: "border-box", minHeight: "160px" }}
                      >
                        {admin_reducer.all_data.length > 0 ? (
                          <>
                            <h4>{admin_reducer.data[3]}</h4>
                            <p>{admin_reducer.key[3]}</p>
                          </>
                        ) : (
                          <p>Loading ...</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-8">
              <div className="table-responsive table--no-card m-b-40">
                <table className="table table-borderless table-striped table-earning">
                  <thead>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Date of Registration</th>
                  </thead>
                  <tbody>
                    {admin_reducer.tb_data.map((val, i) => (
                      <tr key={i}>
                        <td>{val.username}</td>
                        <td>{val.email}</td>
                        <td>{date_space_fm(val.dateofregistration)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4 scroll-custom" style={{ height: "400px" }}>
              <div className="table-responsive table--no-card m-b-40">
                <table className="table table-borderless table-striped table-earning">
                  <thead>
                    <th>S.No</th>
                    <th>Email Sent Date</th>
                    <th>Userid</th>
                  </thead>
                  <tbody>
                    {admin_reducer.email_data
                      .sort(
                        (a, b) =>
                          new Date(b.email_sent_time) -
                          new Date(a.email_sent_time),
                      )
                      .map((val, i) => (
                        <tr key={i}>
                          <td>{i}</td>
                          <td>{dt_fm(val.email_sent_time)}</td>
                          <td>{user_id_filter(val.filename)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

Dashboard.propTypes = {
  admin_chart: PropTypes.func,
  admin_reducer: PropTypes.object,
  arr_calc: PropTypes.func,
  date_space_fm: PropTypes.func,
  email_sent: PropTypes.func,
  users: PropTypes.object,
};

const mapStateToProps = (state) => ({
  users: state.users,
  admin_reducer: state.admin_reducer,
});
export default connect(mapStateToProps, {
  date_space_fm,
  admin_chart,
  arr_calc,
  email_sent,
})(Dashboard);

import { combineReducers } from "redux";
import adminReducers from "./adminReducers";
import statsReducer from "./statsReducer";
import userReducer from "./userReducer";
import filter_reducer from "./filterReducer";

export default combineReducers({
  users: userReducer,
  stats: statsReducer,
  admin_reducer: adminReducers,
  filter_reducer,
});

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Individualuser from "./Individualuser";
import UserHomeV2 from "../TableUi/components/home/UserHomeV2";

function Mainuserstats({ users }) {
  return (
    <div className="wrapper">
      <div id="content" className={users.sidebar_activ ? "active" : null}>
        <section>
          <UserHomeV2 />
          <Individualuser />
        </section>
      </div>
    </div>
  );
}
Mainuserstats.propTypes = {
  users: PropTypes.object,
};
const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps)(Mainuserstats);

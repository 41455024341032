import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import UserMainNav from "../Navbar/UserMainNav";
import Sidenav from "../Navbar/Sidenav";
import "react-toastify/dist/ReactToastify.css";
import CompanyParametersFillModal from "./CompanyParametersFillModal";
import { styles_background_img } from "../../../utils/Globals/global_functions";
import { get_user_dashboard_pref } from "../../../actions/userAction";
import { useSelector, useDispatch } from "react-redux";
import "../../../css/Dashboard/company_dashboard.css";

function CompanyDashboard() {
  const dispatch = useDispatch();
  const dashboard_status = useSelector(
    (state) => state.users.company_dashboard,
  );
  const [open_fill_param_modal, set_open_fill_param_modal] = useState(false);
  const [show_sidenav, set_show_sidenav] = useState(false);
  const user_name_session = localStorage.getItem("admin_status");
  const company_status = localStorage.getItem("company_status");
  useEffect(() => {
    if (company_status === "None") {
      get_user_dashboard_pref(dispatch);
    }
    return;
  }, []);
  return (
    <div className="company_stats_main_container" style={styles_background_img}>
      <Grid container>
        {user_name_session === "super_admin" ||
        user_name_session === "admin" ? (
          <Grid
            item
            xs={show_sidenav === true ? 0.5 : 0}
            sm={show_sidenav === true ? 0.5 : 0}
          >
            {show_sidenav ? <Sidenav /> : null}
          </Grid>
        ) : null}
        <Grid
          item
          xs={show_sidenav === true ? 11.5 : 12}
          sm={show_sidenav === true ? 11.5 : 12}
        >
          <UserMainNav
            show_sidenav={show_sidenav}
            set_show_sidenav={set_show_sidenav}
          />
          <div className="comp_dash_main">
            <div className="comp_dash_btns_div">
              {company_status === "None" && dashboard_status === false ? (
                <button
                  className="comp_dash_btn comp_dash_btn_fill"
                  onClick={() => set_open_fill_param_modal(true)}
                >
                  <LibraryBooksIcon fontSize="large" sx={{ mr: 1 }} />
                  Fill company Parameters
                </button>
              ) : (
                <Link to="/company-statspage" className="comp_dash_btn">
                  <span className="comp_dash_btn_search">
                    <QueryStatsIcon fontSize="large" sx={{ mr: 1 }} />
                    Company Dashboard
                  </span>
                </Link>
              )}
              <Link to="/dashboard" className="comp_dash_btn">
                <span className="comp_dash_btn_search">
                  <SearchIcon fontSize="large" sx={{ mr: 1 }} />
                  Telegram Search
                </span>
              </Link>
            </div>
          </div>
        </Grid>
      </Grid>
      {open_fill_param_modal === true ? (
        <CompanyParametersFillModal
          open_fill_param_modal={open_fill_param_modal}
          set_open_fill_param_modal={set_open_fill_param_modal}
          fill_status="create"
        />
      ) : null}
    </div>
  );
}
export default CompanyDashboard;

import {
  UPDATE_ADMIN_PAGE,
  LOGOUT,
  NAV_CHANGER,
  GET_ALL_USER,
  ADMIN_NOTIFICATION,
  ADMIN_CHART_DATA,
  EMAIL_SENT,
  TABLE_SORT,
  REG_USER,
  UNAUTH_USER,
} from "../actions/types";

const intialState = {
  redirect: false,
  boot_active: true,
  user_name: "",
  all_data: [],
  tb_data: [],
  email_data: [],
  key: [],
  data: [],
  today_users: 0,
  admin_page: "users",
  unauth_users: 0,
  options: {
    chart: {
      id: "apexchart-example",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "monospace",
          fontWeight: 400,

          colors: [
            "#FF7EA5",
            "#00FA9A",
            "#4EE2EC",
            "#C388F6",
            "#FF7EA5",
            "#00FA9A",
            "#4EE2EC",
            "#C388F6",
            "#FF7EA5",
            "#00FA9A",
          ],
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "monospace",
          fontWeight: 400,

          colors: [
            "#FF7EA5",
            "#00FA9A",
            "#4EE2EC",
            "#C388F6",
            "#FF7EA5",
            "#00FA9A",
            "#4EE2EC",
            "#C388F6",
            "#FF7EA5",
            "#00FA9A",
          ],
        },
      },
    },
    colors: ["#FF7EA5", "#00FA9A", "#4EE2EC", "#C388F6"],
  },
  series: [
    {
      name: "Total Logins",
      data: [],
    },
  ],
};
export default function admin_reducer(state = intialState, action) {
  switch (action.type) {
    case ADMIN_NOTIFICATION:
      return {
        ...state,
        redirect: action.redirect,
        user_name: action.user_name,
      };
    case GET_ALL_USER:
      return {
        ...state,
        all_data: action.all_data,
      };
    case ADMIN_CHART_DATA:
      return {
        ...state,
        all_data: action.all_data,
        series: action.series,
        options: action.options,
        key: action.key,
        data: action.data,
        tb_data: action.tb_data,
        unauth_users: action.unauth,
      };
    case EMAIL_SENT:
      return {
        ...state,
        email_data: action.data,
      };
    case TABLE_SORT:
      return {
        ...state,
        tb_data: action.tb_data,
      };
    case NAV_CHANGER:
      return {
        ...state,
        boot_active: action.value,
      };
    case LOGOUT:
      return {
        ...state,
        redirect: true,
      };
    case UPDATE_ADMIN_PAGE:
      return {
        ...state,
        admin_page: action.value,
      };
    case REG_USER:
      return {
        ...state,
        today_users: action.value,
      };
    case UNAUTH_USER:
      return {
        ...state,
        unauth_users: action.value,
      };

    default:
      return state;
  }
}

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaSignOutAlt, FaSortDown, FaBars } from "react-icons/fa";
import "bootstrap/dist/js/bootstrap.min.js";
import { connect } from "react-redux";
import {
  nav_style_changer,
  logout,
  user_nav_func,
} from "../actions/userAction";
import { handle_clear_localStorage } from "../utils/SessionStorage/handle_session";
import { useDispatch } from "react-redux";
import { handle_store_url_location } from "../actions/filterAction";

function Userunfixednavbar({
  users,
  nav_style_changer,
  logout,
  user_nav_func,
}) {
  useEffect(() => {
    user_nav_func();
  }, []);
  const dispatch = useDispatch();

  if (users.redirect) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <section>
      <nav
        className="navbar navbar-expand-sm navbar-light text-center"
        style={
          users.dark_theme
            ? {
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
                background: " #22313a",
                height: "65px",
                marginTop: "-10px",
              }
            : {
                boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
                background: " white",
                height: "65px",
                marginTop: "-10px",
              }
        }
      >
        <div className="container text-center ">
          <div
            className="mlcust"
            style={{ cursor: "pointer" }}
            onClick={() => {
              nav_style_changer(!users.sidebar_activ);
            }}
          >
            <IconContext.Provider
              value={{
                size: 24,
                color: "#1FCECB",
              }}
            >
              <FaBars />
            </IconContext.Provider>
          </div>

          <Link to="/dashboard">
            <span
              className="navbar-brand text-center"
              style={
                users.dark_theme ? { color: "#20dfcd" } : { color: "#066E80" }
              }
              id="tesset"
            >
              <b />
            </span>
          </Link>

          <div
            className="navbar-collapse collapse"
            id="navbarCollapse"
            style={{ position: "absolute", right: "15%" }}
          >
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <div className="dropdown">
                  <span
                    className="nav-link"
                    style={
                      users.dark_theme
                        ? {
                            textTransform: "capitalize",
                            background: "#22313a",
                            cursor: "pointer",
                          }
                        : {
                            textTransform: "capitalize",
                            background: "white",
                            cursor: "pointer",
                          }
                    }
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {/* Welcome {this.state.username} */}
                    <img
                      alt="propfpic"
                      className="userprof"
                      src="https://img.icons8.com/bubbles/100/000000/user.png"
                    />
                    <div
                      style={{
                        display: "inline",
                        position: "absolute",
                        left: "45px",
                        top: "5px",
                      }}
                    >
                      <IconContext.Provider
                        value={{
                          size: 18,
                          background: "white",
                          color: "#8d8d8d",
                        }}
                      >
                        <FaSortDown />
                      </IconContext.Provider>
                    </div>
                  </span>
                  <ul
                    className="dropdown-menu"
                    style={
                      users.dark_theme
                        ? { background: "#22313a", color: "#9bbcd1" }
                        : { background: "white", color: "black" }
                    }
                    aria-labelledby="dropdownMenuLink"
                  >
                    <Link to="/userprof" style={{ width: "0px" }}>
                      <li>
                        <span
                          style={
                            users.dark_theme
                              ? { color: "#9bbcd1" }
                              : { color: "black" }
                          }
                          className="dropdown-item"
                          href="#"
                        >
                          Profile
                        </span>
                      </li>
                    </Link>
                    <li>
                      <span
                        className="dropdown-item"
                        href="#"
                        style={
                          users.dark_theme
                            ? {
                                paddingLeft: "15px",
                                fontSize: "15px",
                                color: "#9bbcd1",
                              }
                            : {
                                paddingLeft: "15px",
                                fontSize: "15px",
                                color: "black",
                              }
                        }
                        onClick={() => {
                          logout();
                          sessionStorage.clear();
                          handle_clear_localStorage();
                          window.location.href = "/";
                        }}
                      >
                        Log Out
                        <div style={{ display: "inline", float: "right" }}>
                          <IconContext.Provider
                            value={{
                              size: 12,
                              background: "white",
                              color: "#8d8d8d",
                            }}
                          >
                            <FaSignOutAlt />
                          </IconContext.Provider>
                        </div>
                      </span>
                    </li>
                  </ul>
                </div>
              </li>

              <li
                className="nav-item"
                style={{ marginLeft: "8px", textTransform: "capitalize" }}
              >
                <span
                  className="proftext"
                  style={
                    users.dark_theme
                      ? { color: "#20dfcd" }
                      : { color: "#5a5a5a" }
                  }
                >
                  {users.user_name}
                </span>
              </li>
              <li></li>
            </ul>
          </div>
        </div>
      </nav>
    </section>
  );
}
Userunfixednavbar.propTypes = {
  users: PropTypes.object,
  nav_style_changer: PropTypes.func,
  logout: PropTypes.func,
  user_nav_func: PropTypes.func,
};
const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps, {
  nav_style_changer,
  logout,
  user_nav_func,
})(Userunfixednavbar);

import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import { options_post_category } from "../../../../Assets/globalfunctions";
import FormControlLabel from "@mui/material/FormControlLabel";
import { handle_session_get } from "../../../../utils/SessionStorage/handle_session";
import PropTypes from "prop-types";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import DialogAlert from "../../modalContainer/DialogAlert";

function Categories({ postCategory, setPostCategory, container_type }) {
  const session_categories = handle_session_get("enabled_categories");
  const user_name_session = handle_session_get("admin_status");
  const [scroll_div_height, set_scroll_div_height] = useState(true);
  const [session_category_array, set_session_category_array] = useState([]);
  const [open_dialog, set_open_dialog] = useState(false);
  const [show_filter_dialog, set_show_filter_dialog] = useState(false);
  const isAllselected =
    options_post_category.length > 0 &&
    postCategory.length === options_post_category.length;

  useEffect(() => {
    if (session_categories !== "all") {
      const array_data = session_categories.split(",");
      set_session_category_array(array_data);
    } else {
      set_session_category_array(options_post_category);
    }
  }, []);

  const handleChangePostCategory = (event) => {
    set_show_filter_dialog(true);
    const { value } = event.target;
    if (user_name_session === "super_admin" || user_name_session === "admin") {
      if (value === "all") {
        setPostCategory(
          postCategory.length === options_post_category.length
            ? []
            : options_post_category,
        );
      } else {
        const list_post_category = [...postCategory];
        const index_post_category = list_post_category.indexOf(value);
        index_post_category === -1
          ? list_post_category.push(value)
          : list_post_category.splice(index_post_category, 1);
        setPostCategory(list_post_category);
      }
    } else if (session_categories === "all") {
      if (value === "all") {
        setPostCategory(
          postCategory.length === options_post_category.length
            ? []
            : options_post_category,
        );
      } else {
        const list_post_category = [...postCategory];
        const index_post_category = list_post_category.indexOf(value);
        index_post_category === -1
          ? list_post_category.push(value)
          : list_post_category.splice(index_post_category, 1);
        setPostCategory(list_post_category);
      }
    } else if (value === "all") {
      setPostCategory(
        postCategory.length === session_category_array.length
          ? []
          : session_category_array,
      );
    } else if (session_category_array.includes(value)) {
      const list_post_category = [...postCategory];
      const index_post_category = list_post_category.indexOf(value);
      index_post_category === -1
        ? list_post_category.push(value)
        : list_post_category.splice(index_post_category, 1);
      setPostCategory(list_post_category);
    } else {
      set_open_dialog(true);
    }
  };

  return (
    <>
      <FormControl>
        <FormLabel
          id="demo-controlled-radio-buttons-group"
          className="label__title filter_category_title"
        >
          Category
        </FormLabel>
        <Box
          className={
            container_type === "column"
              ? "filter__category"
              : "filter_category_modal"
          }
          id="scroll_filter"
        >
          <FormGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            row={container_type === "row"}
          >
            {options_post_category.map((item, index) => {
              let channel_name = "";
              try {
                switch (item) {
                  case "telegram2":
                    channel_name = "Hacking";
                    break;
                  case "extremepolitical2":
                    channel_name = "Political";
                    break;
                  case "extremepolitical":
                    channel_name = "Political";
                    break;
                  case "criminal_activities":
                    channel_name = "Criminal";
                    break;
                  case "religion_spirituality":
                    channel_name = "Religious";
                    break;
                  case "information_technology":
                    channel_name = "IT";
                    break;
                  case "pharma_drugs":
                    channel_name = "Pharma";
                    break;
                  case "betting_gambling":
                    channel_name = "Gambling";
                    break;
                  case "blogs_vlogs":
                    channel_name = "Blogs";
                    break;
                  case "science_index":
                    channel_name = "Science";
                    break;
                  case "books_comics":
                    channel_name = "Books";
                    break;
                  case "fashion_beauty":
                    channel_name = "Fashion";
                    break;
                  case "design_arch":
                    channel_name = "Design";
                    break;
                  case "humor_entertainment":
                    channel_name = "Humor";
                    break;
                  case "culture_events":
                    channel_name = "Culture";
                    break;
                  case "adult_content":
                    channel_name = "Adult";
                    break;
                  case "marketing_and_ads":
                    channel_name = "Marketing";
                    break;
                  case "sports_and_activities":
                    channel_name = "Sports";
                    break;
                  case "cyber_security":
                    channel_name = "Cyber";
                    break;
                  case "arab":
                    channel_name = "arabic";
                    break;
                  default:
                    channel_name = item;
                    break;
                }
                channel_name = channel_name.replaceAll("_", " ");
              } catch {
                channel_name = item;
              }
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      value={item}
                      onChange={handleChangePostCategory}
                      checked={postCategory.includes(item)}
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label={channel_name}
                  key={index}
                />
              );
            })}
            <FormControlLabel
              control={
                <Checkbox
                  value="all"
                  onChange={handleChangePostCategory}
                  checked={isAllselected}
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="All"
            />
          </FormGroup>
        </Box>
        {container_type === "column" ? (
          <button
            className="filter_category_arrow"
            onClick={() => {
              const element = document.getElementById("scroll_filter");
              if (element.scrollTop === 0) {
                element.scrollTop = element.scrollHeight;
                set_scroll_div_height(false);
              } else {
                element.scrollTo({ top: 0 });
                set_scroll_div_height(true);
              }
            }}
          >
            {scroll_div_height ? (
              <KeyboardDoubleArrowDownIcon sx={{ color: "#78ffd3" }} />
            ) : (
              <KeyboardDoubleArrowUpIcon sx={{ color: "#78ffd3" }} />
            )}
          </button>
        ) : null}
      </FormControl>
      <DialogAlert
        open_dialog={open_dialog}
        set_open_dialog={set_open_dialog}
        show_filter_dialog={show_filter_dialog}
      />
    </>
  );
}

Categories.propTypes = {
  postCategory: PropTypes.array,
  setPostCategory: PropTypes.func,
  container_type: PropTypes.string,
};

export default Categories;

import {
  CHANGE_STATE,
  SIGNUP,
  LOGOUT,
  FORUM_DATA,
  THEME,
  INV_DATA,
  NAV_CHANGER,
  HOME_INDV_FILTER,
  GET_USER_DATA,
  FILT_DATA,
  UPDATE_USER,
  NAV_USER,
  LOGIN,
  LOADING,
  SEARCH_DATA,
  CHART_INFO_DATA,
  SEARCH_DATA_ERROR,
  CHART_DATA,
  POST_FILT_DATA,
  SHOW_MAIN_DASHBOARD,
} from "../actions/types";

const intialState = {
  boot_active: true,
  update_user: false,
  user_dat: null,
  email: "",
  ratelimit: "",
  superadmin: "",
  tokensgenerated: "",
  username: "",
  userid: "",
  password: "None",
  user_name: "",
  redirect: false,
  signup_redirect: false,
  login_redirect: false,
  admin_status: false,
  company_status: "",
  all_data: null,
  conv_data: null,
  search: "",
  regex: false,
  date_filter: false,
  date_filter_status: false,
  prev_date: "None",
  end_date: "None",
  post_filter: false,
  post_view_num: 0,
  post_alt_filt: false,
  no_post_filter: false,
  no_post: 100,
  content_filter: "post search",
  front_content_filter: "post search",
  no_post_filter_status: false,
  modal_data: [],
  data_sort: "high",
  show_filt: "grid",
  sort_order: "desc",
  rate_limit_status: false,
  rate_limit: "",
  loading: false,
  stats: false,
  json_form: false,
  word_filter: [],
  or_filt: false,
  word_filt_status: "None",
  user_filt_status: "username",
  dt_data: [],
  dt_channels: [],
  channels: [],
  data: [],
  search_next_id: null,
  forums_search: null,
  tot_channel: null,
  tot_groups: null,
  top_channel_name: null,
  top_channel_value: null,
  top_group_name: null,
  top_group_value: null,
  chart_default: "user",
  copied_clipboard: false,
  or_filt_status: "AND",
  post_filters: [],
  srch_filter: "Channel/Group",
  data_display: "grid view",
  front_data_display: "grid view",
  multimedia_option: "disabled",
  display_info: true,
  dark_theme: true,
  sidebar_activ: true,
  phone_filter: "False",
  search_category: "all",
  total_post: 0,
  load_more_loading: true,
  load_more_count: 0,
  other_search_filter: "forum search",
  user_channel_filter: [],
  fuzziness: 0,
  search_filter: "contains",
  company_dashboard: false,
  options: {
    chart: {
      id: "apexchart-example",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "monospace",
          fontWeight: 400,
          colors: [
            "#FF7EA5",
            "#00FA9A",
            "#4EE2EC",
            "#C388F6",
            "#FF7EA5",
            "#00FA9A",
            "#4EE2EC",
            "#C388F6",
            "#FF7EA5",
            "#00FA9A",
          ],
        },
      },
    },
    colors: ["#FF7EA5", "#00FA9A", "#4EE2EC", "#C388F6"],
  },
  series: [
    {
      name: "Messages",
      data: [],
    },
  ],
};

export default function user_reducer(state = intialState, action) {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        ...state,
        user_dat: action.data,
        email: action.data.email,
        ratelimit: action.data.ratelimit,
        superadmin: action.data.superadmin,
        tokensgenerated: action.data.tokensgenerated,
        userid: action.data.userid,
        username: action.data.username,
      };
    case NAV_CHANGER:
      return {
        ...state,
        sidebar_activ: action.value,
      };
    case INV_DATA:
      return {
        ...state,
        userid: action.userid,
      };
    case THEME:
      return {
        ...state,
        dark_theme: action.value,
      };
    case CHANGE_STATE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case UPDATE_USER:
      return {
        ...state,
        update_user: action.update_user,
      };
    case NAV_USER:
      return {
        ...state,
        user_name: action.user_name,
        redirect: action.redirect,
      };
    case LOGOUT:
      return {
        ...state,
        redirect: true,
      };
    case SIGNUP:
      return {
        ...state,
        signup_redirect: action.signup_redirect,
      };
    case LOGIN:
      return {
        ...state,
        admin_status: action.admin_status,
        login_redirect: true,
        company_status: action.company_status,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_DATA:
      return {
        ...state,
        all_data: action.all_data,
        conv_data: action.conv_data,
        loading: action.loading,
        rate_limit: action.rate_limit,
        json_data: action.json_data,
        total_post: action.total_post,
        search_next_id: action.search_next_id,
        load_more_loading: false,
        load_more_count: action.load_more_count,
      };
    case FORUM_DATA:
      return {
        ...state,
        forums_search: action.forums_search,
        loading: action.loading,
        rate_limit: action.rate_limit,
        all_data: [],
      };
    case CHART_INFO_DATA:
      return {
        ...state,
        top_channel_name: action.top_channel_name,
        top_channel_value: action.top_channel_value,
        top_group_name: action.top_group_name,
        top_group_value: action.top_group_value,
        tot_channel: action.tot_channel,
        tot_groups: action.tot_groups,
      };
    case SEARCH_DATA_ERROR:
      return {
        ...state,
        all_data: action.all_data,
        rate_limit_status: action.rate_limit_status,
        loading: action.loading,
        total_post: action.total_post,
      };
    case CHART_DATA:
      return {
        ...state,
        series: action.series,
        options: action.options,
        channels: action.channels,
        data: action.data,
      };
    case HOME_INDV_FILTER:
      return {
        ...state,
        [action.name]: action.value,
      };
    case FILT_DATA:
      return {
        ...state,
        all_data: action.all_data,
        loading: action.loading,
      };
    case POST_FILT_DATA:
      return {
        ...state,
        post_filters: action.value,
      };
    case SHOW_MAIN_DASHBOARD:
      return {
        ...state,
        company_dashboard: action.dashboard_status,
      };
    default:
      return state;
  }
}

import React from "react";
import PropTypes from "prop-types";
import "../../../../css/Dashboard/company_dashboard.css";

function TransparentContainer({ container, heading }) {
  return (
    <div className="company_stats_graph_div">
      <div>
        {heading ? (
          <div className="card-header">
            <div
              className="stats_title_color"
              style={{
                background: "none",
                textTransform: "capitalize",
              }}
            >
              <span style={{ fontSize: "20px", marginLeft: "1rem" }}>
                {heading}
              </span>
            </div>
          </div>
        ) : null}
        <div className="transparent_div_container">{container}</div>
      </div>
      <div className="card_arrow">
        <div className="card_arrow_top_left" />
        <div className="card_arrow_top_right" />
        <div className="card_arrow_bottom_left" />
        <div className="card_arrow_bottom_right" />
      </div>
    </div>
  );
}
TransparentContainer.propTypes = {
  container: PropTypes.any,
  heading: PropTypes.string,
};

export default TransparentContainer;

import PropTypes from "prop-types";
import React from "react";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { FaSignOutAlt, FaSortDown } from "react-icons/fa";
import { connect } from "react-redux";
import { logout } from "../../actions/userAction";
import { handle_clear_localStorage } from "../../utils/SessionStorage/handle_session";

function Adminmainnav({ users, logout }) {
  const customer_name = localStorage.getItem("User");
  return (
    <nav
      className="navbar navbar-expand-sm navbar-light text-center"
      style={
        users.dark_theme
          ? {
              boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
              background: " #22313a",
              height: "65px",
              marginTop: "-10px",
            }
          : {
              boxShadow: "0 3px 10px rgb(0 0 0 / 0.1)",
              background: " white",
              height: "65px",
              marginTop: "-10px",
            }
      }
    >
      <div className="container text-center ">
        <Link to="/admin">
          <a href="#/" className="navbar-brand text-center" id="tesset">
            <b
              style={
                users.dark_theme ? { color: "#20dfcd" } : { color: "#066E80" }
              }
            />
          </a>
        </Link>

        <div
          className="navbar-collapse collapse"
          id="navbarCollapse"
          style={{ position: "absolute", right: "15%" }}
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <div className="dropdown" style={{ display: "flex" }}>
                <img
                  alt="propfpic"
                  className="userprof"
                  src="https://img.icons8.com/bubbles/100/000000/user.png"
                />
                <span
                  className="nav-link"
                  style={
                    users.dark_theme
                      ? {
                          textTransform: "capitalize",
                          background: "#22313a",
                          cursor: "pointer",
                        }
                      : {
                          textTransform: "capitalize",
                          background: "white",
                          cursor: "pointer",
                        }
                  }
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {/* Welcome {this.state.username} */}

                  <div
                    style={{
                      display: "flex",
                      position: "absolute",
                      left: "45px",
                      top: "5px",
                    }}
                  >
                    <div
                      className="nav-item"
                      style={{ marginLeft: "8px", textTransform: "capitalize" }}
                    >
                      <span
                        className="proftext"
                        style={
                          users.dark_theme
                            ? { color: "#20dfcd" }
                            : { color: "#5a5a5a" }
                        }
                      >
                        {" "}
                        {customer_name}
                      </span>
                    </div>
                    <IconContext.Provider
                      value={{
                        size: 18,
                        background: "white",
                        color: "#8d8d8d",
                      }}
                    >
                      <FaSortDown />
                    </IconContext.Provider>
                  </div>
                </span>
                <ul
                  className="dropdown-menu"
                  style={
                    users.dark_theme
                      ? {
                          background: "#22313a",
                          color: "#9bbcd1",
                          marginLeft: "30px",
                        }
                      : { background: "white", color: "black" }
                  }
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link to="/userprof" style={{ width: "0px" }}>
                    <li>
                      <span
                        style={
                          users.dark_theme
                            ? { color: "#9bbcd1" }
                            : { color: "black" }
                        }
                        className="dropdown-item"
                        href="#"
                      >
                        Profile
                      </span>
                    </li>
                  </Link>
                  <li>
                    <span
                      className="dropdown-item"
                      href="#"
                      style={
                        users.dark_theme
                          ? {
                              paddingLeft: "15px",
                              fontSize: "15px",
                              color: "#9bbcd1",
                            }
                          : {
                              paddingLeft: "15px",
                              fontSize: "15px",
                              color: "black",
                            }
                      }
                      onClick={() => {
                        logout();
                        sessionStorage.clear();
                        handle_clear_localStorage();
                        window.location.href = "/";
                      }}
                    >
                      Log Out
                      <div style={{ display: "inline", float: "right" }}>
                        <IconContext.Provider
                          value={{
                            size: 12,
                            background: "white",
                            color: "#8d8d8d",
                          }}
                        >
                          <FaSignOutAlt />
                        </IconContext.Provider>
                      </div>
                    </span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

Adminmainnav.propTypes = {
  logout: PropTypes.func,
  users: PropTypes.object,
};
const mapStateToProps = (state) => ({
  users: state.users,
});

export default connect(mapStateToProps, { logout })(Adminmainnav);

/* eslint-disable no-undef */
import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import ModalContainer from "../modalContainer/ModalContainer";
import PreferenceType from "../modalContainer/PreferenceType";
import Highlighter from "react-highlight-words";
import { GlobalStyles, Stack, Grid, Avatar, Box } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import BlockIcon from "@mui/icons-material/Block";
import NotificationsIcon from "@mui/icons-material/Notifications";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import PushPinIcon from "@mui/icons-material/PushPin";
import BlindsClosedIcon from "@mui/icons-material/BlindsClosed";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider, { SliderTooltip } from "rc-slider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { link_website } from "../../../Assets/globalfunctions";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useSelector } from "react-redux";
import ModalChannel from "../modalContainer/ModalChannel";
import DownloadFileOptions from "../modalContainer/DownloadFileOptions";
import { participants } from "../../../Assets/globalfunctions";
import { sort_cloumn_datewise } from "../../../utils/Globals/global_functions";
import { rename_all_categories_name } from "../../../utils/Globals/global_functions";
import {
  handle_session_get,
  handle_disable_radio_btn,
} from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import {
  handle_success_message,
  handle_error_message,
} from "../../../utils/ResponseMessage/response_message";
import {
  delete_custom_preference,
  get_custom_preference,
} from "../../../utils/Fetch/fetchApi";
import "../../../css/Table/tabledata.css";

/*
NOTE: This component handles the Table 
of Post, User, File and Profile search of the Telegram search
*/
function DataTable({
  teleSearch,
  Data,
  getSearchedData,
  postSearch_props,
  postsearch_sub,
  telegramOption_post_props,
  telegramOption_user,
  telegramOption_channel,
  total_db_data,
  telegram_limit,
  set_telegram_limit,
  file_id,
  user_group,
  getData,
  loadmore_loading,
  keyword,
  proximity,
  post_no,
  nested_channel_regex,
  hybrid_channels,
  set_hybrid_channels,
  paginationValue,
  setPaginationValue,
  table_mode,
}) {
  const token = handle_session_get("Token");
  const user_role =
    handle_session_get("admin_status") === "company_admin" ||
    handle_session_get("admin_status") === "super_admin";
  const arrayLogical = useSelector(
    (state) => state.filter_reducer.array_logical,
  );
  const bulk_search_array = useSelector(
    (state) => state.filter_reducer.bulk_search_array,
  );
  const trans_lang = useSelector((state) => state.filter_reducer.trans_lang);
  const user_name = handle_session_get("User");
  const [user_table_row_id, set_user_table_row_id] = useState([]);

  const [openModal, setOpenModal] = useState(false);
  const [open_notification_modal, set_open_notification_modal] =
    useState(false);
  const [open_modal_pref, set_open_modal_pref] = useState({
    channel_name: "",
    status: false,
    pref_type: "telegram_channel",
  });
  const [open_blocklist_modal, set_open_blocklist_modal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [clone_array_logical, set_clone_array_logical] = useState([]);
  const [open_modal_channel, set_open_modal_channel] = useState(false);
  const [notification_data, set_notification_data] = useState([]);
  const [notificaton_raw, set_notification_raw] = useState([]);

  // State for the notificatin modal
  const [row_link, set_row_link] = useState();
  const [row_name, set_row_name] = useState();
  const [interval_type, set_interval_type] = useState("day");
  const [interval_number, set_interval_number] = useState(1);
  const [is_checked, set_is_checked] = useState(false);
  const [is_checked_blocklist, set_is_checked_blocklist] = useState(false);
  const [blocklist_value, set_blocklist_value] = useState();
  const [blocklist_type, set_blocklist_type] = useState();
  const [block_type, set_block_type] = useState("search");

  const { Handle } = Slider;
  const wrapperStyle = { width: 250, marginLeft: "25px" };

  const handle = (props) => {
    const {
      // eslint-disable-next-line react/prop-types
      value,
      // eslint-disable-next-line react/prop-types
      dragging,
      // eslint-disable-next-line react/prop-types
      index,
      ...restProps
    } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} `}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  const text_index = (text) => {
    try {
      if (text && text.length > 0) {
        if (text.toUpperCase().indexOf(getSearchedData.toUpperCase()) > 0) {
          return true;
        }
        return false;
      }
      return false;
    } catch {
      //Do nothing
    }
  };

  const load_more = () => {
    try {
      const filters_array = JSON.parse(handle_session_get("filters_array"));
      const {
        searchedData,
        new_tele,
        date_data,
        telegramOption_post,
        telegramOption_user,
        user_group,
        userFilter,
        postSearch,
        postSearchSub,
        keyword,
        postCategory,
        multimedia,
        postNo,
        dataDisplay,
        dataOrder,
        spamFilter,
        proximity,
        language_array,
        checked_exclude,
        telegramOption_channel,
        channels_list,
      } = filters_array;
      getData(
        searchedData,
        new_tele,
        date_data,
        telegramOption_post,
        telegramOption_user,
        user_group,
        userFilter,
        postSearch,
        postSearchSub,
        keyword,
        postCategory,
        multimedia,
        postNo,
        dataDisplay,
        dataOrder,
        spamFilter,
        proximity,
        language_array,
        checked_exclude,
        telegramOption_channel,
        channels_list,
      );
    } catch {
      //Do nothing
    }
  };
  const handle_close_notification_modal = () => {
    set_open_notification_modal(false);
  };
  const handle_open_notification_modal = (link, name) => {
    set_open_notification_modal(true);
    set_row_link(link);
    set_row_name(name);
  };
  // function for creating the notification alert from the modal
  const create_notification_alert = () => {
    set_open_notification_modal(false);
    if (is_checked === true) {
      localStorage.setItem(
        `${user_name}_interval_array`,
        JSON.stringify(interval_array),
      );
    }
    setTimeout(() => notification_alert(row_link, row_name), 500);
  };
  const handleOpenModal = (required_row) => {
    setOpenModal(true);
    setSelectedRow(required_row);
  };

  const handleOpenModalChannel = (required_row) => {
    set_open_modal_channel(true);
    setSelectedRow(required_row);
  };

  const [pageSize, setPageSize] = useState(25);
  useEffect(() => {
    notification_extractor();
    get_custom_preference(set_hybrid_channels, "table");
    set_clone_array_logical([...arrayLogical]);
  }, []);

  // Delete channel
  const data_delete = (channel_name) => {
    let new_channel_name = null;
    if (channel_name !== null) {
      if (channel_name.includes("t.me/")) {
        const array = channel_name.split("/");
        const splitted_value = array[array.length - 1];
        new_channel_name = splitted_value.toLowerCase();
      } else {
        new_channel_name = channel_name.toLowerCase();
      }
    }
    const channel_id = notificaton_raw.filter(
      (val) => val.keyword_entered === new_channel_name,
    );
    if (channel_id.length > 0) {
      const data = { hash_id: channel_id[0].id };
      axios
        .post(
          "https://dev.recordedtelegram.com/v2/delete_user_notification",
          data,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          if (res.data.data.length > 0) {
            const new_value = res.data.data.map((item) =>
              item.keyword_entered.toLowerCase(),
            );
            set_notification_data(new_value);

            const raw_data = res.data.data.map((item) => {
              item.keyword_entered = item.keyword_entered.toLowerCase();
              return item;
            });
            set_notification_raw(raw_data);
          }
          handle_success_message(`Alert for ${channel_name} has been removed.`);
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          handle_error_message(
            "Sorry could not delete the data. Please contact service provider",
          );
        });
    }
  };

  const interval_array = [interval_type, interval_number];

  // function for the date
  const date_fm = (dt) => {
    if (dt !== undefined) {
      const new_dt = dt.split("T");
      const sp_dt = new_dt[0].split("-");
      const new_sp_month = `0${parseInt(sp_dt[1]) - 1}`;

      const date = new Date(sp_dt[0], new_sp_month, sp_dt[2]); // 2009-11-10

      const month = date.toLocaleString("default", { month: "long" });
      const mod_month = month.substring(0, 3);
      const ret_dt = `${mod_month}-${sp_dt[2]}-${sp_dt[0]}`;
      return ret_dt;
    }
    return null;
  };

  // Function for the type is group or category
  const channel_type = (typ) => {
    if (typ !== undefined) {
      const channel_type = typ === "True" ? "Group" : "Channel";
      return channel_type;
    }
    return null;
  };

  // Highlight Name
  const highlight_name = (words) => {
    if (postSearch_props === "logical" || trans_lang.length > 0) {
      try {
        let required_string = "";
        const filter_array = trans_lang.length > 0 ? trans_lang : arrayLogical;
        filter_array.map((item) => {
          const result = words.toUpperCase().indexOf(item.toUpperCase());
          if (result >= 0) {
            required_string += `${words.slice(
              result >= 20 ? result - 15 : 0,
              result + 50,
            )}  .....  `;
          }
          return null;
        });

        return required_string.length === 0 ? words : required_string;
      } catch {
        return words;
      }
    }

    if (words) {
      try {
        const result = words
          .toUpperCase()
          .indexOf(getSearchedData.toUpperCase());

        if (result <= 25) {
          return words;
        }
        return words.slice(result - 10, result + 80);
      } catch {
        return words;
      }
    } else {
      return null;
    }
  };

  // Highlight message for the post and file
  const highlight_message = (messages) => {
    let words = "";
    try {
      words = messages.replace(/\s+/g, " ").trim();
    } catch {
      words = messages;
    }
    if (
      (postSearch_props === "logical" && trans_lang.length === 0) ||
      (postSearch_props === "individual" && arrayLogical.length > 1)
    ) {
      try {
        let required_string = "";
        arrayLogical.map((item) => {
          const result = words.toUpperCase().indexOf(item.toUpperCase());
          if (result >= 0) {
            required_string += `${words.slice(
              result >= 20 ? result - 15 : 0,
              result + 80,
            )}  .....  `;
          }
          return null;
        });

        return required_string.length === 0 ? words : required_string;
      } catch {
        return words;
      }
    }

    // Match regex while searching without logical operation
    if (
      (postSearch_props === "regex" && arrayLogical.length < 2) ||
      (postSearch_props === "individual" && nested_channel_regex === true) ||
      handle_exactsearch_highlight(postSearch_props, keyword, getSearchedData)
        .is_exact === true
    ) {
      try {
        const filter_word =
          handle_exactsearch_highlight(
            postSearch_props,
            keyword,
            getSearchedData,
          ).is_exact === true
            ? handle_exactsearch_highlight(
                postSearch_props,
                keyword,
                getSearchedData,
              ).word_regex
            : getSearchedData;
        const re = new RegExp(filter_word.toLowerCase());
        const match = re.exec(words.toLowerCase());
        if (match) {
          if (match.index < 180) {
            return words;
          }
          return words.slice(match.index - 20, words.length);
        }
      } catch (err) {
        return words;
      }
    }
    // Match regex while searching with logical operation
    else if (postSearch_props === "regex" && arrayLogical.length > 1) {
      try {
        let required_string = "";
        arrayLogical.map((item) => {
          const re = new RegExp(item.toLowerCase());
          const result = re.exec(words.toLowerCase());
          if (result) {
            if (result.index >= 0) {
              required_string += `${words.slice(
                result.index >= 20 ? result.index - 15 : 0,
                result.index + 80,
              )}  .....  `;
            }
          }
          return null;
        });

        return required_string.length === 0 ? words : required_string;
      } catch (err) {
        return words;
      }
    }
    // Match Translation
    if (
      (teleSearch === "post" || teleSearch === "file") &&
      (postSearch_props === "None" || postSearch_props === "logical") &&
      trans_lang.length > 0
    ) {
      try {
        let required_string = "";
        trans_lang.map((item) => {
          const result = words.toUpperCase().indexOf(item.toUpperCase());
          if (postSearch_props === "logical") {
            if (result >= 0) {
              required_string += `${words.slice(
                result >= 20 ? result - 15 : 0,
                result + 80,
              )}  .....  `;
            }
          } else {
            if (result > 0 && result <= 180) {
              required_string = words;
            } else if (result > 180) {
              required_string = words.slice(result - 50, result + words.length);
            }
          }
          return null;
        });
        return required_string.length === 0 ? words : required_string;
      } catch (err) {
        return words;
      }
    }
    if (
      (teleSearch === "post" || teleSearch === "file") &&
      postSearch_props === "bulk"
    ) {
      try {
        let required_string = "";
        bulk_search_array.map((item) => {
          const result = words.toUpperCase().indexOf(item.toUpperCase());
          if (result >= 0) {
            required_string += `${words.slice(
              result >= 20 ? result - 15 : 0,
              result + 100,
            )}  .....  `;
          }
          return null;
        });

        return required_string.length === 0 ? words : required_string;
      } catch {
        return words;
      }
    } else {
      try {
        const result = words
          .toUpperCase()
          .indexOf(getSearchedData.toUpperCase());

        if (result <= 180) {
          return words;
        }
        return words.slice(result - 20, words.length);
      } catch {
        return words;
      }
    }
  };
  // Check data for the notification
  const split_link_notification = (link_notify) => {
    if (link_notify !== null) {
      if (link_notify.includes("t.me/")) {
        const array = link_notify.split("/");
        const splitted_value = array[array.length - 1];
        return splitted_value.toLowerCase();
      }
      return link_notify.toLowerCase();
    }
  };
  // Hit for the alert
  const notification_alert = (link, channel_name) => {
    const get_interval_array = JSON.parse(
      localStorage.getItem(`${user_name}_interval_array`),
    );
    const data = {
      interval_number: get_interval_array
        ? get_interval_array[1]
        : interval_number,
      interval_type: get_interval_array ? get_interval_array[0] : interval_type,
      keyword_to_watch: link,
      notification_status: "True",
      notification_type: "channel",
      regex_status: "False",
      tag_name: "None",
    };
    axios
      .post("https://dev.recordedtelegram.com/v2/create_notifications", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          const new_value = res.data.data.map((item) =>
            item.keyword_entered.toLowerCase(),
          );
          set_notification_data(new_value);

          const raw_data = res.data.data.map((item) => {
            item.keyword_entered = item.keyword_entered.toLowerCase();
            return item;
          });
          set_notification_raw(raw_data);
        }
        handle_success_message(`Alert for ${channel_name} activated`);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };
  // Carried out when the block icon is clicked from the action column of the table
  const handle_open_blocklist_modal = (val, type) => {
    set_open_blocklist_modal(true);
    set_blocklist_value(val);
    set_blocklist_type(type);
  };

  // Add the channel to blocklist from modal of blocklist after clicking the block button
  const create_add_blocklist = () => {
    set_open_blocklist_modal(false);
    if (is_checked_blocklist === true) {
      localStorage.setItem(
        `${user_name}_blocklist_type`,
        JSON.stringify(block_type),
      );
    }
    setTimeout(() => Add_blocklist(blocklist_value, blocklist_type), 500);
  };
  // Function to add the channel for blocklist
  const Add_blocklist = (val, search_type) => {
    const get_block_type = JSON.parse(
      localStorage.getItem(`${user_name}_blocklist_type`),
    );
    const data = {
      block_status: get_block_type || block_type,
      search_type,
      qtext: val,
    };

    axios
      .post("https://dev.recordedtelegram.com/v2/add_blocklist", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        handle_success_message("Added Sucessfully");
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message("Failed to Add");
      });
  };

  // Split the each item of trans_lang array according to space
  const split_trans_lang = (filter_space) => {
    try {
      let final_lang = [];
      if (filter_space === "enable") {
        const new_arr = trans_lang.map((val) => {
          if (val.includes(" ")) {
            const replace_space = val.replace(" ", ".{1}");
            return replace_space;
          } else {
            return val;
          }
        });
        final_lang = [...new Set(new_arr)];
      } else {
        final_lang = [...new Set(trans_lang)];
      }
      return final_lang;
    } catch (err) {
      return trans_lang;
    }
  };
  // Custom tooltip
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#2b3c46",
      maxWidth: "max-content",
      fontSize: theme.typography.pxToRem(12),
    },
  }));

  const handle_remove_white_space_check_equal = (texts, word) => {
    try {
      if (texts && texts.length > 0) {
        return texts.trim() == word.trim();
      } else return false;
    } catch {
      return false;
    }
  };

  const alert_display = (link) => {
    if (link !== null) {
      if (link.includes("t.me")) {
        return true;
      }
    }
    return false;
  };
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  function containsSpecialCharacters(string) {
    return /[.*+?^${}()|[\]\\]/.test(string);
  }
  const handle_exactsearch_highlight = (postSearch_props, keyword, query) => {
    try {
      if (
        (postSearch_props === "None" || postSearch_props === "individual") &&
        keyword === "exact"
      ) {
        let regex;
        if (containsSpecialCharacters(query)) {
          const escapedQuery = escapeRegExp(query);
          regex = `(^|\\s)${escapedQuery}($|\\s)`;
        } else {
          regex = `\\b${query}\\b`;
        }
        return {
          is_exact: true,
          // eslint-disable-next-line no-useless-escape
          word_regex: regex ?? "",
        };
      }
      return {
        is_exact: false,
        word_regex: query,
      };
    } catch {
      return {
        is_exact: false,
        word_regex: query,
      };
    }
  };

  const columns_post_file = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Name",
      width: 145,

      renderCell: (cellValues) => (
        <>
          {teleSearch === "file" || teleSearch === "post" ? (
            <Stack alignItems="flex-start">
              <Grid container>
                <Grid
                  item
                  xs={
                    postSearch_props === "None" ||
                    postSearch_props === "logical"
                      ? cellValues.row.all_data.lang
                        ? cellValues.row.all_data.lang.length !== 0
                          ? 9
                          : 12
                        : 12
                      : 12
                  }
                  sx={{
                    overflow: "hidden",
                    wordBreak: "break-all",
                    overflowWrap: "break-word",
                  }}
                >
                  <button
                    disabled={
                      !(
                        cellValues.row.all_data.link !== null &&
                        cellValues.row.all_data.link !== "None"
                      )
                    }
                    className={
                      cellValues.row.all_data.link !== null &&
                      cellValues.row.all_data.link !== "None"
                        ? "filter_file_link"
                        : "filter_file_link filter_file_link_disabled"
                    }
                    onClick={() =>
                      file_link_adder(
                        cellValues.row.all_data.link,
                        cellValues.row.all_data.msgid,
                      )
                    }
                  >
                    {teleSearch === "file" ||
                    ((telegramOption_post_props === "None" ||
                      telegramOption_post_props === "conv_name") &&
                      teleSearch === "post") ? (
                      <Highlighter
                        highlightClassName="highlightclass"
                        searchWords={
                          teleSearch === "post" && trans_lang.length > 0
                            ? split_trans_lang("disable")
                            : postSearch_props === "logical" ||
                              (postSearch_props === "regex" &&
                                arrayLogical.length > 1)
                            ? clone_array_logical
                            : postSearch_props === "bulk"
                            ? bulk_search_array
                            : handle_exactsearch_highlight(
                                postSearch_props,
                                keyword,
                                getSearchedData,
                              ).is_exact === true
                            ? [
                                handle_exactsearch_highlight(
                                  postSearch_props,
                                  keyword,
                                  getSearchedData,
                                ).word_regex,
                              ]
                            : text_index(cellValues.row.name) === true
                            ? [getSearchedData]
                            : postSearch_props === "regex"
                            ? [getSearchedData]
                            : [getSearchedData]
                        }
                        autoEscape={
                          !(
                            postSearch_props == "regex" ||
                            handle_exactsearch_highlight(
                              postSearch_props,
                              keyword,
                              getSearchedData,
                            ).is_exact === true
                          )
                        }
                        textToHighlight={highlight_name(cellValues.row.name)}
                        className="text_hidden"
                      />
                    ) : (
                      <div className="text_hidden">{cellValues.row.name}</div>
                    )}
                  </button>
                </Grid>
                <Grid
                  item
                  xs={
                    postSearch_props === "None" ||
                    postSearch_props === "logical"
                      ? cellValues.row.all_data.lang
                        ? cellValues.row.all_data.lang.length !== 0
                          ? 2
                          : 0
                        : 0
                      : 0
                  }
                >
                  {postSearch_props === "None" ||
                  postSearch_props === "logical" ? (
                    cellValues.row.all_data.lang ? (
                      cellValues.row.all_data.lang.length !== 0 ? (
                        <div>
                          <HtmlTooltip
                            title={cellValues.row.all_data.lang.map(
                              (item, index) => (
                                <div className="flags_tooltip" key={index}>
                                  {item.translated_lang && (
                                    <img
                                      width="20.5411"
                                      height="20"
                                      src={`${process.env.PUBLIC_URL}/static/images/flags/${item.translated_lang}.png`}
                                      style={{ objectFit: "cover" }}
                                      alt="flag"
                                    />
                                  )}
                                  <p style={{ color: "#dcf3ff", margin: 0 }}>
                                    {item.translated_lang_name}
                                  </p>
                                </div>
                              ),
                            )}
                          >
                            <img
                              width="20.5411"
                              height="20"
                              src={`${process.env.PUBLIC_URL}/static/images/flags/${cellValues.row.all_data.lang[0].translated_lang}.png`}
                              style={{ objectFit: "cover" }}
                              alt="flag"
                            />
                          </HtmlTooltip>
                        </div>
                      ) : null
                    ) : null
                  ) : null}
                </Grid>
              </Grid>
              <div className="column_type">
                {cellValues.row.type}
                <span className="column_type_category">{`(${rename_all_categories_name(
                  cellValues.row.all_data.category,
                )})`}</span>
              </div>
            </Stack>
          ) : null}
        </>
      ),
    },

    {
      field: "date",
      headerName: "Date",
      width: 116,
      type: "date",
      sortComparator: sort_cloumn_datewise,
      renderCell: (cellValues) => {
        return (
          <p className="text_hidden text-nowrap m-0 p-0">
            {cellValues.row.date}
          </p>
        );
      },
    },

    {
      field: "filename",
      headerName: "File Name",
      width: 410,

      renderCell: (cellValues) => {
        return (
          <>
            <Highlighter
              highlightClassName="highlightclass"
              searchWords={
                postSearch_props === "logical" && trans_lang.length === 0
                  ? clone_array_logical
                  : postSearch_props === "bulk"
                  ? bulk_search_array
                  : postSearch_props === "regex"
                  ? [getSearchedData]
                  : trans_lang.length > 0
                  ? split_trans_lang("enable")
                  : handle_exactsearch_highlight(
                      postSearch_props,
                      keyword,
                      getSearchedData,
                    ).is_exact === true
                  ? [
                      handle_exactsearch_highlight(
                        postSearch_props,
                        keyword,
                        getSearchedData,
                      ).word_regex,
                    ]
                  : text_index(cellValues.row.filename) === true
                  ? [getSearchedData]
                  : getSearchedData.split(" ")
              }
              autoEscape={
                !(
                  postSearch_props === "regex" ||
                  (postSearch_props === "individual" &&
                    nested_channel_regex === true) ||
                  trans_lang.length > 0 ||
                  handle_exactsearch_highlight(
                    postSearch_props,
                    keyword,
                    getSearchedData,
                  ).is_exact === true
                )
              }
              textToHighlight={cellValues.row.filename}
              className="text_hidden"
            />
          </>
        );
      },
    },

    {
      field: "fileextension",
      headerName: "File Ext",
      width: 100,
    },
    {
      field: "user_id",
      headerName: "User",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 57,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.user_id !== "None" ? (
            <Link
              className="user_id_link"
              to={`/users?page=${cellValues.row.user_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Tooltip title="view profile" disableInteractive>
                <GroupsIcon sx={{ color: "#37b992" }} />
              </Tooltip>
            </Link>
          ) : (
            <div className="text_hidden">
              <PersonOffIcon sx={{ color: "#999" }} />
            </div>
          )}
        </>
      ),
    },
    {
      field: "seemore",
      headerName: "Message",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (cellValues) => {
        const check_filename = (file, file_type) => {
          if (file_type === "filename") {
            try {
              if (file) {
                if (
                  file !== "None" &&
                  file.includes("SIZE_EXCEEDED_NOT_DOWNLOADED") === false
                ) {
                  return true;
                }
                return false;
              }
            } catch {
              return false;
            }
          } else if (file_type === "fileext") {
            try {
              if (file) {
                if (
                  file === "None" ||
                  file.includes("SIZE_EXCEEDED_NOT_DOWNLOADED") === true
                ) {
                  return true;
                }
                return false;
              }
            } catch {
              return false;
            }
          }
        };
        return (
          <>
            {teleSearch === "post" && !cellValues.row.message ? (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Avatar
                  sx={{ bgcolor: "#3290a1", width: "33px", height: "33px" }}
                >
                  <InsertDriveFileIcon
                    fontSize="small"
                    sx={{ color: "#c2d2dd" }}
                  />
                </Avatar>
                <Stack alignItems="start">
                  {check_filename(
                    cellValues.row.all_data.filename,
                    "filename",
                  ) === true ? (
                    <span className="text_hidden">
                      {cellValues.row.all_data.filename}
                    </span>
                  ) : (
                    <span className="text_hidden">No File Name</span>
                  )}
                  {check_filename(
                    cellValues.row.all_data.filename,
                    "fileext",
                  ) === true ? (
                    <span className="text_hidden">
                      {cellValues.row.all_data.fileext}
                    </span>
                  ) : null}
                </Stack>
              </Stack>
            ) : (telegramOption_post_props === "None" ||
                telegramOption_post_props === "message") &&
              (teleSearch === "post" || teleSearch === "file") ? (
              <Highlighter
                highlightClassName="highlightclass"
                searchWords={
                  (postSearch_props === "logical" && trans_lang.length === 0) ||
                  (postSearch_props === "individual" &&
                    arrayLogical.length > 1) ||
                  (postSearch_props === "regex" && arrayLogical.length > 1)
                    ? clone_array_logical
                    : postSearch_props === "bulk"
                    ? bulk_search_array
                    : postSearch_props === "regex"
                    ? [getSearchedData]
                    : teleSearch === "post" &&
                      keyword === "exact" &&
                      proximity === "0"
                    ? trans_lang.length > 0
                      ? trans_lang
                      : [getSearchedData]
                    : trans_lang.length > 0
                    ? split_trans_lang("enable")
                    : handle_exactsearch_highlight(
                        postSearch_props,
                        keyword,
                        getSearchedData,
                      ).is_exact === true
                    ? [
                        handle_exactsearch_highlight(
                          postSearch_props,
                          keyword,
                          getSearchedData,
                        ).word_regex,
                      ]
                    : [getSearchedData]
                }
                // searchWords={postSearch_props === "logical" ? clone_array_logical : [getSearchedData]}

                autoEscape={
                  !(
                    postSearch_props === "regex" ||
                    (postSearch_props === "individual" &&
                      nested_channel_regex === true) ||
                    trans_lang.length > 0 ||
                    handle_exactsearch_highlight(
                      postSearch_props,
                      keyword,
                      getSearchedData,
                    ).is_exact === true
                  )
                }
                textToHighlight={highlight_message(cellValues.row.message)}
                // textToHighlight={(cellValues.row.message)}
                className="text_hidden"
              />
            ) : (
              <div className="text_hidden">{cellValues.row.message}</div>
            )}
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 115,
      disableColumnMenu: true,
      colSpan: 2,
      renderCell: (cellValues) => (
        <Stack
          alignItems="flex-start"
          justifyContent="center"
          alignContent="center"
        >
          <Stack direction="row" justifyContent="center">
            <button
              className="alert_bell_icon_btn"
              onClick={() => handleOpenModal(cellValues.row.all_data)}
            >
              <Tooltip title="see more" disableInteractive>
                <IconButton>
                  <VisibilityIcon sx={{ color: "#9bbcd1" }} />
                </IconButton>
              </Tooltip>
            </button>

            {/* Icon for the channel block list */}
            <button
              className="alert_bell_icon_btn"
              onClick={() =>
                localStorage.getItem(`${user_name}_blocklist_type`)
                  ? Add_blocklist(cellValues.row.name, "channel name")
                  : handle_open_blocklist_modal(
                      cellValues.row.name,
                      "channel name",
                    )
              }
            >
              <Tooltip title="Block" disableInteractive>
                <IconButton>
                  <BlockIcon fontSize="small" sx={{ color: "red" }} />
                </IconButton>
              </Tooltip>
            </button>

            {cellValues.row.type === "Channel" &&
            alert_display(cellValues.row.link) ? (
              <button
                className="alert_bell_icon_btn"
                onClick={() => {
                  notification_data.includes(
                    split_link_notification(
                      cellValues.row.name !== "None"
                        ? cellValues.row.name
                        : cellValues.row.link !== "None"
                        ? cellValues.row.link
                        : cellValues.row.to_id,
                    ),
                  ) === false
                    ? localStorage.getItem(`${user_name}_interval_array`)
                      ? notification_alert(
                          cellValues.row.name !== "None"
                            ? cellValues.row.name
                            : cellValues.row.link !== "None"
                            ? cellValues.row.link
                            : cellValues.row.to_id,
                          cellValues.row.name,
                        )
                      : handle_open_notification_modal(
                          cellValues.row.name !== "None"
                            ? cellValues.row.name
                            : cellValues.row.link !== "None"
                            ? cellValues.row.link
                            : cellValues.row.to_id,
                          cellValues.row.name,
                        )
                    : data_delete(
                        cellValues.row.name !== "None"
                          ? cellValues.row.name
                          : cellValues.row.link !== "None"
                          ? cellValues.row.link
                          : cellValues.row.to_id,
                      );
                }}
              >
                {notification_data.includes(
                  split_link_notification(
                    cellValues.row.name !== "None"
                      ? cellValues.row.name
                      : cellValues.row.link !== "None"
                      ? cellValues.row.link
                      : cellValues.row.to_id,
                  ),
                ) ? (
                  <Tooltip title="Delete alert" disableInteractive>
                    <NotificationsIcon sx={{ color: "yellow" }} />
                  </Tooltip>
                ) : (
                  <Tooltip title="create alert" disableInteractive>
                    <AddAlertIcon sx={{ color: "gray" }} />
                  </Tooltip>
                )}
              </button>
            ) : null}
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Link
              to={
                cellValues.row.name !== "None" && cellValues.row.name !== null
                  ? `/page?page=${cellValues.row.name}&type=channel name`
                  : cellValues.row.link !== "None" &&
                    cellValues.row.link !== null
                  ? `/page?page=${cellValues.row.link}&type=link`
                  : `/page?page=${cellValues.row.to_id}&type=id`
              }
            >
              <Tooltip title="Stats" disableInteractive>
                <IconButton>
                  <QueryStatsIcon fontSize="small" sx={{ color: "#9bbcd1" }} />
                </IconButton>
              </Tooltip>
            </Link>
            <Link
              to={
                cellValues.row.name !== "None" && cellValues.row.name !== null
                  ? `/channel?channel=${cellValues.row.name}&type=channel name`
                  : cellValues.row.link !== "None" &&
                    cellValues.row.link !== null
                  ? `/channel?channel=${cellValues.row.link}&type=link`
                  : `/channel?channel=${cellValues.row.to_id}&type=id`
              }
            >
              <Tooltip title="Posts" disableInteractive>
                <IconButton>
                  <BlindsClosedIcon
                    fontSize="small"
                    sx={{ color: "#9bbcd1" }}
                  />
                </IconButton>
              </Tooltip>
            </Link>
            {[
              ...hybrid_channels["marked_channels"]["user_preference"],
              ...hybrid_channels["marked_channels"]["company_preference"],
            ].length === 0 ||
            [
              ...hybrid_channels["marked_channels"]["user_preference"],
              ...hybrid_channels["marked_channels"]["company_preference"],
            ].some(
              (item) =>
                handle_remove_white_space_check_equal(
                  item["keyword"],
                  cellValues.row.name,
                ) && item["pref_type"] === "telegram_channel",
            ) === false ? (
              <button
                className="alert_bell_icon_btn"
                onClick={() => {
                  set_open_modal_pref({
                    channel_name: cellValues.row.name,
                    status: true,
                    pref_type: "telegram_channel",
                  });
                }}
              >
                <Tooltip title="Mark" disableInteractive>
                  <PushPinIcon sx={{ color: "#9bbcd1" }} />
                </Tooltip>
              </button>
            ) : (
              <button
                className="alert_bell_icon_btn"
                onClick={() => {
                  const detect_prefs = detect_channel_pref_type(
                    hybrid_channels,
                    cellValues.row.name,
                    "telegram_channel",
                  );
                  const { sub_pref_type, dashboard_filter } = detect_prefs;
                  if (sub_pref_type === "user_preference") {
                    delete_custom_preference(
                      cellValues.row.name,
                      "user_preference",
                      dashboard_filter,
                      "telegram_channel",
                      set_hybrid_channels,
                      "table",
                    );
                    return;
                  } else if (
                    sub_pref_type === "company_preference" &&
                    user_role === true
                  ) {
                    delete_custom_preference(
                      cellValues.row.name,
                      "company_preference",
                      dashboard_filter,
                      "telegram_channel",
                      set_hybrid_channels,
                      "table",
                    );
                    return;
                  } else if (
                    sub_pref_type === "company_preference" &&
                    user_role === false
                  ) {
                    handle_error_message(
                      "Only company admin can delete company preference channel.",
                    );
                    return;
                  }
                  return;
                }}
              >
                <Tooltip
                  title={
                    detect_channel_pref_type(
                      hybrid_channels,
                      cellValues.row.name,
                      "telegram_channel",
                    )["sub_pref_type"] === "company_preference" &&
                    user_role === false
                      ? "Only company admin can unmark"
                      : "Unmark"
                  }
                  disableInteractive
                >
                  <PushPinIcon sx={{ color: "yellow" }} />
                </Tooltip>
              </button>
            )}
          </Stack>
        </Stack>
      ),
    },
  ];

  const create_expandable_row = (id, row) => {
    const element = document.querySelector(`[data-id="${id}"]`);
    const main_table = document.querySelector(
      ".MuiDataGrid-virtualScrollerContent",
    );
    if (element) {
      const get_expandable_row = document.getElementById(
        `user_expandable_row_${id}`,
      );
      if (!get_expandable_row) {
        const expandable_row = document.createElement("div");
        const heading = document.createElement("h6");
        heading.textContent = "Also known as";
        heading.setAttribute("class", "expandable_row_heading");
        expandable_row.appendChild(heading);
        expandable_row.setAttribute("id", `user_expandable_row_${id}`);
        expandable_row.setAttribute(
          "class",
          "user_expandable_row scroll-custom",
        );
        if (row.all_data.aliases) {
          if (row.all_data.aliases.length > 0) {
            const table = create_expandable_table(row.all_data.aliases);
            expandable_row.appendChild(table);
          }
        }

        // Insert the new div element just below the existing element
        element.insertAdjacentElement("afterend", expandable_row);
        if (main_table) {
          main_table.style.height = `${
            main_table.getBoundingClientRect().height + 150
          }px`;
        }
        set_user_table_row_id((prev) => [...prev, id]);
      } else {
        get_expandable_row.remove();
        if (main_table) {
          main_table.style.height = `${
            main_table.getBoundingClientRect().height - 150
          }px`;
        }
        set_user_table_row_id((prev) => {
          const filter_id = prev.filter((item) => item !== id);
          return filter_id;
        });
      }
    }
  };

  const create_expandable_table = (user_details) => {
    const table = document.createElement("table");
    const headerRow = document.createElement("tr");
    const headerCell0 = document.createElement("th");
    headerCell0.setAttribute("width", "10%");
    headerCell0.textContent = "SN";

    const headerCell1 = document.createElement("th");
    headerCell1.setAttribute("width", "20%");
    headerCell1.textContent = "user ID";
    const headerCell2 = document.createElement("th");
    headerCell2.setAttribute("width", "20%");
    headerCell2.textContent = "username";

    const headerCell3 = document.createElement("th");
    headerCell3.setAttribute("width", "48%");
    headerCell3.textContent = "fullname";

    headerRow.appendChild(headerCell0);
    headerRow.appendChild(headerCell1);
    headerRow.appendChild(headerCell2);
    headerRow.appendChild(headerCell3);

    table.appendChild(headerRow);
    // Filtered data
    const filter_username = [
      ...handle_filter_expandable_datas(user_details, "username"),
    ];
    const filter_userfullname = [
      ...handle_filter_expandable_datas(filter_username, "fullname"),
    ];
    const filter_dublicate = filter_userfullname.filter(
      (item) => item.username !== item.userfullname,
    );
    const sanitized_dublicate_data =
      user_details.length < 2 ? user_details : filter_dublicate;
    sanitized_dublicate_data.forEach((item, index) => {
      const row = document.createElement("tr");
      const cell1Row0 = document.createElement("td");
      cell1Row0.textContent = index + 1;

      const cell1Row1 = document.createElement("td");
      cell1Row1.textContent = item.userid;

      const cell1Row2 = document.createElement("td");
      cell1Row2.textContent = item.username;

      const cell1Row3 = document.createElement("td");
      cell1Row3.textContent = item.userfullname;

      row.appendChild(cell1Row0);
      row.appendChild(cell1Row1);
      row.appendChild(cell1Row2);
      row.appendChild(cell1Row3);

      table.appendChild(row);
    });
    return table;
  };
  const handle_user_table_width = (pagesize) => {
    if (teleSearch === "user") {
      const main_table = document.querySelector(
        ".MuiDataGrid-virtualScrollerContent",
      );
      try {
        if (user_table_row_id.length > 0) {
          user_table_row_id.forEach((item) => {
            const get_expandable_row = document.getElementById(
              `user_expandable_row_${item}`,
            );
            get_expandable_row.remove();
          });
          main_table.style.height =
            pagesize === 75 ? `${1675}px` : `${67 * pagesize}px`;
          set_user_table_row_id([]);
        }
      } catch {
        set_user_table_row_id([]);
      }
      return true;
    }
    return true;
  };
  const handle_filter_expandable_datas = (array, filter_type) => {
    try {
      const userFullNamesToExclude = new Set();
      const filteredAliases = [];

      for (const alias of array) {
        const userFullName = alias.userfullname.trim();
        const { username } = alias;

        if (filter_type === "username") {
          if (username !== "None") {
            filteredAliases.push(alias);
          } else if (!userFullNamesToExclude.has(userFullName)) {
            userFullNamesToExclude.add(userFullName);
          }
        } else if (userFullName !== "None") {
          filteredAliases.push(alias);
        } else if (!userFullNamesToExclude.has(username)) {
          userFullNamesToExclude.add(username);
        }
      }

      const finalFilteredAliases =
        filter_type === "username"
          ? filteredAliases.filter(
              (alias) =>
                alias.username !== "None" ||
                !userFullNamesToExclude.has(alias.userfullname.trim()),
            )
          : filteredAliases.filter(
              (alias) =>
                alias.userFullName !== "None" ||
                !userFullNamesToExclude.has(alias.username.trim()),
            );
      return finalFilteredAliases;
    } catch {
      return array;
    }
  };

  const columns_user = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "detailPanel",
      headerName: "",
      width: 30,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) =>
        cellValues.row.all_data.aliases &&
        cellValues.row.all_data.aliases.length > 0 ? (
          <button
            className="row_expandable_btn"
            onClick={() =>
              create_expandable_row(cellValues.row.id, cellValues.row)
            }
          >
            {document.getElementById(
              `user_expandable_row_${cellValues.row.id}`,
            ) ? (
              <KeyboardArrowUpIcon fontSize="large" sx={{ color: "#dcf3ff" }} />
            ) : (
              <KeyboardArrowDownIcon
                fontSize="large"
                sx={{ color: "#dcf3ff" }}
              />
            )}
          </button>
        ) : null,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      renderCell: (cellValues) => (
        <>
          {telegramOption_user === "username" ? (
            <Highlighter
              highlightClassName="highlightclass"
              searchWords={
                text_index(cellValues.row.username) === true
                  ? [getSearchedData]
                  : getSearchedData.split(" ")
              }
              autoEscape={postSearch_props !== "regex"}
              textToHighlight={cellValues.row.username ?? "None"}
              className="text_hidden"
            />
          ) : (
            <p className="text_hidden">{cellValues.row.username ?? "None"}</p>
          )}
        </>
      ),
    },

    {
      field: "fullname",
      headerName: "Fullname",
      flex: 1,
      renderCell: (cellValues) => (
        <>
          {["username", "firstname", "lastname", "fullname"].includes(
            telegramOption_user,
          ) === true ? (
            <Highlighter
              highlightClassName="highlightclass"
              searchWords={[getSearchedData]}
              autoEscape={postSearch_props !== "regex"}
              textToHighlight={
                cellValues.row.fullname
                  ? cellValues.row.fullname.trim() || "None"
                  : "None"
              }
              className="text_hidden"
            />
          ) : (
            <p className="text_hidden">
              {cellValues.row.fullname
                ? cellValues.row.fullname.trim() || "None"
                : "None"}
            </p>
          )}
        </>
      ),
    },
    {
      field: "user_filter_id",
      headerName: "User Id",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => (
        <>
          {teleSearch === "user" && telegramOption_user === "user_id" ? (
            cellValues.row.user_id !== "None" ? (
              <Link
                className="user_id_link"
                to={`/users?page=${cellValues.row.user_id}`}
              >
                <Highlighter
                  highlightClassName="highlightclass"
                  searchWords={[getSearchedData]}
                  autoEscape={postSearch_props !== "regex"}
                  textToHighlight={cellValues.row.user_id.toString()}
                  className="text_hidden"
                />
              </Link>
            ) : (
              <p className="text_hidden">None</p>
            )
          ) : cellValues.row.user_id !== "None" ? (
            <Link
              className="user_id_link"
              to={`/users?page=${cellValues.row.user_id}`}
            >
              {cellValues.row.user_id}
            </Link>
          ) : (
            <p className="text_hidden">None</p>
          )}
        </>
      ),
    },
    {
      field: "group_id",
      headerName: "Grp ID",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => (
        <>
          {telegramOption_user === "group" &&
          user_group === "groupid" &&
          cellValues.row.group_id !== "None" ? (
            <Link
              className="user_id_link"
              to={
                cellValues.row.group_title !== "None" &&
                cellValues.row.group_title !== null
                  ? `/page?page=${cellValues.row.group_title}&type=channel name`
                  : cellValues.row.group_id !== "None" &&
                    cellValues.row.group_id !== null
                  ? `/page?page=${cellValues.row.group_id}&type=id`
                  : null
              }
            >
              <Highlighter
                highlightClassName="highlightclass"
                searchWords={[getSearchedData]}
                autoEscape={postSearch_props !== "regex"}
                textToHighlight={cellValues.row.group_id.toString()}
                className="text_hidden"
              />
            </Link>
          ) : cellValues.row.group_id !== "None" ? (
            <Link
              className="user_id_link"
              to={
                cellValues.row.group_title !== "None" &&
                cellValues.row.group_title !== null
                  ? `/page?page=${cellValues.row.group_title}&type=channel name`
                  : cellValues.row.group_id !== "None" &&
                    cellValues.row.group_id !== null
                  ? `/page?page=${cellValues.row.group_id}&type=id`
                  : null
              }
            >
              {cellValues.row.group_id}
            </Link>
          ) : (
            cellValues.row.group_id
          )}
        </>
      ),
    },
    {
      field: "group_title",
      headerName: "Grp Title",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => (
        <>
          {telegramOption_user === "group" &&
          (user_group === "group_username" ||
            user_group === "group_channel_name") &&
          cellValues.row.group_title &&
          cellValues.row.group_title !== "None" ? (
            <Link
              className="user_id_link"
              to={
                cellValues.row.group_title !== "None" &&
                cellValues.row.group_title !== null
                  ? `/page?page=${cellValues.row.group_title}&type=channel name`
                  : cellValues.row.group_id !== "None" &&
                    cellValues.row.group_id !== null
                  ? `/page?page=${cellValues.row.group_id}&type=id`
                  : null
              }
            >
              <Highlighter
                highlightClassName="highlightclass"
                searchWords={[getSearchedData]}
                autoEscape={postSearch_props !== "regex"}
                textToHighlight={cellValues.row.group_title.toString()}
                className="text_hidden"
              />
            </Link>
          ) : cellValues.row.group_title !== "None" ? (
            <Link
              className="user_id_link"
              to={
                cellValues.row.group_title !== "None" &&
                cellValues.row.group_title !== null
                  ? `/page?page=${cellValues.row.group_title}&type=channel name`
                  : cellValues.row.group_id !== "None" &&
                    cellValues.row.group_id !== null
                  ? `/page?page=${cellValues.row.group_id}&type=id`
                  : null
              }
            >
              {cellValues.row.group_title}
            </Link>
          ) : (
            cellValues.row.group_title
          )}
        </>
      ),
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,

      renderCell: (cellValues) => (
        <>
          {cellValues.row.phone !== "None" &&
          telegramOption_user === "phone" ? (
            <Highlighter
              highlightClassName="highlightclass"
              searchWords={[getSearchedData]}
              autoEscape={postSearch_props !== "regex"}
              textToHighlight={
                cellValues.row.phone ? cellValues.row.phone.toString() : "None"
              }
              className="text_hidden"
            />
          ) : (
            cellValues.row.phone
          )}
        </>
      ),
    },
    {
      field: "recordingdate",
      headerName: "Date of recording",
      flex: 1,
      type: "date",
      sortComparator: sort_cloumn_datewise,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 90,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        return (
          <Stack direction="row" justifyContent="center" columnGap={1}>
            <Link to={`/users?page=${cellValues.row.user_id}`}>
              <Tooltip title="Profile">
                <VisibilityIcon className="view_icon_telegram" />
              </Tooltip>
            </Link>
            {[
              ...hybrid_channels["marked_channels"]["user_preference"],
              ...hybrid_channels["marked_channels"]["company_preference"],
            ].length === 0 ||
            [
              ...hybrid_channels["marked_channels"]["user_preference"],
              ...hybrid_channels["marked_channels"]["company_preference"],
            ].some(
              (item) =>
                handle_remove_white_space_check_equal(
                  item["keyword"],
                  cellValues.row.user_id,
                ) && item["pref_type"] === "telegram_user",
            ) === false ? (
              <button
                className="alert_bell_icon_btn"
                onClick={() => {
                  set_open_modal_pref({
                    channel_name: cellValues.row.user_id,
                    status: true,
                    pref_type: "telegram_user",
                  });
                }}
              >
                <Tooltip title="Mark">
                  <PushPinIcon sx={{ color: "#9bbcd1" }} />
                </Tooltip>
              </button>
            ) : (
              <button
                className="alert_bell_icon_btn"
                onClick={() => {
                  const detect_prefs = detect_channel_pref_type(
                    hybrid_channels,
                    cellValues.row.user_id,
                    "telegram_user",
                  );
                  const { sub_pref_type, dashboard_filter } = detect_prefs;
                  if (sub_pref_type === "user_preference") {
                    delete_custom_preference(
                      cellValues.row.user_id,
                      "user_preference",
                      dashboard_filter,
                      "telegram_user",
                      set_hybrid_channels,
                      "table",
                    );
                    return;
                  } else if (
                    sub_pref_type === "company_preference" &&
                    user_role === true
                  ) {
                    delete_custom_preference(
                      cellValues.row.user_id,
                      "company_preference",
                      dashboard_filter,
                      "telegram_user",
                      set_hybrid_channels,
                      "table",
                    );
                    return;
                  } else if (
                    sub_pref_type === "company_preference" &&
                    user_role === false
                  ) {
                    handle_error_message(
                      "Only company admin can delete company preference channel.",
                    );
                    return;
                  }
                  return;
                }}
              >
                <Tooltip
                  title={
                    detect_channel_pref_type(
                      hybrid_channels,
                      cellValues.row.user_id,
                      "telegram_user",
                    )["sub_pref_type"] === "company_preference" &&
                    user_role === false
                      ? "Only company admin can unmark"
                      : "Unmark"
                  }
                >
                  <PushPinIcon sx={{ color: "yellow" }} />
                </Tooltip>
              </button>
            )}
          </Stack>
        );
      },
    },
  ];

  const columns_channel = [
    { field: "id", headerName: "ID", width: 50 },
    { field: "profileid", headerName: "Id", width: 110 },
    {
      field: "name",
      headerName: "Name",
      width: 140,
      renderCell: (cellValues) => (
        <Highlighter
          highlightClassName="highlightclass"
          searchWords={
            handle_exactsearch_highlight(
              postSearch_props,
              keyword,
              getSearchedData,
            ).is_exact === true
              ? [
                  handle_exactsearch_highlight(
                    postSearch_props,
                    keyword,
                    getSearchedData,
                  ).word_regex,
                ]
              : text_index(cellValues.row.name) === true
              ? [getSearchedData]
              : getSearchedData.split(" ")
          }
          autoEscape={
            !(
              postSearch_props === "regex" ||
              handle_exactsearch_highlight(
                postSearch_props,
                keyword,
                getSearchedData,
              ).is_exact === true
            )
          }
          textToHighlight={
            cellValues.row.name
              ? cellValues.row.name.includes(
                  "</XC Fixxedsࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡ (Xc ࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡDDoS) ࠡࠡࠡࠡࠡࠡࠡࠡࠡࠡ</>",
                )
                ? "</XC Fixxeds (Xc DDos)</>"
                : highlight_name(cellValues.row.name)
              : ""
          }
          className="text_hidden"
        />
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 74,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => (
        <Highlighter
          highlightClassName="highlightclass"
          searchWords={
            handle_exactsearch_highlight(
              postSearch_props,
              keyword,
              getSearchedData,
            ).is_exact === true
              ? [
                  handle_exactsearch_highlight(
                    postSearch_props,
                    keyword,
                    getSearchedData,
                  ).word_regex,
                ]
              : text_index(cellValues.row.description) === true
              ? [getSearchedData]
              : getSearchedData.split(" ")
          }
          autoEscape={
            !(
              postSearch_props === "regex" ||
              handle_exactsearch_highlight(
                postSearch_props,
                keyword,
                getSearchedData,
              ).is_exact === true
            )
          }
          textToHighlight={highlight_message(cellValues.row.description)}
          className="text_hidden"
        />
      ),
    },
    {
      field: "telegramlink",
      headerName: "Telegram link",
      width: 200,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => {
        let tele_link = "";
        if (cellValues.row.telegramlink !== "None") {
          try {
            if (
              cellValues.row.telegramlink.includes("https://t.me/") === true
            ) {
              tele_link = cellValues.row.telegramlink;
            } else {
              tele_link = `https://t.me/${cellValues.row.telegramlink}`;
            }
          } catch (err) {
            tele_link = cellValues.row.telegramlink;
          }
        } else {
          tele_link = cellValues.row.telegramlink;
        }

        return (
          <>
            {telegramOption_channel === "link" ? (
              <button
                className="filter_file_link text_hidden"
                onClick={() => link_website(tele_link)}
              >
                <Highlighter
                  highlightClassName="highlightclass"
                  searchWords={[getSearchedData]}
                  autoEscape={postSearch_props !== "regex"}
                  textToHighlight={highlight_message(tele_link)}
                  className="text_hidden"
                />
              </button>
            ) : (
              <button
                className="filter_file_link text_hidden"
                onClick={() => link_website(tele_link)}
              >
                {tele_link}
              </button>
            )}
          </>
        );
      },
    },
    { field: "totalpost", headerName: "Posts", width: 80 },
    { field: "subscribers", headerName: "Subs", width: 70 },
    { field: "totallinkpost", headerName: "Links", width: 80 },
    {
      field: "totalfilepost",
      headerName: "Files",
      width: 83,
      renderCell: (cellValues) => (
        <Stack>
          {cellValues.row.totalfilepost}
          <Button
            onClick={() => {
              handleOpenModalChannel(cellValues.row);
            }}
          >
            <span className="modalBtn">see more</span>
          </Button>
        </Stack>
      ),
    },
  ];

  const filterData_post_file = Data.map((item, index) => ({
    id: index,
    to_id: item.to_id,
    name: item.conv_name,
    link: item.link,
    date: date_fm(item.date),
    subscribers: participants(item.participants_count).subs,
    user_id: item.id,
    message: item.message,
    views: item.views ? item.views : 0,
    type: channel_type(item.is_group),
    fileextension: item.fileext,
    filename: item.filename
      ? item.filename.replace("SIZE_EXCEEDED_NOT_DOWNLOADED_", "")
      : null,
    all_data: item,
  }));

  const filterData_user = Data.map((item, index) => ({
    id: index,
    username: item.username,
    firstname: item.userfirstname,
    lastname: item.userlastname,
    fullname: item.userfullname,
    group_id: item.groupid,
    group_title: item.grouptitle,
    phone: item.phone,
    recordingdate: date_fm(item.dateofrecording),
    lastseen: item.lastseenstatus,
    user_id: item.userid ? item.userid.toString() : item.userid,
    all_data: item,
  }));

  const filterData_channel = Data.map((item, index) => ({
    id: index,
    profileid: item.to_id,
    name: item.conv_name,
    type: channel_type(item.is_group),
    description: item.about ? item.about : "None",
    telegramlink: item.link,
    totalpost: item.total_data,
    subscribers: participants(item.participants_count).subs,
    totalmediapost: participants(item.participants_count).photos,
    totallinkpost: participants(item.participants_count).links,
    totalfilepost: participants(item.participants_count).files,
    all_data: item,
  }));

  const rows =
    teleSearch === "post" || teleSearch === "file"
      ? filterData_post_file
      : teleSearch === "user"
      ? filterData_user
      : filterData_channel;

  const cloneData = [...rows];
  const new_state = cloneData.slice(
    (parseInt(paginationValue) - 1) * pageSize,
    parseInt(paginationValue) * pageSize,
  );
  const new_total_page = Math.ceil(rows.length / pageSize);

  const handlePaginationChange = (event, value) => {
    setPaginationValue(value);
    handle_user_table_width(pageSize);
  };

  // Select the required page size
  const page_size_fixer = (newPageSize) => {
    setPageSize(newPageSize);
    setPaginationValue(Math.ceil(rows.length / newPageSize));
    handle_user_table_width(newPageSize);
  };

  // link the file
  const file_link_adder = (links, msgid) => {
    if (links !== null && links !== "None") {
      if (links.includes("t.me")) {
        const new_link = links.replace("s/", "");
        const open_link = `${new_link}/${msgid}`;

        window.open(
          open_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      } else {
        const new_link = `https://t.me/${links}/${msgid}`;
        window.open(
          new_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      }
    }
  };
  const notification_extractor = () => {
    axios
      .get(
        "https://dev.recordedtelegram.com/v2/keyword_post_notification_data",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((res) => {
        if (res.data.data.length > 0) {
          const new_value = res.data.data.map((item) =>
            item.keyword_entered.toLowerCase(),
          );
          set_notification_data(new_value);

          const raw_data = res.data.data.map((item) => {
            item.keyword_entered = item.keyword_entered.toLowerCase();
            return item;
          });
          set_notification_raw(raw_data);
        }
      });
  };
  const detect_channel_pref_type = (
    mixed_channels,
    selected_channel,
    filter_type,
  ) => {
    const user_pref = [...mixed_channels["marked_channels"]["user_preference"]];
    const company_pref = [
      ...mixed_channels["marked_channels"]["company_preference"],
    ];
    const find_user_pref = user_pref.find(
      (item) =>
        handle_remove_white_space_check_equal(
          item["keyword"],
          selected_channel,
        ) && item["pref_type"] === filter_type,
    );
    const find_company_pref = company_pref.find(
      (item) =>
        handle_remove_white_space_check_equal(
          item["keyword"],
          selected_channel,
        ) && item["pref_type"] === filter_type,
    );

    if (find_user_pref) {
      return {
        sub_pref_type: "user_preference",
        dashboard_filter: find_user_pref["dashboard_filter"],
      };
    } else if (find_company_pref) {
      return {
        sub_pref_type: "company_preference",
        dashboard_filter: find_company_pref["dashboard_filter"],
      };
    } else
      return {
        sub_pref_type: "",
        dashboard_filter: "",
      };
  };
  return (
    <>
      <div
        className={
          table_mode === "main_search_table"
            ? "main__table"
            : "main__table main__table_dashboard"
        }
      >
        <div className="total_data_number">
          <h6>
            Results shown/found :{" "}
            <span>
              {Data.length}/{total_db_data}
            </span>
          </h6>
          <div className="total_data_number_child">
            {(teleSearch === "post" || teleSearch === "file") && (
              <DownloadFileOptions
                getSearchedData={getSearchedData}
                file_id={file_id}
                post_no={post_no}
                search_table_type={"telegram"}
              />
            )}
            <h6>
              Credits remaining :{" "}
              <span className="credit_limit_colors">{telegram_limit}</span>
            </h6>
          </div>
        </div>

        <GlobalStyles
          styles={{
            ".MuiDataGrid-menuList": {
              backgroundColor: "#394e5a",
            },
            ".MuiMenu-list ": {
              backgroundColor: "#394e5a",
            },
            ".MuiDataGrid-menuList li": {
              color: "#dcf3ff",
            },
            ".MuiMenu-list li": {
              color: "#dcf3ff",
            },
            ".MuiPaginationItem-root": {
              color: "#9bbcd1",
              backgroundColor: "#1e2a31",
            },
            ".MuiDataGrid-filterForm": {
              backgroundColor: "#394e5a",
              border: "1px solid #9bbcd1",
            },
          }}
        />
        <DataGrid
          rows={new_state}
          columns={
            teleSearch === "post" || teleSearch === "file"
              ? columns_post_file
              : teleSearch === "user"
              ? columns_user
              : columns_channel
          }
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => page_size_fixer(newPageSize)}
          rowsPerPageOptions={[25, 50, 75, 100]}
          autoHeight
          columnVisibilityModel={{
            id: false,
            fileextension: teleSearch === "file",
            filename: teleSearch === "file",
            user_id: postsearch_sub !== "channel",
            profileid: false,
          }}
          disableColumnSelector
          density="comfortable"
        />

        <Pagination
          count={new_total_page}
          color="primary"
          page={paginationValue}
          onChange={handlePaginationChange}
          size="small"
        />
        {total_db_data && Number(total_db_data) > Data.length ? (
          <Stack
            direction="row"
            alignItem="center"
            justifyContent="center"
            spacing={2}
            mt={1}
          >
            {" "}
            {table_mode === "main_search_table" ? (
              new_total_page === paginationValue ? (
                loadmore_loading === true ? (
                  <CircularProgress
                    fontSize="small"
                    sx={{ color: "#28cab3" }}
                  />
                ) : (
                  <button onClick={load_more} className="load_more_btn">
                    Load more
                  </button>
                )
              ) : null
            ) : null}
          </Stack>
        ) : null}
      </div>
      {openModal === true && (
        <ModalContainer
          openModal={openModal}
          setOpenModal={setOpenModal}
          selectedRow={selectedRow}
          set_telegram_limit={set_telegram_limit}
        />
      )}
      {open_modal_channel === true && (
        <ModalChannel
          open_modal_channel={open_modal_channel}
          set_open_modal_channel={set_open_modal_channel}
          selectedRow={selectedRow}
        />
      )}

      {/* Modal for the notification alert */}
      {open_notification_modal === true ? (
        <Dialog
          open={open_notification_modal}
          onClose={handle_close_notification_modal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box
            sx={{
              backgroundColor: "rgba(29, 40, 53, .95)",
              color: "white",
              paddingBottom: "55px",
            }}
          >
            <Stack alignItems="flex-end">
              <CloseIcon
                onClick={handle_close_notification_modal}
                fontSize="small"
                sx={{ color: "red", cursor: "pointer", m: 0.5 }}
              />
            </Stack>

            <DialogTitle id="alert-dialog-title">Post Filters</DialogTitle>
            <DialogContent>
              <div className="cardbox-item">
                <p
                  className="card-text text-left "
                  style={{ fontWeight: 900, color: "#fff" }}
                >
                  {" "}
                  Interval Type
                </p>
                <div className="row">
                  <div className="col text-left">
                    <div className="notify-filter">
                      <input
                        className="hyp"
                        type="radio"
                        onChange={(e) => {
                          set_interval_type(e.target.value);
                        }}
                        name="search_type"
                        value="day"
                      />
                      <span className="links_name">Day</span>
                      <input
                        className="hyp"
                        type="radio"
                        onChange={(e) => {
                          set_interval_type(e.target.value);
                        }}
                        name="search_type"
                        value="week"
                      />
                      <span className="links_name">Weekly</span>
                      <input
                        className="hyp"
                        type="radio"
                        disabled={handle_disable_radio_btn()}
                        onChange={(e) => {
                          set_interval_type(e.target.value);
                        }}
                        name="search_type"
                        value="minutes"
                      />
                      <span className="links_name">Minutes </span>
                      <span
                        style={{
                          background: "#f82649",
                          fontSize: "12px",
                          marginLeft: "0.5px",
                          lineHeight: "14px",
                          padding: "0 3px",
                          marginTop: "-14px",
                        }}
                        className="filters-text"
                      >
                        Premium
                      </span>
                    </div>
                  </div>
                </div>
                <p
                  className="card-text text-left "
                  style={{ fontWeight: 900, color: "#fff", marginTop: "20px" }}
                >
                  {" "}
                  Interval/Duration
                </p>
                <div className="row">
                  <div className="col text-left">
                    <div style={wrapperStyle}>
                      <Slider
                        min={interval_type === "minutes" ? 5 : 1}
                        max={
                          interval_type === "minutes"
                            ? 1439
                            : interval_type === "week"
                            ? 4
                            : 7
                        }
                        onChange={(e) => {
                          set_interval_number(e);
                        }}
                        value={interval_number}
                        handle={handle}
                      />
                      <p
                        className="txtslider"
                        style={{
                          color: "#fff",
                          fontWeight: 500,
                          marginLeft: "80px",
                        }}
                      >
                        Interval:{" "}
                        {interval_type === "minutes"
                          ? interval_number >= 60
                            ? `${Math.floor(Number(interval_number) / 60)} hr ${
                                Number(interval_number) % 60
                              } mins`
                            : `${interval_number} mins`
                          : interval_number}
                      </p>
                    </div>
                  </div>
                </div>
                <button
                  style={{
                    marginTop: "20px",
                  }}
                  onClick={create_notification_alert}
                  className="button_global_style"
                >
                  Create Alert
                </button>
              </div>
            </DialogContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              columnGap={2}
            >
              <input
                type="checkbox"
                id="checkbox_notification"
                name="checkbox"
                onChange={() => set_is_checked(!is_checked)}
                checked={is_checked}
                className="checkbox_notification"
              />
              <label htmlFor="checkbox_notification" className="mt-2">
                Do not show this message again ?
              </label>
            </Stack>
          </Box>
        </Dialog>
      ) : null}

      {/* Modal for the blocklist channel */}
      {open_blocklist_modal === true ? (
        <Dialog
          open={open_blocklist_modal}
          onClose={() => set_open_blocklist_modal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Box
            sx={{
              backgroundColor: "rgba(29, 40, 53, .95)",
              color: "white",
              padding: "1rem 1rem 55px 1rem",
            }}
          >
            <Stack alignItems="flex-end">
              <CloseIcon
                onClick={() => set_open_blocklist_modal(false)}
                fontSize="small"
                sx={{ color: "red", cursor: "pointer", m: 0.5 }}
              />
            </Stack>

            <DialogTitle id="alert-dialog-title">Block Filters</DialogTitle>
            <DialogContent>
              <div>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Block Type
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={block_type}
                    onChange={(event) => set_block_type(event.target.value)}
                  >
                    <FormControlLabel
                      value="search"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Search"
                    />
                    <FormControlLabel
                      value="alert"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Alert"
                    />
                    <FormControlLabel
                      value="both"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Both"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <div>
                <button
                  style={{
                    marginTop: "20px",
                  }}
                  onClick={create_add_blocklist}
                  className="button_global_style"
                >
                  Block
                </button>
              </div>
            </DialogContent>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              columnGap={2}
            >
              <input
                type="checkbox"
                id="checkbox_notification"
                name="checkbox"
                onChange={() => set_is_checked_blocklist(!is_checked_blocklist)}
                checked={is_checked_blocklist}
                className="checkbox_notification"
              />
              <label htmlFor="checkbox_notification" className="mt-2">
                Do not show this message again ?
              </label>
            </Stack>
          </Box>
        </Dialog>
      ) : null}
      {open_modal_pref.status === true ? (
        <PreferenceType
          open_modal_pref={open_modal_pref}
          set_open_modal_pref={set_open_modal_pref}
          status_type={"table"}
          set_channels={set_hybrid_channels}
        />
      ) : null}
      <ToastContainer />
    </>
  );
}
DataTable.propTypes = {
  teleSearch: PropTypes.string,
  Data: PropTypes.array,
  getSearchedData: PropTypes.string,
  postSearch_props: PropTypes.string,
  postsearch_sub: PropTypes.string,
  telegramOption_post_props: PropTypes.string,
  telegramOption_user: PropTypes.string,
  telegramOption_channel: PropTypes.string,
  total_db_data: PropTypes.number,
  telegram_limit: PropTypes.number,
  set_telegram_limit: PropTypes.func,
  file_id: PropTypes.string,
  user_group: PropTypes.string,
  getData: PropTypes.func,
  loadmore_loading: PropTypes.bool,
  keyword: PropTypes.string,
  proximity: PropTypes.number,
  search_after_id: PropTypes.string,
  post_no: PropTypes.number,
  nested_channel_regex: PropTypes.bool,
  paginationValue: PropTypes.number,
  setPaginationValue: PropTypes.func,
  hybrid_channels: PropTypes.object,
  set_hybrid_channels: PropTypes.func,
  table_mode: PropTypes.string,
};
export default memo(DataTable);

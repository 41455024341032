import React, { useState } from "react";
import PropTypes from "prop-types";
// Modal
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Chip from "@mui/material/Chip";
import Slider from "@mui/material/Slider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Badge from "@mui/material/Badge";
import {
  handle_session_get,
  handle_disable_radio_btn,
} from "../../../utils/SessionStorage/handle_session";
import { RollerAnimation } from "../loader/Loader";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_warning_message } from "../../../utils/ResponseMessage/response_message";
import "../../../css/Modal/modal.css";

function ChannelLogicalAlert({
  open_channel_logical_alert_modal,
  set_open_channel_logical_alert_modal,
  keyword,
  setKeyword,
  interval_type_filter,
  set_interval_type_filter,
  interval_number_filter,
  set_interval_number_filter,
  alert_type,
  set_alert_type,
  channels_list,
  set_channels_list,
  post_data,
  alert_file_type,
  set_alert_file_type,
  alert_post_no,
  set_alert_post_no,
  end_point,
  multiple_emails_list,
  multiple_email_type,
  set_multiple_email_type,
  set_open_email_alert,
  alert_status,
}) {
  const [search_val, set_search_val] = useState("");
  const [search_data, set_search_data] = useState(null); // Get the list of channels
  const [stat_loading, set_stat_loading] = useState(false);
  const [search_error, set_search_error] = useState(null);
  const [enable_keyword_field, set_enable_keyword__field] = useState(false);

  const valuetext = (value) => {
    set_interval_number_filter(value);
  };
  const handleChangeAlertType = (event) => {
    if (event.target.value === "custom_endpoint" && end_point === null) {
      handle_warning_message("Please add endpoint first");
      set_open_email_alert(true);
      return;
    }
    if (
      event.target.value === "multiple_email" &&
      (multiple_emails_list.emails.length === 0 ||
        multiple_emails_list.emails.length === 1)
    ) {
      handle_warning_message("Please add some emails first");
      set_open_email_alert(true);
      return;
    }
    set_alert_type(event.target.value);
    if (event.target.value === "custom_endpoint") {
      set_alert_file_type("csv");
    }
    if (event.target.value !== "multiple_email") {
      set_multiple_email_type("cc");
    }
  };

  const handleChangeMultipleEmailType = (event) => {
    set_multiple_email_type(event.target.value);
  };
  const handleChangeIntervalType = (event) => {
    set_interval_type_filter(event.target.value);
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  };

  const add_channels_list = (item) => {
    if (channels_list.includes(item) === false) {
      set_channels_list([...channels_list, item]);
    } else {
      set_channels_list(channels_list);
    }
    set_search_val("");
  };

  const handle_delete_channels = (clicked) => {
    const filter_channels = channels_list.filter((item) => item !== clicked);
    set_channels_list(filter_channels);
  };
  const channels_list_chips = channels_list.map((item, index) => (
    <Chip
      label={item}
      onDelete={() => handle_delete_channels(item)}
      color="success"
      key={index}
    />
  ));

  const val_change = (e) => {
    set_search_val(e.target.value);
    const token = handle_session_get("Token");

    if (e.target.value.length > 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value, search_type: "alert" };
      axios
        .post("https://dev.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          set_search_data(res.data);
          set_stat_loading(false);
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_stat_loading(false);
          set_search_error(err.response.data.errormsg);
        });
    } else {
      set_search_data(null);
    }
  };

  return (
    <div>
      <Modal
        open={open_channel_logical_alert_modal}
        onClose={() => set_open_channel_logical_alert_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="container_modal_nested_channel scroll-custom">
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_channel_logical_alert_modal(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          <div className="modal_nested_channel_desc">
            <Formik
              initialValues={{ channel_name: "", search_word: "" }}
              validate={(values) => {
                const errors = {};

                if (
                  alert_status === "user_alert" &&
                  values.search_word.length === 0
                ) {
                  errors.search_word = "";
                }
                if (channels_list.length === 0) {
                  errors.channel_name = "";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  set_open_channel_logical_alert_modal(false);
                  post_data(
                    values.search_word.trim(),
                    "channel",
                    channels_list,
                  );
                }, 400);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="nested_input_div">
                    <label htmlFor="nested_channel_name">Channels/Groups</label>
                    <input
                      name="channel_name"
                      className="nested_channel_name"
                      id="nested_channel_name"
                      onChange={(e) => {
                        handleChange(e);
                        val_change(e);
                      }}
                      onBlur={handleBlur}
                      value={search_val}
                      type="search"
                      placeholder="add channels/groups"
                    />
                  </div>
                  <div className="channels_list_chips">
                    {channels_list_chips}
                  </div>
                  <div style={{ position: "relative", width: "100%" }}>
                    {/* list of channels */}
                    {search_val.length > 2 ? (
                      <div
                        className="autocom-box mx-auto"
                        style={{
                          width: "70%",
                          position: "absolute",
                          zIndex: 1,
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        {stat_loading ? (
                          <RollerAnimation />
                        ) : search_error !== null ? (
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "larger",
                              color: "white",
                              marginTop: "10%",
                            }}
                          >
                            {search_error}
                          </div>
                        ) : search_data !== null && search_data.length === 0 ? (
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "larger",
                              color: "white",
                              marginTop: "10%",
                            }}
                          >
                            No Data to display
                          </div>
                        ) : (
                          <>
                            <h4
                              style={{
                                paddingLeft: "1rem",
                                marginLeft: "15px",
                                marginTop: "5px",
                                fontSize: "18px",
                                color: "whitesmoke",
                              }}
                            >
                              List Of Channels
                            </h4>
                            <ul>
                              {search_data !== null &&
                                search_data.map((val, id) => (
                                  <li key={id}>
                                    <div className="row">
                                      <div
                                        className="col-md-8"
                                        style={{ textAlign: "left" }}
                                      >
                                        {" "}
                                        <img
                                          style={{
                                            width: "36px",
                                            height: "36px",
                                            padding: "2px",
                                            border: "2px solid #f4f4f4",
                                          }}
                                          className="img-fluid rounded-circle"
                                          src={
                                            val.channel_id !== "None"
                                              ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                          }
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                          }}
                                          alt="User"
                                        />
                                        <span
                                          className="channels_list_map"
                                          style={{
                                            marginLeft: "10px",
                                            color: "#dcf3ff",
                                          }}
                                        >
                                          {val.channel_name}
                                        </span>
                                      </div>
                                      <div
                                        className="col-md-4"
                                        style={{
                                          textAlign: "right",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {channels_list.includes(
                                          val.channel_name,
                                        ) === false ? (
                                          <button
                                            type="button"
                                            style={{
                                              borderRadius: "40px",
                                              background: "#d03940",
                                              color: "white",
                                              lineHeight: "1",
                                            }}
                                            className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                            onClick={() =>
                                              add_channels_list(
                                                val.channel_name,
                                              )
                                            }
                                          >
                                            Add
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            disabled
                                            style={{
                                              borderRadius: "40px",
                                              background: "#4cabd9",
                                              color: "white",
                                              lineHeight: "1",
                                            }}
                                            className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                          >
                                            Added
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>
                  {alert_status === "admin_alert" &&
                  enable_keyword_field === false ? (
                    <button
                      type="button"
                      className="enabled_keyword_alert_individual_modal"
                      onClick={() => set_enable_keyword__field(true)}
                    >
                      Add keyword
                    </button>
                  ) : null}
                  {alert_status === "user_alert" ||
                  (alert_status === "admin_alert" &&
                    enable_keyword_field === true) ? (
                    <div className="nested_input_div">
                      <label htmlFor="nested_search_word">Search</label>
                      <input
                        name="search_word"
                        className="nested_search_word"
                        id="nested_search_word"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.search_word}
                        placeholder="type to search"
                      />
                      <p className="channel_search_note">
                        <b>Note</b> : Logical and Regex queries are also
                        supported.
                      </p>
                    </div>
                  ) : null}
                  <h5
                    style={{
                      color: "white",
                      marginBottom: 0,
                      marginTop: "4px",
                    }}
                  >
                    Filters
                  </h5>
                  <div
                    style={{
                      borderBottom: "0.3px solid #f5f5f55e",
                      width: "80%",
                    }}
                  />
                  <div
                    className="nested_channel_filters"
                    style={{ height: "182px" }}
                  >
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Keyword
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={keyword}
                        onChange={handleChangeKeyword}
                      >
                        <FormControlLabel
                          value="contains"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Contains"
                        />
                        <FormControlLabel
                          value="exact"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Exact"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Interval Type
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={interval_type_filter}
                        onChange={handleChangeIntervalType}
                      >
                        <FormControlLabel
                          value="day"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Day"
                        />

                        <FormControlLabel
                          value="week"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Week"
                        />

                        <FormControlLabel
                          value="minutes"
                          control={
                            <Radio
                              disabled={handle_disable_radio_btn()}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={
                            <Badge color="secondary" badgeContent="premium">
                              <span>Minutes</span>
                            </Badge>
                          }
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Interval Duration
                      </FormLabel>
                      <Box sx={{ width: 140 }}>
                        <Slider
                          aria-label="Temperature"
                          defaultValue={
                            interval_type_filter === "minutes" ? 5 : 1
                          }
                          getAriaValueText={valuetext}
                          valueLabelDisplay="auto"
                          min={interval_type_filter === "minutes" ? 5 : 1}
                          max={
                            interval_type_filter === "minutes"
                              ? 1439
                              : interval_type_filter === "week"
                              ? 4
                              : 7
                          }
                          sx={{ color: "#28cab3" }}
                        />
                        <p
                          style={{
                            color: "whitesmoke",
                            fontWeight: 500,
                            textAlign: "start",
                          }}
                        >
                          Interval:{" "}
                          {interval_type_filter === "minutes"
                            ? interval_number_filter >= 60
                              ? `${Math.floor(
                                  Number(interval_number_filter) / 60,
                                )} hr ${
                                  Number(interval_number_filter) % 60
                                } mins`
                              : `${interval_number_filter} mins`
                            : interval_number_filter}
                        </p>
                      </Box>
                    </FormControl>
                    {alert_status === "user_alert" &&
                    (handle_session_get === "PAID_CUSTOMER" ||
                      handle_session_get("User") === "administrator") ? (
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title"
                        >
                          Alert Type
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={alert_type}
                          onChange={handleChangeAlertType}
                        >
                          <FormControlLabel
                            value="email"
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="Email"
                          />
                          <FormControlLabel
                            value="custom_endpoint"
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="End point"
                          />
                          <FormControlLabel
                            value="multiple_email"
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="Multiple Emails"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : null}

                    {alert_type === "multiple_email" ? (
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title"
                        >
                          Emails Type
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={multiple_email_type}
                          onChange={handleChangeMultipleEmailType}
                        >
                          <FormControlLabel
                            value="cc"
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="CC"
                          />
                          <FormControlLabel
                            value="bcc"
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="BCC"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : null}

                    {/* Alert file type */}
                    {alert_type === "email" ||
                    alert_type === "multiple_email" ? (
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title"
                        >
                          Alert File Type
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={alert_file_type}
                          onChange={(e) => {
                            set_alert_file_type(e.target.value);
                          }}
                        >
                          <FormControlLabel
                            value="csv"
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="CSV"
                          />
                          <FormControlLabel
                            value="html"
                            control={
                              <Radio
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label="HTML"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : null}

                    {/* Alert Post no */}
                    {alert_file_type === "html" ? (
                      <FormControl>
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title"
                        >
                          Alert Post No
                        </FormLabel>
                        <Box className="filter__category filter_category_channel_alert">
                          <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={alert_post_no}
                            onChange={(e) => {
                              set_alert_post_no(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value={0}
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={0}
                            />
                            <FormControlLabel
                              value={1}
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={1}
                            />
                            <FormControlLabel
                              value={5}
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={5}
                            />
                            <FormControlLabel
                              value={10}
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={10}
                            />
                            <FormControlLabel
                              value={15}
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={15}
                            />
                            <FormControlLabel
                              value={20}
                              control={
                                <Radio
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={20}
                            />
                          </RadioGroup>
                        </Box>
                      </FormControl>
                    ) : null}
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="button_global_style"
                  >
                    Submit
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
ChannelLogicalAlert.propTypes = {
  open_channel_logical_alert_modal: PropTypes.bool,
  set_open_channel_logical_alert_modal: PropTypes.func,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
  interval_type_filter: PropTypes.string,
  set_interval_type_filter: PropTypes.func,
  interval_number_filter: PropTypes.string,
  set_interval_number_filter: PropTypes.func,
  alert_type: PropTypes.string,
  set_alert_type: PropTypes.func,
  channels_list: PropTypes.array,
  set_channels_list: PropTypes.func,
  post_data: PropTypes.func,
  alert_file_type: PropTypes.string,
  set_alert_file_type: PropTypes.func,
  alert_post_no: PropTypes.number,
  set_alert_post_no: PropTypes.func,
  end_point: PropTypes.string,
  multiple_emails_list: PropTypes.array,
  multiple_email_type: PropTypes.string,
  set_multiple_email_type: PropTypes.func,
  set_open_email_alert: PropTypes.func,
  alert_status: PropTypes.string,
};

export default ChannelLogicalAlert;

/* eslint-disable no-undef */
import PropTypes from "prop-types";
import React, { useState, memo, useContext } from "react";
import TableViewIcon from "@mui/icons-material/TableView";
// eslint-disable-next-line no-unused-vars
import { Tooltip as tooltip_box, Grid } from "@mui/material";
import { RollerAnimation, RoundAnimationLoader } from "../loader/Loader";
import ModalCompanyAdminTable from "./ModalCompanyAdminTable";
import CompanyParametersFillModal from "./CompanyParametersFillModal";
import CompanyStatsNavbar from "../Navbar/CompanyStatsNavbar";
import Sidenav from "../Navbar/Sidenav";
import DashboardChart from "./DashboardChart";
import AdminChannelsList from "./AdminChannelsList";
import UniversalTableDashboard from "./UniversalTableDashboard";
import SettingsIcon from "@mui/icons-material/Settings";
// import UserDashboardInsertion from "../modalContainer/UserDashboardInsertion";
import DashboardInsertionModal from "../modalContainer/DashboardInsertionModal";
import { styles_background_img } from "../../../utils/Globals/global_functions";
import { chart_filter_context } from "./CompanyStatsFunc";
// import { handle_show_div } from "./companyglobalfunc";
import Badge from "@mui/material/Badge";
import AddchartIcon from "@mui/icons-material/Addchart";
import axios from "axios";
import { chart_static_keys_value } from "../../../utils/Globals/global_variables";
import "../../../css/Dashboard/company_dashboard.css";

function CompanyStats({
  product_stats,
  set_product_stats,
  domain_stats,
  set_domain_stats,
  tag_stats,
  set_tag_stats,
  tag_name_obj,
  channel_stats,
  set_channel_stats,
  alert_stats,
  set_alert_stats,
  product_status,
  domain_status,
  tag_status,
  channel_status,
  alert_status,
  get_chart_data_func,
  company_pref_data,
  dashbord_version,
}) {
  const {
    minimized_chart_list,
    set_minimized_chart_list,
    get_individual_chart_alert_stats,
  } = useContext(chart_filter_context);
  const [open_company_admin_table, set_open_company_admin_table] =
    useState(false);
  const [open_fill_param_modal, set_open_fill_param_modal] = useState(false);
  const [open_user_dashboard_insertion, set_open_user_dashboard_insertion] =
    useState(false);
  const [show_sidenav, set_show_sidenav] = useState(false);
  const [show_minimized_charts, set_show_minimized_charts] = useState(false);

  // const CustomTooltip = ({ active, payload, label }) => {
  //   if (active && payload && payload.length) {
  //     return (
  //       <div className="company_dash_domain_custom_tooltip">
  //         <p>{`${label}`}</p>
  //         <p>Tags Stats : <span style={{ color: "#28CAB3" }}>{payload[0].value}</span></p>
  //         <p>Stealer Stats : <span style={{ color: "#28CAB3" }} >{payload[1].value}</span></p>
  //       </div>
  //     );
  //   }
  //   return null;
  // };

  const user_name_session = localStorage.getItem("admin_status");
  const token = localStorage.getItem("Token");
  const handle_show_hidden_chart = (selected_chart) => {
    try {
      const type_chart = selected_chart["dashboard_type"];
      const stats_name = selected_chart["keyword"];
      set_alert_stats((prev) => ({
        ...prev,
        [type_chart]: {
          ...prev[type_chart],
          [stats_name]:
            type_chart === "keyword" || type_chart === "tag"
              ? {
                  alert_id: selected_chart["id"],
                  ...chart_static_keys_value[type_chart],
                }
              : {
                  alert_id: selected_chart["id"],
                  ...chart_static_keys_value["combined"],
                },
        },
      }));
    } catch (error) {
      set_alert_stats((prev) => prev);
    }
    const route = `https://dev.recordedtelegram.com/user_dashboard/${selected_chart.id}`;
    const data = {
      is_enabled: true,
    };
    axios
      .patch(route, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        const filter_chart = minimized_chart_list.filter(
          (item) => item.id !== selected_chart.id,
        );
        set_minimized_chart_list(filter_chart);
        get_individual_chart_alert_stats(selected_chart);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };
  function sortById(obj) {
    if (!obj) return {};
    if (
      Object.fromEntries(
        Object.entries(obj).sort(([, a], [, b]) => {
          if (a.alert_id && b.alert_id) {
            return a.alert_id - b.alert_id;
          }
        }),
      )
    ) {
      return Object.fromEntries(
        Object.entries(obj).sort(([, a], [, b]) => {
          if (a.alert_id && b.alert_id) {
            return a.alert_id - b.alert_id;
          }
        }),
      );
    }
  }
  const keyOrder = ["file", "channel", "keyword", "tag", "stealer log"];
  const sortedStats = Object.fromEntries(
    keyOrder.map((key) => [key, sortById(alert_stats[key])]),
  );
  return (
    <Grid container>
      {user_name_session === "super_admin" || user_name_session === "admin" ? (
        <Grid
          item
          xs={show_sidenav === true ? 0.5 : 0}
          sm={show_sidenav === true ? 0.5 : 0}
        >
          {show_sidenav ? <Sidenav /> : null}
        </Grid>
      ) : null}
      <Grid
        item
        xs={show_sidenav === true ? 11.5 : 12}
        sm={show_sidenav === true ? 11.5 : 12}
      >
        <CompanyStatsNavbar
          show_sidenav={show_sidenav}
          set_show_sidenav={set_show_sidenav}
        />
        <div
          className="company_stats_main_container"
          style={styles_background_img}
        >
          {dashbord_version === "version_1" ? (
            <>
              <AdminChannelsList />
              <div className="company_stats_product_container">
                <h5 className="company_stats_chart_heading">Product Stats</h5>
                {product_status.loading === false ? (
                  product_status.error ||
                  Object.keys(product_stats).length === 0 ? (
                    <NoData
                      text={
                        product_status.error
                          ? product_status.error
                          : "No data to display"
                      }
                    />
                  ) : (
                    <div className="company_stats_chart_parent_div">
                      {Object.keys(product_stats).length > 0 &&
                        Object.keys(product_stats).map((item, index) => (
                          <DashboardChart
                            chart_stats={product_stats}
                            set_chart_stats={set_product_stats}
                            item={item}
                            index={index}
                            company_pref_data={company_pref_data}
                            chart_type={"product"}
                            key={index}
                          />
                        ))}
                    </div>
                  )
                ) : (
                  <RoundAnimationLoader />
                )}
              </div>
              <div className="comp_dash_divider_container">
                <div className="comp_dash_divider_top" />
                {/* <div class="comp_dash_divider_bottom"></div> */}
              </div>
              <div className="company_stats_domain_container">
                <h5 className="company_stats_chart_heading">Domain Stats</h5>
                {domain_status.loading === false ? (
                  domain_status.error ||
                  Object.keys(domain_stats).length === 0 ? (
                    <NoData
                      text={
                        domain_status.error
                          ? domain_status.error
                          : "No data to display"
                      }
                    />
                  ) : (
                    <div className="company_stats_chart_parent_div">
                      {Object.keys(domain_stats).map((item, index) => (
                        <DashboardChart
                          chart_stats={domain_stats}
                          set_chart_stats={set_domain_stats}
                          item={item}
                          index={index}
                          company_pref_data={company_pref_data}
                          chart_type={"domain"}
                          key={index}
                        />
                      ))}
                    </div>
                  )
                ) : (
                  <RoundAnimationLoader />
                )}
              </div>
              <div className="comp_dash_divider_container">
                <div className="comp_dash_divider_top" />
                {/* <div class="comp_dash_divider_bottom"></div> */}
              </div>
              <div className="company_stats_domain_container">
                <h5 className="company_stats_chart_heading">Tag Stats</h5>
                {tag_status.loading === false ? (
                  tag_status.error || Object.keys(tag_stats).length === 0 ? (
                    <NoData
                      text={
                        tag_status.error
                          ? tag_status.error
                          : "No data to display"
                      }
                    />
                  ) : (
                    <div className="company_stats_chart_parent_div">
                      {Object.keys(tag_stats).map((item, index) => (
                        <DashboardChart
                          chart_stats={tag_stats}
                          set_chart_stats={set_tag_stats}
                          item={item}
                          index={index}
                          tag_name_obj={tag_name_obj}
                          company_pref_data={company_pref_data}
                          key={index}
                          chart_type={"tag"}
                        />
                      ))}
                    </div>
                  )
                ) : (
                  <RoundAnimationLoader />
                )}
              </div>
              <div className="comp_dash_divider_container">
                <div className="comp_dash_divider_top" />
                {/* <div class="comp_dash_divider_bottom"></div> */}
              </div>
              {/* Channel Chart */}
              <div className="company_stats_domain_container">
                <h5 className="company_stats_chart_heading">Channel Stats</h5>
                {channel_status.loading === false ? (
                  channel_status.error ||
                  Object.keys(channel_stats).length === 0 ? (
                    <NoData
                      text={
                        channel_status.error
                          ? channel_status.error
                          : "No data to display"
                      }
                    />
                  ) : (
                    <div className="company_stats_chart_parent_div">
                      {Object.keys(channel_stats).map((item, index) => (
                        <DashboardChart
                          chart_stats={channel_stats}
                          set_chart_stats={set_channel_stats}
                          item={item}
                          index={index}
                          company_pref_data={company_pref_data}
                          chart_type={"channel"}
                          key={index}
                        />
                      ))}
                    </div>
                  )
                ) : (
                  <RoundAnimationLoader />
                )}
              </div>
            </>
          ) : (
            <div>
              <UniversalTableDashboard />
              <div className="company_stats_product_container">
                <h5 className="company_stats_chart_heading"></h5>
                {alert_status.loading === false &&
                dashbord_version.length > 0 ? (
                  alert_status.error ||
                  Object.keys(alert_stats).length === 0 ? (
                    <NoData
                      text={
                        alert_status.error
                          ? alert_status.error
                          : "No data to display"
                      }
                    />
                  ) : (
                    <div className="company_stats_chart_parent_div">
                      {Object.keys(alert_stats).length > 0 &&
                        Object.keys(sortedStats).map((val) =>
                          Object.keys(sortedStats[val]).map((item, index) => (
                            <DashboardChart
                              chart_stats={sortedStats[val]}
                              set_chart_stats={set_alert_stats}
                              item={item}
                              index={index}
                              company_pref_data={company_pref_data}
                              chart_type={"alert"}
                              alert_type={val}
                              key={index}
                            />
                          )),
                        )}
                    </div>
                  )
                ) : (
                  <RollerAnimation />
                )}
              </div>
            </div>
          )}

          {open_company_admin_table === true ? (
            <ModalCompanyAdminTable
              open_modal={open_company_admin_table}
              set_open_modal={set_open_company_admin_table}
            />
          ) : null}
          {open_fill_param_modal === true ? (
            <CompanyParametersFillModal
              open_fill_param_modal={open_fill_param_modal}
              set_open_fill_param_modal={set_open_fill_param_modal}
              fill_status="update"
              get_chart_data_func={get_chart_data_func}
            />
          ) : null}
          {open_user_dashboard_insertion ? (
            <DashboardInsertionModal
              openModal={open_user_dashboard_insertion}
              setOpenModal={set_open_user_dashboard_insertion}
            />
          ) : null}

          {/* Minimized chart lists */}
          {minimized_chart_list.length > 0 ? (
            <div className="company_stats_minimized_chart_container">
              {show_minimized_charts && minimized_chart_list.length > 0 ? (
                <div className="company_stats_minimized_chart_list scroll-custom">
                  {minimized_chart_list.map((item, id) => {
                    return (
                      <p
                        className="company_stats_minimized_chart_list_item"
                        onClick={() => {
                          // handle_show_div(
                          //   `alert_${item.keyword}_company_stats_chart`,
                          // );
                          handle_show_hidden_chart(item);
                          set_show_minimized_charts(false);
                        }}
                        key={id}
                      >
                        {`${item.keyword} `}
                        <span style={{ color: "gray", fontWeight: "normal" }}>
                          ({item.dashboard_type})
                        </span>
                      </p>
                    );
                  })}
                </div>
              ) : null}
              <button
                onClick={() => set_show_minimized_charts((prev) => !prev)}
              >
                <Badge
                  color="warning"
                  badgeContent={minimized_chart_list.length}
                >
                  <AddchartIcon sx={{ color: "#3cd2a5" }} fontSize="large" />
                </Badge>
                {/* {`${minimized_chart_list.length} minimized charts`} */}
              </button>
            </div>
          ) : null}
          {dashbord_version === "version_1" ? (
            <div className="company_stats_btns_div company_stats_graph_div">
              {user_name_session === "company_admin" ||
              user_name_session === "super_admin" ? (
                <button
                  className="company_admin_table_btn"
                  onClick={() => set_open_company_admin_table(true)}
                >
                  <tooltip_box title="Open company admin table">
                    <TableViewIcon
                      fontSize="large"
                      sx={{ color: "whitesmoke" }}
                    />
                  </tooltip_box>{" "}
                </button>
              ) : null}
              <button
                className="company_admin_table_btn"
                onClick={() => set_open_fill_param_modal(true)}
              >
                <tooltip_box title="Update company parameters">
                  <SettingsIcon fontSize="large" sx={{ color: "whitesmoke" }} />
                </tooltip_box>{" "}
              </button>
              <div className="card_arrow">
                <div className="card_arrow_top_left" />
                <div className="card_arrow_top_right" />
                <div className="card_arrow_bottom_left" />
                <div className="card_arrow_bottom_right" />
              </div>
            </div>
          ) : null}
          {dashbord_version === "version_2" ? (
            <div className="company_stats_btns_div company_stats_graph_div">
              <button
                className="company_admin_table_btn"
                onClick={() => set_open_user_dashboard_insertion(true)}
              >
                <tooltip_box title="Insert Dashboard">
                  <SettingsIcon
                    fontSize="large"
                    sx={{ color: "whitesmoke" }}
                    className="admin_edit_icon"
                  />
                </tooltip_box>{" "}
              </button>
              <div className="card_arrow">
                <div className="card_arrow_top_left" />
                <div className="card_arrow_top_right" />
                <div className="card_arrow_bottom_left" />
                <div className="card_arrow_bottom_right" />
              </div>
            </div>
          ) : null}
        </div>
        {/* Lodading modal animation */}
        <div
          className="modal fade"
          id="downloadmodal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="downloadmodal"
          aria-hidden="true"
          style={{ background: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-confirm"
            style={{ background: "#2b3c46", marginTop: "13rem" }}
          >
            <div className="modal-content dk-mode">
              <div className="modal-header flex-column">
                <h4
                  className="modal-title w-100"
                  id="model_title"
                  style={{ fontSize: "18px", color: "#359daf" }}
                >
                  Please wait
                </h4>
              </div>
              <div className="modal-body" style={{ marginTop: "50px" }}>
                <div className="load-wrapper" style={{ marginLeft: "200px" }}>
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  />
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  />
                  <div
                    className="load-circle"
                    style={{ background: "#359daf" }}
                  >
                    {" "}
                  </div>
                  <div className="load-shadow" />
                  <div className="load-shadow" />
                  <div className="load-shadow" />
                  <span style={{ left: "2%", color: "#359daf" }}>
                    Deleting...
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

CompanyStats.propTypes = {
  company_pref_data: PropTypes.array,
  domain_stats: PropTypes.object,
  domain_status: PropTypes.object,
  get_chart_data_func: PropTypes.func,
  product_stats: PropTypes.object,
  product_status: PropTypes.object,
  set_domain_stats: PropTypes.func,
  set_product_stats: PropTypes.func,
  set_tag_stats: PropTypes.func,
  channel_stats: PropTypes.object,
  set_channel_stats: PropTypes.func,
  tag_name_obj: PropTypes.object,
  tag_stats: PropTypes.object,
  tag_status: PropTypes.object,
  channel_status: PropTypes.object,
  alert_status: PropTypes.object,
  alert_stats: PropTypes.object,
  set_alert_stats: PropTypes.func,
  dashbord_version: PropTypes.string,
};
export function NoData({ text }) {
  return (
    <div
      className="company_stats_graph_div"
      style={{
        height: "220px",
        width: "25%",
        margin: "auto",
        marginTop: "1rem",
      }}
    >
      <div className="card-body" style={{ height: "200px" }}>
        <h5 style={{ marginTop: "20px", color: "white" }}>{text}</h5>
      </div>
      <div className="card_arrow">
        <div className="card_arrow_top_left" />
        <div className="card_arrow_top_right" />
        <div className="card_arrow_bottom_left" />
        <div className="card_arrow_bottom_right" />
      </div>
    </div>
  );
}

NoData.propTypes = {
  text: PropTypes.string,
};

export default memo(CompanyStats);

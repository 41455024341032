import React from "react";
import PropTypes from "prop-types";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

// Custom tooltip
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#2b3c46",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
function TooltipCustom({ tooltip_text }) {
  return (
    <div>
      <HtmlTooltip title={tooltip_text}>
        <p className="chart_query_heading">{tooltip_text}</p>
      </HtmlTooltip>
    </div>
  );
}
TooltipCustom.propTypes = {
  tooltip_text: PropTypes.string,
};

export default TooltipCustom;

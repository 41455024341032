/* eslint-disable no-undef */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Stack } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import InputSwitch from "../sections/Switch/InputSwitch";

import "../../../css/Modal/modal.css";

function StealerlogsCountry({
  open_modal,
  set_open_modal,
  all_country_details,
  selected_countries,
  set_selected_countries,
  enable_to_company_user,
  set_enable_to_company_user,
  company_name,
  modal_type,
}) {
  const [input_value, set_input_value] = useState("");
  const [select_all, set_select_all] = useState(false);
  const filter_all_country_code = useMemo(
    () => all_country_details.map((item) => item.key),
    [all_country_details],
  );
  useEffect(() => {
    if (modal_type === "custom_stealer") {
      set_selected_countries([]);
      return;
    }
    if (all_country_details.length > 0 && selected_countries === "all") {
      set_selected_countries(filter_all_country_code);
      set_select_all(true);
    }
    if (Array.isArray(selected_countries) && selected_countries.length >= 252) {
      set_select_all(true);
    }
  }, []);

  const handle_country_selection = (event) => {
    const { value } = event.target;
    const list_lang = [...selected_countries];
    const index_lang = list_lang.indexOf(value);
    index_lang === -1 ? list_lang.push(value) : list_lang.splice(index_lang, 1);
    set_selected_countries(list_lang);
  };

  const handleChange = (event) => {
    set_select_all(event.target.checked);
    if (event.target.checked === true) {
      set_selected_countries(filter_all_country_code);
    } else {
      set_selected_countries([]);
    }
  };
  const filter_all_countries = all_country_details.filter(
    (item) =>
      item.country_name.toLowerCase().startsWith(input_value.toLowerCase()) &&
      ["None", "Republic of Somaliland"].includes(item.country_name) === false,
  );
  return (
    <div>
      <Modal
        open={open_modal}
        onClose={() => set_open_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          id="translation_modal_id"
          className={
            modal_type === "custom_stealer"
              ? "container_modal_stealerlogs_countries_custom scroll-custom"
              : "container_modal_stealerlogs_countries scroll-custom"
          }
        >
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_modal(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          <div
            className="modal_translate_desc"
            id="modal_translate_desc_less_flag"
          >
            <Stack
              direction="row"
              gap={4}
              justifyContent="center"
              alignItems="flex-end"
            >
              <Stack>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Select all
                </FormLabel>
                <InputSwitch
                  checked_status={select_all}
                  handle_change={handleChange}
                  disabled_status={false}
                />
              </Stack>
              {company_name.company_name !== "None" ? (
                <Stack>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Enable to all company users
                  </FormLabel>
                  <InputSwitch
                    checked_status={enable_to_company_user}
                    handle_change={(event) =>
                      set_enable_to_company_user(event.target.checked)
                    }
                    disabled_status={false}
                  />
                </Stack>
              ) : null}

              <input
                placeholder="Filter the countries"
                style={{ marginBlockEnd: 0, alignSelf: "center" }}
                className="global_input_box container_modal_stealerlogs_countries_input"
                value={input_value}
                onChange={(e) => set_input_value(e.target.value)}
              />
              <p className="selected_countries_label">
                Selected countries : <span>{selected_countries.length}</span>{" "}
              </p>
            </Stack>
            <div className="container_modal_stealerlogs_countries_select_container scroll-custom">
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                countries
              </FormLabel>
              <Grid container>
                <Grid item xs={4}>
                  <FormControl>
                    <Box className="container_modal_stealerlogs_countries_select_box">
                      {filter_all_countries.slice(0, 86).map((item, index) => {
                        const getFlagImageUrl =
                          item.key === "AE"
                            ? `${process.env.PUBLIC_URL}/static/images/AE.png`
                            : `https://flagcdn.com/w20/${item[
                                "key"
                              ].toLowerCase()}.png`;
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item["key"]}
                                onChange={(event) =>
                                  handle_country_selection(event)
                                }
                                checked={selected_countries.includes(
                                  item["key"],
                                )}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={
                              <Stack
                                direction="row"
                                columnGap={0.5}
                                alignItems="center"
                              >
                                <img
                                  width="18"
                                  height="15"
                                  src={getFlagImageUrl}
                                  alt="flag"
                                  style={{ objectFit: "cover" }}
                                />
                                {item["country_name"]}
                              </Stack>
                            }
                            key={index}
                            sx={{ float: "left" }}
                          />
                          // <button className="country_select_btn" key={index} onClick={() => handle_country_selection(item)}>
                          //     {item["country_name"]}

                          // </button>
                        );
                      })}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    {/* <FormLabel
                                            id="demo-controlled-radio-buttons-group"
                                            className="label__title"
                                        >
                                            countries
                                        </FormLabel> */}
                    <Box className="container_modal_stealerlogs_countries_select_box">
                      {filter_all_countries
                        .slice(86, 171)
                        .map((item, index) => {
                          const getFlagImageUrl =
                            item.key === "AE"
                              ? `${process.env.PUBLIC_URL}/static/images/AE.png`
                              : `https://flagcdn.com/w20/${item[
                                  "key"
                                ].toLowerCase()}.png`;
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item["key"]}
                                  onChange={(event) =>
                                    handle_country_selection(event)
                                  }
                                  checked={selected_countries.includes(
                                    item["key"],
                                  )}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Stack
                                  direction="row"
                                  columnGap={0.5}
                                  alignItems="center"
                                >
                                  <img
                                    width="18"
                                    height="15"
                                    src={getFlagImageUrl}
                                    alt="flag"
                                    style={{ objectFit: "cover" }}
                                  />
                                  {item["country_name"]}
                                </Stack>
                              }
                              key={index}
                              sx={{ float: "left" }}
                            />
                            // <button className="country_select_btn" key={index} onClick={() => handle_country_selection(item)}>
                            //     {item["country_name"]}

                            // </button>
                          );
                        })}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    {/* <FormLabel
                                            id="demo-controlled-radio-buttons-group"
                                            className="label__title"
                                        >
                                            countries
                                        </FormLabel> */}
                    <Box className="container_modal_stealerlogs_countries_select_box">
                      {filter_all_countries
                        .slice(171, 255)
                        .map((item, index) => {
                          const getFlagImageUrl =
                            item.key === "AE"
                              ? `${process.env.PUBLIC_URL}/static/images/AE.png`
                              : `https://flagcdn.com/w20/${item[
                                  "key"
                                ].toLowerCase()}.png`;
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item["key"]}
                                  onChange={(event) =>
                                    handle_country_selection(event)
                                  }
                                  checked={selected_countries.includes(
                                    item["key"],
                                  )}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Stack
                                  direction="row"
                                  columnGap={0.5}
                                  alignItems="center"
                                >
                                  <img
                                    width="18"
                                    height="15"
                                    src={getFlagImageUrl}
                                    alt="flag"
                                    style={{ objectFit: "cover" }}
                                  />
                                  {item["country_name"]}
                                </Stack>
                              }
                              key={index}
                              sx={{ float: "left" }}
                            />
                          );
                        })}
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
StealerlogsCountry.propTypes = {
  open_modal: PropTypes.bool,
  set_open_modal: PropTypes.func,
  all_country_details: PropTypes.array,
  set_all_country_details: PropTypes.func,
  selected_countries: PropTypes.array,
  set_selected_countries: PropTypes.func,
  enable_to_company_user: PropTypes.bool,
  set_enable_to_company_user: PropTypes.func,
  company_name: PropTypes.object,
  modal_type: PropTypes.string,
};
export default StealerlogsCountry;

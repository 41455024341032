import React, { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Modal from "@mui/material/Modal";
import { add_custom_preference } from "../../../utils/Fetch/fetchApi";
import { Box, Stack } from "@mui/material";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";

function PreferenceType({
  open_modal_pref,
  set_open_modal_pref,
  status_type,
  set_channels,
  set_search_val,
}) {
  const [sub_pref_type, set_sub_pref_type] = useState("user_preference");
  const [dashboard_filter, set_dashboard_filter] = useState("table");
  const company_status = handle_session_get("company_status");
  return (
    <div>
      <Modal
        open={open_modal_pref.status}
        onClose={() =>
          set_open_modal_pref((prev) => ({ ...prev, status: false }))
        }
        // aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="container__modal_preferencetype">
          <Stack justifyContent="space-between" direction="row">
            <div />
            <h5>Choose your Preference.</h5>
            <button
              className="modal-heading-closeBtn"
              onClick={() =>
                set_open_modal_pref((prev) => ({ ...prev, status: false }))
              }
            >
              <CloseIcon
                fontSize="small"
                sx={{
                  color: "#eb0000 ",
                  cursor: "pointer",
                  marginRight: "10px",
                }}
              />
            </button>
          </Stack>

          <div className="modal_desc_preference">
            <Stack alignItems="center" rowGap={2}>
              {company_status !== "None" ? (
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Preference Type
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    row
                    name="controlled-radio-buttons-group"
                    value={sub_pref_type}
                    onChange={(e) => set_sub_pref_type(e.target.value)}
                  >
                    <FormControlLabel
                      value="user_preference"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="User"
                    />
                    <FormControlLabel
                      value="company_preference"
                      control={
                        <Radio
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="Company"
                    />
                  </RadioGroup>
                </FormControl>
              ) : null}
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Dashboard Filter
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  row
                  name="controlled-radio-buttons-group"
                  value={dashboard_filter}
                  onChange={(e) => set_dashboard_filter(e.target.value)}
                >
                  <FormControlLabel
                    value="table"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Table"
                  />
                  <FormControlLabel
                    value="graph"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Graph"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <div className="justify-content-center mt-2">
              <button
                className="button_global_style"
                onClick={() => {
                  add_custom_preference(
                    open_modal_pref["channel_name"],
                    sub_pref_type,
                    dashboard_filter,
                    open_modal_pref["pref_type"],
                    set_channels,
                    status_type,
                  );
                  set_open_modal_pref((prev) => ({ ...prev, status: false }));
                  if (set_search_val) {
                    set_search_val("");
                  }
                }}
              >
                Mark
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
PreferenceType.propTypes = {
  open_modal_pref: PropTypes.object,
  set_open_modal_pref: PropTypes.func,
  status_type: PropTypes.string,
  set_channels: PropTypes.func,
  set_search_val: PropTypes.func,
};

export default PreferenceType;

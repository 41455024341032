import React, {
  useState,
  useEffect,
  useCallback,
  memo,
  useContext,
} from "react";
import PropTypes from "prop-types";
import { ThreeCircles } from "react-loader-spinner";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import DataTable from "./TableData";
import DisplayJson from "../display_json/DisplayJson";
import DisplayGraph from "../display_graph/DisplayGraph";
import { DialogAlertPostNo } from "../modalContainer/DialogAlert";
import "react-toastify/dist/ReactToastify.css";
import { handle_check_category_length } from "../../../Assets/globalfunctions";
import {
  handle_session_get,
  handle_session_set,
} from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
// Redux import
import {
  store_fetched_data,
  handle_pagination_value,
  handle_store_array,
} from "../../../actions/filterAction";
import { filter_logical_keyword } from "../../../utils/Globals/global_functions";
import { filter_context } from "../searchbar/Searchbar";

/*
NOTE : This component is used to make request
for the telegram search which includes Post, User, File and Profile
search including Keyword, Regex, Logical, Bulk and nested search.
*/
function TableDataFunction({ childRef, re_render_table, set_re_render_table }) {
  const history = useHistory();
  const { nested_channel_regex, hybrid_channels, set_hybrid_channels } =
    useContext(filter_context)["telegram_filters"];
  const all_filters_state = useSelector((state) => state.filter_reducer);
  const dispatch = useDispatch();

  const [Data, setData] = useState([]);
  const [showTable, setShowTable] = useState(null);
  const [loadmore_loading, set_loadmore_loading] = useState(false);
  const [open_modal_postNo_limit, set_open_modal_postNo_limit] =
    useState(false);
  const [getSearchedData, setGetSearchedData] = useState("");
  const [total_db_data, set_total_db_data] = useState();
  const [telegram_limit, set_telegram_limit] = useState();
  const [file_id, set_file_id] = useState();
  const [session_status, set_session_status] = useState(false);
  const [search_after_id, set_search_after_id] = useState(null);

  // Pagination value from parent
  const [paginationValue, setPaginationValue] = useState(1);

  // Mediator state for the Data display when form is submitted
  const [template_filters, set_template_filters] = useState({
    template_teleSearch: "post",
    template_postSearch: "None",
    template_postsearch_sub: "Channel/Group",
    template_telegramOption_post: "None",
    template_telegramOption_user: "username",
    template_user_group: "group_channel_name",
    template_telegramOption_channnel: "conv_name",
    template_proximity: 0,
    template_post_no: 100,
    template_keyword: "contains",
    template_dataDisplay: "grid",
  });
  const [error_status, set_error_status] = useState(false);
  const [telegram_error, set_telegram_error] = useState();
  useEffect(() => {
    childRef.current = getData;
  }, []);

  useEffect(() => {
    let carry_loop = false;
    if (history.action === "POP" && re_render_table === false) {
      if (session_status === false) {
        if (
          history.location.pathname === "/dashboard" ||
          history.location.pathname === "/beta"
        ) {
          carry_loop = true;
        }
      }
    }
    try {
      if (carry_loop === true) {
        const new_data = handle_session_get("Data");
        const Ratelimits = handle_session_get("Ratelimits");
        const filters_array = JSON.parse(handle_session_get("filters_array"));
        const {
          searchedData,
          new_tele,
          postSearch,
          postNo,
          dataDisplay,
          telegramOption_post,
          telegramOption_user,
          user_group,
          telegramOption_channel,
          keyword,
        } = filters_array;
        const { total_db_data, file_id, search_id } = JSON.parse(new_data);
        setData(all_filters_state.user_searched_data);
        set_total_db_data(total_db_data);
        set_telegram_limit(Ratelimits);
        if (all_filters_state.user_searched_data.length > 0) {
          setShowTable(true);
        }
        set_file_id(file_id);
        set_search_after_id(search_id);
        set_session_status(true);
        set_template_filters((prev) => ({
          ...prev,
          template_teleSearch: new_tele,
          template_postSearch: postSearch,
          template_dataDisplay: dataDisplay,
          template_post_no: postNo,
          template_telegramOption_post: telegramOption_post,
          template_telegramOption_user: telegramOption_user,
          template_user_group: user_group,
          template_telegramOption_channnel: telegramOption_channel,
          template_keyword: keyword,
        }));
        setGetSearchedData(searchedData);
        setPaginationValue(all_filters_state.pagination_value);
        set_re_render_table(true);
      }
    } catch {
      set_re_render_table(true);
    }
  }, [re_render_table]);

  useEffect(() => {
    dispatch(handle_pagination_value(paginationValue));
  }, [paginationValue]);

  try {
    window.onbeforeunload = function () {
      sessionStorage.removeItem("Data");
      sessionStorage.removeItem("filters_array");
      localStorage.removeItem("Data");
      localStorage.removeItem("filters_array");
      localStorage.removeItem("filters_array_tag");
      localStorage.removeItem("filters_array_stealer");
    };
  } catch {
    //Do nothing
  }

  const filter_json = (value) => {
    try {
      if (value.length > 0) {
        const new_value = value.replaceAll("\\n", " ").replaceAll("\n", " ");
        const text = JSON.parse(new_value);
        return text;
      }
    } catch {
      return "";
    }
  };
  // To stop multiple fecthing of data
  let isSubmitBlocked = false;

  // filter date from input to Api
  const getData = useCallback(
    (
      searchedData,
      new_tele,
      date_data,
      telegramOption_post,
      telegramOption_user,
      user_group,
      userFilter,
      postSearch,
      postSearchSub,
      keyword,
      postCategory,
      multimedia,
      postNo,
      dataDisplay,
      dataOrder,
      spamFilter,
      proximity,
      language_array,
      checked_exclude,
      telegramOption_channel,
      channels_list,
    ) => {
      if (dataDisplay !== "json") {
        if (postNo > 2000) {
          set_open_modal_postNo_limit(true);
          return;
        }
      }

      if (showTable === null && Data.length === 0) {
        setShowTable(false);
      } else if (showTable === true && Data.length > 0) {
        set_loadmore_loading(true);
      }

      if (postSearch === "bulk") {
        setGetSearchedData(filter_json(JSON.stringify(searchedData)));
        if (searchedData.includes("\n")) {
          dispatch(
            handle_store_array("bulk_search_array", searchedData.split("\n")),
          );
        } else if (searchedData.includes(",")) {
          dispatch(
            handle_store_array("bulk_search_array", searchedData.split(",")),
          );
        }
      } else {
        setGetSearchedData(searchedData);
      }

      setPaginationValue((prev) => {
        if (search_after_id === null) {
          return 1;
        }
        return prev;
      });
      set_template_filters((prev) => ({
        ...prev,
        template_telegramOption_post: telegramOption_post,
        template_telegramOption_user: telegramOption_user,
        template_user_group: user_group,
        template_dataDisplay: dataDisplay,
        template_keyword: keyword,
        template_proximity: proximity,
        template_post_no: postNo,
        template_telegramOption_channnel: telegramOption_channel,
      }));
      const token = handle_session_get("Token");
      const data_keyword = {
        api_mode: 1,
        qtext: searchedData,
        max: parseInt(postNo),
        multimedia_option: multimedia,
        search_filter: keyword,
        search_type: postSearchSub,
        selectCategory:
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory,
        start_date: date_data[0],
        end_date: date_data[1],
        sort_order: dataOrder,
        select_field: telegramOption_post,
        search_after_id: search_after_id !== "None" ? search_after_id : null,
        fuzzing: 0,
        spam_filter: new_tele === "post" ? spamFilter : null,
        proximity: keyword === "exact" ? Number(proximity) : null,
        target_lang:
          (new_tele === "post" || new_tele === "file") &&
          language_array.length !== 0
            ? language_array
            : "None",
        exclude_source:
          new_tele === "post" || new_tele === "file"
            ? checked_exclude === true
              ? "True"
              : "False"
            : "None",
      };
      const data_regex = {
        api_mode: 2,
        qtext: searchedData,
        max: parseInt(postNo),
        search_type: postSearchSub,
        selectCategory:
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory,
        start_date: date_data[0],
        end_date: date_data[1],
        sort_order: dataOrder,
        search_after_id: search_after_id !== "None" ? search_after_id : null,
        fuzzing: 0,
        select_field: telegramOption_post,
      };
      const data_bulk = {
        api_mode: 4,
        qtext: searchedData,
        max: parseInt(postNo),
        search_type: postSearchSub,
        selectCategory:
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory,
        start_date: date_data[0],
        end_date: date_data[1],
        sort_order: dataOrder,
        select_field: telegramOption_post,
        search_after_id: search_after_id !== "None" ? search_after_id : null,
        fuzzing: 0,
      };
      const data_individual = {
        api_mode: 5,
        qtext: searchedData,
        max: parseInt(postNo),
        search_filter: keyword,
        start_date: date_data[0],
        end_date: date_data[1],
        search_after_id: search_after_id !== "None" ? search_after_id : null,
        channel_name: channels_list,
      };
      const data_logical = {
        api_mode: 3,
        qtext: searchedData.toLowerCase(),
        logic: null,
        max: parseInt(postNo),
        search_type: postSearchSub,
        selectCategory:
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory,
        start_date: date_data[0],
        end_date: date_data[1],
        sort_order: dataOrder,
        select_field: telegramOption_post,
        search_after_id: search_after_id !== "None" ? search_after_id : null,
        fuzzing: 0,
        target_lang: language_array.length !== 0 ? language_array : "None",
        exclude_source:
          language_array.length > 0
            ? checked_exclude === true
              ? "True"
              : "False"
            : "None",
      };
      const data_channel = {
        api_mode: postSearch === "regex" ? 2 : 1,
        qtext: searchedData,
        max: parseInt(postNo),
        multimedia_option: postSearch === "regex" ? null : multimedia,
        search_filter: postSearch === "regex" ? null : keyword,
        search_type: postSearchSub,
        selectCategory:
          handle_check_category_length(postCategory) === true
            ? "all"
            : postCategory,
        start_date: date_data[0],
        end_date: date_data[1],
        sort_order: dataOrder,
        select_field: telegramOption_channel,
        search_after_id: search_after_id !== "None" ? search_after_id : null,
        fuzzing: 0,
      };
      const data_user = {
        api_mode: postSearch === "regex" ? 2 : 1,
        name: searchedData,
        max: parseInt(postNo),
        sort_order: dataOrder,
        fuzzing: 1,
        phone_filter: userFilter === "phone" ? "True" : "False",
        search_type: telegramOption_user,
        search_filter: postSearch === "regex" ? null : keyword,
        group_search_type: user_group,
        search_after_id: search_after_id !== "None" ? search_after_id : null,
      };
      let filter_payload = {};
      if (new_tele === "post" || new_tele === "file") {
        switch (postSearch) {
          case "None":
            filter_payload = { ...data_keyword };
            break;
          case "regex":
            filter_payload = { ...data_regex };
            break;
          case "bulk":
            filter_payload = { ...data_bulk };
            break;
          case "individual":
            filter_payload = { ...data_individual };
            break;
          case "logical":
            filter_payload = { ...data_logical };
            break;
          default:
            filter_payload = { ...data_keyword };
            break;
        }
      } else if (new_tele === "channel") {
        filter_payload = { ...data_channel };
      } else {
        filter_payload = { ...data_user };
      }

      if (!isSubmitBlocked) {
        isSubmitBlocked = true;
        axios
          .post(
            new_tele === "post"
              ? "https://dev.recordedtelegram.com/v2/posts"
              : new_tele === "file"
              ? "https://dev.recordedtelegram.com/file_search"
              : new_tele === "channel"
              ? "https://dev.recordedtelegram.com/v2/profile_search"
              : "https://dev.recordedtelegram.com/v2/users",
            filter_payload,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then((res) => {
            setData([...Data, ...res.data.data]);
            if (dataDisplay !== "json") {
              handle_session_set(
                "Data",
                JSON.stringify({
                  total_db_data: res.data.total_db_data,
                  file_id: res.data.file_id,
                  search_id: res.data.search_id,
                }),
              );
              handle_session_set(
                "Ratelimits",
                JSON.stringify(res.data.ratelimit),
              );
              dispatch(store_fetched_data([...Data, ...res.data.data]));
            }
            if (new_tele === "post" || new_tele === "file") {
              if (
                postSearch === "None" &&
                Object.keys(res.data).includes("trans_lang") &&
                Array.isArray(res.data.trans_lang) &&
                res.data.trans_lang.length > 0
              ) {
                const new_trans_lang = res.data.trans_lang.map(
                  (item) => item.translated_text,
                );
                dispatch(handle_store_array("trans_lang", new_trans_lang));
              } else if (
                postSearch === "logical" &&
                Object.keys(res.data).includes("trans_lang") &&
                Array.isArray(res.data.trans_lang) &&
                res.data.trans_lang.length > 0
              ) {
                let trans_lang_string = "";
                res.data.trans_lang.forEach((item) => {
                  trans_lang_string += `${item.translated_text} or `;
                });
                const new_trans_lang =
                  filter_logical_keyword(trans_lang_string);
                dispatch(handle_store_array("trans_lang", new_trans_lang));
              } else {
                dispatch(handle_store_array("trans_lang", []));
              }
            } else {
              dispatch(handle_store_array("trans_lang", []));
            }
            setShowTable(true);
            set_template_filters((prev) => ({
              ...prev,
              template_teleSearch: new_tele,
              template_postSearch: postSearch,
              template_postsearch_sub: postSearchSub,
            }));
            set_total_db_data(res.data.total_db_data);
            set_telegram_limit(() =>
              new_tele !== "channel" ? res.data.ratelimit : res.data.rate_limit,
            );
            set_error_status(false);
            if (new_tele !== "channel" && new_tele !== "user") {
              set_file_id(res.data.file_id);
            }
            set_search_after_id(res.data.search_id);
            set_loadmore_loading(false);
            isSubmitBlocked = false;
          })
          .catch((err) => {
            try {
              const token_expire_status = handle_token_expire(
                err.response.data,
              );
              if (token_expire_status === true) return;
              setShowTable(true);
              set_error_status(true);
              if ([500, 501, 502, 503, 504].includes(err.response.status)) {
                set_telegram_error(
                  "Internal Server Error: We're experiencing technical difficulties. Please try again later.",
                );
              } else {
                set_telegram_error(err.response.data.errormsg);
              }
              set_loadmore_loading(false);
              isSubmitBlocked = false;
            } catch {
              setShowTable(true);
              set_error_status(true);
              if ([500, 501, 502, 503, 504].includes(err.response.status)) {
                set_telegram_error(
                  "Internal Server Error: We're experiencing technical difficulties. Please try again later.",
                );
              } else {
                set_telegram_error(err.response.data.errormsg);
              }
              set_loadmore_loading(false);
              isSubmitBlocked = false;
            }
          });
      }
    },
    [getSearchedData, search_after_id, Data],
  );

  const {
    template_teleSearch,
    template_postSearch,
    template_postsearch_sub,
    template_telegramOption_post,
    template_telegramOption_user,
    template_user_group,
    template_telegramOption_channnel,
    template_proximity,
    template_post_no,
    template_keyword,
    template_dataDisplay,
  } = template_filters;

  return (
    <>
      {showTable ? (
        error_status === true ? (
          <div className="main__table no_data">
            {telegram_error &&
            telegram_error.includes(
              "'No results. Please try again after some time.'. ERROR_CODE: API v2.0.4 Mode 1.",
            ) === true
              ? "There are no results for your search in our records. Please try another search."
              : telegram_error}
          </div>
        ) : total_db_data === 0 ? (
          <div className="main__table no_data">No data to display</div>
        ) : (
          <div>
            {(template_dataDisplay === "graph" &&
              template_teleSearch === "user") ||
            template_dataDisplay === "grid" ? (
              <DataTable
                teleSearch={template_teleSearch}
                Data={Data}
                getSearchedData={getSearchedData}
                postSearch_props={template_postSearch}
                postsearch_sub={template_postsearch_sub}
                telegramOption_post_props={template_telegramOption_post}
                telegramOption_user={template_telegramOption_user}
                telegramOption_channel={template_telegramOption_channnel}
                total_db_data={total_db_data}
                telegram_limit={telegram_limit}
                set_telegram_limit={set_telegram_limit}
                file_id={file_id}
                user_group={template_user_group}
                getData={getData}
                loadmore_loading={loadmore_loading}
                keyword={template_keyword}
                proximity={template_proximity}
                search_after_id={search_after_id}
                post_no={template_post_no}
                nested_channel_regex={nested_channel_regex}
                hybrid_channels={hybrid_channels}
                set_hybrid_channels={set_hybrid_channels}
                paginationValue={paginationValue}
                setPaginationValue={setPaginationValue}
                table_mode={"main_search_table"}
              />
            ) : template_dataDisplay === "json" ? (
              <DisplayJson
                Data={Data}
                teleSearch={template_teleSearch}
                getSearchedData={getSearchedData}
                file_id={file_id}
                post_no={template_post_no}
                total_db_data={total_db_data}
                telegram_limit={telegram_limit}
              />
            ) : (
              <DisplayGraph
                Data={Data}
                total_db_data={total_db_data}
                telegram_limit={telegram_limit}
              />
            )}
          </div>
        )
      ) : (
        showTable !== null && (
          <div className="spinner">
            <ThreeCircles
              color="#28cab3"
              height={60}
              width={60}
              ariaLabel="three-circles-rotating"
            />
          </div>
        )
      )}
      <DialogAlertPostNo
        open_modal_postNo_limit={open_modal_postNo_limit}
        set_open_modal_postNo_limit={set_open_modal_postNo_limit}
      />
      <ToastContainer />
    </>
  );
}
TableDataFunction.propTypes = {
  childRef: PropTypes.object,
  re_render_table: PropTypes.bool,
  set_re_render_table: PropTypes.func,
};

export default memo(TableDataFunction);

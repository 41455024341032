import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts";
import "../../../../css/Sections/Chart/linechart.css";

function LineChart({ chart_status, chart_data, chart_type }) {
  const line_chart_hourly = {
    series: [
      {
        name: "Posts",
        data: chart_data,
      },
    ],
    options: {
      chart: {
        height: 450,
        type: "line",
        zoom: {
          enabled: false,
        },
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1000,
          },
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
        labels: {
          colors: "#fff",
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: "#fff",
            fontSize: "12px",
          },
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["#00ff00"],
      },
      grid: {
        show: true,
        borderColor: "#476677",
        lineWidth: 0.1,
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 5,
        },
      },
      xaxis: {
        labels: {
          formatter(value) {
            if (value) {
              return `${value}\n(UTC)`;
            }
          },
          style: {
            colors: "white",
            fontSize: "11px",
          },
        },
      },
      markers: {
        size: [3, 5],
        strokeColors: "#00ff00",
        strokeWidth: 1,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        shape: "circle",
        radius: 2,
      },
    },
  };
  return (
    <div className="company_stats_graph_div">
      <div className="card-header">
        <div
          className="stats_title_color"
          style={{
            background: "none",
            textTransform: "capitalize",
          }}
        >
          <span style={{ fontSize: "20px", marginLeft: "1rem" }}>
            {chart_type === "channel"
              ? `Channel/Group Posts`
              : `Activity Graph`}
          </span>
        </div>
      </div>
      {chart_status.loading === true ? (
        <div className="card-body scroll-custom" style={{ height: "450px" }}>
          {" "}
          <div className="blocks list-tab">
            <div className="block orange" />
            <div className="block blue" />
          </div>
          <h6 style={{ marginTop: "-25px", color: "white" }}>
            Data may take time to load
          </h6>
        </div>
      ) : (chart_status.error_status === true && chart_status.error) ||
        chart_data.length === 0 ? (
        <div
          className="card-body no_data_container"
          style={{ height: "450px" }}
        >
          <h2 style={{ color: "white" }}>No Data to Display</h2>
        </div>
      ) : (
        <div className="hourly_post_line_chart_graph">
          <Chart
            options={line_chart_hourly.options}
            series={line_chart_hourly.series}
            type="line"
            height={450}
          />
        </div>
      )}
      <div className="card_arrow">
        <div className="card_arrow_top_left" />
        <div className="card_arrow_top_right" />
        <div className="card_arrow_bottom_left" />
        <div className="card_arrow_bottom_right" />
      </div>
    </div>
  );
}
LineChart.propTypes = {
  chart_status: PropTypes.object,
  chart_data: PropTypes.array,
  chart_type: PropTypes.string,
};

export default LineChart;

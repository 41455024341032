/* eslint-disable react/react-in-jsx-scope */
import { ThemeProvider } from "@mui/material/styles";
import HomeTable from "./components/home/Home";
import theme from "./Theme";

function VersionTable() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <HomeTable />
      </div>
    </ThemeProvider>
  );
}
export default VersionTable;

import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik } from "formik";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import { Stack } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import { useDispatch } from "react-redux";
import { RollerAnimation } from "../loader/Loader";
import Datepicker from "../sections/DatePicker/Datepicker";
import { handle_array_logical } from "../../../actions/filterAction";
import { filter_context } from "../searchbar/Searchbar";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { get_custom_preference } from "../../../utils/Fetch/fetchApi";
import "../../../css/Modal/modal.css";

function NestedChannelSearch({
  open_nested_channel_modal,
  set_open_nested_channel_modal,
}) {
  const {
    keyword,
    setKeyword,
    setDateInput,
    date_from,
    set_date_from,
    date_to,
    set_date_to,
    postNo,
    setPostNo,
    dataDisplay,
    setDataDisplay,
    channels_list,
    set_channels_list,
    hybrid_channels,
    set_hybrid_channels,
    set_typed_value,
    set_nested_channel_regex,
    search_sender,
    search_value,
    set_search_value,
  } = useContext(filter_context)["telegram_filters"];
  const dispatch = useDispatch();
  const [search_val, set_search_val] = useState("");
  const [search_data, set_search_data] = useState(null); // Get the list of channels
  const [stat_loading, set_stat_loading] = useState(false);
  const [search_error, set_search_error] = useState(null);
  useEffect(() => {
    setTimeout(() => {
      get_custom_preference(set_hybrid_channels, "table");
    });
  }, []);
  useEffect(() => {
    const marked_channels_company = hybrid_channels["marked_channels"][
      "company_preference"
    ]
      .filter((item) => item["pref_type"] === "telegram_channel")
      .map((item) => item["keyword"]);
    const marked_channels_user = hybrid_channels["marked_channels"][
      "user_preference"
    ]
      .filter((item) => item["pref_type"] === "telegram_channel")
      .map((item) => item["keyword"]);
    set_channels_list([
      ...marked_channels_company,
      ...marked_channels_user,
      ...hybrid_channels["unmarked_channels"],
    ]);
  }, [hybrid_channels]);
  const val_change = (e) => {
    set_search_val(e.target.value);
    const token = localStorage.getItem("Token");
    if (e.target.value.length > 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value };
      axios
        .post("https://dev.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          set_search_data(res.data);
          set_stat_loading(false);
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_stat_loading(false);
          set_search_error(err.response.data.errormsg);
        });
    } else {
      set_search_data(null);
    }
  };
  useEffect(() => {
    setDateInput([date_from, date_to]);
  }, [date_from, date_to]);

  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  };

  const handleChangeDataDisplay = (event) => {
    setDataDisplay(event.target.value);
  };

  const handleChangePostNo = (event) => {
    setPostNo(event.target.value);
  };

  const add_channels_list = (item) => {
    set_hybrid_channels((prev) => {
      if (prev["unmarked_channels"].includes(item) === false) {
        return {
          ...prev,
          unmarked_channels: [...prev["unmarked_channels"], item],
        };
      } else return prev;
    });
    set_search_val("");
  };

  const handle_delete_channels = (clicked, pref_type) => {
    if (pref_type === "unmarked") {
      const filter_channels = hybrid_channels["unmarked_channels"].filter(
        (item) => item !== clicked,
      );
      set_hybrid_channels((prev) => ({
        ...prev,
        unmarked_channels: filter_channels,
      }));
    } else if (pref_type === "user_pref") {
      const filter_channels = hybrid_channels["marked_channels"][
        "user_preference"
      ].filter((item) => item.keyword !== clicked);
      set_hybrid_channels((prev) => ({
        ...prev,
        marked_channels: {
          ...prev["marked_channels"],
          user_preference: filter_channels,
        },
      }));
    } else if (pref_type === "company_pref") {
      const filter_channels = hybrid_channels["marked_channels"][
        "company_preference"
      ].filter((item) => item.keyword !== clicked);
      set_hybrid_channels((prev) => ({
        ...prev,
        marked_channels: {
          ...prev["marked_channels"],
          company_preference: filter_channels,
        },
      }));
    }
  };
  const channels_list_chips_marked_user_preference = hybrid_channels[
    "marked_channels"
  ]["user_preference"]
    .filter((val) => val["pref_type"] === "telegram_channel")
    .map((item, index) => (
      <Chip
        label={item.keyword}
        onDelete={() => handle_delete_channels(item.keyword, "user_pref")}
        color="primary"
        key={index}
        sx={{ margin: "2px" }}
      />
    ));
  const channels_list_chips_marked_company_preference = hybrid_channels[
    "marked_channels"
  ]["company_preference"]
    .filter((val) => val["pref_type"] === "telegram_channel")
    .map((item, index) => (
      <Chip
        label={item.keyword}
        onDelete={() => handle_delete_channels(item.keyword, "company_pref")}
        color="secondary"
        key={index}
        sx={{ margin: "2px" }}
      />
    ));
  const channels_list_chips_unmarked = hybrid_channels["unmarked_channels"].map(
    (item, index) => (
      <Chip
        label={item}
        onDelete={() => handle_delete_channels(item, "unmarked")}
        color="success"
        key={index}
        sx={{ margin: "2px" }}
      />
    ),
  );
  return (
    <div>
      <Modal
        open={open_nested_channel_modal}
        onClose={() => set_open_nested_channel_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="container_modal_nested_channel scroll-custom"
          id="container_modal_nested_channel"
          sx={{ height: "70vh" }}
        >
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_nested_channel_modal(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          <div className="modal_nested_channel_desc">
            <Formik
              initialValues={{ channel_name: "", search_word: "" }}
              validate={(values) => {
                const errors = {};

                if (values.search_word.length === 0) {
                  errors.search_word = "";
                }
                if (channels_list.length === 0) {
                  errors.channel_name = "";
                }
                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                  set_open_nested_channel_modal(false);
                  const alert_validator = (txt) => {
                    const new_sub = /\s{2,}/g;
                    const double_space_remover = txt.replaceAll(new_sub, " ");
                    const open_braket_space_remover =
                      double_space_remover.replace(/\([^\w+]/g, "(");
                    const close_braket_space_remover =
                      open_braket_space_remover.replace(/[^\w+]\)/g, ")");
                    return close_braket_space_remover;
                  };
                  const logical_checker = (arr) => {
                    const new_arr = arr
                      .filter(
                        (item) =>
                          item.toLowerCase() !== "or" &&
                          item.toLowerCase() !== "and" &&
                          item.toLowerCase() !== "not",
                      )
                      .map((value) => value.trim());
                    dispatch(handle_array_logical(new_arr));
                    return true;
                  };

                  const conv_data = alert_validator(values.search_word);
                  const remove_bracket_open = conv_data.replaceAll("(", "");
                  const remove_bracket_close = remove_bracket_open.replaceAll(
                    ")",
                    "",
                  );
                  const remove_quatation = remove_bracket_close
                    .replaceAll('"', "")
                    .replaceAll("'", "");
                  logical_checker(
                    remove_quatation.split(/\s\b(and|or|not|AND|OR|NOT)\b\s/i),
                  );

                  const regex_validator = () => {
                    // eslint-disable-next-line no-useless-escape
                    const re = /[+*\[\]?||\(\)]/g;
                    const hits = [];
                    let match = null;
                    try {
                      do {
                        match = re.exec(values.search_word);
                        if (match) {
                          hits.push(match[0]);
                        }
                      } while (match);
                    } catch {
                      // Do nothing
                    }
                    if (hits.length > 1) {
                      return true;
                    }
                    if (
                      values.search_word.includes("[a-z]") ||
                      values.search_word.includes("[0-9]") ||
                      values.search_word.includes("w") ||
                      values.search_word.includes("d")
                    ) {
                      return true;
                    }
                  };

                  if (regex_validator(values.search_word) === true) {
                    set_nested_channel_regex(true);
                  } else {
                    set_nested_channel_regex(false);
                  }

                  set_typed_value(values.search_word.trim());
                  if (values.search_word.trim() === search_value) {
                    search_sender();
                  } else {
                    set_search_value(values.search_word.trim());
                  }
                }, 400);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div className="nested_input_div">
                    <label htmlFor="nested_channel_name">Channels/Groups</label>
                    <input
                      name="channel_name"
                      className="nested_channel_name"
                      id="nested_channel_name"
                      onChange={(e) => {
                        handleChange(e);
                        val_change(e);
                      }}
                      onBlur={handleBlur}
                      value={search_val}
                      type="search"
                      placeholder="add channels/groups"
                      autoComplete="off"
                    />
                  </div>
                  {/* list of channels */}
                  <div style={{ position: "relative", width: "100%" }}>
                    {search_val.length > 2 ? (
                      <div
                        className="autocom-box mx-auto"
                        style={{
                          width: "70%",
                          position: "absolute",
                          zIndex: 1,
                          left: "50%",
                          transform: "translateX(-50%)",
                        }}
                      >
                        {stat_loading ? (
                          <RollerAnimation />
                        ) : search_error !== null ? (
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "larger",
                              color: "white",
                              marginTop: "10%",
                            }}
                          >
                            {search_error}
                          </div>
                        ) : search_data !== null && search_data.length === 0 ? (
                          <div
                            style={{
                              textAlign: "center",
                              fontSize: "larger",
                              color: "white",
                              marginTop: "10%",
                            }}
                          >
                            No Data to display
                          </div>
                        ) : (
                          <>
                            <h4
                              style={{
                                paddingLeft: "1rem",
                                marginLeft: "15px",
                                marginTop: "5px",
                                fontSize: "18px",
                                color: "whitesmoke",
                              }}
                            >
                              List Of Channels
                            </h4>
                            <ul>
                              {search_data !== null &&
                                search_data.map((val, index) => (
                                  <li key={index}>
                                    <div className="row">
                                      <div
                                        className="col-md-7"
                                        style={{ textAlign: "left" }}
                                      >
                                        {" "}
                                        <img
                                          style={{
                                            width: "36px",
                                            height: "36px",
                                            padding: "2px",
                                            border: "2px solid #f4f4f4",
                                          }}
                                          className="img-fluid rounded-circle"
                                          src={
                                            val.channel_id !== "None"
                                              ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                          }
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                          }}
                                          alt="User"
                                        />
                                        <span
                                          className="channels_list_map"
                                          style={{
                                            marginLeft: "10px",
                                            color: "#dcf3ff",
                                          }}
                                        >
                                          {val.channel_name}
                                        </span>
                                      </div>
                                      <div
                                        className="col-md-5"
                                        style={{
                                          textAlign: "right",
                                          marginTop: "5px",
                                        }}
                                      >
                                        {hybrid_channels[
                                          "unmarked_channels"
                                        ].includes(val.channel_name) ===
                                        false ? (
                                          <button
                                            type="button"
                                            style={{
                                              borderRadius: "40px",
                                              background: "#d03940",
                                              color: "white",
                                              lineHeight: "1",
                                            }}
                                            className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                            onClick={() =>
                                              add_channels_list(
                                                val.channel_name,
                                              )
                                            }
                                          >
                                            Add
                                          </button>
                                        ) : (
                                          <button
                                            type="button"
                                            disabled
                                            style={{
                                              borderRadius: "40px",
                                              background: "#4cabd9",
                                              color: "white",
                                              lineHeight: "1",
                                            }}
                                            className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                          >
                                            Added
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </>
                        )}
                      </div>
                    ) : null}
                  </div>
                  {[
                    ...hybrid_channels["marked_channels"][
                      "company_preference"
                    ].filter(
                      (item) => item["pref_type"] === "telegram_channel",
                    ),
                    ...hybrid_channels["marked_channels"][
                      "user_preference"
                    ].filter(
                      (item) => item["pref_type"] === "telegram_channel",
                    ),
                  ].length > 0 ? (
                    <div className="channels_container">
                      <p className="preference_channels_title">
                        Marked Channels
                      </p>
                      <Stack
                        direction="row"
                        style={{ width: "92%", marginTop: "-28px" }}
                        justifyContent="flex-end"
                      >
                        <button
                          type="button"
                          className="clear_all_marked_channels_btn"
                          onClick={() =>
                            set_hybrid_channels((prev) => ({
                              ...prev,
                              marked_channels: {
                                company_preference: [],
                                user_preference: [],
                              },
                            }))
                          }
                        >
                          clear all
                        </button>
                      </Stack>

                      <div>
                        {(channels_list_chips_marked_user_preference.length ??
                          0) > 0 ? (
                          <>
                            <p className="preference_channels_heading">
                              User Preference
                            </p>
                            <div className="preference_channels_list_chips">
                              {channels_list_chips_marked_user_preference}
                            </div>
                          </>
                        ) : null}
                        {(channels_list_chips_marked_company_preference.length ??
                          0) > 0 ? (
                          <>
                            <p className="preference_channels_heading mt-3">
                              Company Preference
                            </p>
                            <div className="preference_channels_list_chips">
                              {channels_list_chips_marked_company_preference}
                            </div>
                          </>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                  {hybrid_channels["unmarked_channels"].length > 0 ? (
                    <div className="channels_container">
                      <p className="preference_channels_title">
                        Unmarked Channels
                      </p>
                      <div className="preference_channels_list_chips">
                        {channels_list_chips_unmarked}
                      </div>
                    </div>
                  ) : null}
                  <div className="nested_input_div">
                    <label htmlFor="nested_search_word">Search</label>
                    <input
                      name="search_word"
                      className="nested_search_word"
                      id="nested_search_word"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.search_word}
                      placeholder="type to search"
                      autoComplete="off"
                    />
                    <p className="channel_search_note">
                      <b>Note</b> : Logical and Regex queries are also
                      supported.
                    </p>
                  </div>
                  <h5
                    style={{
                      color: "var(--headingColor)",
                      marginBottom: 0,
                      marginTop: "4px",
                    }}
                  >
                    Filters
                  </h5>
                  <div
                    style={{
                      borderBottom: "0.3px solid #f5f5f55e",
                      width: "80%",
                    }}
                  />
                  <div
                    className="nested_channel_filters"
                    style={{ height: "182px" }}
                  >
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Keyword
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={keyword}
                        onChange={handleChangeKeyword}
                      >
                        <FormControlLabel
                          value="contains"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Contains"
                        />
                        <FormControlLabel
                          value="exact"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Exact"
                        />
                      </RadioGroup>
                    </FormControl>

                    <FormControl>
                      <div className="dateRangePicker">
                        <FormLabel
                          id="demo-controlled-radio-buttons-group"
                          className="label__title"
                        >
                          Date
                        </FormLabel>
                        <Datepicker
                          date_from={date_from}
                          set_date_from={set_date_from}
                          date_to={date_to}
                          set_date_to={set_date_to}
                        />
                      </div>
                    </FormControl>

                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Display
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={dataDisplay}
                        onChange={handleChangeDataDisplay}
                      >
                        <FormControlLabel
                          value="grid"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Table"
                        />
                        <FormControlLabel
                          value="graph"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="Graph"
                        />
                        <FormControlLabel
                          value="json"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label="json"
                        />
                      </RadioGroup>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        id="demo-controlled-radio-buttons-group"
                        className="label__title"
                      >
                        Post No
                      </FormLabel>

                      <input
                        type="number"
                        value={postNo}
                        onChange={handleChangePostNo}
                        className="post_no_input"
                      />
                    </FormControl>
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="button_global_style"
                  >
                    Search
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
NestedChannelSearch.propTypes = {
  open_nested_channel_modal: PropTypes.bool,
  set_open_nested_channel_modal: PropTypes.func,
};

export default NestedChannelSearch;

import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TweetdeckDisplay from "./TweetdeckDisplay";
import Usernavstats from "../Navbar/Usernavstats";
import {
  handle_session_get,
  handle_session_set,
} from "../../../utils/SessionStorage/handle_session";
import { styles_background_img } from "../../../utils/Globals/global_functions";
import "../../../css/Display_flex/displayflex.css";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";

function DisplayFlexFunction() {
  const token = handle_session_get("Token");
  const [flex_data, set_flex_data] = useState([]);
  const [showTable, setShowTable] = useState(null);

  const [flex_error, set_flex_error] = useState();
  const [flex_error_status, set_flex_error_status] = useState(false);
  const [channelName, setChannelName] = useState();

  const [search_after_id, set_search_after_id] = useState(null);
  const [flex_loading, set_flex_loading] = useState(false);
  const [flex_loadmore_loading, set_flex_loadmore_loading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [search_type, set_search_type] = useState();

  const containsEncodedComponents = (x) => {
    try {
      return decodeURI(x);
    } catch {
      return x;
    }
  };

  useEffect(() => {
    if (token !== null) {
      const url = window.location.href.toString();
      const new_url = containsEncodedComponents(url);
      const url_split = new_url.split("&type=");
      const search_type = url_split[1];
      const channel_name = url_split[0].split("?")[1].split("channel=")[1];
      get_flex_data(channel_name, search_type, "main_loading");
      setChannelName(channel_name);
      set_search_type(search_type);
    }
  }, []);

  let isSubmitBlocked = false;
  const get_flex_data = useCallback(
    (channel, search_type, loading_type) => {
      setShowTable(false);
      if (loading_type === "main_loading") {
        set_flex_loading(true);
      } else {
        set_flex_loadmore_loading(true);
      }
      const data = {
        qtext: channel,
        max: 50,
        start_date: "None",
        end_date: "None",
        sort_order: "desc",
        search_type,
        filter: "None",
        search_after_id,
      };
      if (!isSubmitBlocked) {
        isSubmitBlocked = true;
        axios
          .post(
            " https://dev.recordedtelegram.com/individual_channel_posts",
            data,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            },
          )
          .then((res) => {
            set_flex_data((prev) => [...prev, ...res.data.data]);
            setHasMore(res.data.data.length > 0);
            setShowTable(true);
            set_flex_error_status(false);
            set_flex_loading(false);
            set_flex_loadmore_loading(false);
            set_search_after_id(res.data.search_id);
            handle_session_set(
              "Ratelimits",
              JSON.stringify(res.data.ratelimit),
            );
            isSubmitBlocked = false;
          })
          .catch((err) => {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_flex_loading(false);
            set_flex_loadmore_loading(false);
            setShowTable(true);
            set_flex_error_status(true);
            set_flex_error(err.response.data.errormsg);
            isSubmitBlocked = false;
          });
      }
    },
    [flex_data, search_after_id],
  );

  return (
    <>
      <div
        className="company_stats_main_container"
        style={styles_background_img}
      >
        <div className="display_flex_nav">
          <Usernavstats />
        </div>
        <TweetdeckDisplay
          Data={flex_data}
          flex_loading={flex_loading}
          flex_loadmore_loading={flex_loadmore_loading}
          hasMore={hasMore}
          get_flex_data={get_flex_data}
          channelName={channelName}
          showTable={showTable}
          flex_error_status={flex_error_status}
          flex_error={flex_error}
          search_type={search_type}
        />
      </div>

      <ToastContainer />
    </>
  );
}

export default DisplayFlexFunction;

import React, { useState, useEffect, createContext } from "react";
import { IconContext } from "react-icons";
import { Redirect } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import "../../styles2.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { GlobalStyles } from "@mui/material";
import Adminmainnav from "./Adminmainnav";
// import Adminsidenav from "./Adminsidenav";
import Sidenav from "../../TableUi/components/Navbar/Sidenav";
import AddCompanyNameAdmin from "../../TableUi/components/modalContainer/AddCompanyNameAdmin";
import { handle_check_category_length } from "../../Assets/globalfunctions";
import { handle_session_get } from "../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../utils/Validation/Token/token_checker";
import { options_post_category } from "../../Assets/globalfunctions";
import { rename_all_categories_name } from "../../utils/Globals/global_functions";
import {
  handle_success_message,
  handle_error_message,
} from "../../utils/ResponseMessage/response_message";
import { useDispatch } from "react-redux";
import { handle_store_url_location } from "../../actions/filterAction";
import StealerlogsCountry from "../../TableUi/components/modalContainer/StealerlogsCountry";
import { get_all_country_details } from "../../utils/Fetch/fetchApi";

const industry_context = createContext();
function Userprof() {
  const token = handle_session_get("Token");
  const dispatch = useDispatch();
  const [update_user, set_update_user] = useState(false);
  const [user_dat, set_user_dat] = useState(null);
  const [authorization, set_authorization] = useState("");
  const [ratelimit, set_ratelimit] = useState("");
  const [userid, set_userid] = useState("");
  const [redirect, set_redirect] = useState(false);
  const [password, set_password] = useState("None");
  const [edit_pass, set_edit_pass] = useState(false);
  const [persistent_daily, set_persistent_daily] = useState("");
  const [breached_limit, set_breached_limit] = useState("");
  const [reportgenerator_ratelimit, set_reportgenerator_ratelimit] =
    useState("");
  const [forums_access_limit, set_forums_access_limit] = useState("");

  const [max_result, set_max_result] = useState("");
  const [monthly_channel_search, set_monthly_channel_search] = useState("");
  const [monthly_pagination_limit, set_monthlty_pagination_limit] =
    useState("");
  const [company_details, set_company_details] = useState([]);
  const [company_name, set_company_name] = useState({});

  const [show_company_dropdown, set_show_company_dropdown] = useState(false);

  const [open_stealer_logs_country, set_open_stealer_logs_country] =
    useState(false);
  const [all_country_details, set_all_country_details] = useState([]);
  const [selected_countries, set_selected_countries] = useState([]);
  const [enable_to_company_user, set_enable_to_company_user] = useState(false);

  // state to know for what , Modal is open for creating new company or update
  const [company_modal_status, set_company_modal_status] = useState();
  const [channel_suggestion_limit, set_channel_suggestion_limit] = useState("");

  // Modal to create the company
  const [open_modal_company_name, set_open_modal_company_name] =
    useState(false);

  // Drop down
  const [user_account, set_user_account] = useState();
  const [tag_search_auth, set_tag_search_auth] = useState();

  const [tag_search_limit, set_tag_search_limit] = useState();
  const [stealer_logs_limit, set_stealer_logs_limit] = useState();
  const [user_search_token_limit, set_user_search_token_limit] = useState(0);
  const [stealer_logs_access, set_stealer_logs_access] = useState(true);
  const [dashboard_version2, set_dashboard_version2] = useState(false);
  const [brand_protection_limit, set_brand_protection_limit] = useState({
    monthly_limit: 0,
    csv_limit: 0,
  });
  const [user_industry, set_user_industry] = useState("");
  const [all_industries, set_all_industries] = useState([]);
  const [enable_industry, set_enable_industry] = useState(false);
  useEffect(() => {
    toast.configure();
    const copiedDate = new Date(Date.now() + 6 * (60 * 60 * 1000));
    new Date();
    const low_date = new Date(Date.now() - 6 * (60 * 60 * 1000));
    new Date();
    let tok_date = null;

    try {
      tok_date = new Date(localStorage.getItem("Expdate"));
    } catch (e) {
      //Do nothing
    }
    if (tok_date <= copiedDate && tok_date >= low_date) {
      set_redirect(false);
    } else {
      set_redirect(true);
    }
    const auth = localStorage.getItem("Type");
    if (auth !== "Auth") {
      set_redirect(true);
    }
    get_company_name();
    handle_get_industry_credential();
    get_all_country_details(set_all_country_details);
  }, []);
  const get_data = (company_array) => {
    const url = window.location.href.split("page=")[1];

    axios
      .post(
        "https://dev.recordedtelegram.com/v2/getclient",
        { id: url },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then((response) => {
        const data =
          Array.isArray(response.data) === true
            ? response.data[0]
            : response.data;
        set_user_dat(data);
        set_ratelimit(data.ratelimit);
        set_userid(data.userid);
        set_user_account(data.user_role);
        set_update_user(false);
        set_edit_pass(false);
        set_authorization(data.authorization === true ? "True" : "False");
        set_persistent_daily(data.persistent_daily);
        set_breached_limit(data.breached_access_limit);
        set_reportgenerator_ratelimit(data.reportgenerator_ratelimit);
        set_forums_access_limit(data.forums_access_limit);
        set_max_result(data.max_results_per_search_query);
        set_monthly_channel_search(data.max_changroup_search_ratelimit);
        set_monthlty_pagination_limit(data.pagination_limit);
        set_channel_suggestion_limit(data.channel_suggestion_limit);
        set_tag_search_auth(data.tags_search_auth === true ? "True" : "False");
        set_enable_industry(
          data.enable_industry_preference === "True" ||
            data.enable_industry_preference === true
            ? "True"
            : "False",
        );
        set_tag_search_limit(data.tags_search_limit);
        set_stealer_logs_limit(data.stealer_log_limit);
        set_user_search_token_limit(data.user_search_token ?? 0);
        set_brand_protection_limit({
          monthly_limit: data.brand_protection_monthly_limit ?? 0,
          csv_limit: data.brand_protection_csv_limit ?? 0,
        });
        set_user_industry((prev) => {
          if (Array.isArray(data.industries)) {
            if (data.industries.length > 0) {
              return data.industries[0].name;
            }
          }
          return prev;
        });
        set_stealer_logs_access(
          [true, "True", "true"].includes(data.stealer_logs_access),
        );
        set_dashboard_version2(
          [true, "True", "true"].includes(data.enable_dashboard),
        );
        if (company_array && company_array.length > 0) {
          const filter_company_name = company_array.filter(
            (val) => val.company_id === parseInt(data.company_id),
          )[0];
          set_company_name(filter_company_name);
        }
        if (data.country_access) {
          if (data.country_access === "all" && all_country_details.length > 0) {
            const filter_country_key = all_country_details.map(
              (item) => item.key,
            );
            set_selected_countries(filter_country_key);
          } else {
            if (data.country_access !== "all") {
              set_selected_countries(data.country_access);
            } else if (all_country_details.length === 0) {
              set_selected_countries(data.country_access);
            }
          }
        }
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };
  const get_company_name = () => {
    axios
      .get("https://dev.recordedtelegram.com/company_info", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        set_company_details(response.data.data);
        get_data(response.data.data);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };
  const handle_get_industry_credential = () => {
    axios
      .get("https://dev.recordedtelegram.com/industry_preference", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        const response = res.data.data;
        set_all_industries(response);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };
  const updt_user_and_authorization = (e, status) => {
    e.preventDefault();
    const data = {
      id: userid,
      ratelimit,
      category_access:
        handle_check_category_length(company_name.category_access) === true
          ? "all"
          : company_name.category_access,
      isauthorized: authorization === "True",
      newpassword: status === "password" ? password : "None",
      persistent_daily,
      breached_ratelimit: breached_limit,
      forums_ratelimit: forums_access_limit,
      reportgen_ratelimit: reportgenerator_ratelimit,
      ratelimit_maxsearch: max_result,
      ratelimit_maxchannelsearch: monthly_channel_search,
      user_role: user_account,
      pagination: Number(monthly_pagination_limit),
      company_id: company_name.company_id,
      channel_suggestion_limit: Number(channel_suggestion_limit),
      tags_search_auth: tag_search_auth === "True",
      enable_industry_preference: enable_industry === "True",
      tags_search_limit: Number(tag_search_limit),
      stealer_log_limit: Number(stealer_logs_limit),
      stealer_logs_access: [true, "True", "true"].includes(stealer_logs_access),
      enable_dashboard: [true, "True", "true"].includes(dashboard_version2),
      brand_protection_monthly_limit: Number(
        brand_protection_limit["monthly_limit"],
      ),
      brand_protection_csv_limit: Number(brand_protection_limit["csv_limit"]),
      user_search_token: Number(user_search_token_limit),
      industries:
        user_industry === "none"
          ? null
          : all_industries
              .filter((item) => user_industry === item.name)
              .map((item) => item.id) ?? [],
      country_access:
        selected_countries.length >= 252 ? "all" : selected_countries,
      enable_company_country_restriction:
        enable_to_company_user === true || enable_to_company_user === "True",
    };
    axios
      .post("https://dev.recordedtelegram.com/v2/admin/updateclient", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        handle_success_message("Updated Sucessfully");
        get_data();
      })
      .catch((error) => {
        const token_expire_status = handle_token_expire(error.response.data);
        if (token_expire_status === true) return;
        handle_error_message(error.response.data.errormsg);
      });
  };
  // Custom tooltip
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#2b3c46",
      maxWidth: 500,
      fontSize: theme.typography.pxToRem(12),
      textAlign: "start",
      color: "#dcf3ff",
      padding: "10px",
    },
  }));
  const tooltip_text = (
    "category_access" in company_name
      ? company_name.category_access === "all"
        ? options_post_category
        : company_name.category_access
      : []
  )
    .map((item) => rename_all_categories_name(item))
    .join(`, `);

  if (redirect) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <>
      <div className="wrapper">
        <GlobalStyles
          styles={{
            ".MuiFormControlLabel-root span": {
              color: "white",
              fontSize: "2 rem",
            },
          }}
        />
        {/* Sidebar  */}
        {/* <Adminsidenav /> */}
        <Sidenav />
        {/* Page Content  */}
        <div id="content" className="active">
          <section>
            <Adminmainnav />
            {user_dat !== null ? (
              <section id="boxes" className="">
                <div className="page-content page-container" id="page-content">
                  <div>
                    <div className="row d-flex justify-content-center w-100">
                      <div className="col-xl-10 col-md-12">
                        <div className="card user-card-full">
                          <div className="row m-l-0 m-r-0">
                            <div className="col-sm-3 bg-c-lite-green user-profile">
                              <div className="card-block text-center text-white">
                                <div className="m-b-25">
                                  {" "}
                                  <img
                                    src="https://img.icons8.com/bubbles/100/000000/user.png"
                                    className="img-radius"
                                    alt="User-Profile"
                                  />{" "}
                                </div>
                                <h6 className="f-w-600">{user_dat.username}</h6>

                                <h6 className="f-w-600">
                                  {user_dat.superadmin === "False"
                                    ? "User"
                                    : " Admin"}
                                </h6>
                                <button style={{ display: "contents" }}>
                                  <IconContext.Provider
                                    value={{ size: 24, color: "#fff" }}
                                  >
                                    <FaEdit
                                      onClick={() => {
                                        set_update_user(true);
                                      }}
                                    />
                                  </IconContext.Provider>
                                </button>
                              </div>
                            </div>
                            <div className="col-sm-8">
                              <div className="card-block">
                                <h5 className="m-b-20 p-b-5 b-b-default f-w-600 text-secondary">
                                  Information
                                </h5>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600">Username</p>
                                    <h6 className="text-muted f-w-400">
                                      {user_dat.username}
                                    </h6>
                                  </div>
                                  <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600">Email</p>
                                    <h6 className="text-muted f-w-400">
                                      {user_dat.email}
                                    </h6>
                                  </div>
                                </div>

                                <div
                                  className="row"
                                  style={{ marginTop: "20px" }}
                                >
                                  <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600">Password</p>
                                    {edit_pass ? (
                                      <div>
                                        <input
                                          type="password"
                                          name="password"
                                          id="password"
                                          className="form-cont"
                                          placeholder="Password"
                                          required
                                          onChange={(e) => {
                                            set_password(e.target.value);
                                          }}
                                        />
                                        {password.length === 0 ? (
                                          <h6
                                            style={{
                                              marginTop: "5px",
                                              color: "red",
                                              marginLeft: "11%",
                                              fontSize: "small",
                                            }}
                                          >
                                            Password must not be empty
                                          </h6>
                                        ) : password.length < 6 ||
                                          password.length > 30 ? (
                                          <h6
                                            style={{
                                              marginTop: "5px",
                                              color: "red",
                                              marginLeft: "11%",
                                              fontSize: "small",
                                            }}
                                          >
                                            Password must be between 6 to 30
                                            characters
                                          </h6>
                                        ) : null}
                                      </div>
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        ********
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600">Tokens</p>
                                    <h6 className="text-muted f-w-400">
                                      {user_dat.tokensgenerated}
                                    </h6>
                                  </div>
                                </div>
                                {edit_pass ? (
                                  <button
                                    style={{ marginLeft: "20px" }}
                                    className="btn btn-danger"
                                    onClick={(event) =>
                                      updt_user_and_authorization(
                                        event,
                                        "password",
                                      )
                                    }
                                  >
                                    Update
                                  </button>
                                ) : update_user ? (
                                  <button
                                    style={{
                                      marginLeft: "20px",
                                      minWidth: "85px",
                                    }}
                                    className="btn btn-primary"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      set_edit_pass(true);
                                    }}
                                  >
                                    Edit
                                  </button>
                                ) : null}

                                <h5 className="m-b-20 m-t-40 p-b-5 b-b-default f-w-600 text-secondary">
                                  Authorizations
                                </h5>
                                <div className="row">
                                  <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600">Authorize</p>
                                    {update_user ? (
                                      <select
                                        className="form-cont"
                                        style={{ WebkitAppearance: "auto" }}
                                        name="authorization"
                                        onChange={(e) => {
                                          set_authorization(e.target.value);
                                        }}
                                      >
                                        <option
                                          selected={authorization === "True"}
                                          value="True"
                                        >
                                          Authenticate
                                        </option>
                                        <option
                                          selected={authorization === "False"}
                                          value="False"
                                        >
                                          Disable
                                        </option>
                                      </select>
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.authorization === true
                                          ? "Authorized"
                                          : " Disable"}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p className="m-b-10 f-w-600">Rate Limit</p>
                                    {update_user ? (
                                      <input
                                        type="text"
                                        name="ratelimit"
                                        id="rate"
                                        className="form-cont"
                                        value={ratelimit}
                                        placeholder="Rate Limit"
                                        required
                                        onChange={(e) => {
                                          set_ratelimit(e.target.value);
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.ratelimit}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Persistent Rate Limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="text"
                                        name="ratelimit"
                                        id="rate"
                                        className="form-cont"
                                        value={persistent_daily}
                                        placeholder="Rate Limit"
                                        required
                                        onChange={(e) => {
                                          set_persistent_daily(e.target.value);
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.persistent_daily}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Dehashed Rate Limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="text"
                                        name="ratelimit"
                                        id="rate"
                                        className="form-cont"
                                        value={breached_limit}
                                        placeholder="Rate Limit"
                                        required
                                        onChange={(e) => {
                                          set_breached_limit(e.target.value);
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.breached_access_limit}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Forum Rate Limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="text"
                                        name="ratelimit"
                                        id="rate"
                                        className="form-cont"
                                        value={forums_access_limit}
                                        placeholder="Rate Limit"
                                        required
                                        onChange={(e) => {
                                          set_forums_access_limit(
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.forums_access_limit}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Report Generator Rate Limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="text"
                                        name="ratelimit"
                                        id="rate"
                                        className="form-cont"
                                        value={reportgenerator_ratelimit}
                                        placeholder="Rate Limit"
                                        required
                                        onChange={(e) => {
                                          set_reportgenerator_ratelimit(
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.reportgenerator_ratelimit}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Max Search Result
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="text"
                                        name="ratelimit"
                                        id="rate"
                                        className="form-cont"
                                        value={max_result}
                                        placeholder="Rate Limit"
                                        required
                                        onChange={(e) => {
                                          set_max_result(e.target.value);
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.max_results_per_search_query}
                                      </h6>
                                    )}
                                  </div>

                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Monthly channel search Limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="text"
                                        name="ratelimit"
                                        id="rate"
                                        className="form-cont"
                                        value={monthly_channel_search}
                                        placeholder="Rate Limit"
                                        required
                                        onChange={(e) => {
                                          set_monthly_channel_search(
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {
                                          user_dat.max_changroup_search_ratelimit
                                        }
                                      </h6>
                                    )}
                                  </div>

                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Monthly pagination limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="number"
                                        name="pagination_limit"
                                        id="rate"
                                        className="form-cont"
                                        value={monthly_pagination_limit}
                                        placeholder="monthly pagination limit"
                                        required
                                        onChange={(e) => {
                                          set_monthlty_pagination_limit(
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.pagination_limit}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Company name
                                    </p>
                                    {update_user ? (
                                      <div className="company_name_custom_dropdown_div">
                                        <input
                                          className="form-cont"
                                          value={company_name.company_name}
                                          onClick={() =>
                                            set_show_company_dropdown(
                                              (prev) => !prev,
                                            )
                                          }
                                        />
                                        {show_company_dropdown === true ? (
                                          <ClickAwayListener
                                            onClickAway={() =>
                                              set_show_company_dropdown(false)
                                            }
                                          >
                                            <div className="company_name_custom_dropdown scroll-custom">
                                              {company_details.map(
                                                (item, id) => (
                                                  <div
                                                    className="company_name_custom_dropdown_content"
                                                    key={id}
                                                  >
                                                    <p
                                                      onClick={() => {
                                                        set_company_name(item);
                                                        set_show_company_dropdown(
                                                          false,
                                                        );
                                                      }}
                                                    >
                                                      {item.company_name}
                                                    </p>
                                                    {item.company_name !==
                                                    "None" ? (
                                                      <Tooltip title="Update">
                                                        <button
                                                          onClick={() => {
                                                            set_open_modal_company_name(
                                                              true,
                                                            );
                                                            set_company_modal_status(
                                                              "update",
                                                            );
                                                            set_company_name(
                                                              item,
                                                            );
                                                          }}
                                                        >
                                                          <SettingsIcon
                                                            fontSize="small"
                                                            sx={{
                                                              color: "#0b5ed7",
                                                            }}
                                                          />
                                                        </button>
                                                      </Tooltip>
                                                    ) : null}
                                                  </div>
                                                ),
                                              )}
                                              <button
                                                className="create_new_company_btn"
                                                onClick={() => {
                                                  set_open_modal_company_name(
                                                    true,
                                                  );
                                                  set_company_modal_status(
                                                    "new",
                                                  );
                                                }}
                                              >
                                                Create New
                                              </button>
                                            </div>
                                          </ClickAwayListener>
                                        ) : null}
                                      </div>
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {company_name.company_name}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Categories
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="text"
                                        name="categories"
                                        id="categories"
                                        className="form-cont"
                                        value={
                                          "category_access" in company_name
                                            ? company_name.category_access ===
                                              "all"
                                              ? `${options_post_category.length} categories`
                                              : `${company_name.category_access.length} categories`
                                            : "None"
                                        }
                                        placeholder="Rate Limit"
                                        onClick={() => {
                                          set_open_modal_company_name(true);
                                          set_company_modal_status(
                                            "update_categories",
                                          );
                                        }}
                                        required
                                      />
                                    ) : (
                                      <HtmlTooltip title={tooltip_text}>
                                        <h6 className="text-muted f-w-400">
                                          {"category_access" in company_name
                                            ? company_name.category_access ===
                                              "all"
                                              ? `${options_post_category.length} categories`
                                              : `${company_name.category_access.length} categories`
                                            : "None"}
                                        </h6>
                                      </HtmlTooltip>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Channel suggestion limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="number"
                                        name="channel_suggestion_limit"
                                        id="rate"
                                        className="form-cont"
                                        value={channel_suggestion_limit}
                                        placeholder="channel suggestion limit"
                                        required
                                        onChange={(e) => {
                                          set_channel_suggestion_limit(
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.channel_suggestion_limit}
                                      </h6>
                                    )}
                                  </div>

                                  {/* drop down for tag search auth */}
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Tag Search Auth
                                    </p>
                                    {update_user ? (
                                      <select
                                        value={tag_search_auth}
                                        onChange={(e) =>
                                          set_tag_search_auth(e.target.value)
                                        }
                                        style={{
                                          width: "63%",
                                          marginLeft: "9%",
                                          padding: "0.35rem 0.75rem",
                                        }}
                                      >
                                        <option value="True">True</option>
                                        <option value="False">False</option>
                                      </select>
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.tags_search_auth === true
                                          ? "Authorized"
                                          : "Not Authorized"}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Tag Search limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="number"
                                        name="tag_search_limit"
                                        id="rate"
                                        className="form-cont"
                                        value={tag_search_limit}
                                        placeholder="channel suggestion limit"
                                        required
                                        onChange={(e) => {
                                          set_tag_search_limit(e.target.value);
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.tags_search_limit}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      User Search limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="number"
                                        name="tag_search_limit"
                                        id="rate"
                                        className="form-cont"
                                        value={user_search_token_limit}
                                        placeholder="user search limit"
                                        required
                                        onChange={(e) => {
                                          set_user_search_token_limit(
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.user_search_token || 0}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Roles
                                    </p>
                                    {update_user ? (
                                      <select
                                        value={user_account}
                                        onChange={(e) =>
                                          set_user_account(e.target.value)
                                        }
                                        style={{
                                          width: "63%",
                                          marginLeft: "9%",
                                          padding: "0.35rem 0.75rem",
                                        }}
                                      >
                                        <option value="admin">Admin</option>
                                        <option value="marketing">
                                          Marketing
                                        </option>
                                        <option value="company_admin">
                                          Company Admin
                                        </option>
                                        <option value="premium_user">
                                          Paid
                                        </option>
                                        <option value="user">Trial</option>
                                      </select>
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_account}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Stealer Logs Access
                                    </p>
                                    {update_user ? (
                                      <select
                                        value={stealer_logs_access}
                                        onChange={(e) =>
                                          set_stealer_logs_access(
                                            e.target.value,
                                          )
                                        }
                                        style={{
                                          width: "63%",
                                          marginLeft: "9%",
                                          padding: "0.35rem 0.75rem",
                                        }}
                                      >
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                      </select>
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.stealer_logs_access ===
                                          true ||
                                        user_dat.stealer_logs_access === "True"
                                          ? "Accessed"
                                          : "Restricted"}
                                      </h6>
                                    )}
                                  </div>

                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Stealer logs limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="number"
                                        name="tag_search_limit"
                                        id="rate"
                                        className="form-cont"
                                        value={stealer_logs_limit}
                                        placeholder="channel suggestion limit"
                                        required
                                        onChange={(e) => {
                                          set_stealer_logs_limit(
                                            e.target.value,
                                          );
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.stealer_log_limit}
                                      </h6>
                                    )}
                                  </div>

                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Brand Protection Monthly Limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="number"
                                        name="tag_search_limit"
                                        id="rate"
                                        className="form-cont"
                                        value={
                                          brand_protection_limit[
                                            "monthly_limit"
                                          ]
                                        }
                                        placeholder="Brand protection monthly limit"
                                        required
                                        onChange={(e) => {
                                          set_brand_protection_limit(
                                            (prev) => ({
                                              ...prev,
                                              monthly_limit: e.target.value,
                                            }),
                                          );
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {
                                          user_dat.brand_protection_monthly_limit
                                        }
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Brand protection CSV Limit
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="number"
                                        name="tag_search_limit"
                                        id="rate"
                                        className="form-cont"
                                        value={
                                          brand_protection_limit["csv_limit"]
                                        }
                                        placeholder="Brand protection csv limit"
                                        required
                                        onChange={(e) => {
                                          set_brand_protection_limit(
                                            (prev) => ({
                                              ...prev,
                                              csv_limit: e.target.value,
                                            }),
                                          );
                                        }}
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.brand_protection_csv_limit}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Choose Industry
                                    </p>
                                    {update_user ? (
                                      <select
                                        value={user_industry}
                                        onChange={(e) =>
                                          set_user_industry(e.target.value)
                                        }
                                        style={{
                                          width: "63%",
                                          marginLeft: "9%",
                                          padding: "0.35rem 0.75rem",
                                        }}
                                      >
                                        <option value={"none"}>None</option>
                                        {all_industries
                                          .map((val) => val.name)
                                          .map((item, index) => {
                                            return (
                                              <option key={index} value={item}>
                                                {item}
                                              </option>
                                            );
                                          })}
                                      </select>
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.industries &&
                                        user_dat.industries.length > 0
                                          ? user_dat.industries[0].name
                                          : "No industry assigned"}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Industry Preference
                                    </p>
                                    {update_user ? (
                                      <select
                                        value={enable_industry}
                                        onChange={(e) =>
                                          set_enable_industry(e.target.value)
                                        }
                                        style={{
                                          width: "63%",
                                          marginLeft: "9%",
                                          padding: "0.35rem 0.75rem",
                                        }}
                                      >
                                        <option value="True">Enable</option>
                                        <option value="False">Disable</option>
                                      </select>
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.enable_industry_preference ===
                                          "True" ||
                                        user_dat.enable_industry_preference ===
                                          true
                                          ? "Enabled"
                                          : "Disabled"}
                                      </h6>
                                    )}
                                  </div>

                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Select countries
                                    </p>
                                    {update_user ? (
                                      <input
                                        type="text"
                                        name="categories"
                                        id="categories"
                                        className="form-cont"
                                        value={
                                          user_dat.country_access === "all"
                                            ? "All countries"
                                            : `${user_dat.country_access.length} countries`
                                        }
                                        placeholder="Rate Limit"
                                        onClick={() => {
                                          set_open_stealer_logs_country(true);
                                        }}
                                        required
                                      />
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.country_access === "all"
                                          ? "All countries"
                                          : `${user_dat.country_access.length} countries`}
                                      </h6>
                                    )}
                                  </div>
                                  <div className="col-sm-6">
                                    <p
                                      className="m-b-10 f-w-600"
                                      style={{ marginTop: "15px" }}
                                    >
                                      Dashboard version 2
                                    </p>
                                    {update_user ? (
                                      <select
                                        value={dashboard_version2}
                                        onChange={(e) =>
                                          set_dashboard_version2(e.target.value)
                                        }
                                        style={{
                                          width: "63%",
                                          marginLeft: "9%",
                                          padding: "0.35rem 0.75rem",
                                        }}
                                      >
                                        <option value={true}>True</option>
                                        <option value={false}>False</option>
                                      </select>
                                    ) : (
                                      <h6 className="text-muted f-w-400">
                                        {user_dat.enable_dashboard === true ||
                                        user_dat.enable_dashboard === "True"
                                          ? "Accessed"
                                          : "Restricted"}
                                      </h6>
                                    )}
                                  </div>
                                </div>
                                {update_user ? (
                                  <button
                                    style={{
                                      marginLeft: "20px",
                                      marginTop: "25px",
                                    }}
                                    className="btn btn-danger"
                                    onClick={(event) =>
                                      updt_user_and_authorization(
                                        event,
                                        "authorization",
                                      )
                                    }
                                  >
                                    Update
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            ) : null}
          </section>
        </div>
      </div>
      {open_modal_company_name === true ? (
        <industry_context.Provider value={all_industries}>
          <AddCompanyNameAdmin
            open_modal={open_modal_company_name}
            set_open_modal={set_open_modal_company_name}
            company_modal_status={company_modal_status}
            company_name={company_name}
            get_company_name={get_company_name}
          />
        </industry_context.Provider>
      ) : null}
      {open_stealer_logs_country ? (
        <StealerlogsCountry
          open_modal={open_stealer_logs_country}
          set_open_modal={set_open_stealer_logs_country}
          all_country_details={all_country_details}
          selected_countries={selected_countries}
          set_selected_countries={set_selected_countries}
          enable_to_company_user={enable_to_company_user}
          set_enable_to_company_user={set_enable_to_company_user}
          company_name={company_name}
        />
      ) : null}
      <ToastContainer />
    </>
  );
}

export default Userprof;
export { industry_context };

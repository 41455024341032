import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import Chart from "react-apexcharts";
import { FaUsers, FaUserTie } from "react-icons/fa";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import crypto from "crypto-js";
import update from "immutability-helper";
import "../../../css/Display_graph/displaygraph.css";

function DisplayGraph({ Data }) {
  const [chart_default, set_chart_default] = useState("user");
  const [user_data, set_user_data] = useState(null);
  const [post_data, set_post_data] = useState(null);
  const [table_loading, set_table_loading] = useState(false);

  // state for chart_data function
  const [series, set_series] = useState([
    {
      name: "Messages",
      data: [],
    },
  ]);
  const [options, set_options] = useState({
    chart: {
      id: "apexchart-example",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
      },
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "monospace",
          fontWeight: 400,
          colors: [
            "#FF7EA5",
            "#00FA9A",
            "#4EE2EC",
            "#C388F6",
            "#FF7EA5",
            "#00FA9A",
            "#4EE2EC",
            "#C388F6",
            "#FF7EA5",
            "#00FA9A",
          ],
        },
      },
    },
    yaxis: {
      categories: [],
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "monospace",
          fontWeight: 400,
          colors: [
            "#FF7EA5",
            "#00FA9A",
            "#4EE2EC",
            "#C388F6",
            "#FF7EA5",
            "#00FA9A",
            "#4EE2EC",
            "#C388F6",
            "#FF7EA5",
            "#00FA9A",
          ],
        },
      },
    },

    colors: ["#FF7EA5", "#00FA9A", "#4EE2EC", "#C388F6"],
  });
  const [channels, set_channels] = useState(null);
  const [data, set_data] = useState(null);

  // state for the chart_other_info
  const [top_channel_name, set_top_channel_name] = useState(null);
  const [top_group_name, set_top_group_name] = useState(null);

  const [tot_channel, set_tot_channel] = useState(null);
  const [tot_groups, set_tot_groups] = useState(null);

  useEffect(() => {
    chart_data(Data, chart_default);
    chart_other_info(Data);
    table_data();
    user_data_returner("643116203");
  }, []);

  const arr_obj = (obj) => {
    const arr = [];
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        arr.push({
          key: prop,
          value: obj[prop],
        });
      }
    }
    arr.sort((a, b) => b.value - a.value);
    // arr.sort(function(a, b) { a.value.toLowerCase().localeCompare(b.value.toLowerCase()); }); //use this to sort as strings
    return arr; // returns array
  };

  const table_data = () => {
    set_table_loading(true);

    const res = {};
    const user_res = {};
    const new_filt_arr = Data;
    const user_filt_arr = Data.filter(
      (val) => val.id !== "None" && val.id !== "NOT_A_USER",
    );

    new_filt_arr.forEach((v) => {
      res[v.conv_name] = (res[v.conv_name] || 0) + 1;
    });
    user_filt_arr.forEach((v) => {
      user_res[v.id] = (user_res[v.id] || 0) + 1;
    });

    const channel_sorted = arr_obj(res);

    const user_sorted = arr_obj(user_res);
    set_post_data(channel_sorted);
    set_user_data(user_sorted);
    set_table_loading(false);
  };
  const user_data_returner = (id) => {
    const user_data = Data.filter((val) => val.id === id);

    const new_encrypt = crypto.AES.encrypt(JSON.stringify(user_data), "secret");

    return new_encrypt;
  };

  // Chart_data function
  const chart_data = (arr, arr_filt) => {
    const res = {};
    let new_filt_arr = null;
    if (arr_filt !== "user") {
      new_filt_arr = arr.filter(
        (val) => val.id !== "None" && val.id !== "NOT_A_USER",
      );
    } else {
      new_filt_arr = arr;
    }

    new_filt_arr.forEach((v) => {
      if (arr_filt === "user") {
        res[v.conv_name] = (res[v.conv_name] || 0) + 1;
      } else {
        res[v.id] = (res[v.id] || 0) + 1;
      }
    });

    const sortable = Object.keys(res).sort((a, b) => {
      if (a !== "None" && b !== "None") {
        return res[b] - res[a];
      }
      // Handle the case when a or b is equal to 'None'
      if (a === "None") {
        return 1; // Move 'None' to the end
      }
      if (b === "None") {
        return -1; // Move 'None' to the end
      }
      return 0; // a and b are both 'None', maintain the order
    });
    const sort_val = Object.values(res);
    const highestToLowest = sort_val.sort((a, b) => b - a);
    let new_srt = null;
    let chnl_srt = null;
    if (sort_val.length > 10) {
      new_srt = highestToLowest.slice(0, 10);
      chnl_srt = sortable.slice(0, 10);
    } else {
      new_srt = highestToLowest;
      chnl_srt = sortable;
    }
    // let first_nm = []
    const fg = chnl_srt.map((val) => {
      let new_val = null;
      if (/\s/.test(val)) {
        const fg = val.split(" ");
        if (fg[0].length > 4) {
          new_val = fg[0];
        } else {
          new_val = fg[1];
        }
      } else {
        new_val = val.substring(0, 7);
      }
      return new_val;
    });
    // It has any kind of whitespace

    const old_series = series;
    const old_state = options;
    const new_state = update(old_state, {
      xaxis: { categories: { $set: fg } },
    });
    if (old_series.length > 1) {
      old_series.pop();
    }
    const new_series = update(old_series, { 0: { data: { $set: new_srt } } });
    set_series(new_series);
    set_options(new_state);
    set_channels(chnl_srt);
    set_data(new_srt);
  };

  // chart_other_info Functionn
  const chart_other_info = (arr) => {
    const res = {};
    const all_channels = arr.filter((val) => val.is_group === "False");
    const grp_channels = arr.filter((val) => val.is_group !== "False");

    all_channels.forEach((v) => {
      res[v.conv_name] = (res[v.conv_name] || 0) + 1;
    });
    const sortable = Object.keys(res).sort((a, b) => res[b] - res[a]);

    const group_res = {};
    grp_channels.forEach((v) => {
      group_res[v.conv_name] = (group_res[v.groconv_nameupname] || 0) + 1;
    });
    const grp_sortable = Object.keys(group_res).sort(
      (a, b) => group_res[b] - group_res[a],
    );

    set_top_channel_name(sortable[0]);

    set_top_group_name(grp_sortable[0]);

    set_tot_channel(all_channels.length);
    set_tot_groups(grp_channels.length);
  };

  return (
    <div className="main__table_graph">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="quick_activity_wrap">
            <div className="single_quick_activity">
              <h4>Total Groups Post</h4>
              <h3>
                <span className="counter">
                  {tot_groups !== null ? tot_groups : "..."}
                </span>
              </h3>
            </div>
            <div className="single_quick_activity">
              <h4>Total Channel Post</h4>
              <h3>
                <span className="counter">
                  {tot_channel !== null ? tot_channel : "..."}
                </span>
              </h3>
            </div>
            <div className="single_quick_activity">
              <h4>Top Group</h4>
              <h3>
                <span
                  style={{
                    overflow: "hidden",
                    width: "250px",
                    textOverflow: "ellipsis",
                    height: "2rem",
                    whiteSpace: "nowrap",
                  }}
                  className="counter"
                >
                  {top_group_name !== null ? top_group_name : "..."}
                </span>
              </h3>
            </div>
            <div className="single_quick_activity">
              <h4>Top Channel</h4>
              <h3>
                <span
                  style={{
                    overflow: "hidden",
                    width: "250px",
                    textOverflow: "2rem",
                    height: "2rem",
                    whiteSpace: "nowrap",
                  }}
                  className="counter"
                >
                  {top_channel_name !== null ? top_channel_name : "..."}
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="row" style={{ marginBottom: "60px" }}>
        <div className="col-xl-8 shadow-lg">
          <div className="white_card mb_30 card_height_100">
            <div className="white_card_header">
              <div className="row align-items-center justify-content-between flex-wrap">
                <div className="col-lg-6">
                  <div className="main-title">
                    <div
                      className="m-0"
                      style={{ float: "left", color: "white" }}
                    >
                      Top groups
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <ul className="nav navbar-right panel_toolbox">
                    <div
                      style={{
                        marginLeft: "8px",
                        display: "inline",
                        cursor: "pointer",
                      }}
                    >
                      <li>
                        <IconContext.Provider
                          value={
                            chart_default === "user"
                              ? {
                                  size: 28,
                                  background: "white",
                                  color: "#1FCECB",
                                }
                              : {
                                  size: 28,
                                  background: "white",
                                  color: "#8d8d8d",
                                }
                          }
                        >
                          <FaUsers
                            onClick={() => {
                              set_chart_default("user");
                              chart_data(Data, "user");
                            }}
                          />
                        </IconContext.Provider>
                      </li>
                    </div>
                    <div
                      style={{
                        marginLeft: "15px",
                        display: "inline",
                        cursor: "pointer",
                      }}
                    >
                      <li>
                        <IconContext.Provider
                          value={
                            chart_default === "date"
                              ? {
                                  size: 24,
                                  background: "white",
                                  color: "#1FCECB",
                                }
                              : {
                                  size: 28,
                                  background: "white",
                                  color: "#8d8d8d",
                                }
                          }
                        >
                          <FaUserTie
                            onClick={() => {
                              set_chart_default("date");
                              chart_data(Data, "date");
                            }}
                          />
                        </IconContext.Provider>
                      </li>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="white_card_body" style={{ position: "relative" }}>
              <div style={{ minHeight: "400px" }}>
                <Chart
                  options={options}
                  series={series}
                  type="bar"
                  width="100%"
                  height={400}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-4">
          <div className="white_card card_height_100 mb_30 user_crm_wrapper">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="single_crm shadow-lg">
                  <div className="crm_head d-flex align-items-center justify-content-between">
                    <div className="thumb" style={{ boxSizing: "border-box" }}>
                      <img
                        alt="prof_pic"
                        src="https://demo.dashboardpack.com/user-management-html/img/crm/businessman.svg"
                      />
                      <p>
                        {chart_default === "date"
                          ? "Top Uploaded User ID"
                          : "Group/Channel Name"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="crm_body"
                    style={{ boxSizing: "border-box", minHeight: "175px" }}
                  >
                    {data != null ? (
                      data.length > 0 && channels.length > 0 ? (
                        <>
                          <h4>{data[0]}</h4>
                          <p>{channels[0]}</p>
                        </>
                      ) : (
                        <p>Loading ...</p>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="single_crm shadow-lg">
                  <div className="crm_head crm_bg_1 d-flex align-items-center justify-content-between">
                    <div className="thumb" style={{ boxSizing: "border-box" }}>
                      <img
                        alt="prof_pic_2"
                        src="https://demo.dashboardpack.com/user-management-html/img/crm/businessman.svg"
                      />
                      <p>
                        {chart_default === "date"
                          ? "Top Uploaded User ID"
                          : "Group/Channel Name"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="crm_body"
                    style={{ boxSizing: "border-box", minHeight: "175px" }}
                  >
                    {data != null ? (
                      data.length > 0 && channels.length > 0 ? (
                        <>
                          <h4>{data[1]}</h4>
                          <p>{channels[1]}</p>
                        </>
                      ) : (
                        <p>Loading ...</p>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 ">
                <div className="single_crm shadow-lg">
                  <div className="crm_head crm_bg_2 d-flex align-items-center justify-content-between">
                    <div className="thumb" style={{ boxSizing: "border-box" }}>
                      <img
                        alt="prof_pic_3"
                        src="https://demo.dashboardpack.com/user-management-html/img/crm/businessman.svg"
                      />
                      <p>
                        {chart_default === "date"
                          ? "Top Uploaded User ID"
                          : "Group/Channel Name"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="crm_body"
                    style={{ boxSizing: "border-box", minHeight: "175px" }}
                  >
                    {data != null ? (
                      data.length > 0 && channels.length > 0 ? (
                        <>
                          <h4>{data[2]}</h4>
                          <p>{channels[2]}</p>
                        </>
                      ) : (
                        <p>Loading ...</p>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="single_crm shadow-lg">
                  <div className="crm_head crm_bg_3 d-flex align-items-center justify-content-between">
                    <div className="thumb" style={{ boxSizing: "border-box" }}>
                      <img
                        alt="prof_pic_4"
                        src="https://demo.dashboardpack.com/user-management-html/img/crm/businessman.svg"
                      />
                      <p>
                        {chart_default === "date"
                          ? "Top Uploaded User ID"
                          : "Group/Channel Name"}
                      </p>
                    </div>
                  </div>
                  <div
                    className="crm_body"
                    style={{ boxSizing: "border-box", minHeight: "175px" }}
                  >
                    {data != null ? (
                      data.length > 0 && channels.length > 0 ? (
                        <>
                          <h4>{data[3]}</h4>
                          <p>{channels[3]}</p>
                        </>
                      ) : (
                        <p>Loading ...</p>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-7">
          <div className="row dk-mode">
            <div className="col-lg-12 col-xl-12">
              <div className="mb-3 card dk-mode">
                <div
                  className="card-header-tab card-header"
                  style={{ background: "#2b3c46", color: "white" }}
                >
                  <div
                    className="card-header-title"
                    style={{ color: "white", textTransform: "capitalize" }}
                  >
                    <i className="header-icon lnr-rocket icon-gradient bg-tempting-azure" />{" "}
                    Channel/Group Post (Total Channels/Group:{" "}
                    {post_data !== null ? post_data.length : "..."})
                  </div>
                </div>
                <div className="pt-2 pb-0 card-body" />
                <div className="widget-chart p-0">
                  {table_loading ? (
                    <div
                      className="card-body scroll-custom"
                      style={{ height: "450px" }}
                    >
                      {" "}
                      <div className="blocks list-tab">
                        <div className="block orange" />
                        <div className="block blue" />
                      </div>
                      <h6 style={{ marginTop: "-25px" }}>
                        Data may take time to load
                      </h6>
                    </div>
                  ) : post_data !== null ? (
                    post_data.length === 0 ? (
                      <div className="card-body" style={{ height: "450px" }}>
                        <h2 style={{ marginTop: "100px" }}>
                          No Data to Display
                        </h2>
                      </div>
                    ) : (
                      <div
                        className="card-body scroll-custom"
                        style={{ height: "450px" }}
                      >
                        <ul
                          style={{ paddingLeft: "0px" }}
                          className="responsive-table dk-mode"
                        >
                          <li
                            className="table-header"
                            style={{ marginBottom: "0px", textAlign: "left" }}
                          >
                            <div className="col col-1" />
                            <div className="col col-5">Channel Name</div>
                            <div className="col col-2">Posts</div>
                            <div className="col col-1" />
                            <div className="col col-1" />
                          </li>
                          {post_data.map((val, i) => (
                            <li
                              className="table-row"
                              key={i}
                              style={{
                                marginBottom: "0px",
                                boxShadow: "none",
                                textAlign: "left",
                              }}
                            >
                              <div
                                className="col col-1"
                                data-label="Job Id"
                                style={{ textAlign: "left" }}
                              >
                                {" "}
                                {i + 1}
                              </div>
                              <div
                                className="col col-5"
                                data-label="Customer Name"
                              >
                                {" "}
                                {val.key}
                              </div>
                              <div
                                className="col col-1"
                                data-label="Post value"
                              >
                                {" "}
                                {val.value}
                              </div>
                              <div className="col col-1" data-label="Amount">
                                <Link
                                  to={`/page?page=${val.key}&type=channel name`}
                                >
                                  <button
                                    style={{
                                      borderRadius: "40px",
                                      background: "#2a8a85",
                                      color: "white",
                                      lineHeight: "1",
                                    }}
                                    className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                  >
                                    Stats
                                  </button>
                                </Link>
                              </div>
                              <div
                                className="col col-1"
                                data-label="Payment Status"
                              >
                                <Link
                                  to={`/channel?channel=${val.key}&type=channel name`}
                                >
                                  <button
                                    style={{
                                      borderRadius: "40px",
                                      color: "white",
                                      lineHeight: "1",
                                    }}
                                    className="btn btn-round bg-primary mr-md-2 mb-md-0 mb-1"
                                  >
                                    Post
                                  </button>
                                </Link>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="row dk-mode">
            <div className="col-lg-12 col-xl-12">
              <div className="mb-3 card dk-mode">
                <div
                  className="card-header-tab card-header"
                  style={{ background: "#2b3c46", color: "white" }}
                >
                  <div
                    className="card-header-title"
                    style={{ color: "white", textTransform: "capitalize" }}
                  >
                    <i className="header-icon lnr-rocket icon-gradient bg-tempting-azure" />
                    User Post (Total Users:{" "}
                    {user_data !== null ? user_data.length : "..."})
                  </div>
                </div>
                <div className="pt-2 pb-0 card-body" />
                <div className="widget-chart p-0">
                  {table_loading ? (
                    <div
                      className="card-body scroll-custom"
                      style={{ height: "450px" }}
                    >
                      {" "}
                      <div className="blocks list-tab">
                        <div className="block orange" />
                        <div className="block blue" />
                      </div>
                      <h6 style={{ marginTop: "-25px" }}>
                        Data may take time to load
                      </h6>
                    </div>
                  ) : user_data !== null ? (
                    user_data.length === 0 ? (
                      <div className="card-body" style={{ height: "450px" }}>
                        <h2 style={{ marginTop: "100px" }}>
                          No Data to Display
                        </h2>
                      </div>
                    ) : (
                      <div
                        className="card-body scroll-custom"
                        style={{ height: "450px" }}
                      >
                        <ul
                          style={{ paddingLeft: "0px" }}
                          className="responsive-table dk-mode"
                        >
                          <li
                            className="table-header"
                            style={{ marginBottom: "0px", textAlign: "left" }}
                          >
                            <div className="col col-1" />
                            <div className="col col-4">User Id</div>
                            <div className="col col-3">Posts</div>
                            <div className="col col-1" />
                          </li>

                          {user_data.map((val, i) => (
                            <li
                              className="table-row"
                              key={i}
                              style={{
                                marginBottom: "0px",
                                boxShadow: "none",
                                textAlign: "left",
                              }}
                            >
                              <div
                                className="col col-2"
                                data-label="Job Id"
                                style={{ textAlign: "left" }}
                              >
                                {" "}
                                {i + 1}
                              </div>
                              <div
                                className="col col-4"
                                data-label="Customer Name"
                              >
                                {" "}
                                {val.key}
                              </div>
                              <div
                                className="col col-2"
                                data-label="Post value"
                              >
                                {" "}
                                {val.value}
                              </div>
                              <div
                                className="col col-2"
                                data-label="Payment Status"
                              >
                                <button
                                  style={{
                                    borderRadius: "40px",
                                    color: "white",
                                    lineHeight: "1",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(`/users?page=${val.key}`);
                                  }}
                                  className="btn btn-round bg-primary mr-md-2 mb-md-0 mb-1"
                                >
                                  Stats
                                </button>
                                {/* </Link> */}
                              </div>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

DisplayGraph.propTypes = {
  Data: PropTypes.array,
};
export default DisplayGraph;

/* eslint-disable no-undef */
import React, { useContext, useEffect, useState, useMemo, memo } from "react";
import Box from "@mui/material/Box";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CancelIcon from "@mui/icons-material/Cancel";
import { Stack } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import InputSwitch from "../sections/Switch/InputSwitch";
import { filter_context } from "../searchbar/Searchbar";
import "../../../css/Modal/modal.css";

function MultipleCountryOption() {
  const {
    set_open_multiple_counry,
    all_country_details,
    selected_countries,
    set_selected_countries,
  } = useContext(filter_context)["stealer_filters"];

  const [input_value, set_input_value] = useState("");
  const [select_all, set_select_all] = useState(false);
  const filter_all_country_code = useMemo(
    () => all_country_details.map((item) => item.key),
    [all_country_details],
  );
  useEffect(() => {
    set_selected_countries([]);
  }, []);
  const handle_country_selection = (event) => {
    const { value } = event.target;
    const list_lang = [...selected_countries];
    const index_lang = list_lang.indexOf(value);
    index_lang === -1 ? list_lang.push(value) : list_lang.splice(index_lang, 1);
    set_selected_countries(list_lang);
  };

  const handleChange = (event) => {
    set_select_all(event.target.checked);
    if (event.target.checked === true) {
      set_selected_countries(filter_all_country_code);
    } else {
      set_selected_countries([]);
    }
  };
  const filter_all_countries = all_country_details.filter(
    (item) =>
      item.country_name.toLowerCase().startsWith(input_value.toLowerCase()) &&
      ["None", "Republic of Somaliland"].includes(item.country_name) === false,
  );

  return (
    <ClickAwayListener onClickAway={() => set_open_multiple_counry(false)}>
      <div
        className="multiple_country_option_container"
        onMouseLeave={() => set_open_multiple_counry(false)}
      >
        <Box>
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_multiple_counry(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          <div
            className="modal_translate_desc"
            id="modal_translate_desc_less_flag"
          >
            <Stack
              direction="row"
              gap={4}
              justifyContent="center"
              alignItems="flex-end"
            >
              <Stack>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Select all
                </FormLabel>
                <InputSwitch
                  checked_status={
                    select_all ||
                    selected_countries.length === all_country_details.length
                  }
                  handle_change={handleChange}
                  disabled_status={false}
                />
              </Stack>

              <input
                placeholder="Filter the countries"
                style={{ marginBlockEnd: 0, alignSelf: "center" }}
                className="global_input_box container_modal_stealerlogs_countries_input"
                value={input_value}
                onChange={(e) => set_input_value(e.target.value)}
              />
              <p className="selected_countries_label">
                Selected countries : <span>{selected_countries.length}</span>{" "}
              </p>
            </Stack>
            <div className="container_modal_stealerlogs_countries_select_options scroll-custom">
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                countries
              </FormLabel>
              <Grid container>
                <Grid item xs={4}>
                  <FormControl>
                    <Box className="container_modal_stealerlogs_countries_select_box">
                      {filter_all_countries.slice(0, 86).map((item, index) => {
                        const getFlagImageUrl =
                          item.key === "AE"
                            ? `${process.env.PUBLIC_URL}/static/images/AE.png`
                            : `https://flagcdn.com/w20/${item[
                                "key"
                              ].toLowerCase()}.png`;
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item["key"]}
                                onChange={(event) =>
                                  handle_country_selection(event)
                                }
                                checked={selected_countries.includes(
                                  item["key"],
                                )}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={
                              <Stack
                                direction="row"
                                columnGap={0.5}
                                alignItems="center"
                              >
                                <img
                                  width="18"
                                  height="15"
                                  src={getFlagImageUrl}
                                  alt="flag"
                                  style={{ objectFit: "cover" }}
                                />
                                {item["country_name"]}
                              </Stack>
                            }
                            key={index}
                            sx={{ float: "left" }}
                          />
                          // <button className="country_select_btn" key={index} onClick={() => handle_country_selection(item)}>
                          //     {item["country_name"]}

                          // </button>
                        );
                      })}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    {/* <FormLabel
                                            id="demo-controlled-radio-buttons-group"
                                            className="label__title"
                                        >
                                            countries
                                        </FormLabel> */}
                    <Box className="container_modal_stealerlogs_countries_select_box">
                      {filter_all_countries
                        .slice(86, 171)
                        .map((item, index) => {
                          const getFlagImageUrl =
                            item.key === "AE"
                              ? `${process.env.PUBLIC_URL}/static/images/AE.png`
                              : `https://flagcdn.com/w20/${item[
                                  "key"
                                ].toLowerCase()}.png`;
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item["key"]}
                                  onChange={(event) =>
                                    handle_country_selection(event)
                                  }
                                  checked={selected_countries.includes(
                                    item["key"],
                                  )}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Stack
                                  direction="row"
                                  columnGap={0.5}
                                  alignItems="center"
                                >
                                  <img
                                    width="18"
                                    height="15"
                                    src={getFlagImageUrl}
                                    alt="flag"
                                    style={{ objectFit: "cover" }}
                                  />
                                  {item["country_name"]}
                                </Stack>
                              }
                              key={index}
                              sx={{ float: "left" }}
                            />
                            // <button className="country_select_btn" key={index} onClick={() => handle_country_selection(item)}>
                            //     {item["country_name"]}

                            // </button>
                          );
                        })}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    {/* <FormLabel
                                            id="demo-controlled-radio-buttons-group"
                                            className="label__title"
                                        >
                                            countries
                                        </FormLabel> */}
                    <Box className="container_modal_stealerlogs_countries_select_box">
                      {filter_all_countries
                        .slice(171, 255)
                        .map((item, index) => {
                          const getFlagImageUrl =
                            item.key === "AE"
                              ? `${process.env.PUBLIC_URL}/static/images/AE.png`
                              : `https://flagcdn.com/w20/${item[
                                  "key"
                                ].toLowerCase()}.png`;
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  value={item["key"]}
                                  onChange={(event) =>
                                    handle_country_selection(event)
                                  }
                                  checked={selected_countries.includes(
                                    item["key"],
                                  )}
                                  size="small"
                                  sx={{
                                    color: "white",
                                    "&.Mui-checked": {
                                      color: "#28cab3",
                                    },
                                  }}
                                />
                              }
                              label={
                                <Stack
                                  direction="row"
                                  columnGap={0.5}
                                  alignItems="center"
                                >
                                  <img
                                    width="18"
                                    height="15"
                                    src={getFlagImageUrl}
                                    alt="flag"
                                    style={{ objectFit: "cover" }}
                                  />
                                  {item["country_name"]}
                                </Stack>
                              }
                              key={index}
                              sx={{ float: "left" }}
                            />
                          );
                        })}
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
        </Box>
      </div>
    </ClickAwayListener>
  );
}

export default memo(MultipleCountryOption);

import PropTypes from "prop-types";
import React, { memo } from "react";
import { Stack, Grid } from "@mui/material";
import ShowMoreText from "react-show-more-text";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import CloseIcon from "@mui/icons-material/Close";
import Highlighter from "react-highlight-words";
import { date_fm } from "../../../Assets/globalfunctions";
import "../../../css/Dashboard/company_dashboard.css";

function TooltipChannels({
  chart,
  chart_stats,
  set_chart_stats,
  chart_type,
  alert_type,
}) {
  function executeOnClick() {}
  const handle_click_away_tooltip_channels = () => {
    if (chart_stats[chart].show_message === false) {
      if (chart_type === "alert") {
        set_chart_stats((prev) => ({
          ...prev,
          [alert_type]: {
            ...prev[alert_type],
            [chart]: {
              ...prev[alert_type][chart],
              show_channel: false,
            },
          },
        }));
      } else {
        set_chart_stats((prev) => ({
          ...prev,
          [chart]: { ...prev[chart], show_channel: false },
        }));
      }
    }
  };

  const split_title_name_value = (title) => {
    try {
      const split_title = title.split("$@");
      return { first: split_title[0], second: split_title[1] };
    } catch {
      return { first: title, second: "" };
    }
  };

  return (
    <ClickAwayListener onClickAway={handle_click_away_tooltip_channels}>
      <div
        className={
          chart_type === "channel"
            ? "chart_tooltip_channels_message scroll-custom-thin-bar"
            : "chart_tooltip_channels scroll-custom-thin-bar"
        }
      >
        <Stack direction="row" justifyContent="space-between">
          <h6 className="chart_tooltip_channels_title">
            {split_title_name_value(
              chart_stats[chart].hovered_category,
            ).first.replaceAll("_", " ")}{" "}
            <span style={{ color: "#ff9d00" }}>
              (
              {
                split_title_name_value(chart_stats[chart].hovered_category)
                  .second
              }
              )
            </span>
          </h6>
          <CloseIcon
            fontSize="small"
            sx={{ color: "red", cursor: "pointer" }}
            onClick={() => {
              if (chart_type === "alert") {
                set_chart_stats((prev) => ({
                  ...prev,
                  [alert_type]: {
                    ...prev[alert_type],
                    [chart]: {
                      ...prev[alert_type][chart],
                      show_channel: false,
                    },
                  },
                }));
              } else {
                set_chart_stats((prev) => ({
                  ...prev,
                  [chart]: { ...prev[chart], show_channel: false },
                }));
              }
            }}
          />
        </Stack>
        <Stack>
          {(chart_type === "product" ||
          chart_type === "channel" ||
          (chart_type === "alert" &&
            ["file", "channel", "keyword"].includes(alert_type) === true)
            ? chart_stats[chart].channel_details
            : Object.keys(chart_stats[chart].channel_details)
          ).map((item, id) => {
            if (chart_type !== "channel") {
              return (
                <Grid container key={id}>
                  <Grid item xs={8.5}>
                    <p
                      className="chart_tooltip_channels_name"
                      onMouseEnter={() => {
                        setTimeout(() => {
                          set_chart_stats((prev) => {
                            if (chart_type === "product") {
                              return {
                                ...prev,
                                [chart]: {
                                  ...prev[chart],
                                  message_details: {
                                    hovered_name: item.channel_name,
                                    hovered_messages: item.messages,
                                  },
                                  show_message: true,
                                },
                              };
                            } else if (
                              chart_type === "alert" &&
                              ["file", "channel", "keyword"].includes(
                                alert_type,
                              ) === true
                            ) {
                              return {
                                ...prev,
                                [alert_type]: {
                                  ...prev[alert_type],
                                  [chart]: {
                                    ...prev[alert_type][chart],
                                    message_details: {
                                      hovered_name: item.channel_name,
                                      hovered_messages: item.messages,
                                    },
                                    show_message: true,
                                  },
                                },
                              };
                            } else if (
                              chart_type === "alert" &&
                              ["tag"].includes(alert_type) === true
                            ) {
                              return {
                                ...prev,
                                [alert_type]: {
                                  ...prev[alert_type],
                                  [chart]: {
                                    ...prev[alert_type][chart],
                                    message_details: {
                                      hovered_name: item,
                                      hovered_messages:
                                        chart_stats[chart].channel_details[item]
                                          .messages,
                                    },
                                    show_message: true,
                                  },
                                },
                              };
                            } else if (chart_type === "tag") {
                              return {
                                ...prev,
                                [chart]: {
                                  ...prev[chart],
                                  message_details: {
                                    hovered_name: item,
                                    hovered_messages:
                                      chart_stats[chart].channel_details[item]
                                        .messages,
                                  },
                                  show_message: true,
                                },
                              };
                            }
                          });
                        }, 150);
                      }}
                    >
                      {chart_type === "product" ||
                      (chart_type === "alert" &&
                        ["file", "channel", "keyword"].includes(alert_type) ===
                          true)
                        ? item.channel_name
                        : item}
                    </p>
                  </Grid>
                  <Grid item xs={3.5}>
                    <p className="chart_tooltip_channels_post_no">
                      {chart_type === "product" ||
                      (chart_type === "alert" &&
                        ["file", "channel", "keyword"].includes(alert_type) ===
                          true)
                        ? item.total_message
                        : chart_stats[chart].channel_details[item]
                            .total_messages}
                    </p>
                  </Grid>
                </Grid>
              );
            } else {
              if (item.message && item.message.length > 0) {
                return (
                  <div
                    className="chart_tooltip_posts_div"
                    style={{ marginTop: "10px" }}
                    key={id}
                  >
                    <ShowMoreText
                      /* Default options */
                      lines={2}
                      more="Show more"
                      less="Show less"
                      className="content-css"
                      anchorClass="my-anchor-css-class"
                      onClick={executeOnClick}
                      expanded={false}
                    >
                      {item.message}
                    </ShowMoreText>
                    <p>{date_fm(item.date)}</p>
                  </div>
                );
              }
            }
          })}
        </Stack>
      </div>
    </ClickAwayListener>
  );
}

TooltipChannels.propTypes = {
  chart: PropTypes.string,
  chart_stats: PropTypes.object,
  set_chart_stats: PropTypes.func,
  chart_type: PropTypes.string,
  alert_type: PropTypes.string,
};
function TooltipPosts({
  chart,
  chart_stats,
  set_chart_stats,
  chart_type,
  alert_type,
}) {
  const handleOpenNewTab = (e) => {
    e.preventDefault();
    const url_state = {
      chart_name: chart,
      channel_list: chart_stats[chart].message_details.hovered_name,
      status: true,
    };
    localStorage.setItem("fetch_mode_5", JSON.stringify(url_state));
    window.open("/dashboard", "_blank");
  };

  // function for the Show More text
  function executeOnClick() {}
  return (
    <ClickAwayListener
      onClickAway={() => {
        if (chart_type === "alert") {
          set_chart_stats((prev) => ({
            ...prev,
            [alert_type]: {
              ...prev[alert_type],
              [chart]: {
                ...prev[alert_type][chart],
                show_message: false,
              },
            },
          }));
        } else {
          set_chart_stats((prev) => ({
            ...prev,
            [chart]: { ...prev[chart], show_message: false },
          }));
        }
      }}
    >
      <div className="chart_tooltip_posts scroll-custom-thin-bar">
        {chart_stats[chart]["message_details"]["hovered_messages"] &&
        chart_stats[chart]["message_details"]["hovered_messages"].length > 0
          ? chart_stats[chart]["message_details"]["hovered_messages"].map(
              (item, id) => (
                <div className="chart_tooltip_posts_div" key={id}>
                  <p>{chart_stats[chart].message_details.hovered_name}</p>
                  <ShowMoreText
                    /* Default options */
                    lines={2}
                    more="Show more"
                    less="Show less"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    onClick={executeOnClick}
                    expanded={false}
                  >
                    <Highlighter
                      highlightClassName="highlightclass"
                      searchWords={[chart]}
                      autoEscape
                      textToHighlight={item.message}
                      className="chart_tooltip_post_div_highlight"
                    />
                  </ShowMoreText>
                  <p>{date_fm(item.date)}</p>
                </div>
              ),
            )
          : null}
        {chart_type === "product" ? (
          <button
            className="chart_tooltip_post_showmore_btn"
            onClick={(e) => handleOpenNewTab(e)}
          >
            show more result
          </button>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}

TooltipPosts.propTypes = {
  chart: PropTypes.string,
  chart_stats: PropTypes.object,
  set_chart_stats: PropTypes.func,
  chart_type: PropTypes.string,
  alert_type: PropTypes.string,
};
export const ChartTooltipChannels = memo(TooltipChannels);
export const ChartTooltipPosts = memo(TooltipPosts);

import PropTypes from "prop-types";
import React, { useState } from "react";
import { IconContext } from "react-icons";
import Editor from "@monaco-editor/react";
import { FaCopy } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import DownloadFileOptions from "../modalContainer/DownloadFileOptions";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Display_json/displayjson.css";

function JsonEditor({
  Data,
  getSearchedData,
  file_id,
  total_data,
  tag_rate_limit,
  json_display_type,
}) {
  const [copied_clipboard, set_copied_clipboard] = useState(false);
  const check_data_type = (user_array) => {
    try {
      if (json_display_type === "admin_user_search") {
        return Object.keys(user_array).length;
      } else {
        return user_array.length;
      }
    } catch {
      return user_array.length;
    }
  };
  return (
    <>
      <div className="row justify-content-center main__table__json">
        {json_display_type === "tag_search" ? (
          <div className="total_data_number">
            <h6>
              Results shown/found :{" "}
              <span>
                {Data.length}/{total_data}
              </span>
            </h6>
            <div className="total_data_number_child">
              <DownloadFileOptions
                getSearchedData={getSearchedData}
                file_id={file_id}
                post_no={100}
                search_table_type={"tag"}
              />
              <h6>
                Credits remaining :{" "}
                <span className="credit_limit_colors">{tag_rate_limit}</span>
              </h6>
            </div>
          </div>
        ) : null}

        {Data !== null ? (
          <div className="col-lg-10 b ">
            <div />
            <p
              className="m-b-10 f-w-600"
              style={{
                position: "absolute",
                marginLeft: "37%",
                marginTop: "2%",
                zIndex: 995,
                display: "inline",
                flexShrink: "unset",
                cursor: "pointer",
              }}
              onClick={() => {
                navigator.clipboard.writeText(JSON.stringify(Data, null, 4));
                set_copied_clipboard(true);
              }}
              onMouseEnter={() => {
                const popup = document.getElementById("copy_id");
                popup.style.left = "-191%";
                popup.style.bottom = "134%";
                popup.style.visibility = "visible";
              }}
              onMouseLeave={() => {
                const popup = document.getElementById("copy_id");

                popup.style.visibility = "hidden";
              }}
            >
              <IconContext.Provider
                value={
                  copied_clipboard
                    ? { size: 24, color: "#1FCECB" }
                    : { size: 24, color: "#8d8d8d" }
                }
              >
                <FaCopy />
              </IconContext.Provider>
              <span className="popuptext" id="copy_id">
                {copied_clipboard ? "Copied" : "Copy"}{" "}
              </span>
            </p>
            {check_data_type(Data) > 0 ? (
              <Editor
                height="100vh"
                defaultLanguage="json"
                defaultValue={JSON.stringify(Data, null, 4)}
                value={JSON.stringify(Data, null, 4)}
                options={{
                  readOnly: true,
                  selectionClipboard: true,
                  emptySelectionClipboard: true,
                }}
                theme="vs-dark"
                language="json"
                selectionClipboard={true}
              />
            ) : null}
          </div>
        ) : null}
      </div>
      <ToastContainer />
    </>
  );
}
JsonEditor.propTypes = {
  Data: PropTypes.any,
  getSearchedData: PropTypes.string,
  file_id: PropTypes.string,
  total_data: PropTypes.number,
  tag_rate_limit: PropTypes.number,
  json_display_type: PropTypes.string,
};

export default JsonEditor;

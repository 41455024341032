import React, { useContext } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Badge from "@mui/material/Badge";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import { filter_context } from "../searchbar/Searchbar";
import { handle_disable_radio_btn } from "../../../utils/SessionStorage/handle_session";
import "../../../css/Filters/filters.css";

function FiltersBreach() {
  const {
    setShowFilters,
    display_type_breach,
    set_display_type_breach,
    max_result_breach,
    set_max_result_breach,
    breached_date,
    set_breached_date,
  } = useContext(filter_context)["breach_filters"];
  // Click outside function
  const handleClickAway = () => {
    setShowFilters(false);
  };
  const handle_breach_display_type = (event) => {
    set_display_type_breach(event.target.value);
  };

  const handle_max_result_breach = (event) => {
    set_max_result_breach(event.target.value);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className="filters__main filters_breach">
        <FormControl>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="label__title"
          >
            View Type
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={display_type_breach}
            onChange={handle_breach_display_type}
            sx={{ columnGap: 2 }}
          >
            <FormControlLabel
              value="table"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="Table"
            />
            <FormControlLabel
              value="json"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="JSON"
            />
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="label__title"
          >
            Date Availability
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={breached_date}
            onChange={(e) => set_breached_date(e.target.value)}
            sx={{ columnGap: 2 }}
          >
            <FormControlLabel
              value="False"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="OFF"
            />
            <FormControlLabel
              value="True"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#28cab3",
                    },
                  }}
                />
              }
              label="ON"
            />
          </RadioGroup>
        </FormControl>

        <FormControl>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            className="label__title"
          >
            Max result
          </FormLabel>
          <Box
            className="filter__category "
            id="scroll_filter"
            sx={{ width: "130%" }}
          >
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={max_result_breach}
              onChange={handle_max_result_breach}
              sx={{ columnGap: 2 }}
            >
              <FormControlLabel
                value={100}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="100"
              />
              <FormControlLabel
                value={1000}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="1000"
              />
              <FormControlLabel
                value={5000}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="5000"
              />
              <FormControlLabel
                value={10000}
                control={
                  <Radio
                    disabled={handle_disable_radio_btn()}
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={
                  <Badge color="secondary" badgeContent="premium">
                    <span>10000</span>
                  </Badge>
                }
              />
              <FormControlLabel
                value={20000}
                control={
                  <Radio
                    disabled={handle_disable_radio_btn()}
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={
                  <Badge color="secondary" badgeContent="premium">
                    <span>20000</span>
                  </Badge>
                }
              />

              <FormControlLabel
                value={30000}
                control={
                  <Radio
                    disabled={handle_disable_radio_btn()}
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={
                  <Badge color="secondary" badgeContent="premium">
                    <span>30000</span>
                  </Badge>
                }
              />
              <FormControlLabel
                value={40000}
                control={
                  <Radio
                    disabled={handle_disable_radio_btn()}
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={
                  <Badge color="secondary" badgeContent="premium">
                    <span>40000</span>
                  </Badge>
                }
              />
            </RadioGroup>
          </Box>
        </FormControl>
      </div>
    </ClickAwayListener>
  );
}
export default FiltersBreach;

import axios from "axios";
import { toast } from "react-toastify";
import update from "immutability-helper";
import {
  SIGNUP,
  LOGOUT,
  HOME_INDV_FILTER,
  NAV_CHANGER,
  NAV_USER,
  LOGIN,
  LOADING,
  FILT_DATA,
  SEARCH_DATA,
  CHART_INFO_DATA,
  SEARCH_DATA_ERROR,
  CHART_DATA,
  POST_FILT_DATA,
  GET_USER_DATA,
  UPDATE_USER,
  INV_DATA,
  SHOW_MAIN_DASHBOARD,
} from "./types";
import { handle_session_set } from "../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../utils/Validation/Token/token_checker";
import { handle_clear_localStorage } from "../utils/SessionStorage/handle_session";

const get_token = () => {
  const token = localStorage.getItem("Token");
  return token;
};
export const get_token_front = () => () => {
  const token = localStorage.getItem("Token");
  return token;
};
// user signup
export const register_user = (data) => (dispatch) => {
  toast.configure();
  axios
    .post("https://dev.recordedtelegram.com/v2/signup", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.status === 201 || res.status === 200) {
        toast.success("User successfully registered. Redirecting to login...", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setTimeout(() => {
          dispatch({ type: SIGNUP, signup_redirect: true });
        }, 4000);
      }
    })
    .catch((err) => {
      const error_msg = err.response.data.errormsg;
      toast.error(error_msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    });
};
const notify = (error) => {
  toast.configure();
  try {
    if (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    } else {
      toast.error("Something went wrong. Please try again later", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    }
  } catch {
    toast.error("Something went wrong. Please try again later", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000,
    });
  }
};
export const login_func = (data) => (dispatch) => {
  axios
    .post("https://dev.recordedtelegram.com/v2/login", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const all_keys = Object.keys(res.data);
      if (all_keys.includes("errormsg")) {
        toast.configure();
        toast.error(res.data.errormsg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      } else if (all_keys.includes("access_token")) {
        const token = res.data.access_token;
        localStorage.setItem("Token", token);
        axios
          .get("https://dev.recordedtelegram.com/api/user", {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((user_res) => {
            localStorage.setItem("Expdate", new Date());
            localStorage.setItem("User", data.username);
            localStorage.setItem("user_id", user_res.data.userid);
            localStorage.setItem(
              "enabled_categories",
              user_res.data.enabled_categories,
            );
            if (
              user_res.data.user_role === "super_admin" ||
              user_res.data.user_role === "admin"
            ) {
              localStorage.setItem("Type", "Auth");
            } else {
              localStorage.setItem("Type", "Basic");
            }
            if (user_res.data.user_role === "user") {
              localStorage.setItem("Acc_typ", "TRIAL_CUSTOMER");
            } else {
              localStorage.setItem("Acc_typ", "PAID_CUSTOMER");
            }
            localStorage.setItem("admin_status", user_res.data.user_role);
            localStorage.setItem(
              "company_status",
              user_res.data.company_info.company_name,
            );
            localStorage.setItem(
              "stealer_logs_access",
              user_res.data.has_stealer_log_access === true
                ? "accessed"
                : "restricted",
            );
            const country_code =
              user_res.data.country_code_access === "all"
                ? "all"
                : JSON.stringify(user_res.data.country_code_access);
            localStorage.setItem("country_code_access", country_code);
            dispatch({
              type: LOGIN,
              admin_status: user_res.data.user_role,
              company_status: user_res.data.company_info.company_name,
            });
          })
          .catch((err) => {
            notify(
              err.response.data.errormsg ? err.response.data.errormsg : "",
            );
          });
      } else {
        notify();
      }
    })
    .catch((err) => {
      notify(err.response.data.errormsg ? err.response.data.errormsg : "");
    });
};
export const nav_style_changer = (val) => (dispatch) => {
  dispatch({ type: NAV_CHANGER, value: val });
};
export const logout = () => (dispatch) => {
  sessionStorage.clear();
  handle_clear_localStorage();
  dispatch({ type: LOGOUT, redirect: true });
};

export const token_validator = () => {
  const copiedDate = new Date(Date.now() + 6 * (60 * 60 * 1000));
  const low_date = new Date(Date.now() - 6 * (60 * 60 * 1000));
  let tok_date = null;

  try {
    const new_tok_date = localStorage.getItem("Expdate");
    if (new_tok_date !== null) {
      tok_date = new Date(new_tok_date);
    }
  } catch (e) {
    return "True";
  }

  if (tok_date === null) {
    return "True";
  }

  if (tok_date <= copiedDate && tok_date >= low_date) {
    return false;
  }
  sessionStorage.clear();
  handle_clear_localStorage();
  return true;
};
export const user_nav_func = () => (dispatch) => {
  const user_name = localStorage.getItem("User");
  const copiedDate = new Date(Date.now() + 6 * (60 * 60 * 1000));
  const low_date = new Date(Date.now() - 6 * (60 * 60 * 1000));
  let tok_date = null;

  try {
    tok_date = new Date(localStorage.getItem("Expdate"));
  } catch (e) {
    // Do nothing
  }

  if (tok_date === null) {
    dispatch({ type: NAV_USER, user_name, redirect: true });
  }

  if (tok_date <= copiedDate && tok_date >= low_date) {
    dispatch({ type: NAV_USER, user_name, redirect: false });
  } else {
    dispatch({ type: NAV_USER, user_name, redirect: true });
  }
};
export const user_preference = () => () => {
  document.body.style.backgroundColor = "green";
};
export const change_inv_func = (name, value) => (dispatch) => {
  dispatch({ type: HOME_INDV_FILTER, name, value });
};

export const post_filt_add = (filters) => (dispatch, getState) => {
  const state = getState().users;
  const old_post = [...state.post_filters];
  if (old_post.includes(filters)) {
    // Do nothing
  } else if (filters === "all") {
    const old_post = [...state.post_filters];
    const new_rem = old_post.filter(
      (val) => val !== "forwarded" && val !== "replied" && val !== "uploaded",
    );
    let new_value = [];

    if (new_rem.length < 1) {
      new_value.push(filters);
    } else {
      new_value = [...new_rem, filters];
    }

    //
    dispatch({ type: POST_FILT_DATA, value: new_value });
  } else if (filters === "default") {
    const old_post = [...state.post_filters];
    const new_rem = old_post.filter(
      (val) => val !== "links" && val !== "username",
    );
    let new_value = [];

    if (new_rem.length < 1) {
      new_value.push(filters);
    } else {
      new_value = [...new_rem, filters];
    }
    dispatch({ type: POST_FILT_DATA, value: new_value });
  } else if (filters === "username" || filters === "links") {
    const old_post = [...state.post_filters];
    const new_rem = old_post.filter((val) => val !== "default");
    let new_value = [];

    if (new_rem.length < 1) {
      new_value.push(filters);
    } else {
      new_value = [...new_rem, filters];
    }

    //
    dispatch({ type: POST_FILT_DATA, value: new_value });
  } else {
    const old_post = [...state.post_filters];
    const new_rem = old_post.filter((val) => val !== "all");
    let new_value = [];

    if (new_rem.length < 1) {
      new_value.push(filters);
    } else {
      new_value = [...new_rem, filters];
    }

    //
    dispatch({ type: POST_FILT_DATA, value: new_value });
  }
};
const regex_filter = (text) => {
  const urlRegex = new RegExp("(http|https|ftp)://");

  if (urlRegex.test(text)) {
    return true;
  }
  return false;
};
const file_filter = (text) => {
  const urlRegex = /@[a-zA-z0-9]+\s/;

  if (text.match(urlRegex)) {
    return true;
  }
  return false;
};
const post_engagement_filter = (val, filt) => {
  // let new_arr = val.filter(val => val.forwardedfromchanid !== 'None')
  //

  if (filt.includes("uploaded") && filt.includes("forwarded")) {
    if (val.forwardedfromchanid !== "None" && val.userdetails !== "None") {
      return true;
    }
  } else if (filt.includes("forwarded")) {
    if (val.forwardedfromchanid !== "None") {
      return true;
    }
  } else if (filt.includes("uploaded")) {
    if (val.userdetails !== "None") {
      return true;
    }
  } else {
    return true;
  }
};
export const post_filter_function = (arr, type) => (dispatch) => {
  let filt_arr = [];
  let second_filter = [];
  if (type.includes("default")) {
    filt_arr = arr;
  } else {
    arr.forEach((val) => {
      if (type.includes("links") && type.includes("username")) {
        if (
          file_filter(val.message) === true &&
          regex_filter(val.message) === true
        ) {
          filt_arr.push(val);
        }
      } else if (type.includes("links")) {
        if (regex_filter(val.message) === true) {
          filt_arr.push(val);
        }
      } else if (type.includes("username")) {
        if (file_filter(val.message) === true) {
          filt_arr.push(val);
        }
      } else {
        filt_arr.push(val);
      }
    });
  }
  if (type.includes("all")) {
    second_filter = filt_arr;
  } else {
    filt_arr.forEach((val) => {
      const conv_data = post_engagement_filter(val, type);
      if (type.includes("replied")) {
        if (val.reply !== "None") {
          if (conv_data === true) {
            second_filter.push(val);
          }
        }
      } else if (conv_data === true) {
        second_filter.push(val);
      }
    });
  }

  dispatch({
    type: FILT_DATA,
    all_data: second_filter,
    loading: false,
  });
};
export const get_search_data =
  (search_data, default_type = "None", loading = "False") =>
  (dispatch, getState) => {
    const state = getState().users;
    let new_post_filt = state.word_filt_status;
    let search_next_id = null;
    let { search_filter } = state;
    let fuzz = state.fuzziness;
    if (default_type !== "None") {
      new_post_filt = "conv_name";
      search_filter = "exact";
      fuzz = 0;
    }
    if (loading === "True") {
      search_next_id = state.search_next_id;
    } else {
      dispatch({ type: LOADING, loading: true });
    }

    let data = null;

    let ap_mode = 1;
    if (
      state.word_filter.length >= 1 &&
      state.content_filter !== "user search" &&
      state.content_filter !== "file search"
    ) {
      data = {
        api_mode: 3,
        qtext: state.word_filter,
        logic: [state.or_filt_status],
        max: parseInt(state.no_post),
        start_date: state.prev_date,
        end_date: state.end_date,
        sort_order: state.sort_order,
        select_field: state.word_filt_status,
        search_after_id: search_next_id,
      };
    } else if (state.content_filter === "regex search") {
      ap_mode = 2;
      data = {
        api_mode: ap_mode,
        qtext: search_data,
        max: parseInt(state.no_post),
        field_name: new_post_filt,
        fuzzing: 1,
        start_date: state.prev_date,
        end_date: state.end_date,
        sort_order: state.sort_order,
        search_type: state.srch_filter,
        search_after_id: search_next_id,
      };
    } else {
      data = {
        api_mode: ap_mode,
        qtext: search_data,
        max: parseInt(state.no_post),
        start_date: state.prev_date,
        end_date: state.end_date,
        sort_order: state.sort_order,
        select_field: new_post_filt,
        search_type: state.srch_filter,
        selectCategory: state.search_category,
        multimedia_option: state.multimedia_option,
        search_after_id: search_next_id,
        fuzzing: fuzz,
        search_filter,
      };
    }

    const token = get_token();
    if (
      state.content_filter !== "user search" &&
      state.content_filter !== "file search" &&
      state.content_filter !== "forum_search" &&
      state.content_filter !== "forum_search" &&
      state.content_filter !== "profile search"
    ) {
      axios
        .post("https://dev.recordedtelegram.com/v2/posts", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const res_data = response.data.data;

          if (typeof response.data.data[0] === "string") {
            if (response.data[0].includes("No results")) {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: false,
                loading: false,
                total_post: 0,
              });
            }
          } else if (state.post_alt_filt) {
            const new_arr = [];
            const new_filt = res_data.splice(-1, 1);

            for (let i = 0; i < res_data.length; i++) {
              if (parseInt(state.post_view_num) === 0) {
                new_arr.push(res_data[i]);
              } else if (
                parseInt(res_data[i].views) >= parseInt(state.post_view_num)
              ) {
                new_arr.push(res_data[i]);
              }
            }

            dispatch({
              type: SEARCH_DATA,
              all_data: new_arr,
              conv_data: new_arr,
              loading: false,
              rate_limit: parseInt(new_filt[0].ratelimit),
              total_post: response.data.total_db_data,
              search_next_id: response.data.search_id,
            });
          } else {
            const new_filt = res_data.splice(-1, 1);

            dispatch({
              type: HOME_INDV_FILTER,
              name: "front_content_filter",
              value: state.content_filter,
            });
            dispatch({
              type: HOME_INDV_FILTER,
              name: "front_data_display",
              value: state.data_display,
            });
            let data_to_be_stored = res_data;
            let load_more_count = 0;

            if (loading === "True") {
              data_to_be_stored = [...state.all_data, ...res_data];
              if (response.data.scroll_auth === "True") {
                load_more_count = 0;
              } else {
                load_more_count = state.load_more_count + 1;
              }
            }

            dispatch({
              type: SEARCH_DATA,
              all_data: data_to_be_stored,
              conv_data: data_to_be_stored,
              loading: false,
              rate_limit: parseInt(new_filt[0].ratelimit),
              total_post: response.data.total_db_data,
              search_next_id: response.data.search_id,
              load_more_count,
            });
          }
        })
        .catch((error) => {
          try {
            if (
              error.response.data["Fatal Error"].includes(
                "You have consumed your daily limit. Please contact your service provider to increase your daily limit",
              )
            ) {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: true,
                loading: false,
                total_post: 0,
              });
            } else {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: false,
                loading: false,
                total_post: 0,
              });
            }
          } catch {
            dispatch({
              type: SEARCH_DATA_ERROR,
              all_data: [],
              rate_limit_status: false,
              loading: false,
              total_post: 0,
            });
          }
        });
    } else if (state.content_filter === "file search") {
      axios
        .post("https://dev.recordedtelegram.com/file_search", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const res_data = response.data.data;

          if (typeof response.data.data[0] === "string") {
            if (response.data[0].includes("No results")) {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: false,
                loading: false,
                total_post: 0,
              });
            }
          } else if (state.post_alt_filt) {
            const new_arr = [];
            const new_filt = res_data.splice(-1, 1);

            for (let i = 0; i < res_data.length; i++) {
              if (parseInt(state.post_view_num) === 0) {
                new_arr.push(res_data[i]);
              } else if (
                parseInt(res_data[i].views) >= parseInt(state.post_view_num)
              ) {
                new_arr.push(res_data[i]);
              }
            }
            // date_sorter(res_data)
            dispatch({
              type: SEARCH_DATA,
              all_data: new_arr,
              conv_data: new_arr,
              loading: false,
              rate_limit: parseInt(new_filt[0].ratelimit),
              total_post: response.data.total_db_data,
              search_next_id: response.data.search_id,
              load_more_loading: false,
            });
          } else {
            const new_filt = res_data.splice(-1, 1);
            dispatch({
              type: HOME_INDV_FILTER,
              name: "front_content_filter",
              value: state.content_filter,
            });
            dispatch({
              type: HOME_INDV_FILTER,
              name: "front_data_display",
              value: state.data_display,
            });
            let data_to_be_stored = res_data;
            let load_more_count = 0;
            if (loading === "True") {
              data_to_be_stored = [...state.all_data, ...res_data];
              if (response.data.scroll_auth === "True") {
                load_more_count = 0;
              } else {
                load_more_count = state.load_more_count + 1;
              }
            }

            dispatch({
              type: SEARCH_DATA,
              all_data: data_to_be_stored,
              conv_data: data_to_be_stored,
              loading: false,
              rate_limit: parseInt(new_filt[0].ratelimit),
              total_post: response.data.total_db_data,
              search_next_id: response.data.search_id,
              load_more_loading: false,
              load_more_count,
            });
          }
        })
        .catch((error) => {
          try {
            if (
              error.response.data["Fatal Error"].includes(
                "You have consumed your daily limit. Please contact your service provider to increase your daily limit",
              )
            ) {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: true,
                loading: false,
                total_post: 0,
              });
            } else {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: false,
                loading: false,
                total_post: 0,
              });
            }
          } catch {
            dispatch({
              type: SEARCH_DATA_ERROR,
              all_data: [],
              rate_limit_status: false,
              loading: false,
              total_post: 0,
            });
          }
        });
    } else if (state.content_filter === "profile search") {
      let new_search_data = search_data;
      let new_new_post_filt = new_post_filt;
      if (search_data.includes("t.me")) {
        const split_data = search_data.split("/");
        new_search_data = split_data[split_data.length - 1];
        new_new_post_filt = "links";
      } else {
        new_new_post_filt = "conv_name";
      }

      const data = {
        api_mode: ap_mode,
        qtext: new_search_data,
        max: parseInt(state.no_post),
        start_date: state.prev_date,
        end_date: state.end_date,
        sort_order: state.sort_order,
        select_field: new_new_post_filt,
        search_type: state.srch_filter,
        selectCategory: state.search_category,
        multimedia_option: state.multimedia_option,
        search_after_id: search_next_id,
        fuzzing: fuzz,
        search_filter,
      };
      axios
        .post("https://dev.recordedtelegram.com/v2/profile_search", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const res_data = response.data.data;
          if (typeof response.data.data[0] === "string") {
            if (response.data[0].includes("No results")) {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: false,
                loading: false,
                total_post: 0,
              });
            }
          } else if (state.post_alt_filt) {
            const new_arr = [];

            for (let i = 0; i < res_data.length; i++) {
              if (parseInt(state.post_view_num) === 0) {
                new_arr.push(res_data[i]);
              } else if (
                parseInt(res_data[i].views) >= parseInt(state.post_view_num)
              ) {
                new_arr.push(res_data[i]);
              }
            }
            // date_sorter(res_data)
            dispatch({
              type: SEARCH_DATA,
              all_data: new_arr,
              conv_data: new_arr,
              loading: false,
              rate_limit: parseInt(response.data.rate_limit.ratelimit),
              total_post: response.data.total_db_data,
              search_next_id: response.data.search_id,
              load_more_loading: false,
            });
          } else {
            dispatch({
              type: HOME_INDV_FILTER,
              name: "front_content_filter",
              value: state.content_filter,
            });
            dispatch({
              type: HOME_INDV_FILTER,
              name: "front_data_display",
              value: state.data_display,
            });
            let data_to_be_stored = res_data;
            let load_more_count = 0;
            if (loading === "True") {
              data_to_be_stored = [...state.all_data, ...res_data];
              if (response.data.scroll_auth === "True") {
                load_more_count = 0;
              } else {
                load_more_count = 3;
              }
            }

            dispatch({
              type: SEARCH_DATA,
              all_data: data_to_be_stored,
              conv_data: data_to_be_stored,
              loading: false,
              rate_limit: response.data.rate_limit.ratelimit,
              total_post: response.data.total_db_data,
              search_next_id: response.data.search_id,
              load_more_loading: false,
              load_more_count,
            });
          }
        })
        .catch((error) => {
          try {
            if (
              error.response.data["Fatal Error"].includes(
                "You have consumed your daily limit. Please contact your service provider to increase your daily limit",
              )
            ) {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: true,
                loading: false,
                total_post: 0,
              });
            } else {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: false,
                loading: false,
                total_post: 0,
              });
            }
          } catch {
            dispatch({
              type: SEARCH_DATA_ERROR,
              all_data: [],
              rate_limit_status: false,
              loading: false,
              total_post: 0,
            });
          }
        });
    } else {
      const data = {
        api_mode: ap_mode,
        name: search_data,
        max: parseInt(state.no_post),
        fuzzing: 1,
        phone_filter: state.phone_filter,
        search_filter: state.user_filt_status,
      };

      axios
        .post(" https://dev.recordedtelegram.com/v2/users", data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const res_data = response.data.data;
          const new_filt = res_data.splice(-1, 1);

          if (
            response.data.data[0] ===
            "No results. Please try again after some time."
          ) {
            dispatch({
              type: SEARCH_DATA_ERROR,
              all_data: [],
              rate_limit_status: parseInt(res_data[1].ratelimit),
              loading: false,
              total_post: 0,
            });
          } else {
            let data_to_be_stored = res_data;
            let load_more_count = 0;
            if (loading === "True") {
              data_to_be_stored = [...state.all_data, ...res_data];
              if (response.data.scroll_auth === "True") {
                load_more_count = 0;
              } else {
                load_more_count = state.load_more_count + 1;
              }
            }
            dispatch({
              type: HOME_INDV_FILTER,
              name: "front_content_filter",
              value: state.content_filter,
            });
            dispatch({
              type: HOME_INDV_FILTER,
              name: "front_data_display",
              value: state.data_display,
            });
            dispatch({
              type: HOME_INDV_FILTER,
              name: "all_data",
              value: data_to_be_stored,
            });
            dispatch({ type: HOME_INDV_FILTER, name: "loading", value: false });
            dispatch({
              type: HOME_INDV_FILTER,
              name: "rate_limit",
              value: parseInt(new_filt[0].ratelimit),
            });
            dispatch({
              type: HOME_INDV_FILTER,
              name: "total_post",
              value: response.data.total_db_data,
            });
            dispatch({
              type: HOME_INDV_FILTER,
              name: "search_next_id",
              value: response.data.search_id,
            });
            dispatch({
              type: HOME_INDV_FILTER,
              name: "load_more_loading",
              value: false,
            });
            dispatch({
              type: HOME_INDV_FILTER,
              name: "load_more_count",
              value: load_more_count,
            });
          }
        })
        .catch((error) => {
          try {
            if (
              error.response.data["Fatal Error"].includes(
                "You have consumed your daily limit. Please contact your service provider to increase your daily limit",
              )
            ) {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: true,
                loading: false,
                total_post: 0,
              });
            } else {
              dispatch({
                type: SEARCH_DATA_ERROR,
                all_data: [],
                rate_limit_status: false,
                loading: false,
                total_post: 0,
              });
            }
          } catch {
            dispatch({
              type: SEARCH_DATA_ERROR,
              all_data: [],
              rate_limit_status: false,
              loading: false,
              total_post: 0,
            });
          }
        });
    }
  };

export const post_filt_rem = (filters) => (dispatch, getState) => {
  const state = getState().users;
  const old_post = [...state.post_filters];
  const new_rem = old_post.filter((val) => val !== filters);
  dispatch({ type: POST_FILT_DATA, value: new_rem });
};
export const chart_other_info = (arr) => (dispatch) => {
  const res = {};
  const all_channels = arr.filter((val) => val.is_group === "False");
  const grp_channels = arr.filter((val) => val.is_group !== "False");

  all_channels.forEach((v) => {
    res[v.conv_name] = (res[v.conv_name] || 0) + 1;
  });
  const sortable = Object.keys(res).sort((a, b) => res[b] - res[a]);
  const sort_val = Object.values(res);
  const highestToLowest = sort_val.sort((a, b) => b - a);
  const group_res = {};
  grp_channels.forEach((v) => {
    group_res[v.conv_name] = (group_res[v.groconv_nameupname] || 0) + 1;
  });
  const grp_sortable = Object.keys(group_res).sort(
    (a, b) => group_res[b] - group_res[a],
  );
  const grp_sort_val = Object.values(group_res);
  const grp_highestToLowest = grp_sort_val.sort((a, b) => b - a);
  dispatch({
    type: CHART_INFO_DATA,
    top_channel_name: sortable[0],
    top_channel_value: highestToLowest[0],
    top_group_name: grp_sortable[0],
    top_group_value: grp_highestToLowest[0],
    tot_channel: all_channels.length,
    tot_groups: grp_channels.length,
  });
};
export const chart_data = (arr, arr_filt) => (dispatch, getState) => {
  const state = getState().users;
  const res = {};
  let new_filt_arr = null;
  if (arr_filt !== "user") {
    new_filt_arr = arr.filter(
      (val) => val.id !== "None" && val.id !== "NOT_A_USER",
    );
  } else {
    new_filt_arr = arr;
  }

  new_filt_arr.forEach((v) => {
    if (arr_filt === "user") {
      res[v.conv_name] = (res[v.conv_name] || 0) + 1;
    } else {
      res[v.id] = (res[v.id] || 0) + 1;
    }
  });
  const sortable = Object.keys(res).sort((a, b) => {
    if (a !== "None" && b !== "None") {
      return res[b] - res[a];
    }
    // Handle the case when a or b is equal to 'None'
    if (a === "None") {
      return 1; // Move 'None' to the end
    }
    if (b === "None") {
      return -1; // Move 'None' to the end
    }
    return 0; // a and b are both 'None', maintain the order
  });

  const sort_val = Object.values(res);
  const highestToLowest = sort_val.sort((a, b) => b - a);
  let new_srt = null;
  let chnl_srt = null;
  if (sort_val.length > 10) {
    new_srt = highestToLowest.slice(0, 10);
    chnl_srt = sortable.slice(0, 10);
  } else {
    new_srt = highestToLowest;
    chnl_srt = sortable;
  }
  // let first_nm = []
  const fg = chnl_srt.map((val) => {
    let new_val = null;
    if (/\s/.test(val)) {
      const fg = val.split(" ");
      if (fg[0].length > 4) {
        new_val = fg[0];
      } else {
        new_val = fg[1];
      }
    } else {
      new_val = val.substring(0, 7);
    }
    return new_val;
  });
  // It has any kind of whitespace

  const old_series = state.series;
  const old_state = state.options;
  const new_state = update(old_state, { xaxis: { categories: { $set: fg } } });
  if (old_series.length > 1) {
    old_series.pop();
  }
  const new_series = update(old_series, { 0: { data: { $set: new_srt } } });

  dispatch({
    type: CHART_DATA,
    series: new_series,
    options: new_state,
    channels: chnl_srt,
    data: new_srt,
  });
};
export const date_fm = (dt) => () => {
  if (dt !== undefined) {
    const new_dt = dt.split("T");
    const sp_dt = new_dt[0].split("-");
    const new_sp_month = `0${parseInt(sp_dt[1]) - 1}`;

    const date = new Date(sp_dt[0], new_sp_month, sp_dt[2]); // 2009-11-10

    const month = date.toLocaleString("default", { month: "long" });
    const mod_month = month.substring(0, 3);
    const ret_dt = `${sp_dt[2]}-${mod_month}-${sp_dt[0]}`;
    return ret_dt;
  }
  return false;
};
export const json_conv = (data) => () => {
  const new_data = data.replace(/\\'/g, "");

  const json_dt = JSON.parse(new_data);

  return json_dt;
};
export const get_data = () => (dispatch) => {
  const token = get_token();

  axios
    .post(
      "https://dev.recordedtelegram.com/v2/getclient",
      { id: "None" },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((response) => {
      const { data } = response;
      dispatch({ type: GET_USER_DATA, data });
    })
    .catch(() => {
      // Do nothing
    });
};
export const updt_pass = (e, password) => (dispatch, getState) => {
  const state = getState().users;
  e.preventDefault();
  const token = get_token();
  const data = { id: state.userid, newpassword: password };
  axios
    .post("https://dev.recordedtelegram.com/v2/updateself", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const all_arr = Object.keys(response.data);

      if (all_arr.includes("Success")) {
        update_user_type(false);
        toast.success("Updated Sucessfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      } else {
        toast.error(response.data.errormsg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }
    })
    .catch(() => {
      toast.error("Unable to update", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    });
};
export const updt_email = (e, email) => (dispatch, getState) => {
  e.preventDefault();
  const state = getState().users;
  const token = get_token();
  const data = { id: state.userid, newemail: email };
  axios
    .post("https://dev.recordedtelegram.com/v2/update_email", data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      const all_arr = Object.keys(response.data);

      get_data();
      if (all_arr.includes("Success")) {
        update_user_type(false);
        toast.success("Updated Sucessfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
      } else {
        toast.error(response.data.errormsg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
      }
    })
    .catch((error) => {
      toast.error(error.response.data.errormsg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    });
};
export const update_user_type = (val) => (dispatch) => {
  dispatch({ type: UPDATE_USER, update_user: val });
};

export const inv_data = () => (dispatch) => {
  toast.configure();
  const token = get_token();
  axios
    .post(
      "https://dev.recordedtelegram.com/v2/getselfid",
      { id: "None" },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      dispatch({ type: INV_DATA, userid: res.data.id });
    })

    .catch(() => {
      // Do nothing
    });
};

// Note : check the user dashboard pref
export const get_user_dashboard_pref = (dispatch) => {
  const token = localStorage.getItem("Token");
  axios
    .get("https://dev.recordedtelegram.com/get_user_dashboard_preference", {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const dash_pref = res.data.data;
      const is_company = dash_pref["user_preference"]["company"]
        ? dash_pref["user_preference"]["company"].length
        : 0;
      if (is_company > 0) {
        dispatch({ type: SHOW_MAIN_DASHBOARD, dashboard_status: true });
        handle_session_set("dashboard_status", true);
      } else {
        dispatch({ type: SHOW_MAIN_DASHBOARD, dashboard_status: false });
        handle_session_set("dashboard_status", false);
      }
    })
    .catch((err) => {
      const token_expire_status = handle_token_expire(err.response.data);
      if (token_expire_status === true) return;
      dispatch({ type: SHOW_MAIN_DASHBOARD, dashboard_status: false });
      handle_session_set("dashboard_status", false);
    });
};

import PropTypes from "prop-types";
import React, { useState, useEffect, memo } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { DotsLoading, NoData } from "../loader/Loader";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import {
  handle_success_message,
  handle_error_message,
} from "../../../utils/ResponseMessage/response_message";
import "../../../css/Dashboard/company_dashboard.css";

function ModalCompanyAdminTable({ open_modal, set_open_modal }) {
  const token = handle_session_get("Token");
  const [company_admin_data, set_company_admin_data] = useState([]);
  const [company_admin_status, set_company_admin_status] = useState({
    loading: false,
    error: "",
  });
  useEffect(() => {
    get_company_preference();
  }, []);
  const get_company_preference = () => {
    set_company_admin_status((prev) => ({ ...prev, loading: true }));
    axios
      .get("https://dev.recordedtelegram.com/company_preference", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        set_company_admin_data(res.data.data);
        set_company_admin_status((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_company_admin_status((prev) => ({
          ...prev,
          loading: false,
          error: err.response.data,
        }));
      });
  };

  const handle_delete_company_pref = (id) => {
    const data = { pref_id: id };
    axios
      .post("https://dev.recordedtelegram.com/company_preference", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        handle_success_message("Deleted successfully");
        get_company_preference();
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };

  return (
    <div>
      <Modal
        open={open_modal}
        onClose={() => set_open_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="company_admin_table_modal">
          <div className="modal_admin_close">
            <button
              style={{ background: "None", outline: "None", border: "None" }}
              onClick={() => set_open_modal(false)}
            >
              <CancelIcon
                fontSize="medium"
                sx={{ color: "#eb0000 ", cursor: "pointer" }}
              />
            </button>
          </div>
          <h5 className="company_admin_table_heading">Company Admin Table</h5>
          <Box className="company_admin_table_content">
            {company_admin_status.loading === false ? (
              company_admin_status.error || company_admin_data.length === 0 ? (
                <NoData />
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Added By</th>
                      <th>Sub Type</th>
                      <th>Value</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {company_admin_data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.preference_type}</td>
                        <td>{item.added_by}</td>
                        <td>{item.preference_sub_type}</td>
                        <td>{item.preference_value}</td>
                        <td>
                          <button
                            className="company_admin_table_delete_row_btn"
                            onClick={() => handle_delete_company_pref(item.id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )
            ) : (
              <Box sx={{ mt: "4rem" }}>
                <DotsLoading />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}

ModalCompanyAdminTable.propTypes = {
  open_modal: PropTypes.bool,
  set_open_modal: PropTypes.func,
};

export default memo(ModalCompanyAdminTable);

import {
  AUD_INfO,
  CALENDAR_STATS,
  CALENDAR_DATA,
  CALENDAR_LOADING,
  FORWARDED_DAT,
  FORWARDED_CHANNEL,
  STATS_LOADING,
  FILE_EXTENSION,
  FILE_DATA,
  MENTIONS_DATA,
  HASH_DATA,
} from "../actions/types";

const intialState = {
  audience: null,
  average_view: null,
  customer_engagement: null,
  total_views: null,
  calendar_stats: null,
  calendar_value: [],
  calendar_date: null,
  forwarded_data: null,
  total_forwarded_post: null,
  tot_forwarded_post_percent: null,
  forwarded_channels: null,
  statsloading: false,
  total_post: null,
  file_extension: null,
  total_file_extension: null,
  file_data: null,
  file_title: [],
  mention_data: null,
  total_mention_post_percent: null,
  total_mention_post: null,
  hash_data: null,
  total_hash_post_percent: null,
  total_hash_post: null,
  calendar_all_data: null,
  calendar_loading: false,
};

export default function stats_reducer(state = intialState, action) {
  switch (action.type) {
    case AUD_INfO:
      return {
        ...state,
        audience: action.audience,
        average_view: action.average_view,
        customer_engagement: action.customer_engagement,
        total_views: action.total_views,
        total_post: action.total_post,
      };
    case CALENDAR_STATS:
      return {
        ...state,
        calendar_stats: action.calendar_stats,
      };
    case MENTIONS_DATA:
      return {
        ...state,
        mention_data: action.mention_data,
        total_mention_post_percent: action.total_mention_post_percent,
        total_mention_post: action.total_mention_post,
      };
    case HASH_DATA:
      return {
        ...state,
        hash_data: action.hash_data,
        total_hash_post_percent: action.total_hash_post_percent,
        total_hash_post: action.total_hash_post,
      };
    case FILE_EXTENSION:
      return {
        ...state,
        file_extension: action.file_extension,
        total_file_extension: action.total_file_extension,
        file_title: action.file_title,
      };
    case FILE_DATA:
      return {
        ...state,
        file_data: action.file_data,
      };
    case CALENDAR_DATA:
      return {
        ...state,
        calendar_value: action.calendar_value,
        calendar_date: action.calendar_date,
        calendar_all_data: action.calendar_all_data,
        calendar_loading: false,
      };
    case FORWARDED_DAT:
      return {
        ...state,
        forwarded_data: action.data,
        total_forwarded_post: action.total_forwarded_post,
        tot_forwarded_post_percent: action.tot_forwarded_post_percent,
        statsloading: false,
      };
    case FORWARDED_CHANNEL:
      return {
        ...state,
        forwarded_channels: action.forwarded_channels,
      };
    case STATS_LOADING:
      return {
        ...state,
        statsloading: action.value,
      };
    case CALENDAR_LOADING:
      return {
        ...state,
        calendar_loading: true,
      };

    default:
      return state;
  }
}

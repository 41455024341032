import axios from "axios";
import {
  AUD_INfO,
  CALENDAR_STATS,
  CALENDAR_DATA,
  CALENDAR_LOADING,
  FORWARDED_DAT,
  FORWARDED_CHANNEL,
  STATS_LOADING,
  FILE_EXTENSION,
  FILE_DATA,
  MENTIONS_DATA,
  HASH_DATA,
} from "./types";

export const set_loading = (val) => (dispatch) => {
  dispatch({ type: STATS_LOADING, value: val });
};

export const get_audience = (val) => (dispatch) => {
  const data = { qtext: val };

  axios
    .post("https://dev.recordedtelegram.com/stats", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.data !== "list index out of range") {
        let tot_count = null;
        try {
          tot_count = res.data.total_post.count;
        } catch {
          //Do nothing
        }
        dispatch({
          type: AUD_INfO,
          audience: res.data.audience_info,
          average_view: res.data.average_view,
          customer_engagement: res.data.customer_engagement,
          total_views: res.data.total_views,
          total_post: tot_count,
        });
      }
    })
    .catch(() => {
      dispatch({
        type: AUD_INfO,
        audience: "...",
        average_view: "...",
        customer_engagement: "...",
        total_views: "...",
      });
    });
};
export const get_calendar_stats = (val) => (dispatch) => {
  const data = { qtext: val };

  axios
    .post("https://dev.recordedtelegram.com/group_date_stats", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({ type: CALENDAR_STATS, calendar_stats: res.data });
    });
};
export const get_file_extension = (val) => (dispatch) => {
  const data = { qtext: val };

  axios
    .post("https://dev.recordedtelegram.com/file_extension_search", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      let new_res = [];
      let new_title = [];

      if (res.data.data !== []) {
        new_res = Object.fromEntries(Object.entries(res.data.data).slice(0, 5));

        new_title = Object.keys(new_res || {}).map((val) => {
          const str = val;
          const str2 = str.charAt(0).toUpperCase() + str.slice(1);
          return str2;
        });
      }

      dispatch({
        type: FILE_EXTENSION,
        file_extension: new_res,
        total_file_extension: res.data.total,
        file_title: new_title,
      });
    });
};
export const get_recent_file_data = (val) => (dispatch) => {
  const data = { qtext: val };

  axios
    .post("https://dev.recordedtelegram.com/file_post_filter", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({ type: FILE_DATA, file_data: res.data });
    });
};
export const get_mention_data = (val) => (dispatch) => {
  const data = { qtext: val };

  axios
    .post("https://dev.recordedtelegram.com/mentions", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({
        type: MENTIONS_DATA,
        mention_data: res.data.data,
        total_mention_post_percent: res.data.total_mention_post_percent,
        total_mention_post: res.data.total_mention_post,
      });
    });
};
export const get_hash_data = (val) => (dispatch) => {
  const data = { qtext: val };

  axios
    .post("https://dev.recordedtelegram.com/hash", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({
        type: HASH_DATA,
        hash_data: res.data.data,
        total_hash_post_percent: res.data.total_hash_post_percent,
        total_hash_post: res.data.total_hash_post,
      });
    });
};
export const get_calendar_data = (val, filters) => (dispatch) => {
  dispatch({ type: CALENDAR_LOADING, loading: true });

  const data = { qtext: val, dt_filter: filters };

  axios
    .post("https://dev.recordedtelegram.com/group_date", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      const date_sort = res.data.sort(
        (a, b) =>
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          new Date(b.key_as_string) - new Date(a.key_as_string),
      );

      const values = date_sort.map((val) => val.doc_count);
      let date = date_sort.map((val) => val.key_as_string);
      if (filters === "year") {
        const new_date = date.map((val) => val.split("-")[0]);

        date = new_date;
      }

      dispatch({
        type: CALENDAR_DATA,
        calendar_value: values.slice(0, 7),
        calendar_date: date.slice(0, 7),
        calendar_all_data: date_sort,
      });
    });
};
export const update_calendar_data =
  (sort_type, filters) => (dispatch, getState) => {
    dispatch({ type: CALENDAR_LOADING, loading: true });
    const state = getState().stats;

    let date_sort = state.calendar_all_data.sort(
      (a, b) => new Date(b.key_as_string) - new Date(a.key_as_string),
    );
    if (sort_type === "highest") {
      date_sort = state.calendar_all_data.sort(
        (a, b) => b.doc_count - a.doc_count,
      );
    }
    const values = date_sort.map((val) => val.doc_count);
    let date = date_sort.map((val) => val.key_as_string);
    if (filters === "year") {
      const new_date = date.map((val) => val.split("-")[0]);

      date = new_date;
    }

    dispatch({
      type: CALENDAR_DATA,
      calendar_value: values.slice(0, 7),
      calendar_date: date.slice(0, 7),
      calendar_all_data: date_sort,
    });
  };
export const get_forwarded_stats = (val) => (dispatch) => {
  const data = { qtext: val };

  axios
    .post("https://dev.recordedtelegram.com/forwaded_post", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({
        type: FORWARDED_DAT,
        data: res.data.forwaded_post,
        total_forwarded_post: res.data.tot_forwarded_post,
        tot_forwarded_post_percent: res.data.tot_forwarded_post_percent,
        loading: false,
      });

      set_loading(false);
    });
};
export const get_forwarded_channel_stats = (val) => (dispatch) => {
  const data = { qtext: val };

  axios
    .post("https://dev.recordedtelegram.com/forwaded_post_channel", data, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      dispatch({ type: FORWARDED_CHANNEL, forwarded_channels: res.data });
    });
};

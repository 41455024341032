import React, { useState } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { GlobalStyles } from "@mui/material";
import Highlighter from "react-highlight-words";
import "../../../css/Table/tabledata.css";
/*
NOTE : This component is used to handle the table of the User Search
*/
function UserSearchTable({
  Data,
  paginationValue,
  setPaginationValue,
  getSearchedData,
}) {
  const [pageSize, setPageSize] = useState(25);

  // Return date in the form of 2020-06-09
  const date_fm = (value) => {
    if (value !== "None") {
      try {
        const date = new Date(value);
        const month = date.getUTCMonth() + 1; // months from 1-12
        const new_month = month >= 10 ? month : `0${month}`;
        const day = date.getUTCDate();
        const new_day = day >= 10 ? day : `0${day}`;
        const year = date.getUTCFullYear();
        const newdate = `${new_day}-${new_month}-${year}`;
        return newdate;
      } catch {
        return value;
      }
    } else {
      return "None";
    }
  };

  const followers = (text) => {
    if (text.includes("follower")) {
      const new_text = text.replaceAll("follower", "");
      const final_text = new_text.replaceAll("s", "").trim();
      return final_text;
    }
    return text;
  };

  const link_website = (link) => {
    window.open(
      link,
      "_blank",
      "location=yes,height=700,width=700,scrollbars=yes,status=yes",
    );
  };

  const columns_user = [
    { field: "id", headerName: "Id", width: 70 },
    { field: "user_id", headerName: "User ID", width: 180 },
    { field: "website", headerName: "Website", width: 150 },
    {
      field: "username",
      headerName: "Username",
      width: 200,

      renderCell: (cellValues) => (
        <Highlighter
          highlightClassName="highlightclass"
          searchWords={[getSearchedData]}
          autoEscape
          textToHighlight={cellValues.row.username}
          // textToHighlight={(cellValues.row.message)}
          className="text_hidden"
        />
      ),
    },
    { field: "fullname", headerName: "Full name", width: 150 },
    { field: "date", headerName: "Date", width: 120 },
    {
      field: "location",
      headerName: "Location",
      width: 110,
      sortable: false,
    },
    {
      field: "followers",
      headerName: "Followers",
      width: 100,
      sortable: false,
    },
    {
      field: "following",
      headerName: "Following",
      width: 100,
      sortable: false,
    },
    {
      field: "profile_url",
      headerName: "Profile Url",
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => (
        <button
          className="filter_file_link link_website_user"
          onClick={() => link_website(cellValues.row.profile_url)}
        >
          {cellValues.row.profile_url}
        </button>
      ),
    },
  ];

  const filter_user_data = Data.map((item, index) => ({
    id: index,
    user_id: item.user_id,
    username: item.username,
    fullname: item.fullname === "" ? "None" : item.fullname,
    website: item.site_name,
    date: date_fm(item.created_date),
    location: item.location ? item.location : "None",
    followers: followers(item.followers.toString()),
    following: item.following,
    profile_url: item.profile_url,
    username_url: item.username_url,
  }));

  const clone_data_user = [...filter_user_data];
  const new_state_user = clone_data_user.slice(
    (parseInt(paginationValue) - 1) * pageSize,
    parseInt(paginationValue) * pageSize,
  );
  const new_total_page = Math.ceil(filter_user_data.length / pageSize);

  const handlePaginationChange = (event, value) => {
    setPaginationValue(value);
  };

  // Select the required page size
  const page_size_fixer = (newPageSize) => {
    setPageSize(newPageSize);
    setPaginationValue(Math.ceil(filter_user_data.length / newPageSize));
  };

  return (
    <div className="main__table breach_main_table">
      <div className="total_data_number">
        <h6>
          Results found/checked :{" "}
          <span>
            {Data.length}/{493}
          </span>
        </h6>
        {/* <div className='total_data_number_child'>
                        <h6>Credits remaining : <span className='credit_limit_colors'>{breach_rate_limit}</span></h6>
                    </div> */}
      </div>
      <GlobalStyles
        styles={{
          ".MuiDataGrid-menuList": {
            backgroundColor: "#394e5a",
          },
          ".MuiMenu-list ": {
            backgroundColor: "#394e5a",
          },
          ".MuiDataGrid-menuList li": {
            color: "#dcf3ff",
          },
          ".MuiMenu-list li": {
            color: "#dcf3ff",
          },
          ".MuiPaginationItem-root": {
            color: "#9bbcd1",
            backgroundColor: "#1e2a31",
          },
          ".MuiDataGrid-filterForm": {
            backgroundColor: "#394e5a",
          },
        }}
      />

      <DataGrid
        rows={new_state_user}
        columns={columns_user}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => page_size_fixer(newPageSize)}
        rowsPerPageOptions={[25, 50, 75, 100]}
        autoHeight
        columnVisibilityModel={{
          id: false,
        }}
        disableColumnSelector
        density="comfortable"
      />
      <Pagination
        count={new_total_page}
        color="primary"
        page={paginationValue}
        onChange={handlePaginationChange}
        size="small"
      />
    </div>
  );
}
UserSearchTable.propTypes = {
  Data: PropTypes.array,
  paginationValue: PropTypes.number,
  setPaginationValue: PropTypes.func,
  getSearchedData: PropTypes.string,
};

export default UserSearchTable;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { IconContext } from "react-icons";
import { Link, Redirect } from "react-router-dom";
import {
  FaHome,
  FaChartLine,
  FaQuidditch,
  FaUserTie,
  FaRegAddressBook,
  FaIndustry,
  FaBell,
} from "react-icons/fa";
import { RiUserSearchLine } from "react-icons/ri";
import "../../styles2.css";
import { connect } from "react-redux";
import TelegramIcon from "@mui/icons-material/Telegram";
import { token_checker, all_user_data } from "../../actions/adminAction";
import { user_preference } from "../../actions/userAction";
import { useDispatch } from "react-redux";
import { handle_store_url_location } from "../../actions/filterAction";

function Adminsidenav({
  users,
  admin_reducer,
  token_checker,
  user_preference,
}) {
  useEffect(() => {
    user_preference();
    token_checker();
  }, []);
  const dispatch = useDispatch();
  if (admin_reducer.redirect) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <section className={users.dark_theme ? "dk-mode" : ""}>
      <nav id="sidebar" className={admin_reducer.boot_active ? "active" : null}>
        <div className="sidebar-header">
          <h3 />
          <strong />
        </div>
        <ul className="list-unstyled components">
          <li className="active">
            <Link to="/admin">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaHome />
                  </IconContext.Provider>
                </div>
                Home
              </a>
            </Link>
          </li>

          <li className="active">
            <Link to="/admin/dashboard">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaChartLine />
                  </IconContext.Provider>
                </div>
                Analysis
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/scraper">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaQuidditch />
                  </IconContext.Provider>
                </div>
                Scraper
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/cred">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaUserTie />
                  </IconContext.Provider>
                </div>
                New User
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/user-search">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <RiUserSearchLine />
                  </IconContext.Provider>
                </div>
                User search
              </a>
            </Link>
          </li>

          <li className="active">
            <Link to="/admin/add-regex">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaRegAddressBook />
                  </IconContext.Provider>
                </div>
                Add regex
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/add-industry">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaIndustry />
                  </IconContext.Provider>
                </div>
                Add Industry
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/add-alert">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaBell />
                  </IconContext.Provider>
                </div>
                Add alert
              </a>
            </Link>
          </li>

          <li className="active">
            <Link to="/company-dashboard">
              <a href="#!">
                <div
                  style={{
                    marginRight: "4px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <TelegramIcon
                    fontSize="large"
                    sx={{
                      color: "#359daf",
                    }}
                  />
                </div>
              </a>
            </Link>
          </li>
        </ul>
      </nav>
    </section>
  );
}
const mapStateToProps = (state) => ({
  users: state.users,
  admin_reducer: state.admin_reducer,
});
Adminsidenav.propTypes = {
  users: PropTypes.object,
  admin_reducer: PropTypes.object,
  user_preference: PropTypes.func,
  token_checker: PropTypes.func,
};
export default connect(mapStateToProps, {
  token_checker,
  all_user_data,
  user_preference,
})(Adminsidenav);

import React from "react";
import "./index.css";
import VersionTable from "./VersionTable";

function IndexTable() {
  return (
    <div>
      <VersionTable />
    </div>
  );
}

export default IndexTable;

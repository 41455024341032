import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Formik, ErrorMessage } from "formik";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import axios from "axios";
import "../../css/Admin/admin.css";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { GlobalStyles, Grid } from "@mui/material";
import Adminmainnav from "./Adminmainnav";
import Sidenav from "../../TableUi/components/Navbar/Sidenav";
import "../.././css/Table/tabledata.css";
import { RollerAnimation } from "../../TableUi/components/loader/Loader";
import { handle_session_get } from "../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../utils/Validation/Token/token_checker";
import {
  handle_success_message,
  handle_error_message,
} from "../../utils/ResponseMessage/response_message";
function AddCredentialRegex({ admin_reducer }) {
  const token = handle_session_get("Token");
  const [regex_filer, set_regex_filter] = useState("post");
  const [regex_data, set_regex_data] = useState([]);
  const [table_loading, set_table_loading] = useState(false);

  const get_all_regex_data = () => {
    set_table_loading(true);
    axios
      .get("https://dev.recordedtelegram.com/get_all_regex_auto", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        set_regex_data(res.data.data);
        set_table_loading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_table_loading(false);
        set_regex_data([]);
      });
  };

  useEffect(() => {
    get_all_regex_data();
  }, []);

  const add_regex = (query, name, filter) => {
    toast.configure();
    axios
      .post(
        "https://dev.recordedtelegram.com/index_regex_auto",
        { regex_query: query, regex_name: name, regex_filter: filter },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(() => {
        handle_success_message("Added sucessfully");
        setTimeout(() => {
          get_all_regex_data();
        }, 1000);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message("Operation Failed");
      });
  };

  return (
    <div className="wrapper">
      <Grid container>
        <Grid item xs={0.5}>
          <Sidenav />
        </Grid>
        <Grid item xs={11.5}>
          <div
            id="content"
            className={admin_reducer.boot_active ? "active" : null}
          >
            <section>
              <Adminmainnav />
              <div>
                <main className="d-flex align-items-center pt-3">
                  <div className="container">
                    <div className="login-card ">
                      <ToastContainer />
                      <div className="col-md-12">
                        <div className="card-body">
                          <p className="login_form_heading">Add Regex</p>
                          <Formik
                            initialValues={{ query: "", name: "" }}
                            validate={(values) => {
                              const errors = {};
                              const isRegExp = (string) => {
                                try {
                                  new RegExp(string);
                                } catch (e) {
                                  return false;
                                }
                                return true;
                              };

                              if (isRegExp(values.query) === false) {
                                errors.query =
                                  "Please enter valid Regular expression";
                              }
                              return errors;
                            }}
                            onSubmit={(values) => {
                              add_regex(
                                values.query.trim(),
                                values.name.trim(),
                                regex_filer,
                              );
                            }}
                          >
                            {({
                              values,
                              handleChange,
                              handleBlur,
                              handleSubmit,
                            }) => (
                              <form onSubmit={handleSubmit} autoComplete="off">
                                <div className="regex_add_div">
                                  <label
                                    htmlFor="query"
                                    className="login_form_label"
                                  >
                                    Query
                                  </label>
                                  <input
                                    type="text"
                                    name="query"
                                    id="query"
                                    className="global_input_box w-100"
                                    placeholder="enter regex query"
                                    required
                                    value={values.query}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="query"
                                    className="login_form_error_msg"
                                  />
                                </div>
                                <div className="regex_add_div">
                                  <label
                                    htmlFor="name"
                                    className="login_form_label"
                                  >
                                    Name
                                  </label>
                                  <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    className="global_input_box w-100"
                                    placeholder="enter regex name"
                                    required
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoComplete="off"
                                  />
                                  <ErrorMessage
                                    component="div"
                                    name="name"
                                    className="login_form_error_msg"
                                  />
                                </div>
                                <div className="form-group">
                                  <p
                                    htmlFor="regex_filter"
                                    className="login_form_label"
                                  >
                                    Regex Filter
                                  </p>
                                  <div>
                                    <select
                                      value={regex_filer}
                                      onChange={(e) =>
                                        set_regex_filter(e.target.value)
                                      }
                                      id="regex_filter"
                                      className="regex_add_select global_input_box"
                                    >
                                      <option value="file">File</option>
                                      <option value="post">Post</option>
                                    </select>
                                  </div>
                                </div>
                                <input
                                  name="add"
                                  id="add"
                                  className="login-form-control-btn"
                                  type="submit"
                                  value="Add"
                                  defaultValue="Add"
                                />
                              </form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
              {table_loading === true ? (
                <RollerAnimation />
              ) : regex_data.length > 0 ? (
                <RegexQueryTable
                  Data={regex_data}
                  get_all_regex_data={get_all_regex_data}
                />
              ) : null}
            </section>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

AddCredentialRegex.propTypes = {
  admin_reducer: PropTypes.object,
};
const mapStateToProps = (state) => ({
  admin_reducer: state.admin_reducer,
});

// Adding table
function RegexQueryTable({ Data, get_all_regex_data }) {
  const token = handle_session_get("Token");
  const [pageSize, setPageSize] = useState(25);
  const [paginationValue, setPaginationValue] = useState(1);
  const delete_regex = (id, e) => {
    e.preventDefault();
    const data = { hash_id: id };

    axios
      .post("https://dev.recordedtelegram.com/delete_regex_auto", data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        handle_success_message("Deleted sucessfully");
        setTimeout(() => {
          get_all_regex_data();
        }, 1000);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message("Operation Failed");
      });
  };
  const columns_regex = [
    { field: "id", headerName: "Id", width: 100 },
    { field: "regex_name", headerName: "Name", width: 230 },
    { field: "regex_query", headerName: "Query", flex: 1 },
    {
      field: "regex_filter",
      headerName: "Filter",
      width: 150,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
    },

    {
      field: "delete",
      headerName: "Delete",
      width: 200,
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => (
        <button
          className="regex_table_delete_btn"
          onClick={(e) => delete_regex(cellValues.row.hash_id, e)}
        >
          Delete
        </button>
      ),
    },
  ];

  const filter_regex_data = Data.map((item, index) => ({
    id: index,
    regex_name: item.regex_name,
    regex_query: item.regex_query,
    regex_filter: item.regex_filter,
    hash_id: item.hash_id,
  }));

  const clone_data_regex = [...filter_regex_data];
  const new_state_regex = clone_data_regex.slice(
    (parseInt(paginationValue) - 1) * pageSize,
    parseInt(paginationValue) * pageSize,
  );
  const new_total_page = Math.ceil(filter_regex_data.length / pageSize);

  const handlePaginationChange = (event, value) => {
    setPaginationValue(value);
  };

  // Select the required page size
  const page_size_fixer = (newPageSize) => {
    setPageSize(newPageSize);
    setPaginationValue(Math.ceil(filter_regex_data.length / newPageSize));
  };

  return (
    <>
      <div className="main__table regex_main_table">
        <div className="total_data_number">
          <h6>
            Results shown/found :{" "}
            <span>
              {Data.length}/{Data.length}
            </span>
          </h6>
        </div>
        <GlobalStyles
          styles={{
            ".MuiDataGrid-menuList": {
              backgroundColor: "#394e5a",
            },
            ".MuiMenu-list ": {
              backgroundColor: "#394e5a",
            },
            ".MuiDataGrid-menuList li": {
              color: "#dcf3ff",
            },
            ".MuiMenu-list li": {
              color: "#dcf3ff",
            },
            ".MuiPaginationItem-root": {
              color: "#9bbcd1",
              backgroundColor: "#1e2a31",
            },
            ".MuiDataGrid-filterForm": {
              backgroundColor: "#394e5a",
            },
          }}
        />

        <DataGrid
          rows={new_state_regex}
          columns={columns_regex}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => page_size_fixer(newPageSize)}
          rowsPerPageOptions={[25, 50, 100]}
          autoHeight
          disableColumnSelector
          density="comfortable"
        />
        <Pagination
          count={new_total_page}
          color="primary"
          page={paginationValue}
          onChange={handlePaginationChange}
          size="small"
        />
      </div>
      <ToastContainer />
    </>
  );
}

RegexQueryTable.propTypes = {
  Data: PropTypes.array,
  get_all_regex_data: PropTypes.func,
};

export default connect(mapStateToProps)(AddCredentialRegex);

import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import axios from "axios";
import { FaInfoCircle, FaStarOfLife, FaTrashAlt } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik } from "formik";
import "rc-slider/assets/index.css";
import Tooltip from "@mui/material/Tooltip";
import { Stack } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { RollerAnimation } from "../loader/Loader";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import TransparentContainer from "../../../TableUi/components/sections/Charts/TransparentContainer";
import {
  delete_custom_preference,
  get_custom_preference,
} from "../../../utils/Fetch/fetchApi";
import PreferenceType from "../modalContainer/PreferenceType";
import { chips_background_color } from "../../../utils/Globals/global_variables";
function MarkedChannels() {
  const token = handle_session_get("Token");
  const user_role =
    handle_session_get("admin_status") === "company_admin" ||
    handle_session_get("admin_status") === "super_admin";
  const [search_val, set_search_val] = useState("");
  const [search_data, set_search_data] = useState([]); // Get the list of channels
  const [marked_channels, set_marked_channels] = useState({
    user_preference: [],
    company_preference: [],
  });
  const [data_preference, set_data_preference] = useState({
    sub_pref_type: "",
    dashboard_filter: "",
    pref_type: "",
  });
  const [stat_loading, set_stat_loading] = useState(false);
  const [search_field_error, set_search_field_error] = useState(null);
  const [open_modal_pref, set_open_modal_pref] = useState({
    channel_name: "",
    status: false,
    pref_type: "telegram_channel",
  });

  useEffect(() => {
    toast.configure();
    get_custom_preference(set_marked_channels, "custom");
  }, []);

  const val_change = (e) => {
    set_search_val(e.target.value);
    if (e.target.value.length > 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value };
      axios
        .post("https://dev.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          set_search_data(res.data);
          set_stat_loading(false);
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_stat_loading(false);
          set_search_field_error(err.response.data.errormsg);
        });
    }
  };
  return (
    <section>
      <div
        className="container-fluid dk-mode"
        style={{ marginTop: "50px", position: "relative", height: "100vh" }}
      >
        <div className="row mb-1 " style={{ justifyContent: "center" }}>
          <div className="col-sm-8">
            <Formik
              initialValues={{ search: "" }}
              validate={(values) => {
                const errors = {};
                const { search } = values;
                if (search.length < 2) {
                  errors.search =
                    "search keyword must be longer than 1 character";
                }

                return errors;
              }}
              onSubmit={() => {}}
            >
              {({
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
              }) => (
                <form onSubmit={handleSubmit} autoComplete="off">
                  <div
                    className="d-flex align-items-baseline"
                    style={{ columnGap: "10px" }}
                  >
                    <div
                      className="rounded input-group rounded-pill  shadow-sm mb-1 block_channel_input_field notification_search_box"
                      style={{
                        padding: "0.4rem",
                        width: "97%",
                      }}
                    >
                      <input
                        id="srch_tab"
                        type="search"
                        placeholder="Enter channel"
                        name="search"
                        value={search_val}
                        onChange={(e) => {
                          handleChange(e);
                          val_change(e);
                        }}
                        onBlur={handleBlur}
                        className="form-control border-0 new-dk"
                      />
                    </div>
                  </div>
                  {errors.search && touched.search ? (
                    <div className="user_search_validation">
                      {errors.search}
                    </div>
                  ) : null}
                  {search_val.length < 2 &&
                  !errors.search &&
                  !touched.search ? (
                    <span style={{ color: "#fff", fontSize: "14px" }}>
                      {" "}
                      <IconContext.Provider
                        value={{ size: 18, color: "#f15e5e" }}
                      >
                        <FaInfoCircle />
                      </IconContext.Provider>{" "}
                      Please type in channel/group name to mark for custom
                      preference.
                    </span>
                  ) : null}
                </form>
              )}
            </Formik>
          </div>

          {/* list of channels */}
          {search_val.length > 2 ? (
            <div
              className="autocom-box channel_list_container mt-3"
              style={{ width: "62%" }}
            >
              {stat_loading ? (
                <RollerAnimation />
              ) : search_field_error !== null ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "larger",
                    color: "white",
                    marginTop: "10%",
                  }}
                >
                  {search_field_error}
                </div>
              ) : search_data !== null && search_data.length === 0 ? (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "larger",
                    color: "white",
                    marginTop: "10%",
                  }}
                >
                  No data to display
                </div>
              ) : (
                <>
                  <Stack direction="row" justifyContent="space-between">
                    <h4
                      style={{
                        paddingLeft: "1rem",
                        marginLeft: "15px",
                        marginTop: "5px",
                        fontSize: "18px",
                        color: "whitesmoke",
                      }}
                    >
                      List Of Channels
                    </h4>
                    <CancelIcon
                      sx={{ color: "red", cursor: "pointer" }}
                      fontSize="small"
                      onClick={() => set_search_val("")}
                    />
                  </Stack>
                  <ul>
                    {search_data !== null &&
                      search_data.map((val, index) => (
                        <li key={index}>
                          <div className="row">
                            <div
                              className="col-md-8"
                              style={{ textAlign: "left" }}
                            >
                              {" "}
                              <img
                                style={{
                                  width: "36px",
                                  height: "36px",
                                  padding: "2px",
                                  border: "2px solid #f4f4f4",
                                }}
                                className="img-fluid rounded-circle"
                                src={
                                  val.channel_id !== "None"
                                    ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                    : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                }}
                                alt="User"
                              />
                              <span
                                className="channels_list_map"
                                style={{ marginLeft: "10px", color: "#dcf3ff" }}
                              >
                                {val.channel_name}
                              </span>
                            </div>
                            {[
                              ...marked_channels["company_preference"],
                              ...marked_channels["user_preference"],
                            ].some(
                              (item) => item["keyword"] == val.channel_name,
                            ) === false ? (
                              <div
                                className="col-md-4"
                                style={{ textAlign: "right", marginTop: "5px" }}
                              >
                                <button
                                  style={{
                                    borderRadius: "40px",
                                    background: "#d03940",
                                    color: "white",
                                    lineHeight: "1",
                                  }}
                                  className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                  onClick={() => {
                                    set_open_modal_pref({
                                      channel_name: val.channel_name,
                                      status: true,
                                      pref_type: "telegram_channel",
                                    });
                                  }}
                                >
                                  Mark
                                </button>
                              </div>
                            ) : (
                              <div
                                className="col-md-4"
                                style={{ textAlign: "right", marginTop: "5px" }}
                              >
                                <button
                                  disabled
                                  style={{
                                    borderRadius: "40px",
                                    background: "#d03940",
                                    color: "white",
                                    lineHeight: "1",
                                  }}
                                  className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                >
                                  Marked
                                </button>
                              </div>
                            )}
                          </div>
                        </li>
                      ))}
                  </ul>
                </>
              )}
            </div>
          ) : null}
        </div>
        <div
          className="position-absolute "
          style={{ marginLeft: "1%", width: "95%" }}
        >
          <div
            className="row"
            style={{ justifyContent: "center", marginTop: "16px" }}
          >
            <div className="col-lg-8">
              <TransparentContainer
                container={
                  <div>
                    <div className="card-header ">
                      <h5 className="stats_title_color">Company Preference</h5>
                    </div>
                    <div
                      className="card-body  scroll-custom"
                      style={{ height: "45vh" }}
                    >
                      <div>
                        <p style={{ textAlign: "start", color: "#c32d2d" }}>
                          {" "}
                          Only company admin can delete the company preference.
                        </p>
                      </div>
                      {marked_channels["error"] ? (
                        <div
                          style={{
                            height: "80%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            alignContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <h5 style={{ color: "var(--textColor)" }}>
                            {marked_channels["error"]}
                          </h5>
                        </div>
                      ) : null}
                      {marked_channels["company_preference"].length > 0
                        ? marked_channels["company_preference"].map(
                            (val, index) => (
                              <div className="file-tab" key={index}>
                                <div className="media">
                                  <div className="media-aside mr-1 align-self-start">
                                    <i
                                      className="file-icon lbg-0"
                                      style={{
                                        fontFamily: "none",
                                        fontSize: "30px",
                                        color: "white",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          size: 18,
                                          marginTop: "10px",
                                          marginLeft: "12px",
                                          color: "white",
                                        }}
                                      >
                                        {val.keyword ? (
                                          val.pref_type ===
                                          "telegram_channel" ? (
                                            val.keyword[0]
                                          ) : (
                                            val.username[0]
                                          )
                                        ) : (
                                          <FaStarOfLife />
                                        )}
                                      </IconContext.Provider>
                                    </i>
                                  </div>
                                  <div className="media-body my-auto ">
                                    <div className="file-name ">
                                      <span style={{ marginLeft: "10px" }}>
                                        {val.pref_type === "telegram_channel"
                                          ? val.keyword
                                          : val.username}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["channel_user_type"]}`,
                                          color:
                                            chips_background_color[
                                              "channel_user_type"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {val.pref_type
                                          ? val.pref_type === "telegram_channel"
                                            ? "Channel"
                                            : "User"
                                          : null}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["dashboard_filter"]}`,
                                          color:
                                            chips_background_color[
                                              "dashboard_filter"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {val.dashboard_filter
                                          ? val.dashboard_filter
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <Tooltip
                                    title={
                                      user_role === false
                                        ? "Only company admin can delete"
                                        : "Delete"
                                    }
                                  >
                                    <button
                                      style={{
                                        marginLeft: "25px",
                                        cursor: "pointer",
                                        background: "none",
                                        outline: "none",
                                        border: "none",
                                      }}
                                      disabled={user_role === false}
                                      onClick={() => {
                                        set_open_modal_pref((prev) => ({
                                          ...prev,
                                          channel_name: val.keyword,
                                        }));
                                        set_data_preference({
                                          sub_pref_type: "company_preference",
                                          dashboard_filter:
                                            val.dashboard_filter,
                                          pref_type: val.pref_type,
                                        });
                                      }}
                                      data-toggle="modal"
                                      data-target="#showdeletebutton"
                                    >
                                      <IconContext.Provider
                                        value={{
                                          size: 28,
                                          marginTop: "10px",
                                          color:
                                            user_role === false
                                              ? "#8f223c"
                                              : "rgb(217, 37, 80)",
                                        }}
                                      >
                                        <FaTrashAlt />
                                      </IconContext.Provider>
                                    </button>
                                  </Tooltip>
                                </div>
                              </div>
                            ),
                          )
                        : null}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <div
            className="row"
            style={{ justifyContent: "center", marginTop: "30px" }}
          >
            <div className="col-lg-8">
              <TransparentContainer
                container={
                  <div>
                    <div className="card-header ">
                      <h5 className="stats_title_color">User Preference</h5>
                    </div>
                    <div className="card-body">
                      {marked_channels["user_preference"].length > 0
                        ? marked_channels["user_preference"].map(
                            (val, index) => (
                              <div className="file-tab" key={index}>
                                <div className="media">
                                  <div className="media-aside mr-1 align-self-start">
                                    <i
                                      className="file-icon lbg-0"
                                      style={{
                                        fontFamily: "none",
                                        fontSize: "30px",
                                        color: "white",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      <IconContext.Provider
                                        value={{
                                          size: 18,
                                          marginTop: "10px",
                                          marginLeft: "12px",
                                          color: "white",
                                        }}
                                      >
                                        {val.keyword ? (
                                          val.pref_type ===
                                          "telegram_channel" ? (
                                            val.keyword[0]
                                          ) : (
                                            val.username[0]
                                          )
                                        ) : (
                                          <FaStarOfLife />
                                        )}
                                      </IconContext.Provider>
                                    </i>
                                  </div>
                                  <div className="media-body my-auto ">
                                    <div className="file-name ">
                                      <span style={{ marginLeft: "10px" }}>
                                        {val.pref_type === "telegram_channel"
                                          ? val.keyword
                                          : val.username}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["channel_user_type"]}`,
                                          color:
                                            chips_background_color[
                                              "channel_user_type"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {val.pref_type
                                          ? val.pref_type === "telegram_channel"
                                            ? "Channel"
                                            : "User"
                                          : null}
                                      </span>
                                      <span
                                        style={{
                                          border: `2px solid ${chips_background_color["dashboard_filter"]}`,
                                          color:
                                            chips_background_color[
                                              "dashboard_filter"
                                            ],
                                        }}
                                        className="filters-text"
                                      >
                                        {val.dashboard_filter
                                          ? val.dashboard_filter
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <Tooltip title="Delete">
                                    <span
                                      style={{
                                        marginLeft: "25px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        set_open_modal_pref((prev) => ({
                                          ...prev,
                                          channel_name: val.keyword,
                                        }));
                                        set_data_preference({
                                          sub_pref_type: "user_preference",
                                          dashboard_filter:
                                            val.dashboard_filter,
                                          pref_type: val.pref_type,
                                        });
                                      }}
                                      data-toggle="modal"
                                      data-target="#showdeletebutton"
                                    >
                                      <IconContext.Provider
                                        value={{
                                          size: 28,
                                          marginTop: "10px",
                                          color: "rgb(217, 37, 80)",
                                        }}
                                      >
                                        <FaTrashAlt />
                                      </IconContext.Provider>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            ),
                          )
                        : null}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      {open_modal_pref["status"] === true ? (
        <PreferenceType
          open_modal_pref={open_modal_pref}
          set_open_modal_pref={set_open_modal_pref}
          status_type={"custom"}
          set_channels={set_marked_channels}
          set_search_val={set_search_val}
        />
      ) : null}

      {/* Modal for delete */}
      <div
        className="modal fade"
        id="showdeletebutton"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="showdeletebutton"
        aria-hidden="true"
        style={{ background: "rgba(29, 40, 53, .95)" }}
      >
        <div
          className="modal-dialog modal-confirm"
          style={{ background: "white" }}
        >
          <div className="modal-content dk-mode">
            <div className="modal-header flex-column">
              <h4 className="modal-title w-100">Are you sure?</h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body">
              <p>
                Do you really want to delete these records? This process cannot
                be undone.
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-dismiss="modal"
                onClick={(e) => {
                  e.preventDefault();
                  delete_custom_preference(
                    open_modal_pref["channel_name"],
                    data_preference["sub_pref_type"],
                    data_preference["dashboard_filter"],
                    data_preference["pref_type"],
                    set_marked_channels,
                    "custom",
                  );
                  document.getElementById("showdeletebutton").className =
                    "modal fade";
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MarkedChannels;

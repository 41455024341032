import React, { useState, useEffect, memo } from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { GlobalStyles, Stack } from "@mui/material";
import Highlighter from "react-highlight-words";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import LanguageIcon from "@mui/icons-material/Language";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import DnsIcon from "@mui/icons-material/Dns";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import TagSearchMultiLine from "../modalContainer/TagSearchMultiLine";
import DownloadFileOptions from "../modalContainer/DownloadFileOptions";
import {
  rename_all_categories_name,
  sort_cloumn_datewise,
  handleOpenNewTab,
} from "../../../utils/Globals/global_functions";
import {
  link_website,
  crypto_link_tag,
  date_time_tooltip,
} from "../../../Assets/globalfunctions";
import "../../../css/Table/tabledata.css";

/*
 NOTE : This component handle the Table of Tag Search
*/
function TagSearchTable({
  Data,
  getSearchedData,
  template_card_filters,
  get_tag_data,
  total_data,
  tag_rate_limit,
  file_id,
  loadmore_loading,
  table_mode,
}) {
  const regex_validator_tag = (txt) => {
    if (txt.match(/^\/.*\/$/) !== null) {
      return true;
    }
    return false;
  };
  const regex_verifier = (txt) => {
    try {
      if (txt.match(/^\/.*\/$/) !== null) {
        const first_filter = txt.replace(/^\//, "");
        const last_filter = first_filter.replace(/\/$/, "");
        return last_filter;
      }
      return txt;
    } catch {
      return txt;
    }
  };

  // Custom tooltip
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#2b3c46",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  useEffect(() => {
    if (table_mode === "main_search_table") {
      if (template_card_filters === "ip_address") {
        document.getElementById("tag_main_table").className =
          "main__table tag_main_table_narrow";
      }
    }
    return;
  }, [template_card_filters]);

  const [pageSize, setPageSize] = useState(25);
  const [paginationValue, setPaginationValue] = useState(1);
  const [open_modal, set_open_modal] = useState(false);
  const [selected_row, set_selected_row] = useState();

  // Return date in the form of 2020-06-09
  const date_fm = (value) => {
    if (value !== "None") {
      try {
        const date = new Date(value);
        const month = date.getUTCMonth() + 1; // months from 1-12
        const new_month = month >= 10 ? month : `0${month}`;
        const day = date.getUTCDate();
        const new_day = day >= 10 ? day : `0${day}`;
        const year = date.getUTCFullYear();
        const newdate = `${new_day}-${new_month}-${year}`;
        return newdate;
      } catch {
        return value;
      }
    } else {
      return "None";
    }
  };

  const total_number_tag = (array, val = getSearchedData) => {
    const tag_obj = {
      bank_card: "bank_card_number",
      email: "email",
      ip_address: "ip_address",
      domain: "domain_name",
      phone: "phone_number",
      bin: "bin",
      crypto: "crypto_adress",
    };

    try {
      let filter_array = [];
      if (
        template_card_filters === "bank_card" ||
        template_card_filters === "bin"
      ) {
        if (array !== null) {
          const clone_array = [...array];

          if (val.match(/^\/.*\/$/) !== null) {
            const first_filter = val.replace(/^\//, "");
            const last_filter = first_filter.replace(/\/$/, "");
            filter_array = clone_array.filter((item) =>
              new RegExp(last_filter).test(
                item[tag_obj[template_card_filters]],
              ),
            );
          } else {
            filter_array = clone_array.filter((item) =>
              item[tag_obj[template_card_filters]].includes(val),
            );
          }

          return `${filter_array.length}/${array.length}`;
        }
      } else if (array !== null) {
        const clone_array = [...array];
        if (val.match(/^\/.*\/$/) !== null) {
          const first_filter = val.replace(/^\//, "");
          const last_filter = first_filter.replace(/\/$/, "");
          filter_array = clone_array.filter((item) =>
            new RegExp(last_filter, "i").test(
              item[tag_obj[template_card_filters]],
            ),
          );
        } else {
          filter_array = clone_array.filter((item) =>
            item[tag_obj[template_card_filters]]
              .toLowerCase()
              .includes(val.toLowerCase()),
          );
        }

        return `${filter_array.length}/${array.length}`;
      }
    } catch {
      return `${array.length}/${array.length}`;
    }
  };

  const load_more = () => {
    try {
      const filters_array = JSON.parse(
        localStorage.getItem("filters_array_tag"),
      );
      const {
        searchedData,
        card_filters,
        exp_year,
        exp_month,
        card_type,
        crypto_provider,
        email_provider,
        card_date_from,
        card_date_to,
        max_tag,
        tag_source,
        tag_filter_type,
        channels_list_tag,
        display_type_tag,
        post_category_tag,
      } = filters_array;
      get_tag_data(
        searchedData,
        card_filters,
        exp_year,
        exp_month,
        card_type,
        crypto_provider,
        email_provider,
        card_date_from,
        card_date_to,
        max_tag,
        tag_source,
        tag_filter_type,
        channels_list_tag,
        display_type_tag,
        post_category_tag,
      );
    } catch {
      //Do nothing
    }
  };
  const columns_tag = [
    { field: "id", headerName: "ID", width: 25 },
    {
      field: "bank_card_no",
      headerName: "Bank Card No",
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.bank_card_no !== null ? (
            template_card_filters === "bank_card" ? (
              <Highlighter
                highlightClassName="highlightclass"
                searchWords={[regex_verifier(getSearchedData)]}
                autoEscape={regex_validator_tag(getSearchedData) !== true}
                textToHighlight={
                  cellValues.row.bank_card_no[0].bank_card_number
                }
                className="text_hidden"
              />
            ) : (
              <p className="text_hidden">
                {cellValues.row.bank_card_no[0].bank_card_number}
              </p>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "bin",
      headerName: "Bin",
      width: 73,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.bin !== null ? (
            template_card_filters === "bin" ? (
              <Highlighter
                highlightClassName="highlightclass"
                searchWords={[regex_verifier(getSearchedData)]}
                autoEscape={regex_validator_tag(getSearchedData) !== true}
                textToHighlight={cellValues.row.bin[0].bin}
                className="text_hidden"
              />
            ) : (
              <p className="text_hidden">{cellValues.row.bin[0].bin}</p>
            )
          ) : null}
        </>
      ),
    },
    {
      field: "exp_year",
      headerName: "Exp year",
      width: 80,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.exp_year !== null ? (
            <p className="text_hidden">
              {cellValues.row.exp_year[0].expiry_year !== null
                ? cellValues.row.exp_year[0].expiry_year
                : "None"}
            </p>
          ) : null}
        </>
      ),
    },
    {
      field: "exp_month",
      headerName: "Exp month",
      width: 70,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.exp_month !== null ? (
            <p className="text_hidden">
              {cellValues.row.exp_month[0].expiry_month !== null
                ? cellValues.row.exp_month[0].expiry_month
                : "None"}
            </p>
          ) : null}
        </>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      sortable: false,
      renderCell: (cellValues) => {
        let text = "";
        if (cellValues.row.type !== null) {
          text = cellValues.row.type[0].bank_card_type;
        }
        let new_txt = "";
        try {
          if (text.includes("_") === true) {
            new_txt = text.replaceAll("_", " ");
          } else {
            new_txt = text;
          }
        } catch {
          //Do nothing
        }
        return (
          <p className="text_hidden" style={{ textTransform: "capitalize" }}>
            {new_txt}
          </p>
        );
      },
    },
    {
      field: "cvv_no",
      headerName: "CVV",
      width: 90,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.cvv_no !== null ? (
            <p className="text_hidden">
              {cellValues.row.cvv_no[0].cvv_number !== null
                ? cellValues.row.cvv_no[0].cvv_number
                : "None"}
            </p>
          ) : null}
        </>
      ),
    },

    {
      field: "email",
      headerName: "Email",
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.email !== null ? (
            <Highlighter
              highlightClassName="highlightclass"
              searchWords={[regex_verifier(getSearchedData)]}
              autoEscape={regex_validator_tag(getSearchedData) !== true}
              textToHighlight={cellValues.row.email[0].email}
              className="text_hidden"
            />
          ) : null}
        </>
      ),
    },
    {
      field: "provider",
      headerName: "Provider",
      width: 130,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.email !== null ? (
            <p className="text_hidden">
              {cellValues.row.email[0].email_provider}
            </p>
          ) : null}
        </>
      ),
    },
    {
      field: "ip_address",
      headerName: "Ip address",
      flex: 2,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.ip_address !== null ? (
            <Highlighter
              highlightClassName="highlightclass"
              searchWords={[regex_verifier(getSearchedData)]}
              autoEscape={regex_validator_tag(getSearchedData) !== true}
              textToHighlight={cellValues.row.ip_address[0].ip_address}
              className="text_hidden"
            />
          ) : null}
        </>
      ),
    },
    {
      field: "crypto",
      headerName: "Crypto Address",
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.crypto !== null ? (
            <button
              onClick={() => {
                if (
                  [cellValues.row.crypto_type[0].crypto_type].some(
                    (cryptoType) =>
                      cryptoType === "bitcoin" ||
                      cryptoType === "ethereum" ||
                      cryptoType === "tron" ||
                      cryptoType === "xrp",
                  )
                ) {
                  link_website(
                    crypto_link_tag(
                      cellValues.row.crypto[0].crypto_adress,
                      cellValues.row.crypto_type[0].crypto_type,
                    ),
                  );
                }
                return;
              }}
              className={
                [cellValues.row.crypto_type[0].crypto_type].some(
                  (cryptoType) =>
                    cryptoType === "bitcoin" ||
                    cryptoType === "ethereum" ||
                    cryptoType === "tron" ||
                    cryptoType === "xrp",
                )
                  ? "tag_crypto_link_btn"
                  : "tag_crypto_link_btn_no_pointer"
              }
            >
              <Highlighter
                highlightClassName={
                  [cellValues.row.crypto_type[0].crypto_type].some(
                    (cryptoType) =>
                      cryptoType === "bitcoin" ||
                      cryptoType === "ethereum" ||
                      cryptoType === "tron" ||
                      cryptoType === "xrp",
                  )
                    ? "tag_crypto_link_btn_highliight"
                    : "tag_crypto_link_btn_no_pointer_highlight"
                }
                searchWords={[regex_verifier(getSearchedData)]}
                autoEscape={regex_validator_tag(getSearchedData) !== true}
                textToHighlight={cellValues.row.crypto[0].crypto_adress}
                className={
                  [cellValues.row.crypto_type[0].crypto_type].some(
                    (cryptoType) =>
                      cryptoType === "bitcoin" ||
                      cryptoType === "ethereum" ||
                      cryptoType === "tron" ||
                      cryptoType === "xrp",
                  )
                    ? "text_hidden tag_crypto_link_btn"
                    : "text_hidden tag_crypto_link_btn_no_pointer"
                }
              />
            </button>
          ) : null}
        </>
      ),
    },
    {
      field: "crypto_type",
      headerName: "Crypto Type",
      width: 120,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.crypto_type !== null ? (
            <p className="text_hidden">
              {cellValues.row.crypto_type[0].crypto_type}
            </p>
          ) : null}
        </>
      ),
    },

    {
      field: "domain",
      headerName: "Domain",
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.domain !== null ? (
            <Highlighter
              highlightClassName="highlightclass"
              searchWords={[regex_verifier(getSearchedData)]}
              autoEscape={regex_validator_tag(getSearchedData) !== true}
              textToHighlight={cellValues.row.domain[0].domain_name}
              className="text_hidden"
            />
          ) : null}
        </>
      ),
    },
    {
      field: "phone_no",
      headerName: "Phone",
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => {
        let number = "";
        try {
          if (cellValues.row.phone_no !== null) {
            number = cellValues.row.phone_no[0].phone_number.toString();
          }
        } catch {
          number = cellValues.row.phone_no[0].phone_number;
        }
        return (
          <>
            {cellValues.row.phone_no !== null ? (
              <Highlighter
                highlightClassName="highlightclass"
                searchWords={[regex_verifier(getSearchedData)]}
                autoEscape={regex_validator_tag(getSearchedData) !== true}
                textToHighlight={number}
                className="text_hidden"
              />
            ) : null}
          </>
        );
      },
    },
    {
      field: "country_code",
      headerName: "Country Code",
      flex: 1,
      sortable: false,
      renderCell: (cellValues) => (
        <>
          {cellValues.row.country_code !== null ? (
            <p className="text_hidden">
              {cellValues.row.country_code[0].country_code}
            </p>
          ) : null}
        </>
      ),
    },
    {
      field: "tag_category",
      headerName: "Category",
      width: 100,
      sortable: false,
    },
    {
      field: "user",
      headerName: "User",
      filterable: false,
      sortable: false,
      disableColumnMenu: true,
      width: 57,
      renderCell: (cellValues) => (
        <>
          <button
            className="table_user_column"
            disabled={cellValues.row.user === "None"}
            onClick={(event) =>
              handleOpenNewTab(`/users?page=${cellValues.row.user}`, event)
            }
          >
            {cellValues.row.user && cellValues.row.user !== "None" ? (
              <Tooltip title="view profile" disableInteractive>
                <GroupsIcon sx={{ color: "#37b992" }} />
              </Tooltip>
            ) : (
              <PersonOffIcon sx={{ color: "#999" }} />
            )}
          </button>
        </>
      ),
    },
    {
      field: "channel_name",
      headerName: "Channel Name",
      flex:
        template_card_filters === "phone" ||
        template_card_filters === "ip_address"
          ? 2
          : 1,
      sortable: false,
    },
    {
      field: "source",
      headerName: "Source",
      width: 100,
      sortable: false,
      renderCell: (cellValues) => (
        <p className="text_hidden" style={{ textTransform: "capitalize" }}>
          {cellValues.row.source !== null
            ? cellValues.row.source
              ? cellValues.row.source.replaceAll("_", " ")
              : null
            : null}
        </p>
      ),
    },
    {
      field: "date",
      headerName: "Date",
      width: 110,
      type: "date",
      sortComparator: sort_cloumn_datewise,
      renderCell: (cellValues) => (
        <Tooltip
          title={date_time_tooltip(cellValues.row.date)}
          placement="bottom"
          disableInteractive
        >
          <p className="text_hidden text-nowrap">
            {date_fm(cellValues.row.date)}
          </p>
        </Tooltip>
      ),
    },
    {
      field: "total",
      headerName:
        template_card_filters === "bank_card"
          ? "Card/Total"
          : template_card_filters === "email"
          ? "Email/Total"
          : template_card_filters === "ip_address"
          ? "IP/Total"
          : template_card_filters === "crypto"
          ? "Crypto/Total"
          : template_card_filters === "domain"
          ? "Domain/Total"
          : template_card_filters === "phone"
          ? "Phone/Total"
          : "Bin/Total",
      renderCell: (cellValues) => (
        <p className="text_hidden">
          {template_card_filters === "bank_card"
            ? total_number_tag(cellValues.row.bank_card_no)
            : template_card_filters === "email"
            ? total_number_tag(cellValues.row.email)
            : template_card_filters === "ip_address"
            ? total_number_tag(cellValues.row.ip_address)
            : template_card_filters === "crypto"
            ? total_number_tag(cellValues.row.crypto)
            : template_card_filters === "domain"
            ? total_number_tag(cellValues.row.domain)
            : template_card_filters === "phone"
            ? total_number_tag(cellValues.row.phone_no)
            : total_number_tag(cellValues.row.bin)}
        </p>
      ),

      width: 110,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "see_more",
      headerName: "Details",
      width: 130,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => (
        <Stack spacing={1}>
          <Stack direction="row" spacing={1.5}>
            <button
              className="alert_bell_icon_btn"
              onClick={() => {
                set_open_modal(true);
                set_selected_row(cellValues.row);
              }}
            >
              <Tooltip title="see more">
                <VisibilityIcon fontSize="small" sx={{ color: "#9bbcd1" }} />
              </Tooltip>
            </button>
            <HtmlTooltip
              title={`Total bank card : ${
                cellValues.row.bank_card_no !== null
                  ? cellValues.row.bank_card_no.length
                  : 0
              }`}
            >
              <CreditCardIcon
                fontSize="small"
                sx={{
                  color:
                    cellValues.row.bank_card_no !== null ? "#a6db27eb" : "gray",
                }}
              />
            </HtmlTooltip>
            <HtmlTooltip
              title={`Total crypto address : ${
                cellValues.row.crypto !== null
                  ? cellValues.row.crypto.length
                  : 0
              }`}
            >
              <CurrencyBitcoinIcon
                fontSize="small"
                sx={{
                  color: cellValues.row.crypto !== null ? "#a6db27eb" : "gray",
                }}
              />
            </HtmlTooltip>
          </Stack>
          <Stack direction="row" spacing={1.5}>
            <HtmlTooltip
              title={`Total email address : ${
                cellValues.row.email !== null ? cellValues.row.email.length : 0
              }`}
            >
              <AlternateEmailIcon
                fontSize="small"
                sx={{
                  color: cellValues.row.email !== null ? "#a6db27eb" : "gray",
                }}
              />
            </HtmlTooltip>
            <HtmlTooltip
              title={`Total domain : ${
                cellValues.row.domain !== null
                  ? cellValues.row.domain.length
                  : 0
              }`}
            >
              <LanguageIcon
                fontSize="small"
                sx={{
                  color: cellValues.row.domain !== null ? "#a6db27eb" : "gray",
                }}
              />
            </HtmlTooltip>
            <HtmlTooltip
              title={`Total Ip address : ${
                cellValues.row.ip_address !== null
                  ? cellValues.row.ip_address.length
                  : 0
              }`}
            >
              <DnsIcon
                fontSize="small"
                sx={{
                  color:
                    cellValues.row.ip_address !== null ? "#a6db27eb" : "gray",
                }}
              />
            </HtmlTooltip>
          </Stack>
        </Stack>
      ),
    },
  ];

  const filter_data_tag = Data.map((item, index) => ({
    id: index,
    bank_card_no: item.bank_card.length !== 0 ? item.bank_card : null,
    bin: item.bank_card.length !== 0 ? item.bank_card : null,
    exp_year: item.bank_card.length !== 0 ? item.bank_card : null,
    exp_month: item.bank_card.length !== 0 ? item.bank_card : null,
    type: item.bank_card.length !== 0 ? item.bank_card : null,
    cvv_no: item.bank_card.length !== 0 ? item.bank_card : null,
    ip_address: item.ip_address.length !== 0 ? item.ip_address : null,
    domain: item.domain.length !== 0 ? item.domain : null,
    country_code: item.phone.length !== 0 ? item.phone : null,
    phone_no: item.phone.length !== 0 ? item.phone : null,
    email: item.email.length !== 0 ? item.email : null,
    crypto: item.crypto.length !== 0 ? item.crypto : null,
    crypto_type: item.crypto.length !== 0 ? item.crypto : null,

    source: item.data_source,
    user: item.post_user ? item.post_user : "None",
    channel_name: item.post_channel ? item.post_channel : "None",
    date: item.date,
    tag_category: item.category
      ? item.category.length > 0
        ? rename_all_categories_name(item.category)
        : "None"
      : "None",
    all_data: item,
  }));

  const clone_data_tag = [...filter_data_tag];
  const new_state_tag = clone_data_tag.slice(
    (parseInt(paginationValue) - 1) * pageSize,
    parseInt(paginationValue) * pageSize,
  );
  const new_total_page = Math.ceil(filter_data_tag.length / pageSize);

  const handlePaginationChange = (event, value) => {
    setPaginationValue(value);
  };

  // Select the required page size
  const page_size_fixer = (newPageSize) => {
    setPageSize(newPageSize);
    setPaginationValue(Math.ceil(filter_data_tag.length / newPageSize));
  };

  return (
    <>
      <div
        className={
          table_mode === "main_search_table"
            ? "main__table breach_main_table tag_main_table"
            : "main__table breach_main_table tag_main_table main__table_dashboard"
        }
        id="tag_main_table"
      >
        <div className="total_data_number">
          <h6>
            Results shown/found :{" "}
            <span>
              {Data.length}/{total_data}
            </span>
          </h6>
          <div className="total_data_number_child">
            {table_mode === "main_search_table" ? (
              <DownloadFileOptions
                getSearchedData={getSearchedData}
                file_id={file_id}
                post_no={100}
                search_table_type={"tag"}
              />
            ) : null}
            <h6>
              Credits remaining :{" "}
              <span className="credit_limit_colors">{tag_rate_limit}</span>
            </h6>
          </div>
        </div>
        <GlobalStyles
          styles={{
            ".MuiDataGrid-menuList": {
              backgroundColor: "#394e5a",
            },
            ".MuiMenu-list ": {
              backgroundColor: "#394e5a",
            },
            ".MuiDataGrid-menuList li": {
              color: "#dcf3ff",
            },
            ".MuiMenu-list li": {
              color: "#dcf3ff",
            },
            ".MuiPaginationItem-root": {
              color: "#9bbcd1",
              backgroundColor: "#1e2a31",
            },
            ".MuiDataGrid-filterForm": {
              backgroundColor: "#394e5a",
            },
          }}
        />

        {/* <div className='divider_line'></div> */}
        <DataGrid
          rows={new_state_tag}
          columns={columns_tag}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => page_size_fixer(newPageSize)}
          rowsPerPageOptions={[25, 50, 75, 100]}
          autoHeight
          columnVisibilityModel={{
            bank_card_no: !!(
              template_card_filters === "bank_card" ||
              template_card_filters === "bin"
            ),
            bin: !!(
              template_card_filters === "bin" ||
              template_card_filters === "bank_card"
            ),
            exp_year: !!(
              template_card_filters === "bank_card" ||
              template_card_filters === "bin"
            ),
            exp_month: !!(
              template_card_filters === "bank_card" ||
              template_card_filters === "bin"
            ),
            type: !!(
              template_card_filters === "bank_card" ||
              template_card_filters === "bin"
            ),
            cvv_no: !!(
              template_card_filters === "bank_card" ||
              template_card_filters === "bin"
            ),

            email: template_card_filters === "email",
            provider: template_card_filters === "email",
            ip_address: template_card_filters === "ip_address",
            crypto: template_card_filters === "crypto",
            crypto_type: template_card_filters === "crypto",

            domain: template_card_filters === "domain",
            country_code: template_card_filters === "phone",
            phone_no: template_card_filters === "phone",
          }}
          disableColumnSelector
          density="comfortable"
        />
        <Pagination
          count={new_total_page}
          color="primary"
          page={paginationValue}
          onChange={handlePaginationChange}
          size="small"
        />
        {total_data && Number(total_data) > Data.length ? (
          <Stack
            direction="row"
            alignItem="center"
            justifyContent="center"
            spacing={2}
            mt={1}
          >
            {" "}
            {table_mode === "main_search_table" ? (
              new_total_page === paginationValue ? (
                loadmore_loading === true ? (
                  <CircularProgress
                    fontSize="small"
                    sx={{ color: "#28cab3" }}
                  />
                ) : (
                  <button onClick={load_more} className="load_more_btn">
                    Load more
                  </button>
                )
              ) : null
            ) : null}
          </Stack>
        ) : null}
      </div>

      {open_modal === true ? (
        <TagSearchMultiLine
          open_modal={open_modal}
          set_open_modal={set_open_modal}
          selected_row={selected_row}
          template_card_filters={template_card_filters}
        />
      ) : null}
    </>
  );
}
TagSearchTable.propTypes = {
  Data: PropTypes.array,
  getSearchedData: PropTypes.string,
  template_card_filters: PropTypes.string,
  get_tag_data: PropTypes.func,
  total_data: PropTypes.number,
  tag_rate_limit: PropTypes.number,
  file_id: PropTypes.string,
  loadmore_loading: PropTypes.bool,
  table_mode: PropTypes.string,
};

export default memo(TagSearchTable);

import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Grid from "@mui/material/Grid";
import ShowMoreText from "react-show-more-text";
import "../../../css/Modal/modal.css";

function ModalChannel({
  open_modal_channel,
  set_open_modal_channel,
  selectedRow,
}) {
  // function for the Show More text
  function executeOnClick() {}

  // link the file
  const file_link_adder = (links, msgid) => {
    if (links !== null && links !== "None") {
      if (links.includes("t.me")) {
        const new_link = links.replace("s/", "");
        const open_link = `${new_link}/${msgid}`;
        window.open(
          open_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      } else {
        const new_link = `https://t.me/${links}/${msgid}`;
        window.open(
          new_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      }
    }
  };

  return (
    <div>
      {selectedRow !== null ? (
        <Modal
          open={open_modal_channel}
          onClose={() => set_open_modal_channel(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="container__modal">
            <Box className="container_modal_child">
              <div className="modal-heading">
                <div className="modal-heading-parent-closeBtn">
                  <button
                    className="modal-heading-closeBtn"
                    onClick={() => set_open_modal_channel(false)}
                  >
                    <CancelIcon
                      sx={{ color: "red", cursor: "pointer", mr: 2 }}
                    />
                  </button>
                </div>
              </div>

              <div id="modal-modal-description" className="modal-description">
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <div className="modal-desc-group">
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        {selectedRow.name}
                      </Typography>
                    </div>
                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">ID</Typography>
                      <Typography className="modal-desc-content">
                        {selectedRow.profileid}
                      </Typography>
                    </div>
                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">
                        Telegram link
                      </Typography>
                      <Typography className="modal-desc-content">
                        {selectedRow.telegramlink !== "None" ? (
                          <button
                            className="link_post"
                            onClick={() =>
                              file_link_adder(
                                selectedRow.telegramlink,
                                selectedRow.all_data?.msgid,
                              )
                            }
                          >
                            {selectedRow.telegramlink}
                          </button>
                        ) : (
                          selectedRow.telegramlink
                        )}
                      </Typography>
                    </div>

                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">
                        Total post
                      </Typography>
                      <Typography className="modal-desc-content">
                        {selectedRow.totalpost}
                      </Typography>
                    </div>

                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">
                        Subscribers
                      </Typography>
                      <Typography className="modal-desc-content">
                        {selectedRow.subscribers}
                      </Typography>
                    </div>
                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">
                        Total media
                      </Typography>
                      <Typography className="modal-desc-content">
                        {selectedRow.totalmediapost}
                      </Typography>
                    </div>

                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">
                        Total link post
                      </Typography>
                      <Typography className="modal-desc-content">
                        {selectedRow.totallinkpost}
                      </Typography>
                    </div>
                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">
                        Total file post
                      </Typography>
                      <Typography className="modal-desc-content">
                        {selectedRow.totalfilepost}
                      </Typography>
                    </div>

                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">
                        Type
                      </Typography>
                      <Typography className="modal-desc-content">
                        {selectedRow.type}
                      </Typography>
                    </div>

                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">
                        Description
                      </Typography>
                      <ShowMoreText
                        /* Default options */
                        lines={10}
                        more="Show more"
                        less="Show less"
                        className="content-css"
                        anchorClass="my-anchor-css-class"
                        onClick={executeOnClick}
                        expanded={false}
                      >
                        <Typography className="modal-desc-content modal-desc-content-post">
                          {selectedRow.description}
                        </Typography>
                      </ShowMoreText>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
}
ModalChannel.propTypes = {
  open_modal_channel: PropTypes.bool,
  set_open_modal_channel: PropTypes.func,
  selectedRow: PropTypes.object,
};

export default ModalChannel;

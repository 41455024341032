export const handle_string_to_array = (text) => {
  try {
    const text_stringigy = JSON.stringify(text);
    let parsedStr = JSON.parse(`[${text_stringigy}]`)[0];
    const result_array = parsedStr.split(/[\n,]+/).filter(Boolean);
    return result_array;
  } catch {
    return text.split();
  }
};

export const handle_validate_bulk_item = (field) => {
  try {
    if (field !== null && field) {
      if (Array.isArray(field) && field.length > 0) {
        return field.toString();
      } else return "None";
    } else return "None";
  } catch {
    return "None";
  }
};

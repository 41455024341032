import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Stack } from "@mui/material";
import { Formik } from "formik";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "@mui/material/Tooltip";
import GridViewIcon from "@mui/icons-material/GridView";
import HelpIcon from "@mui/icons-material/Help";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { GlobalStyles } from "@mui/material";
import { stealer_logs_auto_notification } from "../../../utils/Globals/global_functions";
import UserDashboardInsertionFilters from "../filters/UserDashboardInsertionFilters";
import { UserDashboardInsertionFiltersUpdate } from "../filters/UserDashboardInsertionFilters";
import {
  bank_card_validator,
  ip_address_validator,
  phone_validator,
  crypto_validator,
  email_validator,
  bin_validator,
} from "../../../utils/Validation/Tag/tag_validator";
import { FaInfoCircle } from "react-icons/fa";
import { IconContext } from "react-icons";
import { options_post_category } from "../../../Assets/globalfunctions";
import { handle_check_category_length } from "../../../Assets/globalfunctions";
import {
  card_type_options,
  crypto_options,
  email_options,
  exp_year_options,
  exp_month_options,
} from "../../../utils/Globals/global_variables";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import axios from "axios";
import {
  handle_success_message,
  handle_error_message,
} from "../../../utils/ResponseMessage/response_message";
import { handle_custom_debounce } from "../../../utils/Globals/global_functions";
import { handle_set_edit_value_stealer_log } from "../company_dashboard/companyglobalfunc";

import CircularProgress from "@mui/material/CircularProgress";
// import { options_post_category } from "../../Assets/globalfunctions";
import { RollerAnimation } from "../loader/Loader";
import "../../../css/Modal/modal.css";

function UserDashboardInsertion() {
  const token = handle_session_get("Token");
  const [user_dashboard_data, set_user_dashboard_data] = useState([]);
  const [user_dashboard_status, set_user_dashboard_status] = useState({
    loading: false,
    error: "",
  });
  const [option_dropdown, set_option_dropdown] = useState("keyword");
  const [search_val, set_search_val] = useState("");
  const [stat_loading, set_stat_loading] = useState(false);
  const [search_error, set_search_error] = useState("");
  const [search_data, set_search_data] = useState([]);
  const [notification_filters, set_notification_filters] = useState("default");
  const [notification_filters_tag, set_notification_filters_tag] =
    useState("bank_card");
  const [stealer_multiple_obj, set_stealer_multiple_obj] = useState({});
  const [category_stealer, set_category_stealer] = useState("off");
  const [stealer_search_type, set_stealer_search_type] = useState("contains");
  const [stealer_filter_type, set_stealer_filter_type] = useState("email");

  const [post_search_sub, set_search_sub] = useState("Channel/Group");
  const [postCategory, setPostCategory] = useState([]);
  const [keyword, setKeyword] = useState("contains");
  const [card_type, set_card_type] = useState(card_type_options);
  const [crypto_provider, set_crypto_provider] = useState(crypto_options);
  const [email_provider, set_email_provider] = useState(email_options);
  const [exp_year, set_exp_year] = useState(exp_year_options);
  const [exp_month, set_exp_month] = useState(exp_month_options);
  const [spam_filter, set_spam_filter] = useState(false);
  const [edit_chart_query, set_edit_chart_query] = useState("");
  const [field_filter, set_field_filter] = useState("None");
  const [channel_filter_type, set_channel_filter_type] = useState("default");
  const [channels_list, set_channels_list] = useState([]);
  const [chart_pref, set_chart_pref] = useState("user");
  const [show_filters, set_show_filters] = useState(false);
  const [update_loader, set_update_loader] = useState(false);
  const [modal_data, set_modal_data] = useState({
    stealer_multiple_obj: {},
    category_stealer: "off",
    stealer_search_type: "contains",
    post_search_sub: "channel/Group",
    postCategory: [],
    keyword: "contains",
    card_type: card_type_options,
    crypto_provider: crypto_options,
    email_provider: email_options,
    exp_year: exp_year_options,
    exp_month: exp_month_options,
    spam_filter: false,
    field_filter: "None",
    option_dropdown: "keyword",
    notification_filters_tag: "bank_card",
    stealer_filter_type: "email",
    channels_list: [],
    edit_chart_query: "",
    notification_filters: "default",
    channel_filter_type: "default",
    chart_pref,
    all_data: {},
  });
  const [show_update_filter, set_show_update_filter] = useState(false);
  const [post_category_update, set_post_category_update] = useState([]);
  useEffect(() => {
    const session_categories = handle_session_get("enabled_categories");
    if (session_categories !== "all") {
      const array_data = session_categories.split(",");
      setPostCategory(array_data);
    } else {
      setPostCategory(options_post_category);
    }
    handle_get_user_dashboard_data();
  }, []);

  const handle_check_options_length = (options, selected) => {
    try {
      if (options.length === selected.length) return true;
      return false;
    } catch {
      return false;
    }
  };

  const handle_get_user_dashboard_data = () => {
    let isSubmitBlocked = false;
    set_user_dashboard_status({ loading: true, error: "" });
    if (!isSubmitBlocked) {
      axios
        .get(`https://dev.recordedtelegram.com/user_dashboard`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          set_user_dashboard_data(res.data.data);
          set_user_dashboard_status({ loading: false, error: "" });
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_user_dashboard_status({
            loading: false,
            error:
              err.response?.data?.errormsg ??
              "Something went wrong, please try again later.",
          });
          isSubmitBlocked = false;
        });
    }
  };
  const handle_dashboard_insertion = (value) => {
    let isSubmitBlocked = false;
    set_update_loader(true);
    const filter_stealer_multiple_obj = {};
    for (const key in stealer_multiple_obj) {
      if (Object.prototype.hasOwnProperty.call(stealer_multiple_obj, key)) {
        filter_stealer_multiple_obj[key] =
          stealer_multiple_obj[key].length === 0
            ? null
            : stealer_multiple_obj[key];
      }
    }

    const chart_filter_obj = {
      selectCategory: ["keyword", "file", "tag"].includes(option_dropdown)
        ? handle_check_category_length(postCategory) === true
          ? "all"
          : postCategory
        : "all",
      value: value,
      dashboard_type: option_dropdown,
      dashboard_filter:
        option_dropdown === "channel"
          ? channel_filter_type === "individual"
            ? "nested"
            : "default"
          : notification_filters,
      dashboard_preference: chart_pref,
      is_enabled: true,
    };
    if (["tag", "stealer log"].includes(option_dropdown)) {
      chart_filter_obj["search_mode"] =
        option_dropdown === "tag"
          ? notification_filters_tag
          : stealer_filter_type;
    }
    if (["keyword", "file"].includes(option_dropdown)) {
      chart_filter_obj["telegram_obj"] = {
        field_filter:
          option_dropdown === "keyword"
            ? field_filter === "None"
              ? null
              : field_filter
            : option_dropdown === "file"
            ? "filename"
            : "conv_name",
        spam:
          option_dropdown === "keyword"
            ? ["True", "true", true].includes(spam_filter)
            : false,
        search_type: keyword,
        search_filter: post_search_sub,
      };
    } else if (option_dropdown === "tag") {
      const tag_search_mode = notification_filters_tag;
      chart_filter_obj["tag_obj"] = {
        exp_year: ["bank_card", "bin"].includes(tag_search_mode)
          ? handle_check_options_length(exp_year_options, exp_year) === true
            ? "all"
            : exp_year
          : null,
        exp_month: ["bank_card", "bin"].includes(tag_search_mode)
          ? handle_check_options_length(exp_month_options, exp_month) === true
            ? "all"
            : exp_month
          : null,
        bank_card_type: ["bank_card", "bin"].includes(tag_search_mode)
          ? handle_check_options_length(card_type_options, card_type) === true
            ? "all"
            : card_type
          : null,
        email_type:
          tag_search_mode === "email"
            ? handle_check_options_length(email_options, email_provider) ===
              true
              ? "all"
              : email_provider
            : null,
        crypto_type:
          tag_search_mode === "crypto"
            ? handle_check_options_length(crypto_options, crypto_provider) ===
              true
              ? "all"
              : crypto_provider
            : null,
      };
    } else if (option_dropdown === "stealer log") {
      chart_filter_obj["stealer_obj"] = {
        ...filter_stealer_multiple_obj,
        search_type: stealer_search_type,
        stealer_category: category_stealer === "off" ? null : category_stealer,
      };
    } else if (option_dropdown === "channel") {
      chart_filter_obj["channel_obj"] = {
        channel_name: channel_filter_type === "individual" ? channels_list : [],
      };
    }
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .post(
          `https://dev.recordedtelegram.com/user_dashboard`,
          chart_filter_obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          handle_success_message("Dashboard added successfully.");
          set_update_loader(false);
          set_user_dashboard_data(res.data.data);
          if (option_dropdown === "channel") {
            set_search_val("");
          }
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          handle_error_message(err.response.data.errormsg);
          set_update_loader(false);
          isSubmitBlocked = false;
        });
    }
  };
  const handle_delete_user_dashboard_data = (delete_id) => {
    axios
      .delete(`https://dev.recordedtelegram.com/user_dashboard/${delete_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        handle_success_message("Dashboard deleted successfully");
        const filter_data = user_dashboard_data.filter(
          (item) => item.id !== delete_id,
        );
        set_user_dashboard_data(filter_data);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };

  const val_change = (e) => {
    if (option_dropdown === "channel" && channel_filter_type === "individual") {
      set_search_val("");
      return;
    }
    set_search_val(e.target.value);
    if (e.target.value.length >= 2) {
      set_stat_loading(true);
      const data = { qtext: e.target.value, search_type: "alert" };
      axios
        .post("https://dev.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const filter_data = res.data.filter((item) => item.link !== "None");
          set_search_data(filter_data);
          set_stat_loading(false);
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_stat_loading(false);
            set_search_error(err.response.data.errormsg);
          } catch {
            //Do nothing
          }
        });
    } else {
      set_search_data(null);
    }
  };
  const handle_change_option = (event) => {
    const selected_value = event.target.value;
    set_option_dropdown(selected_value);
    if (["tag", "stealer log"].includes(selected_value)) {
      set_notification_filters("default");
    }
  };
  const handle_update_modal_state = (key, value) => {
    set_modal_data((prev_state) => ({
      ...prev_state,
      [key]: value,
    }));
  };

  const handle_set_default_options = (chart_response, notification_type) => {
    try {
      const graph_type = chart_response["search_mode"];
      const session_categories = handle_session_get("enabled_categories");
      let assign_categories = [];
      if (session_categories !== "all") {
        assign_categories = session_categories.split(",");
      } else {
        assign_categories = options_post_category;
      }
      const chart_query_filter =
        chart_response?.["dashboard_filter"] ?? "default";
      const get_chart_pref = chart_response?.["dashboard_preference"] ?? "user";
      const {value,dashboard_type} = chart_response
      const get_edit_query = handle_set_edit_value_stealer_log(value, dashboard_type,chart_response)
      handle_update_modal_state("edit_chart_query", get_edit_query);
      handle_update_modal_state("option_dropdown", notification_type);
      handle_update_modal_state("all_data", chart_response);
      handle_update_modal_state("chart_pref", get_chart_pref);
      handle_update_modal_state("notification_filters", chart_query_filter);
      if (
        notification_type === "keyword" ||
        notification_type === "file" ||
        notification_type === "file_extension"
      ) {
        const get_categories =
          chart_response?.["category_access"] === "all"
            ? assign_categories
            : chart_response?.["category_access"];
        const get_search_type =
          chart_response?.["telegram_obj"]?.["search_type"];
        const get_channel_group =
          chart_response?.["telegram_obj"]?.["search_filter"];
        set_post_category_update(get_categories ?? assign_categories);
        handle_update_modal_state("keyword", get_search_type);
        handle_update_modal_state("post_search_sub", get_channel_group);
        if (notification_type === "keyword") {
          const get_field_filter =
            chart_response?.["telegram_obj"]?.["field_filter"] ?? "None";
          const get_spam = chart_response?.["telegram_obj"]?.["spam"] ?? false;
          handle_update_modal_state("field_filter", get_field_filter);
          handle_update_modal_state("spam_filter", get_spam);
        }
      } else if (notification_type === "tag") {
        const get_categories =
          chart_response?.["category_access"] === "all"
            ? assign_categories
            : chart_response?.["category_access"];
        set_post_category_update(get_categories ?? assign_categories);
        handle_update_modal_state("notification_filters_tag", graph_type);
        if (graph_type === "bank_card" || graph_type === "bin") {
          const get_exp_year =
            chart_response?.["tag_obj"]?.exp_year === "all"
              ? exp_year_options
              : chart_response?.["tag_obj"]?.exp_year;
          const get_exp_month =
            chart_response?.["tag_obj"]?.exp_month === "all"
              ? exp_month_options
              : chart_response?.["tag_obj"]?.exp_month;
          const get_card_type =
            chart_response?.["tag_obj"]?.bank_card_type === "all"
              ? card_type_options
              : chart_response?.["tag_obj"]?.bank_card_type;
          handle_update_modal_state(
            "card_type",
            get_card_type ?? card_type_options,
          );
          handle_update_modal_state(
            "exp_year",
            get_exp_year ?? exp_year_options,
          );
          handle_update_modal_state(
            "exp_month",
            get_exp_month ?? exp_month_options,
          );
        } else if (graph_type === "email") {
          const get_email =
            chart_response?.["tag_obj"]?.email_type === "all"
              ? email_options
              : chart_response?.["tag_obj"]?.email_type;
          handle_update_modal_state(
            "email_provider",
            get_email ?? email_options,
          );
        } else if (graph_type === "crypto") {
          const get_crypto_type =
            chart_response?.["tag_obj"]?.crypto_type === "all"
              ? crypto_options
              : chart_response?.["tag_obj"]?.crypto_type;
          handle_update_modal_state(
            "crypto_provider",
            get_crypto_type ?? crypto_options,
          );
        }
      } else if (notification_type === "stealer log") {
        const stealer_default_keys = chart_response?.["stealer_obj"];
        const clone_filter = {
          email: stealer_default_keys["email"] ?? "",
          domain: stealer_default_keys["domain"] ?? "",
          password: stealer_default_keys["password"] ?? "",
          country_code: stealer_default_keys["country_code"] ?? "",
          ip_address: stealer_default_keys["ip_address"] ?? "",
          zip: stealer_default_keys["zip"] ?? "",
          location: stealer_default_keys["location"] ?? "",
        };
        const get_stealer_search_type =
          stealer_default_keys["search_type"] ?? "contains";
        const get_stealer_category =
          stealer_default_keys["stealer_category"] ?? "off";
        set_modal_data((prev) => ({
          ...prev,
          stealer_multiple_obj: clone_filter,
        }));
        handle_update_modal_state(
          "stealer_search_type",
          get_stealer_search_type,
        );
        handle_update_modal_state("stealer_filter_type", graph_type);
        handle_update_modal_state("category_stealer", get_stealer_category);
      } else if (notification_type === "channel") {
        const get_channel_list =
          chart_response?.["channel_obj"]?.["channel_name"] ?? [];
        const channel_filter =
          chart_response["dashboard_filter"] === "nested"
            ? "individual"
            : "default";
        handle_update_modal_state("channels_list", get_channel_list);
        handle_update_modal_state("channel_filter_type", channel_filter);
      }
    } catch (err) {
      const session_categories = handle_session_get("enabled_categories");
      let assign_categories = [];
      if (session_categories !== "all") {
        assign_categories = session_categories.split(",");
      } else {
        assign_categories = options_post_category;
      }
      set_post_category_update(assign_categories);
      handle_update_modal_state("card_type", card_type_options);
      handle_update_modal_state("exp_year", exp_year_options);
      handle_update_modal_state("exp_month", exp_month_options);
      handle_update_modal_state("email_provider", email_options);
    }
  };

  const sortedUserData = useMemo(() => {
    const clone_all_user_data = [...user_dashboard_data];
    return clone_all_user_data.sort(
      (a, b) => new Date(b.last_modified) - new Date(a.last_modified),
    );
  }, [user_dashboard_data]);

  const debouncedFetchData = useCallback(
    handle_custom_debounce(val_change, 300),
    [],
  );
  const detail_row_data = (row) => {
    let category_length;
    if (["file", "file_ext", "tag", "keyword"].includes(row.dashboard_type)) {
      if (row.category_access === "all") {
        category_length = "all";
      } else {
        category_length = row.category_access.length;
      }
      const get_search_type = row?.["telegram_obj"]?.["search_type"];
      const get_field_filter = row?.["telegram_obj"]?.["field_filter"]??"None";
      const get_channel_group = row?.["telegram_obj"]?.["search_filter"];
      const tag_type = row["search_mode"] ?? "email";
      return (
        <div>
          <span>{category_length}categories</span>
          {row.dashboard_type !== "tag" && (
            <span>, search type:{get_search_type}</span>
          )}
          {row.dashboard_type !== "tag" && (
            <span>, search filter:{get_channel_group}</span>
          )}
          {row.dashboard_type === "keyword" && (
            <span>
              , field filter:
              {get_field_filter === "None"
                ? "Title/Post"
                : get_field_filter === "conv_name"
                ? "Title"
                : get_field_filter}
            </span>
          )}
          {row.dashboard_type === "tag" && <span>, type:{tag_type}</span>}
        </div>
      );
    } else if (row.dashboard_type === "channel") {
      const channel_filter = handle_detect_nested_channel(row);
      return (
        <div>
          {channel_filter === "nested" ? (
            <span>{(row.channel_obj?.channel_name).length} channels</span>
          ) : (
            <span>channel: {row.value}</span>
          )}
        </div>
      );
    } else {
      const get_stealer_search_type = row?.["stealer_obj"]?.["search_type"];
      const get_stealer_category =
        row?.["stealer_obj"]?.["stealer_category"] ?? "off";
      const stealer_filter_type = row?.["search_mode"];
      return (
        <div>
          <span>search type:{get_stealer_search_type}</span>{" "}
          <span>, type:{stealer_filter_type}</span>{" "}
          <span>, category:{get_stealer_category}</span>
        </div>
      );
    }
  };

  const handle_detect_nested_channel = (row) => {
    if (row.dashboard_type === "channel") {
      if (row.channel_obj?.channel_name) {
        if (
          Array.isArray(row.channel_obj?.channel_name) &&
          (row.channel_obj?.channel_name).length > 0
        )
          return "nested";
      }
    }
    return "default";
  };
  const handle_query_row = (row) => {
    const stealer_category =
      row.dashboard_type === "stealer log"
        ? row?.["stealer_obj"]?.["stealer_category"]
        : null;

    const channel_filter_type = handle_detect_nested_channel(row);

    if (
      row.dashboard_type === "stealer_logs" &&
      row.stealer_category &&
      !row.value
    ) {
      return `stealer category : ${stealer_category}`;
    } else if (
      row.dashboard_type === "channel" &&
      channel_filter_type === "nested"
    ) {
      const channels_list = row.channel_obj?.channel_name.map((item) => item);
      if (row.value) {
        return `Channels : ${channels_list.toString()}}, Keyword : ${
          row.value
        }`;
      } else {
        return `Channels : ${channels_list.toString()}`;
      }
    } else if (Array.isArray(row.value)) {
      return row.value.toString();
    } else {
      return row.value;
    }
  };

  return (
    <div>
      <GlobalStyles
        styles={{
          ".MuiInput-root svg": {
            color: "#1fcecb",
          },
        }}
      />

      <div className="admin_notificaton_container user_dashboard_insertion_container">
        <Formik
          initialValues={{ search: "" }}
          validate={(values) => {
            const errors = {};
            const { search } = values;
            // const regexExp =
            //     /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;
            // For checking valid regular expression
            const isRegExp = (string) => {
              try {
                new RegExp(string);
              } catch (e) {
                return false;
              }
              return true;
            };
            if (
              stealer_logs_auto_notification(
                option_dropdown,
                category_stealer,
                search,
              ) === true
            )
              return;
            if (search.length < 2) {
              errors.search = "Keyword must be longer than 1 character";
            }
            if (
              notification_filters !== "logical" &&
              option_dropdown !== "channel"
            ) {
              const reg = /\s{2,}/g;
              const verifier_conv = search.replaceAll(reg, " ");
              const verifier = /\s(\b(and|or|AND|OR)\b)\s/g.exec(verifier_conv);
              if (verifier !== null && verifier.length > 0) {
                errors.search =
                  "AND/OR operator are only allowed on logical feature";
              }
            }
            if (
              option_dropdown === "file_extension" &&
              notification_filters === "Default"
            ) {
              if (search.startsWith(".") === false) {
                errors.search =
                  "File extension should start with (.) character";
              }
              if (search.length > 8) {
                errors.search = "File extension should not exceed 8 characters";
              }
            }

            if (
              (option_dropdown === "keyword" ||
                option_dropdown === "file" ||
                option_dropdown === "file_extension") &&
              notification_filters === "regex"
            ) {
              if (isRegExp(search) === false) {
                errors.search = "Please enter valid Regular expression";
              }
            }

            // validation for the tag alert
            if (option_dropdown === "tag") {
              if (notification_filters_tag === "bank_card") {
                const error_msg = bank_card_validator(values.search);
                if (error_msg) {
                  errors.search = error_msg;
                }
              } else if (notification_filters_tag === "ip_address") {
                const error_msg = ip_address_validator(values.search);
                if (error_msg) {
                  errors.search = error_msg;
                }
              } else if (notification_filters_tag === "phone") {
                const error_msg = phone_validator(values.search);
                if (error_msg) {
                  errors.search = error_msg;
                }
              } else if (notification_filters_tag === "crypto") {
                const error_msg = crypto_validator(values.search);
                if (error_msg) {
                  errors.search = error_msg;
                }
              } else if (notification_filters_tag === "email") {
                const error_msg = email_validator(values.search);
                if (error_msg) {
                  errors.search = error_msg;
                }
              } else if (notification_filters_tag === "bin") {
                const error_msg = bin_validator(values.search);
                if (error_msg) {
                  errors.search = error_msg;
                }
              }
            }

            return errors;
          }}
          onSubmit={(values, { resetForm }) => {
            handle_dashboard_insertion(values.search);
            setTimeout(() => resetForm());
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            errors,
            touched,
          }) => (
            <div style={{position:"relative"}}>
            <form onSubmit={handleSubmit} autoComplete="off">
              <Stack
                direction="row"
                alignItems="flex-end"
                sx={{
                  width: "75%",
                  margin: "auto",
                  columnGap: 1,
                  position: "relative",
                }}
              >
                <div
                  className="dropdown_notification"
                  style={{ border: "none" }}
                >
                  <p className="alert_pref_select_label">Filters</p>
                  <FormControl
                    sx={{
                      minWidth: 180,
                      border: "1px solid #e7e4e463",
                      borderRadius: "50px",
                    }}
                  >
                    <Select
                      labelId="demo-select-small"
                      id="demo-select-small"
                      value={option_dropdown}
                      onChange={handle_change_option}
                      sx={{ width: "100%", mt: 1 }}
                      disableUnderline
                      variant="standard"
                    >
                      <MenuItem value="keyword">Keyword</MenuItem>
                      <MenuItem value="channel">Channel</MenuItem>
                      <MenuItem value="file">File</MenuItem>
                      <MenuItem value="file_extension">File ext</MenuItem>
                      <MenuItem value="tag">Tag</MenuItem>
                      <MenuItem value="stealer log">Stealer Logs</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div
                  className=" rounded input-group rounded-pill  shadow-sm notification_search_box notification_input"
                  style={{
                    padding: "0.1rem 0.4rem",
                    width: "100%",
                    alignSelf: "flex-end",
                  }}
                >
                  <input
                    disabled={
                      option_dropdown === "channel" &&
                      channel_filter_type === "individual"
                    }
                    id="srch_tab"
                    type="search"
                    placeholder={
                      notification_filters === "logical"
                        ? "e.g: test1 and test2 or (test3 and test4)"
                        : option_dropdown === "channel" &&
                          channel_filter_type === "individual"
                        ? "Please select channels and enter keyword on filter box"
                        : stealer_logs_auto_notification(
                            option_dropdown,
                            category_stealer,
                            values.search,
                          ) === false
                        ? "Please type your Query."
                        : "Please type your Query or press enter for all dashboard."
                    }
                    name="search"
                    value={values.search}
                    onChange={(e) => {
                      handleChange(e);
                      option_dropdown === "channel" && debouncedFetchData(e);
                    }}
                    onClick={() => {
                      if (
                        option_dropdown === "channel" &&
                        channel_filter_type === "individual"
                      ) {
                        set_show_filters(true);
                      }
                    }}
                    onBlur={handleBlur}
                    className="form-control border-0 new-dk"
                    style={{
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                    }}
                  />

                  {(option_dropdown === "keyword" ||
                    option_dropdown === "file" ||
                    option_dropdown === "file_extension") &&
                  notification_filters === "logical" ? (
                    <span data-toggle="modal" data-target="#help_logical">
                      <Tooltip title="Logical Input Guidance">
                        <HelpIcon
                          sx={{
                            color: "white",
                            mt: 0.7,
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    </span>
                  ) : null}

                  <button
                    id="button-addon1"
                    type="submit"
                    style={{
                      outline: "none",
                      outlineWidth: 0,
                      boxShadow: "none",
                    }}
                    className="btn btn-link"
                    disabled={
                      stealer_logs_auto_notification(
                        option_dropdown,
                        category_stealer,
                        values.search,
                      ) === false
                        ? !(values.search.length > 1)
                        : false
                    }
                  >
                    <FontAwesomeIcon
                      style={{ color: "#1FCECB" }}
                      icon={faPlus}
                      size="1x"
                    />
                  </button>
                </div>
                <button
                  className="searchbar-btn searchbar-btn-main"
                  onClick={() => set_show_filters(!show_filters)}
                  type="button"
                >
                  {" "}
                  <Tooltip title="Filters">
                    <GridViewIcon
                      sx={{ color: "#dcf3ff", marginBottom: "10px" }}
                    />
                  </Tooltip>{" "}
                </button>     
              </Stack>
              {show_filters === false && errors.search && touched.search ? (
                <div className="user_search_validation">{errors.search}</div>
              ) : null}

              {search_val.length < 2 &&
              show_filters === false &&
              !errors.search &&
              !touched.search &&
              option_dropdown === "tag" ? (
                <span style={{ color: "#dcf3ff", fontSize: "14px" }}>
                  {" "}
                  <IconContext.Provider value={{ ize: 18, color: "#f15e5e" }}>
                    <FaInfoCircle style={{ marginRight: "6px" }} />
                  </IconContext.Provider>
                  {` ${
                    option_dropdown === "tag"
                      ? "For regex, enclose query within two forward slashes / /."
                      : ""
                  }`}
                </span>
              ) : null}

              <div style={{ margin: "auto" }}>
                {update_loader === true ? <CircularProgress /> : null}
              </div>
            </form>
            {search_val.length >= 2 &&
                option_dropdown === "channel" &&
                show_filters === false ? (
                  <div
                    className="autocom-box channel_list_container m-auto"
                    style={{ width: "52%", top: "100%",left:"25%" }}
                  >
                    {stat_loading ? (
                      <RollerAnimation />
                    ) : search_error ? (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "larger",
                          color: "white",
                          marginTop: "10%",
                        }}
                      >
                        {search_error}
                      </div>
                    ) : search_data !== null && search_data.length === 0 ? (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "larger",
                          color: "white",
                          marginTop: "10%",
                        }}
                      >
                        No Data to display
                      </div>
                    ) : (
                      <>
                        <h4
                          style={{
                            paddingLeft: "1rem",
                            marginLeft: "15px",
                            marginTop: "5px",
                            fontSize: "18px",
                            color: "whitesmoke",
                          }}
                        >
                          List Of Channels
                        </h4>
                        <ul>
                          {search_data !== null &&
                            search_data.map((val, id) => (
                              <li key={id}>
                                <div className="row">
                                  <div
                                    className="col-md-8"
                                    style={{ textAlign: "left" }}
                                  >
                                    {" "}
                                    <img
                                      style={{
                                        width: "36px",
                                        height: "36px",
                                        padding: "2px",
                                        border: "2px solid #f4f4f4",
                                      }}
                                      className="img-fluid rounded-circle"
                                      src={
                                        val.channel_id !== "None"
                                          ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                          : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                                      }
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src =
                                          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                                      }}
                                      alt="User"
                                    />
                                    <span
                                      className="channels_list_map"
                                      style={{
                                        marginLeft: "10px",
                                        color: "#dcf3ff",
                                      }}
                                    >
                                      {val.channel_name}
                                    </span>
                                  </div>
                                  <div
                                    className="col-md-4"
                                    style={{
                                      textAlign: "right",
                                      marginTop: "5px",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      style={{
                                        borderRadius: "40px",
                                        background: "#d03940",
                                        color: "white",
                                        lineHeight: "1",
                                      }}
                                      onClick={() => {
                                        handle_dashboard_insertion(
                                          val.channel_name,
                                        );
                                      }}
                                      className="btn btn-round mr-md-2 mb-md-0 mb-1"
                                    >
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                  </div>
                ) : null}
                {show_filters ? (
                  <div className="notification_filters">
                    <UserDashboardInsertionFilters
                      set_open_modal={set_show_filters}
                      stealer_multiple_obj={stealer_multiple_obj}
                      set_stealer_multiple_obj={set_stealer_multiple_obj}
                      category_stealer={category_stealer}
                      set_category_stealer={set_category_stealer}
                      stealer_search_type={stealer_search_type}
                      set_stealer_search_type={set_stealer_search_type}
                      post_search_sub={post_search_sub}
                      set_search_sub={set_search_sub}
                      postCategory={postCategory}
                      setPostCategory={setPostCategory}
                      keyword={keyword}
                      setKeyword={setKeyword}
                      card_type={card_type}
                      set_card_type={set_card_type}
                      crypto_provider={crypto_provider}
                      set_crypto_provider={set_crypto_provider}
                      email_provider={email_provider}
                      set_email_provider={set_email_provider}
                      exp_year={exp_year}
                      set_exp_year={set_exp_year}
                      exp_month={exp_month}
                      set_exp_month={set_exp_month}
                      notification_filters={notification_filters}
                      set_notification_filters={set_notification_filters}
                      spam_filter={spam_filter}
                      set_spam_filter={set_spam_filter}
                      edit_chart_query={edit_chart_query}
                      set_edit_chart_query={set_edit_chart_query}
                      field_filter={field_filter}
                      set_field_filter={set_field_filter}
                      option_dropdown={option_dropdown}
                      notification_filters_tag={notification_filters_tag}
                      set_notification_filters_tag={
                        set_notification_filters_tag
                      }
                      stealer_filter_type={stealer_filter_type}
                      set_stealer_filter_type={set_stealer_filter_type}
                      channels_list={channels_list}
                      set_channels_list={set_channels_list}
                      handle_dashboard_insertion={handle_dashboard_insertion}
                      channel_filter_type={channel_filter_type}
                      set_channel_filter_type={set_channel_filter_type}
                      chart_pref={chart_pref}
                      set_chart_pref={set_chart_pref}
                    />
                  </div>
                ) : null}
            </div>
          )}
        </Formik>
        {user_dashboard_status.loading === true ? (
          <RollerAnimation />
        ) : user_dashboard_status.error ? (
          <div className="main__table no_data">
            {user_dashboard_status.error}
          </div>
        ) : (
          user_dashboard_data.length > 0 && (
            <div
              className="alert_pref_table_container credentail_insertion_table_container scroll-custom"
              style={{ height: "63vh", overflow: "auto", top: "20%" }}
            >
              <table>
                <thead>
                  <tr>
                    <th style={{ width: "17%" }}>Query</th>
                    <th style={{ width: "10%" }}>Dashboard type</th>
                    <th style={{ width: "8%" }}>Dashboard filter</th>
                    <th style={{ width: "5%" }}>Preference</th>
                    <th style={{ width: "40%" }}>Details</th>
                    <th style={{ width: "5%" }}>Edit</th>
                    <th style={{ width: "8%" }}>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedUserData.map((val, index) => {
                    return (
                      <tr key={index}>
                        <td>{handle_query_row(val)}</td>
                        <td>{val.dashboard_type}</td>
                        <td>{val.dashboard_filter}</td>
                        <td>{val?.["dashboard_preference"] ?? "user"}</td>
                        <td>{detail_row_data(val)}</td>
                        <td>
                          <button
                            className="credential_btn credential_btn_edit"
                            data-toggle="modal"
                            data-target="#updatefilters"
                            onClick={() => {
                              handle_set_default_options(
                                val,
                                val.dashboard_type,
                              );
                              set_show_update_filter(true);
                            }}
                          >
                            Edit
                          </button>
                        </td>
                        <td>
                          <button
                            onClick={() =>
                              handle_delete_user_dashboard_data(val.id)
                            }
                            className="credential_btn credential_btn_delete"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )
        )}
      </div>
      {show_update_filter ? (
        <UserDashboardInsertionFiltersUpdate
          modal_data={modal_data}
          set_modal_data={set_modal_data}
          set_open_modal={set_show_update_filter}
          set_update_loader={set_update_loader}
          post_category_update={post_category_update}
          set_post_category_update={set_post_category_update}
          set_user_dashboard_data={set_user_dashboard_data}
        />
      ) : null}
    </div>
  );
}

export default UserDashboardInsertion;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { IconContext } from "react-icons";
import { Link, Redirect } from "react-router-dom";
import {
  FaHome,
  FaChartLine,
  FaQuidditch,
  FaUserTie,
  FaRegAddressBook,
  FaIndustry,
  FaBell,
} from "react-icons/fa";
import { RiUserSearchLine } from "react-icons/ri";
import "../../../styles2.css";
import TelegramIcon from "@mui/icons-material/Telegram";
import { connect } from "react-redux";
import { token_checker } from "../../../actions/adminAction";
import { useDispatch } from "react-redux";
import { handle_store_url_location } from "../../../actions/filterAction";
import "react-toastify/dist/ReactToastify.css";
import "../../../css/Navbar/navbar.css";

/*
NOTE :
This component includes the navlink on right side.
It includes the navigation like Home, Analysis, scraper, New user, add regex,
Telegram search and is only available to admin. 
*/
function Sidenav({ admin_reducer, token_checker }) {
  useEffect(() => {
    token_checker();
  }, []);
  const dispatch = useDispatch();

  if (admin_reducer.redirect) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <section
      style={{ display: "inline-block", float: "left" }}
      className="dk-mode"
    >
      <nav id="sidebar" className="active sidebar_v2">
        <div className="sidebar-header">
          <h3 />
          <strong />
        </div>
        <ul className="list-unstyled components">
          <li className="active">
            <Link to="/admin">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaHome />
                  </IconContext.Provider>
                </div>
                Home
              </a>
            </Link>
          </li>

          <li className="active">
            <Link to="/admin/dashboard">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaChartLine />
                  </IconContext.Provider>
                </div>
                Analysis
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/scraper">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaQuidditch />
                  </IconContext.Provider>
                </div>
                Scraper
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/cred">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaUserTie />
                  </IconContext.Provider>
                </div>
                New User
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/user-search">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <RiUserSearchLine />
                  </IconContext.Provider>
                </div>
                User search
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/add-regex">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaRegAddressBook />
                  </IconContext.Provider>
                </div>
                Add regex
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/add-industry">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaIndustry />
                  </IconContext.Provider>
                </div>
                Add Industry
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/admin/add-alert">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaBell />
                  </IconContext.Provider>
                </div>
                Add alert
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/company-dashboard">
              <a href="#!">
                <div
                  style={{
                    marginRight: "4px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <TelegramIcon
                    fontSize="large"
                    sx={{
                      color: "#359daf",
                    }}
                  />
                </div>
              </a>
            </Link>
          </li>
        </ul>
      </nav>
    </section>
  );
}
Sidenav.propTypes = {
  admin_reducer: PropTypes.object,
  token_checker: PropTypes.func,
};

const mapStateToProps = (state) => ({
  users: state.users,
  admin_reducer: state.admin_reducer,
});
export default connect(mapStateToProps, { token_checker })(Sidenav);

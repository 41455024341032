import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handle_clear_localStorage } from "../../SessionStorage/handle_session";
export const handle_token_expire = (error, status_code) => {
  try {
    const errors_key = Object.keys(error);
    const token_error_status = errors_key.includes("tokenerror");
    if (token_error_status === true || (status_code && status_code === 408)) {
      toast.warn("Your session has expired. Redirecting you to Login page.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      setTimeout(() => {
        sessionStorage.clear();
        handle_clear_localStorage();
        window.location.href = "/";
      }, 2500);
      return true;
    }
    return false;
  } catch {
    return true;
  }
};

import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Grid } from "@mui/material";
import Sidenav from "../Navbar/Sidenav";
import Customernotification from "../../../components/Customernotification";
import UserMainNav from "../Navbar/UserMainNav";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { useDispatch } from "react-redux";
import { handle_store_url_location } from "../../../actions/filterAction";

function UserHomeV2() {
  // for menu icon ternary operation
  const [show_sidenav, set_show_sidenav] = useState(false);
  const dispatch = useDispatch();

  const url = window.location.pathname;
  const user_name_session = handle_session_get("admin_status");
  if (handle_session_get("Token") == null) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <div>
      <Grid container>
        {user_name_session === "super_admin" ||
        user_name_session === "admin" ? (
          <Grid
            item
            xs={show_sidenav === true ? 0.5 : 0}
            sm={show_sidenav === true ? 0.5 : 0}
          >
            {show_sidenav ? <Sidenav /> : null}
          </Grid>
        ) : null}
        <Grid
          item
          xs={show_sidenav === true ? 11.5 : 12}
          sm={show_sidenav === true ? 11.5 : 12}
        >
          <UserMainNav
            show_sidenav={show_sidenav}
            set_show_sidenav={set_show_sidenav}
          />
          {url.includes("notification") === true ? (
            <Customernotification />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

export default UserHomeV2;

import PropTypes from "prop-types";
import React, { useState } from "react";
import { IconContext } from "react-icons";
import Editor from "@monaco-editor/react";
import { FaCopy } from "react-icons/fa";
import "../../../css/Display_json/displayjson.css";

function MultipleAcctJson({ Data }) {
  const [copied_clipboard, set_copied_clipboard] = useState(false);
  const array_to_text = (arr) => {
    let text = "";
    for (const data of arr) {
      text += `\nusername : ${data.username}\npassword : ${data.password}\nemail : ${data.email}\n`;
    }
    return text;
  };

  return (
    <div className="row justify-content-center main__table__json__multiple_accounts">
      {Data !== null ? (
        <div className="col-lg-10 ">
          <div />
          <p
            className="m-b-10 f-w-600"
            style={{
              position: "absolute",
              marginLeft: "37%",
              marginTop: "2%",
              zIndex: 995,
              display: "inline",
              flexShrink: "unset",
              cursor: "pointer",
            }}
            onClick={() => {
              navigator.clipboard.writeText(array_to_text(Data));
              set_copied_clipboard(true);
            }}
            onMouseEnter={() => {
              const popup = document.getElementById("copy_id");
              popup.style.left = "-191%";
              popup.style.bottom = "134%";
              popup.style.visibility = "visible";
            }}
            onMouseLeave={() => {
              const popup = document.getElementById("copy_id");

              popup.style.visibility = "hidden";
            }}
          >
            <IconContext.Provider
              value={
                copied_clipboard
                  ? { size: 24, color: "#1FCECB" }
                  : { size: 24, color: "#8d8d8d" }
              }
            >
              <FaCopy />
            </IconContext.Provider>
            <span className="popuptext" id="copy_id">
              {copied_clipboard ? "Copied" : "Copy"}{" "}
            </span>
          </p>
          <Editor
            height="100vh"
            defaultLanguage="json"
            defaultValue={array_to_text(Data)}
            value={array_to_text(Data)}
            options={{
              readOnly: true,
              selectionClipboard: true,
              emptySelectionClipboard: true,
            }}
            theme="vs-dark"
            language="json"
            selectionClipboard={true}
          />
        </div>
      ) : null}
    </div>
  );
}

MultipleAcctJson.propTypes = {
  Data: PropTypes.array,
};

export default MultipleAcctJson;

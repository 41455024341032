import React from "react";
import PropTypes from "prop-types";
import { Stack, GlobalStyles } from "@mui/material";
import { Link, Redirect } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  handle_session_get,
  handle_clear_localStorage,
} from "../../../utils/SessionStorage/handle_session";
import { useDispatch } from "react-redux";
import {
  handle_store_url_location,
  handle_open_channel_scrape_modal,
} from "../../../actions/filterAction";
import "../../../css/Navbar/navbar.css";

/*
NOTE :
->This component handles the navigation for the searches
Telegram, Breach, Forum, User,Tag and Stearler logs and is built 
for the company Dashboard page.
-> Also It includes the user name, User profile and logout
*/
function CompanyStatsNavbar({ show_sidenav, set_show_sidenav }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();

  const user_name_session = handle_session_get("admin_status");
  const customer_name = handle_session_get("User");

  if (handle_session_get("Token") == null) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <>
      <GlobalStyles
        styles={{
          ".MuiDataGrid-menuList": {
            backgroundColor: "#394e5a",
          },
          ".MuiMenu-list ": {
            backgroundColor: "#394e5a",
          },
          ".MuiDataGrid-menuList li": {
            color: "#dcf3ff",
          },
          ".MuiMenu-list li": {
            color: "#dcf3ff",
          },
          ".MuiPaginationItem-root": {
            color: "#9bbcd1",
            backgroundColor: "#1e2a31",
          },
        }}
      />
      <div className="navbar__main">
        <Stack alignItems="center" direction="row" sx={{ pl: 2 }}>
          {user_name_session === "super_admin" ||
          user_name_session === "admin" ? (
            <MenuIcon
              sx={{ color: "white", cursor: "pointer", mr: 2 }}
              onClick={() => set_show_sidenav(!show_sidenav)}
            />
          ) : null}
          <Link to="/dashboard">
            <img
              width="0"
              height="0"
              // eslint-disable-next-line no-undef
              src={`${process.env.PUBLIC_URL}/static/images/logo.png`}
              alt="logo"
            />
          </Link>
          <Link to="/dashboard">
            {" "}
            <h3 className="navbar_main_title" />
          </Link>
        </Stack>
        <Stack direction="row" columnGap={1} alignItems="center">
          <Link
            to={{
              pathname: "/dashboard",
              state: { selectedTab: "telegram" },
            }}
          >
            {" "}
            <p className="navbar_telegram_search_link">Telegram</p>
          </Link>
          <Link
            to={{ pathname: "/dashboard", state: { selectedTab: "breach" } }}
          >
            {" "}
            <p className="navbar_telegram_search_link">Breach</p>
          </Link>
          <Link
            to={{ pathname: "/dashboard", state: { selectedTab: "forum" } }}
          >
            {" "}
            <p className="navbar_telegram_search_link">Forum</p>
          </Link>
          <Link
            to={{
              pathname: "/dashboard",
              state: { selectedTab: "user_search" },
            }}
          >
            {" "}
            <p className="navbar_telegram_search_link">User Search</p>
          </Link>
          <Link
            to={{
              pathname: "/dashboard",
              state: { selectedTab: "card_search" },
            }}
          >
            {" "}
            <p className="navbar_telegram_search_link">Tag Search</p>
          </Link>
          <Link
            to={{
              pathname: "/dashboard",
              state: { selectedTab: "stealer_logs_search" },
            }}
          >
            {" "}
            <p className="navbar_telegram_search_link">Stealer Logs</p>
          </Link>
          <p className="navbar_telegram_search_link_dashboard_txt">Dashboard</p>
        </Stack>
        <Stack direction="row" alignItems="baseline">
          <p onClick={handleClick} className="user_name_navbar">
            {customer_name}
          </p>
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <AccountCircleIcon sx={{ color: "white", fontSize: "30px" }} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Link to="/userprof" style={{ width: "0px" }}>
                <MenuItem onClick={handleClose}>Profile</MenuItem>
              </Link>
              <MenuItem
                onClick={() => {
                  dispatch(handle_open_channel_scrape_modal(true));
                  handleClose();
                }}
              >
                Add to scrapping
              </MenuItem>
              <MenuItem
                onClick={() => {
                  sessionStorage.clear();
                  handle_clear_localStorage();
                  window.location.href = "/";
                  sessionStorage.clear();
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </div>
        </Stack>
      </div>
    </>
  );
}

CompanyStatsNavbar.propTypes = {
  show_sidenav: PropTypes.bool,
  set_show_sidenav: PropTypes.func,
};

export default CompanyStatsNavbar;

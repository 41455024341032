import React, { useState } from "react";
import PropTypes from "prop-types";
import { date_fm } from "../../../Assets/globalfunctions";
import ChannelSeeMoreInfo from "../sections/Card/ChannelSeeMoreInfo";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
function Expandabletable({ table_data }) {
  const [expandedRows, setExpandedRows] = useState([]);
  const handleExpand = (file, index) => {
    let unique_id = "";
    try {
      unique_id = file.date + index;
    } catch {
      unique_id = file.date;
    }
    let newExpandedRows = [...expandedRows];
    let idxFound = newExpandedRows.findIndex((id) => id === unique_id);

    if (idxFound > -1) {
      newExpandedRows.splice(idxFound, 1);
    } else {
      newExpandedRows.push(unique_id);
    }

    setExpandedRows([...newExpandedRows]);
  };
  const isExpanded = (file, index) => {
    let unique_id = "";
    try {
      unique_id = file.date + index;
    } catch {
      unique_id = file.date;
    }
    return expandedRows.includes(unique_id);
  };
  const file_link_adder = (links, msgid) => {
    if (links !== null && links !== "None") {
      if (links.includes("t.me")) {
        const new_link = links.replace("s/", "");
        const open_link = `${new_link}/${msgid}`;

        window.open(
          open_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      } else {
        const new_link = `https://t.me/${links}/${msgid}`;
        window.open(
          new_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      }
    }
  };
  return (
    <div className="alert_chart_file_box scroll-custom">
      <table className="alert_chart_file_table">
        <thead>
          <tr>
            <th style={{ width: "35%" }}>File Name</th>
            <th style={{ width: "40%" }}>Channel Name</th>
            <th style={{ width: "20%" }}>Date</th>
            <th style={{ width: "5%" }}></th>
          </tr>
        </thead>
        <tbody>
          {table_data.map((item, index) => {
            return (
              <>
                <tr key={index}>
                  <td>
                    <button
                      className="alert_chart_filename_column"
                      disabled={!(item.link !== null && item.link !== "None")}
                      onClick={() => file_link_adder(item.link, item.msgid)}
                    >
                      {item.filename}
                    </button>
                  </td>
                  <td>{item.conv_name}</td>
                  <td>{date_fm(item.date)}</td>
                  <td>
                    <button
                      className="alert_chart_file_expand"
                      onClick={() => handleExpand(item, index)}
                    >
                      {isExpanded(item, index) ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </button>
                  </td>
                </tr>
                {isExpanded(item, index) ? (
                  <tr style={{ background: "rgba(60,210,165,.25)" }}>
                    <td colSpan={4}>
                      <ChannelSeeMoreInfo item={item} box_type="chart_box" />
                    </td>
                  </tr>
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
Expandabletable.propTypes = {
  table_data: PropTypes.array,
};

export default Expandabletable;

import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Stack } from "@mui/material";
import Grid from "@mui/material/Grid";
import ShowMoreText from "react-show-more-text";
import Avatar from "@mui/material/Avatar";
import "../../../css/Modal/modal.css";

function ModalForum({ openModal, setOpenModal, selectedRow }) {
  // function for the Show More text
  function executeOnClick() {}

  // link the file
  const file_link_adder = (links) => {
    if (links !== null && links !== "None") {
      window.open(
        links,
        "_blank",
        "location=yes,height=6000,width=6000,scrollbars=yes,status=yes",
      );
    }
  };

  return (
    <div>
      {selectedRow !== null ? (
        <Modal
          open={openModal}
          onClose={() => setOpenModal(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="container__modal">
            <Box className="container_modal_child">
              <div className="modal-heading">
                <div className="modal-heading-parent-closeBtn">
                  <button
                    className="modal-heading-closeBtn"
                    onClick={() => setOpenModal(false)}
                  >
                    <CancelIcon sx={{ color: "#ddd ", cursor: "pointer" }} />
                  </button>
                </div>
              </div>

              <div id="modal-modal-description" className="modal-description">
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <div className="modal-desc-group">
                      <Stack alignItems="center" direction="row" spacing={2}>
                        <Avatar
                          alt="Remy Sharp"
                          sx={{ width: 56, height: 56 }}
                          src={
                            selectedRow.image_link !== "None"
                              ? selectedRow.image_link
                              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                          }
                        />
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          {selectedRow.post_title}
                        </Typography>
                      </Stack>
                    </div>
                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">
                        Link
                      </Typography>
                      <Typography className="modal-desc-content">
                        {/* {selectedRow.urllink} */}
                        {selectedRow.urllink !== "None" ? (
                          <button
                            className="link_post"
                            onClick={() => file_link_adder(selectedRow.urllink)}
                          >
                            {selectedRow.urllink}
                          </button>
                        ) : (
                          selectedRow.urllink
                        )}
                      </Typography>
                    </div>

                    <div className="modal-desc-group">
                      <Typography className="modal-desc-heading">
                        Post
                      </Typography>
                      <ShowMoreText
                        /* Default options */
                        lines={10}
                        more="Show more"
                        less="Show less"
                        className="content-css"
                        anchorClass="my-anchor-css-class"
                        onClick={executeOnClick}
                        expanded={false}
                      >
                        <Typography className="modal-desc-content modal-desc-content-post">
                          {selectedRow.snippet}
                        </Typography>
                      </ShowMoreText>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Box>
        </Modal>
      ) : null}
    </div>
  );
}
ModalForum.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  selectedRow: PropTypes.object,
};

export default ModalForum;

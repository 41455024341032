import React, { memo, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import "d3-transition";
import WordCloud from "react-d3-cloud";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import "../../../../css/Sections/wordcloud/wordcloud.css";
import { text_color_array_wordcloud } from "../../../../utils/Globals/global_variables";
const schemeCategory10ScaleOrdinal = scaleOrdinal(schemeCategory10);

function WordCloudd({ word_cloud }) {
  const tooltipRef = useRef(null);
  const mouseover = (event, d) => {
    const tooltipDiv = tooltipRef.current;
    if (tooltipDiv) {
      tooltipRef.current.style.display = "block";
      document.getElementById(
        "wordcloud_tooltip_box_word",
      ).textContent = `Word : ${d.text}`;
      document.getElementById(
        "wordcloud_tooltip_box_value",
      ).textContent = `Times : ${d.value}`;
    }
  };
  const mouseout = () => {
    const tooltipDiv = tooltipRef.current;
    if (tooltipDiv) {
      tooltipRef.current.style.display = "none";
    }
  };
  const highest_value =
    word_cloud.words.length > 0
      ? (Math.floor(word_cloud.words[0].value / 1000) * 1000 < 1000
          ? 500
          : Math.floor(word_cloud.words[0].value / 1000) * 1000) ?? 2000
      : 2000;
  const calculateFontSize = useCallback((word) => {
    const frequencyFactor = word.value / highest_value;
    const baseFontSize = Math.log2(word.value) * 6 * frequencyFactor;
    const minFontSize = 20;
    const maxFontSize = 70;
    const clampedFontSize = Math.max(
      minFontSize,
      Math.min(maxFontSize, baseFontSize),
    );
    return clampedFontSize;
  }, []);
  return (
    <>
      <p className="wordcloud_container_note">
        The size of each word indicates its frequency.{" "}
      </p>
      <div
        style={{
          height: 450,
          width: "100%",
          margin: "auto",
          position: "relative",
          paddingTop: "2rem",
        }}
      >
        <div ref={tooltipRef} className="wordcloud_tooltip_box">
          <p
            id="wordcloud_tooltip_box_word"
            className="wordcloud_tooltip_box_word"
          ></p>
          <p
            id="wordcloud_tooltip_box_value"
            className="wordcloud_tooltip_box_value"
          ></p>
        </div>
        <WordCloud
          width={1000}
          height={450}
          data={word_cloud.words}
          font="Georgia"
          fontSize={calculateFontSize}
          rotate={1}
          padding={4}
          spiral="rectangular"
          fill={(d, i) => {
            if (text_color_array_wordcloud[i]) {
              return text_color_array_wordcloud[i];
            } else {
              return schemeCategory10ScaleOrdinal(i);
            }
          }}
          onWordMouseOver={mouseover}
          onWordMouseOut={mouseout}
        />
      </div>
    </>
  );
}
WordCloudd.propTypes = {
  word_cloud: PropTypes.object,
};

export default memo(WordCloudd);

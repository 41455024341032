import React, { useState, useContext, memo } from "react";
import { Stack } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { DotsLoading } from "../loader/Loader";
import { chart_filter_context } from "./CompanyStatsFunc";
import StealerLogsTable from "../stealerlogs/StealerLogsTable";
import { ThreeCircles } from "react-loader-spinner";
import { handle_add_query_to_dashboard } from "./companyglobalfunc";
import { dashbaord_search_context } from "./UniversalTableDashboard";
import DashboardTableFilters from "../modalContainer/DashboardTableFilters";
function DashboardStealerLogsTable() {
  const {
    dashboard_chart_list_array,
    set_dashboard_chart_list_array,
    get_individual_chart_alert_stats,
  } = useContext(chart_filter_context);

  const {
    stealerlogs_data,
    getSearchedData,
    table_type,
    set_table_type,
    add_query_to_dashboard_loading,
    set_add_query_to_dashboard_loading,
    filter_enabled,
  } = useContext(dashbaord_search_context);
  const [open_dash_table_filters, set_open_dash_table_filters] =
    useState(false);
  const [stealer_multiple_obj, set_stealer_multiple_obj] = useState({});
  const [category_stealer, set_category_stealer] = useState("off");
  const [template_filter_stealer, set_template_stealer_filter] = useState({
    stealer_multiple_obj,
  });
  const handle_dashboard_access_version_2 = (new_chart_list) => {
    const list_of_previous_chart_id = [...dashboard_chart_list_array].map(
      (item) => item.id,
    );
    const dashboard_chart_list = [...new_chart_list];
    const filter_new_id = dashboard_chart_list.filter(
      (item) => list_of_previous_chart_id.includes(item.id) === false,
    );
    if (Array.isArray(filter_new_id) && filter_new_id.length > 0) {
      const clone_obj = {...filter_new_id[0]}
      const new_obj = {
        dashboard_filter :clone_obj["dashboard_filter"],
        dashboard_type:clone_obj["dashboard_type"],
        id:clone_obj["id"],
        keyword:clone_obj["value"]
      }
      get_individual_chart_alert_stats(new_obj);
      setTimeout(() => set_dashboard_chart_list_array(dashboard_chart_list));
    }
  };
  return (
    <div>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ width: "97%" }}
      >
        <h4 className="dashboard_search_table_heading">Stealer logs search</h4>
        <Stack direction={"row"} columnGap={2} alignItems="baseline">
          <button
            className="dashbaord_search_chart_filter_btn"
            onClick={() => {
              set_open_dash_table_filters(true);
              set_table_type("stealer_logs");
            }}
          >
            <FilterListIcon
              sx={{ color: "rgb(144, 202, 249)" }}
              fontSize="large"
            />
          </button>
          {add_query_to_dashboard_loading.stealer_logs_loading ? (
            <DotsLoading />
          ) : (
            <button
              className="dashbaord_search_chart_add_btn"
              onClick={() =>
                handle_add_query_to_dashboard(
                  getSearchedData,
                  "stealer_logs",
                  set_add_query_to_dashboard_loading,
                  handle_dashboard_access_version_2,
                )
              }
            >
              Add to dashboard
            </button>
          )}
        </Stack>
      </Stack>
      <div style={{ position: "relative" }}>
        <div className="dashbaord_table_telegram_search scroll-custom">
          {stealerlogs_data.loading === true ? (
            <div className="spinner">
              <ThreeCircles
                color="#28cab3"
                height={60}
                width={60}
                ariaLabel="three-circles-rotating"
              />
            </div>
          ) : stealerlogs_data.error ? (
            <div className="main__table no_data">{stealerlogs_data.error}</div>
          ) : stealerlogs_data.data.length === 0 ? (
            <div className="main__table no_data">No data to display</div>
          ) : (
            <StealerLogsTable
              getSearchedData={getSearchedData}
              stealer_search_data={stealerlogs_data.data}
              template_stealer_filters={"email"}
              rate_limit_stealer={stealerlogs_data.rate_limit || 100}
              total_db_data_stealer={stealerlogs_data.total_data || 100}
              loadmore_loading={false}
              template_search_type_stealer={"contains"}
              get_stealer_data={() => {}}
              template_date={{ start: "None", end: "None" }}
              template_stealer_filter_type={
                filter_enabled["stealer_logs"] ? "individual" : "default"
              }
              stealer_multiple_obj={
                filter_enabled["stealer_logs"]
                  ? template_filter_stealer["stealer_multiple_obj"]
                  : {}
              }
              template_date_log={false}
              template_stealer_auto_fields={true}
              template_selected_countries={[]}
              table_mode="dashboard_search_table"
            />
          )}
        </div>
        {table_type === "stealer_logs" && open_dash_table_filters ? (
          <DashboardTableFilters
            set_open_modal={set_open_dash_table_filters}
            graph_type={"Default"}
            stealer_multiple_obj={stealer_multiple_obj}
            set_stealer_multiple_obj={set_stealer_multiple_obj}
            category_stealer={category_stealer}
            set_category_stealer={set_category_stealer}
            set_template_stealer_filter={set_template_stealer_filter}
          />
        ) : null}
      </div>
    </div>
  );
}

export default memo(DashboardStealerLogsTable);

import React, { useContext, useEffect } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Box from "@mui/material/Box";
import Datepicker from "../sections/DatePicker/Datepicker";
import Categories from "../sections/Checkbox/Categories";
import "../../../css/Filters/filters.css";
import { filter_context } from "../searchbar/Searchbar";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { options_post_category } from "../../../Assets/globalfunctions";
import { handle_warning_message } from "../../../utils/ResponseMessage/response_message";

function FiltersCard() {
  const {
    setShowFilters,
    post_category_tag,
    set_post_category_tag,
    card_filters,
    exp_year,
    set_exp_year,
    exp_year_options,
    exp_month,
    set_exp_month,
    exp_month_options,
    card_type,
    set_card_type,
    card_type_options,
    tag_source,
    set_tag_source,
    tag_filter_type,
    set_tag_filter_type,
    tag_source_options,
    crypto_provider,
    set_crypto_provider,
    crypto_options,
    email_provider,
    set_email_provider,
    email_options,
    card_date_from,
    set_card_date_from,
    card_date_to,
    set_card_date_to,
    display_type_tag,
    set_display_type_tag,
    max_tag,
    set_max_tag,
  } = useContext(filter_context)["tag_filters"];
  const session_categories = handle_session_get("enabled_categories");
  let session_category_array;
  if (session_categories !== "all") {
    const array_data = session_categories.split(",");
    session_category_array = array_data;
  } else {
    session_category_array = options_post_category;
  }

  useEffect(() => {
    if (session_category_array.length !== post_category_tag.length) {
      if (tag_source.includes("biden_cash_shop") === true) {
        set_tag_source(["telegram"]);
      }
      return;
    }
    return;
  }, [post_category_tag]);

  const isAllselectedExpYear =
    exp_year_options.length > 0 && exp_year.length === exp_year_options.length;

  const isAllselectedExpMonth =
    exp_month_options.length > 0 &&
    exp_month.length === exp_month_options.length;
  const isAllselectedCardType =
    card_type_options.length > 0 &&
    card_type.length === card_type_options.length;
  const isAllselectedTagSource =
    tag_source.length === tag_source_options.length;
  const isAllselectedCryptoProvider =
    crypto_options.length > 0 &&
    crypto_provider.length === crypto_options.length;
  const isAllselectedEmailProvider =
    email_options.length > 0 && email_provider.length === email_options.length;

  const handleClickAway = () => {
    setShowFilters(false);
  };
  const handle_change_exp_year = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_year(
        exp_year.length === exp_year_options.length ? [] : exp_year_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_year = [...exp_year];
      const index_exp_year = list_exp_year.indexOf(num_value);
      index_exp_year === -1
        ? list_exp_year.push(num_value)
        : list_exp_year.splice(index_exp_year, 1);
      set_exp_year(list_exp_year);
    }
  };
  const handle_change_exp_month = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_month(
        exp_month.length === exp_month_options.length ? [] : exp_month_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_month = [...exp_month];
      const index_exp_month = list_exp_month.indexOf(num_value);
      index_exp_month === -1
        ? list_exp_month.push(num_value)
        : list_exp_month.splice(index_exp_month, 1);
      set_exp_month(list_exp_month);
    }
  };
  const handle_change_card_type = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_card_type(
        card_type.length === card_type_options.length ? [] : card_type_options,
      );
    } else {
      const list_card_type = [...card_type];
      const index_card_type = list_card_type.indexOf(value);
      index_card_type === -1
        ? list_card_type.push(value)
        : list_card_type.splice(index_card_type, 1);
      set_card_type(list_card_type);
    }
  };
  const handle_change_tag_source = (event) => {
    const { value } = event.target;
    if (value === "all") {
      if (session_category_array.length !== post_category_tag.length) {
        handle_warning_message(
          "Please ensure that all your accessed categories are selected to enable this source",
        );
        return;
      }
      set_tag_source(
        tag_source.length === tag_source_options.length
          ? []
          : tag_source_options,
      );
    } else {
      if (
        value === "biden_cash_shop" &&
        session_category_array.length !== post_category_tag.length
      ) {
        handle_warning_message(
          "Please ensure that all your accessed categories are selected to enable this source",
        );
        return;
      }
      const list_tag_source = [...tag_source];
      const index_tag_source = list_tag_source.indexOf(value);
      index_tag_source === -1
        ? list_tag_source.push(value)
        : list_tag_source.splice(index_tag_source, 1);
      set_tag_source(list_tag_source);
    }
  };

  const handle_change_crypto_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_crypto_provider(
        crypto_provider.length === crypto_options.length ? [] : crypto_options,
      );
    } else {
      const list_crypto_provider = [...crypto_provider];
      const index_crypto_provider = list_crypto_provider.indexOf(value);
      index_crypto_provider === -1
        ? list_crypto_provider.push(value)
        : list_crypto_provider.splice(index_crypto_provider, 1);
      set_crypto_provider(list_crypto_provider);
    }
  };
  const handle_change_email_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_email_provider(
        email_provider.length === email_options.length ? [] : email_options,
      );
    } else {
      const list_email_provider = [...email_provider];
      const index_email_provider = list_email_provider.indexOf(value);
      index_email_provider === -1
        ? list_email_provider.push(value)
        : list_email_provider.splice(index_email_provider, 1);
      set_email_provider(list_email_provider);
    }
  };
  return (
    <div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="filters__main filters_breach filters_card">
          {(card_filters === "bank_card" || card_filters === "bin") && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title filter_category_title"
              >
                Exp Year
              </FormLabel>
              <Box className="filter__category ">
                <FormGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                >
                  {exp_year_options.map((item, i) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={item}
                          onChange={handle_change_exp_year}
                          checked={exp_year.includes(item)}
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label={item}
                      key={i}
                    />
                  ))}

                  <FormControlLabel
                    control={
                      <Checkbox
                        value="all"
                        onChange={handle_change_exp_year}
                        checked={isAllselectedExpYear}
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="All"
                  />
                </FormGroup>
              </Box>
            </FormControl>
          )}
          {(card_filters === "bank_card" || card_filters === "bin") && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title filter_category_title"
              >
                Exp Month
              </FormLabel>
              <Box className="filter__category ">
                <FormGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                >
                  {exp_month_options.map((item, i) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={item}
                          onChange={handle_change_exp_month}
                          checked={exp_month.includes(item)}
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label={item}
                      key={i}
                    />
                  ))}

                  <FormControlLabel
                    control={
                      <Checkbox
                        value="all"
                        onChange={handle_change_exp_month}
                        checked={isAllselectedExpMonth}
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="All"
                  />
                </FormGroup>
              </Box>
            </FormControl>
          )}

          {(card_filters === "bank_card" || card_filters === "bin") && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title filter_category_title"
              >
                Card Type
              </FormLabel>
              <Box className="filter__category ">
                <FormGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                >
                  {card_type_options.map((item, id) => {
                    let split_card_name = "";
                    if (item === "universal_air_travel_plan_card") {
                      split_card_name = "UATP";
                    } else if (item === "jcb_card") {
                      split_card_name = "JCB";
                    } else if (
                      ["amex_card", "mastercard", "visa_card"].includes(
                        item,
                      ) === false
                    ) {
                      split_card_name = item.replaceAll("card", "");
                    } else {
                      split_card_name = item;
                    }
                    split_card_name = split_card_name.replaceAll("_", " ");
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={item}
                            onChange={handle_change_card_type}
                            checked={card_type.includes(item)}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label={
                          <span className="card_name">{split_card_name}</span>
                        }
                        key={id}
                      />
                    );
                  })}

                  <FormControlLabel
                    control={
                      <Checkbox
                        value="all"
                        onChange={handle_change_card_type}
                        checked={isAllselectedCardType}
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="All"
                  />
                </FormGroup>
              </Box>
            </FormControl>
          )}

          {(card_filters === "bank_card" || card_filters === "bin") &&
            tag_filter_type === "default" && (
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title filter_category_title"
                >
                  Source
                </FormLabel>
                <Box className="filter__category ">
                  <FormGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                  >
                    {tag_source_options.map((item, id) => {
                      let split_card_name = "";
                      if (item === "biden_cash_shop") {
                        split_card_name = "Others";
                      } else {
                        split_card_name = item.replaceAll("_", " ");
                      }
                      return (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item}
                              onChange={handle_change_tag_source}
                              checked={tag_source.includes(item)}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={
                            <span className="card_name">{split_card_name}</span>
                          }
                          key={id}
                        />
                      );
                    })}

                    <FormControlLabel
                      control={
                        <Checkbox
                          value="all"
                          onChange={handle_change_tag_source}
                          checked={isAllselectedTagSource}
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label="All"
                    />
                  </FormGroup>
                </Box>
              </FormControl>
            )}
          {card_filters === "crypto" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title filter_category_title"
              >
                Crypto Provider
              </FormLabel>
              <Box className="filter__category ">
                <FormGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                >
                  {crypto_options.map((item, id) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={item}
                          onChange={handle_change_crypto_provider}
                          checked={crypto_provider.includes(item)}
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label={<span className="card_name">{item}</span>}
                      key={id}
                    />
                  ))}

                  <FormControlLabel
                    control={
                      <Checkbox
                        value="all"
                        onChange={handle_change_crypto_provider}
                        checked={isAllselectedCryptoProvider}
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="All"
                  />
                </FormGroup>
              </Box>
            </FormControl>
          )}
          {card_filters === "email" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title filter_category_title"
              >
                Email Provider
              </FormLabel>
              <Box className="filter__category ">
                <FormGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                >
                  {email_options.map((item, id) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={item}
                          onChange={handle_change_email_provider}
                          checked={email_provider.includes(item)}
                          size="small"
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#28cab3",
                            },
                          }}
                        />
                      }
                      label={<span className="card_name">{item}</span>}
                      key={id}
                    />
                  ))}

                  <FormControlLabel
                    control={
                      <Checkbox
                        value="all"
                        onChange={handle_change_email_provider}
                        checked={isAllselectedEmailProvider}
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="All"
                  />
                </FormGroup>
              </Box>
            </FormControl>
          )}
          <Categories
            postCategory={post_category_tag}
            setPostCategory={set_post_category_tag}
            container_type={"column"}
          />
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Filter Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={tag_filter_type}
              onChange={(e) => {
                set_tag_filter_type(e.target.value);
                if (e.target.value === "individual") {
                  set_tag_source(["telegram"]);
                }
              }}
              sx={{ columnGap: 2 }}
            >
              <FormControlLabel
                value="default"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Default"
              />
              <FormControlLabel
                value="individual"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Individual"
              />
            </RadioGroup>
          </FormControl>

          <FormControl>
            <div className="dateRangePicker">
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Uploaded Date
              </FormLabel>
              <Datepicker
                date_from={card_date_from}
                set_date_from={set_card_date_from}
                date_to={card_date_to}
                set_date_to={set_card_date_to}
              />
            </div>
          </FormControl>

          {/* Data display */}
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Display
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={display_type_tag}
              onChange={(event) => set_display_type_tag(event.target.value)}
            >
              <FormControlLabel
                value="table"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Table"
              />
              <FormControlLabel
                value="json"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="JSON"
              />
            </RadioGroup>
          </FormControl>
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Max Result
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={max_tag}
              onChange={(e) => set_max_tag(e.target.value)}
              sx={{ columnGap: 2 }}
            >
              <FormControlLabel
                value={100}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={100}
              />

              <FormControlLabel
                value={1000}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={1000}
              />
              <FormControlLabel
                value={5000}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={5000}
              />
              <FormControlLabel
                value={10000}
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label={10000}
              />
            </RadioGroup>
          </FormControl>
        </div>
      </ClickAwayListener>
    </div>
  );
}
export default FiltersCard;

import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Formik } from "formik";
import { Stack } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";
import Chip from "@mui/material/Chip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Grid from "@mui/material/Grid";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import {
  handle_success_message,
  response_message_custom,
  handle_error_message,
  handle_warning_message,
} from "../../../utils/ResponseMessage/response_message";
import HorizontalDivider from "../sections/Divider/HorizontalDivider";
import "../../../css/Modal/modal.css";

function EmailAlert({
  open_email_alert,
  set_open_email_alert,
  end_point,
  endpoint_loading,
  get_endpoint,
  header_obj,
  multiple_emails_list,
  set_multiple_emails_list,
  get_multiple_emails,
}) {
  const token = handle_session_get("Token");
  const [user_endpoint, set_user_endpoint] = useState("");
  const [test_loading, set_test_loading] = useState(false);

  const input_ref_email = useRef("");
  // State to add the headers to the endpoints
  const [selectedValues, setSelectedValues] = useState({});
  const all_headers_names = [
    "Accept",
    "Accept-Charset",
    "Accept-Encoding",
    "Accept-Language",
    "Access-Control-Request-Headers",
    "Access-Control-Request-Method",
    "Authorization",
    "Cache-Control",
    "Connection",
    "Content-Encoding",
    "Content-Length",
    "Content-Type",
    "Cookie",
    "Date",
    "DNT",
    "Expect",
    "Forwarded",
    "From",
    "Host",
    "If-Match",
    "If-Modified-Since",
    "If-None-Match",
    "If-Range",
    "If-Unmodified-Since",
    "Max-Forwards",
    "Origin",
    "Pragma",
    "Proxy-Authorization",
    "Range",
    "Referer",
    "TE",
    "Trailer",
    "Transfer-Encoding",
    "User-Agent",
    "Upgrade",
    "Via",
    "Warning",
  ];
  const [inputCount, setInputCount] = useState(1);

  const all_keys = (range) => {
    const key_value = Object.entries(header_obj);
    const obj = {};
    if (key_value.length > 0) {
      for (let i = 0; i < range; i++) {
        obj[`key_${i}`] = key_value[i][0];
        obj[`value_${i}`] = key_value[i][1];
      }
      setSelectedValues(obj);
    }
  };

  const handle_onchange_options = (event) => {
    const { id, value } = event.target;
    setSelectedValues((prevValues) => ({ ...prevValues, [id]: value }));
  };

  const handle_add_headers = (key, value) => {
    if (key && value) {
      setInputCount(inputCount + 1);
    } else {
      handle_warning_message(
        "Please provide the required key and value in these fields.",
      );
    }
  };
  const handle_delete_header = (index) => {
    setSelectedValues((prev) => ({
      ...prev,
      [`key_${index}`]: "",
      [`value_${index}`]: "",
    }));
    setInputCount(inputCount - 1);
  };

  const handle_store_key_value_obj = () => {
    const obj = {};
    if (inputCount === 1) {
      if (!selectedValues[`key_${0}`] && !selectedValues[`value_${0}`]) {
        return {};
      }
    }
    for (let i = 0; i < inputCount; i++) {
      if (selectedValues[`key_${i}`] && selectedValues[`value_${i}`]) {
        obj[selectedValues[`key_${i}`]] = selectedValues[`value_${i}`];
      } else {
        return "terminate";
      }
    }
    return obj;
  };

  useEffect(() => {
    toast.configure();
    if (header_obj) {
      const keys = Object.keys(header_obj);
      if (keys.length > 0) {
        setInputCount(keys.length);
      }
      all_keys(keys.length > 0 ? keys.length : 1);
    }
  }, []);

  const add_endpoint = (input_endpoint) => {
    if (input_endpoint.length === 0) {
      document.getElementById("email_alert_errormsg").innerHTML =
        "Please add endpoint";
      return;
    }
    document.getElementById("email_alert_errormsg").innerHTML = "";
    const header_key_value = handle_store_key_value_obj();
    if (header_key_value === "terminate") {
      handle_warning_message(
        "You can't leave the headers empty. Please enter valid key and value",
      );
      return;
    }
    const data = {
      endpoint_url: input_endpoint,
      headers_key_value:
        Object.keys(header_key_value).length > 0 ? header_key_value : "None",
    };
    axios
      .post("https://dev.recordedtelegram.com/v2/add_endpoints", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        handle_success_message("End point added successfully");
        setTimeout(() => {
          get_endpoint();
        }, 1000);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };

  const update_endpoint = (input_endpoint) => {
    const header_key_value = handle_store_key_value_obj();
    if (header_key_value === "terminate") {
      handle_warning_message(
        "You can't leave the headers empty. Please enter valid key and value",
      );
      return;
    }
    const data = {
      endpoint_url: input_endpoint.length === 0 ? end_point : input_endpoint,
      headers_key_value:
        Object.keys(header_key_value).length > 0 ? header_key_value : "None",
    };
    axios
      .post("https://dev.recordedtelegram.com/v2/add_endpoints", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        handle_success_message("End point updated successfully");
        setTimeout(() => {
          get_endpoint();
        }, 1000);
        set_user_endpoint("");
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);

        set_user_endpoint("");
      });
    document.getElementById("email_alert_errormsg").innerHTML = "";
  };

  const test_endpoint = () => {
    if (end_point === null) {
      document.getElementById("email_alert_errormsg").innerHTML =
        "Please add endpoint";
      return;
    }
    set_test_loading(true);
    axios
      .get("https://dev.recordedtelegram.com/v2/test_endpoint", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        response_message_custom(res.data.message, "success");
        set_test_loading(false);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        response_message_custom(err.response.data.errormsg, "error");
        set_test_loading(false);
      });
    document.getElementById("email_alert_errormsg").innerHTML = "";
  };

  const handle_change_endpoint = (event) => {
    set_user_endpoint(event.target.value);
  };

  // Check multiple if emails if the current emails are equal to the fetched emails
  const check_emails = (current_emails) => {
    try {
      const sorted1 = [...current_emails].sort();
      const sorted2 = [...multiple_emails_list.previous_emails].sort();

      if (JSON.stringify(sorted1) === JSON.stringify(sorted2)) {
        return true;
      }
      return false;
    } catch {
      //Do nothing
    }
  };

  // Function to add the multiple emails
  const add_multiple_emails = (emails_list) => {
    if (emails_list.length === 0) {
      handle_warning_message("Please enter multiple emails");
      return;
    }
    const email_status = check_emails(emails_list);
    if (email_status === true) {
      handle_warning_message("Please add new emails to update");
      return;
    }

    const data = { emails: emails_list };
    axios
      .post("https://dev.recordedtelegram.com/v2/add_multiple_email", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        handle_success_message("Emails added successfully");
        setTimeout(() => {
          get_multiple_emails();
        }, 1000);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
      });
  };

  const handle_delete_emails = (clicked) => {
    if (clicked === multiple_emails_list.user_email) {
      handle_warning_message("User cannot delete the Default email");
      return;
    }
    const filter_emails = multiple_emails_list.emails.filter(
      (item) => item !== clicked,
    );
    set_multiple_emails_list((prev) => ({ ...prev, emails: filter_emails }));
  };
  const multiple_emails_list_chips = multiple_emails_list.emails.map(
    (item, index) => (
      <Chip
        label={item}
        onDelete={() => handle_delete_emails(item)}
        color="success"
        key={index}
      />
    ),
  );

  const handle_add_emails_list = (data) => {
    let email = "";
    try {
      email = data.trim();
    } catch {
      email = data;
    }
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) === false) {
      handle_warning_message("Please enter valid email");
      return;
    }
    if (email === multiple_emails_list.user_email) {
      handle_warning_message(
        "This email is already a default Email. Please Enter another Email",
      );
      return;
    }
    if (multiple_emails_list.emails.includes(email) === true) {
      handle_warning_message("This email is already added");
      return;
    }
    set_multiple_emails_list((prev) => ({
      ...prev,
      emails: [...prev.emails, email],
    }));
    input_ref_email.current.value = "";
  };

  return (
    <div>
      <Modal
        open={open_email_alert}
        onClose={() => set_open_email_alert(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {endpoint_loading === true ? (
          <Box className="container_modal_email_alert">
            <div className="endpoint_loader">
              <LinearProgress sx={{ width: "50%" }} />
              <h5 style={{ color: "white", textAlign: "center" }}>
                please wait
              </h5>
            </div>
          </Box>
        ) : (
          <Box className="container_modal_email_alert scroll-custom">
            <div className="modal_email_alert_close">
              <button
                className="modal-heading-closeBtn"
                onClick={() => set_open_email_alert(false)}
              >
                <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
              </button>
            </div>

            <Box>
              <div className="modal_email_alert_desc">
                <p className="current_endpoint">Current Emails</p>
                <p
                  className="current_endpoint_value"
                  style={{ marginBottom: "5px" }}
                >
                  {` Default Email : ${multiple_emails_list.user_email}`}
                </p>
                <p className="current_endpoint_value">{` Total Emails : ${multiple_emails_list.emails.length}`}</p>
                <div className="email_alert_input_div">
                  <label className="global_input_label" htmlFor="new_email">
                    Enter Email
                  </label>
                  <input
                    id="new_email"
                    className="global_input_box modal_email_input_box"
                    type="text"
                    ref={input_ref_email}
                  />
                  <button
                    className="add_email_btn"
                    onClick={() =>
                      handle_add_emails_list(input_ref_email.current.value)
                    }
                  >
                    <AddCircleIcon sx={{ color: "#198754", fontSize: 20 }} />
                  </button>
                </div>
              </div>
              <div className="multiple_emails_list_chips">
                {multiple_emails_list_chips}
              </div>
              <div className="modal_email_alert_btns modal_add_email_btns">
                <button
                  className="email_alert_btn multiple_add_email_btn"
                  onClick={() => {
                    add_multiple_emails(multiple_emails_list.emails);
                  }}
                >
                  Update
                </button>
              </div>
            </Box>
            <HorizontalDivider />
            <div className="modal_email_alert_desc">
              <p className="current_endpoint">Current Endpoint</p>
              <p className="current_endpoint_value">{end_point}</p>
              <div className="email_alert_input_div">
                <label className="global_input_label" htmlFor="new_endpoint">
                  New End Point
                </label>
                <input
                  className="global_input_box modal_email_input_box"
                  type="text"
                  value={user_endpoint}
                  id="new_endpoint"
                  onChange={handle_change_endpoint}
                />
              </div>
            </div>
            <h5>Headers</h5>
            <div>
              <div className="divider-bottom-header" />
            </div>
            <div className="endpoint_header">
              <Formik
                initialValues={{}}
                validate={() => {
                  const errors = {};

                  return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {
                    setSubmitting(false);
                  }, 400);
                }}
              >
                {({ handleChange, handleBlur, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container alignItems="flex-end">
                      <Grid item xs={10.5}>
                        {Array.from({ length: inputCount }, (_, i) => (
                          <div key={i} className="header_pair_endpoint">
                            <Stack
                              alignItems="flex-start"
                              sx={{ width: "35%" }}
                            >
                              <label
                                className="global_input_label"
                                htmlFor={`key_${i}`}
                              >
                                Key
                              </label>
                              <input
                                id={`key_${i}`}
                                type="text"
                                name={`key_${i}`}
                                autoComplete="off"
                                className="global_input_box w-100"
                                onChange={(event) => {
                                  handleChange(event);
                                  handle_onchange_options(event);
                                }}
                                value={
                                  selectedValues[`key_${i}`]
                                    ? selectedValues[`key_${i}`]
                                    : ""
                                }
                                onBlur={handleBlur}
                                list="header_keys"
                                placeholder="key"
                              />
                              <datalist id="header_keys">
                                {all_headers_names.map((item, index) => (
                                  <option key={index} value={item} />
                                ))}
                              </datalist>
                            </Stack>

                            <Stack
                              alignItems="flex-start"
                              sx={{ width: "60%" }}
                            >
                              <label
                                className="global_input_label"
                                htmlFor={`value_${i}`}
                              >
                                value
                              </label>
                              <input
                                id={`value_${i}`}
                                type="text"
                                name={`value_${i}`}
                                autoComplete="off"
                                className="global_input_box w-100"
                                onChange={(event) => {
                                  handleChange(event);
                                  handle_onchange_options(event);
                                }}
                                value={
                                  selectedValues[`value_${i}`]
                                    ? selectedValues[`value_${i}`]
                                    : ""
                                }
                                onBlur={handleBlur}
                                placeholder="value"
                              />
                            </Stack>
                          </div>
                        ))}
                      </Grid>
                      <Grid item xs={1}>
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          sx={{
                            width: "100%",
                            columnGap: "4px",
                            marginBlockEnd: "1.5rem",
                          }}
                        >
                          <button
                            type="button"
                            className="btn_add_header"
                            onClick={(event) => {
                              event.preventDefault();
                              handle_add_headers(
                                selectedValues[`key_${inputCount - 1}`],
                                selectedValues[`value_${inputCount - 1}`],
                              );
                            }}
                          >
                            <AddCircleOutlineIcon
                              className="admin_icon"
                              sx={{ color: "white", fontSize: 25 }}
                            />
                          </button>

                          {inputCount !== 1 ? (
                            <button
                              type="button"
                              className="btn_delete_header"
                              onClick={(event) => {
                                event.preventDefault();
                                handle_delete_header(inputCount - 1);
                              }}
                            >
                              <RemoveCircleOutlineIcon
                                sx={{ color: "white", fontSize: 25 }}
                              />
                            </button>
                          ) : null}
                        </Stack>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </div>

            <div className="modal_email_alert_btns">
              {end_point === null ? (
                <button
                  className="email_alert_btn email_alert_btn_add"
                  onClick={() => {
                    add_endpoint(user_endpoint);
                  }}
                >
                  Add
                </button>
              ) : (
                <button
                  className="email_alert_btn email_alert_btn_update"
                  onClick={() => {
                    update_endpoint(user_endpoint);
                  }}
                >
                  Update
                </button>
              )}
              {test_loading === false ? (
                <button
                  className="email_alert_btn email_alert_btn_test"
                  onClick={() => {
                    test_endpoint();
                  }}
                >
                  Test
                </button>
              ) : (
                <CircularProgress />
              )}
            </div>
            <p id="email_alert_errormsg" style={{ color: "#bf7a21" }} />
          </Box>
        )}
      </Modal>
      <ToastContainer />
    </div>
  );
}
EmailAlert.propTypes = {
  open_email_alert: PropTypes.bool,
  set_open_email_alert: PropTypes.func,
  end_point: PropTypes.string,
  endpoint_loading: PropTypes.bool,
  get_endpoint: PropTypes.func,
  header_obj: PropTypes.object,
  multiple_emails_list: PropTypes.array,
  set_multiple_emails_list: PropTypes.func,
  get_multiple_emails: PropTypes.func,
};
export default EmailAlert;

import PropTypes from "prop-types";
import React, {
  useState,
  useRef,
  useEffect,
  createContext,
  useContext,
} from "react";
import Box from "@mui/material/Box";
import { Stack, GlobalStyles } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Chip from "@mui/material/Chip";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {
  bank_card_validator,
  ip_address_validator,
  phone_validator,
  crypto_validator,
  email_validator,
  bin_validator,
  domain_validator,
} from "../../../utils/Validation/Tag/tag_validator";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import {
  handle_success_message,
  handle_warning_message,
} from "../../../utils/ResponseMessage/response_message";
import { RollerAnimation } from "../loader/Loader";
import "../../../css/Dashboard/company_dashboard.css";
const UserContext = createContext();
const check_input_array = (array, input) => {
  try {
    const filter_array = array.map((item) => item.toLowerCase());
    const filter_input = input.toLowerCase();
    if (filter_array.includes(filter_input) === true) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
};
function CompanyParametersFillModal({
  open_fill_param_modal,
  set_open_fill_param_modal,
  fill_status,
  get_chart_data_func,
}) {
  const token = handle_session_get("Token");
  const company_status = handle_session_get("company_status");
  const [page_count, set_page_count] = useState(0);
  const [company_dash_pref, set_company_dash_pref] = useState({
    user_preference: {
      company: [],
      product: [],
      domains: [],
      tags: [],
      ip: null,
      industry: [],
      channel: [],
    },
    company_preference: {
      product: [],
      domains: [],
      tags: [],
      ip: null,
      industry: [],
      channel: [],
    },
  });
  const history = useHistory();
  const next_btn_name = (page) => {
    const get_product_array = [
      ...company_dash_pref.user_preference.product,
      ...company_dash_pref.company_preference.product,
    ];
    const get_domains_array = [
      ...company_dash_pref.user_preference.domains,
      ...company_dash_pref.company_preference.domains,
    ];
    const get_company_array = [...company_dash_pref.user_preference.company];
    const get_tags_array = [
      ...company_dash_pref.user_preference.tags,
      ...company_dash_pref.company_preference.tags,
    ];
    try {
      if (page === 0 && get_company_array.length > 0) {
        return "Next";
      }
      if (page === 1 && get_product_array.length > 0) {
        return "Next";
      }
      if (page === 2 && get_domains_array.length > 0) {
        return "Next";
      }
      if (page === 3 && get_tags_array.length > 0) {
        return "Next";
      }
      // else if (page === 3 && get_tags_array.length > 0) {
      //     return "Next"
      // }

      return "Skip";
    } catch {
      return "Skip";
    }
  };

  useEffect(() => {
    if (fill_status === "update") {
      get_user_dashboard_pref();
    }
  }, []);

  let isSubmitBlocked = false;
  const handle_submit_parameters = (company_pref) => {
    const add_company_name =
      company_status !== "None"
        ? { ...company_pref }
        : { ...company_pref, company_preference: null };
    if (!isSubmitBlocked) {
      isSubmitBlocked = true;
      axios
        .post(
          "https://dev.recordedtelegram.com/user_dashboard_preference",
          add_company_name,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          },
        )
        .then((res) => {
          handle_success_message(res.data.message);
          if (fill_status === "create") {
            history.push("./company-statspage");
          } else if (fill_status === "update") {
            if (get_chart_data_func) {
              if (get_chart_data_func !== "undefined") {
                const user_name_session = handle_session_get("admin_status");
                const {
                  get_chart_data_product,
                  get_chart_data_domain,
                  get_chart_data_tag,
                  get_chart_data_channel,
                  get_company_preference,
                } = get_chart_data_func;
                setTimeout(() => {
                  get_chart_data_product();
                  get_chart_data_domain();
                  get_chart_data_tag();
                  get_chart_data_channel();
                  if (
                    user_name_session === "company_admin" ||
                    user_name_session === "super_admin"
                  ) {
                    get_company_preference();
                  }
                });
              }
            }
          }
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          isSubmitBlocked = false;
          handle_warning_message(err.response.data.errormsg);
        });
    }
  };

  const get_user_dashboard_pref = () => {
    axios
      .get("https://dev.recordedtelegram.com/get_user_dashboard_preference", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const dash_pref = res.data.data;
        const filter_dash_pref = {
          user_preference: {
            company: dash_pref.user_preference.company || [],
            product: dash_pref.user_preference.product || [],
            domains: dash_pref.user_preference.domain || [],
            tags: dash_pref.user_preference.tag || [],
            channel: dash_pref.user_preference?.channel || [],
            ip: null,
            industry: [],
          },
          company_preference: {
            product: dash_pref.company_preference.product || [],
            domains: dash_pref.company_preference.domain || [],
            tags: dash_pref.company_preference.tag || [],
            channel: dash_pref.company_preference?.channel || [],
            ip: null,
            industry: [],
          },
        };
        set_company_dash_pref(filter_dash_pref);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
      });
  };

  return (
    <div>
      <Modal
        open={open_fill_param_modal}
        onClose={() => set_open_fill_param_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="comp_dash_modal_container scroll-custom">
          <div>
            <Stack direction="row" justifyContent="space-between">
              <p />
              <h5>Fill your company parameters</h5>
              <CloseIcon
                onClick={() => set_open_fill_param_modal(false)}
                sx={{ color: "#a51111", cursor: "pointer" }}
              />
            </Stack>
            <Box className="comp_dash_modal_desc">
              <Box sx={{ marginLeft: "1.5rem" }}>
                <div className="progressbar_div">
                  {page_count >= 0 && <div className="progressbar" />}
                  {page_count >= 1 && <div className="progressbar" />}
                  {page_count >= 2 && <div className="progressbar" />}
                  {page_count >= 3 && <div className="progressbar" />}
                  {page_count >= 4 && <div className="progressbar" />}
                </div>
                <UserContext.Provider value={fill_status}>
                  {page_count === 0 ? (
                    <CompanyNames
                      company_dash_pref={company_dash_pref}
                      set_company_dash_pref={set_company_dash_pref}
                    />
                  ) : page_count === 1 ? (
                    <ProductNames
                      company_dash_pref={company_dash_pref}
                      set_company_dash_pref={set_company_dash_pref}
                    />
                  ) : page_count === 2 ? (
                    <Domains
                      company_dash_pref={company_dash_pref}
                      set_company_dash_pref={set_company_dash_pref}
                    />
                  ) : page_count === 3 ? (
                    <Tags
                      company_dash_pref={company_dash_pref}
                      set_company_dash_pref={set_company_dash_pref}
                    />
                  ) : (
                    <Channels
                      company_dash_pref={company_dash_pref}
                      set_company_dash_pref={set_company_dash_pref}
                    />
                  )}
                </UserContext.Provider>
              </Box>
            </Box>
          </div>
          <div className="comp_dash_modal_btns">
            <button
              disabled={page_count === 0}
              className={
                page_count === 0
                  ? "comp_dash_modal_btn comp_dash_modal_btn_back_disabled"
                  : "comp_dash_modal_btn comp_dash_modal_btn_back"
              }
              onClick={() => set_page_count(page_count - 1)}
            >
              <span>
                <KeyboardBackspaceIcon />
              </span>
              Back
            </button>
            {page_count <= 3 ? (
              <button
                disabled={
                  !!(
                    page_count === 0 &&
                    company_dash_pref.user_preference.company.length === 0
                  )
                }
                className={
                  page_count === 0 &&
                  company_dash_pref.user_preference.company.length === 0
                    ? "comp_dash_modal_btn comp_dash_modal_btn_back_disabled"
                    : "comp_dash_modal_btn comp_dash_modal_btn_skip"
                }
                onClick={() => set_page_count(page_count + 1)}
              >
                {next_btn_name(page_count)}
                <span>
                  <ArrowForwardIcon />
                </span>
              </button>
            ) : (
              <button
                className="comp_dash_modal_btn comp_dash_modal_btn_skip"
                onClick={() => {
                  handle_submit_parameters(company_dash_pref);
                  set_open_fill_param_modal(false);
                }}
              >
                {fill_status === "update" ? "Update" : "Finish"}
              </button>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}

CompanyParametersFillModal.propTypes = {
  fill_status: PropTypes.string,
  get_chart_data_func: PropTypes.object,
  open_fill_param_modal: PropTypes.bool,
  set_open_fill_param_modal: PropTypes.func,
};

function CompanyNames({ company_dash_pref, set_company_dash_pref }) {
  const handle_delete_name = (val) => {
    const filter_arr = company_dash_pref.user_preference.company.filter(
      (item) => val !== item,
    );
    set_company_dash_pref((prev) => ({
      ...prev,
      user_preference: { ...prev.user_preference, company: filter_arr },
    }));
  };
  const multiple_channel_name = company_dash_pref.user_preference.company.map(
    (item, index) => (
      <Chip
        label={item}
        onDelete={() => handle_delete_name(item)}
        color="success"
        key={index}
      />
    ),
  );
  const input_ref = useRef();
  const handle_add_name = () => {
    const input_value = input_ref.current.value;
    if (!input_value) {
      handle_warning_message("Please enter a value in this field.");
      return;
    }
    if (
      company_dash_pref.user_preference.company.includes(input_value) === true
    ) {
      handle_warning_message("You have already entered this value");
      return;
    }
    if (input_value.trim() !== "") {
      set_company_dash_pref((prev) => ({
        ...prev,
        user_preference: {
          ...prev.user_preference,
          company: [...prev.user_preference.company, input_value],
        },
      }));
      input_ref.current.value = "";
    }
  };
  return (
    <div className="parameter_container">
      <div className="company_name_container">
        <Stack sx={{ width: "80%" }}>
          <label htmlFor="input_field_company_name">Enter Company Name</label>
          <input
            ref={input_ref}
            className="input_field_param input_field_company_name"
            id="input_field_company_name"
            name="input_field_company_name"
            autoComplete="off"
          />
        </Stack>
        {company_dash_pref.user_preference.company.length === 0 ? (
          <div>
            <button onClick={handle_add_name} className="comp_dash_add_btn">
              <AddCircleOutlineIcon
                fontSize="large"
                sx={{ color: "whitesmoke", marginBottom: "18px" }}
              />
            </button>
          </div>
        ) : null}
      </div>
      {company_dash_pref.user_preference.company.length === 0 ? (
        <p className="company_name_required">Company name required </p>
      ) : null}
      <div className="multiple_names_chip_company scroll-custom">
        {multiple_channel_name}
      </div>
    </div>
  );
}

CompanyNames.propTypes = {
  company_dash_pref: PropTypes.object,
  set_company_dash_pref: PropTypes.func,
};
function ProductNames({ company_dash_pref, set_company_dash_pref }) {
  const company_status = handle_session_get("company_status");
  const user_role =
    handle_session_get("admin_status") === "company_admin" ||
    handle_session_get("admin_status") === "super_admin";
  const [product_pref, set_product_pref] = useState("user_preference");
  const fill_status = useContext(UserContext);

  const handle_delete_name = (val, pref) => {
    const filter_arr = company_dash_pref[pref].product.filter(
      (item) => val !== item,
    );
    set_company_dash_pref((prev) => ({
      ...prev,
      [pref]: { ...prev[pref], product: filter_arr },
    }));
  };
  const multiple_product_name_user =
    company_dash_pref.user_preference.product.map((item, index) => (
      <Chip
        label={item}
        onDelete={() => handle_delete_name(item, "user_preference")}
        color="success"
        key={index}
        sx={{ m: "2px" }}
      />
    ));
  const multiple_product_name_company =
    fill_status === "create"
      ? company_dash_pref.company_preference.product.map((item, index) => (
          <Chip
            label={item}
            onDelete={() => handle_delete_name(item, "company_preference")}
            color="secondary"
            key={index}
            sx={{ m: "2px" }}
          />
        ))
      : company_dash_pref.company_preference.product.map((item, index) => {
          if (user_role) {
            return (
              <Chip
                label={item}
                onDelete={() => handle_delete_name(item, "company_preference")}
                color="secondary"
                key={index}
                sx={{ m: "2px" }}
              />
            );
          } else {
            return (
              <Chip
                label={item}
                color="secondary"
                key={index}
                sx={{ m: "2px" }}
              />
            );
          }
        });
  const input_ref = useRef();
  const handle_add_name = () => {
    const input_value = input_ref.current.value;
    if (!input_value) {
      handle_warning_message("Please enter a value in this field.");
      return;
    }
    if (
      check_input_array(
        company_dash_pref.user_preference.product,
        input_value,
      ) === true ||
      check_input_array(
        company_dash_pref.company_preference.product,
        input_value,
      ) === true
    ) {
      handle_warning_message("You have already entered this value");
      return;
    }
    if (input_value.trim() !== "") {
      set_company_dash_pref((prev) => ({
        ...prev,
        [product_pref]: {
          ...prev[product_pref],
          product: [...prev[product_pref].product, input_value.toLowerCase()],
        },
      }));
      input_ref.current.value = "";
    }
  };
  return (
    <div className="parameter_container">
      <div className="company_name_container">
        <Stack sx={{ width: "80%" }}>
          <label htmlFor="input_field_product_name">
            Enter Product Name(s) / Search Term
          </label>
          <input
            ref={input_ref}
            className="input_field_param input_field_product_name"
            id="input_field_product_name"
            name="input_field_product_name"
            autoComplete="off"
          />
        </Stack>
        <div>
          <button onClick={handle_add_name} className="comp_dash_add_btn">
            <AddCircleOutlineIcon
              fontSize="large"
              sx={{ color: "whitesmoke", marginBottom: "18px" }}
            />
          </button>
        </div>
      </div>
      <p className="comp_dash_pref_info">
        <i>
          {product_pref === "user_preference"
            ? "Sets chart for you only"
            : "Sets chart for your organization"}
        </i>
      </p>
      {company_status !== "None" ? (
        <div className="comp_dash_pref_radio">
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Preference Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              row
              name="controlled-radio-buttons-group"
              value={product_pref}
              onChange={(e) => set_product_pref(e.target.value)}
            >
              <FormControlLabel
                value="user_preference"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="User"
              />
              <FormControlLabel
                value="company_preference"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Company"
              />
            </RadioGroup>
          </FormControl>
        </div>
      ) : null}
      <div className="multiple_names_chip scroll-custom">
        {company_dash_pref.user_preference.product.length > 0 ? (
          <p className="multiple_names_chip_pref_title">User Preference</p>
        ) : null}
        <div className="multiple_names_chip_pref">
          {multiple_product_name_user}
        </div>
        {company_dash_pref.company_preference.product.length > 0 ? (
          <p
            className="multiple_names_chip_pref_title"
            style={{ marginTop: "1rem" }}
          >
            Company Preference
          </p>
        ) : null}
        <div className="multiple_names_chip_pref">
          {multiple_product_name_company}
        </div>
      </div>
    </div>
  );
}

ProductNames.propTypes = {
  company_dash_pref: PropTypes.object,
  set_company_dash_pref: PropTypes.func,
};
function Domains({ company_dash_pref, set_company_dash_pref }) {
  const company_status = handle_session_get("company_status");
  const user_role =
    handle_session_get("admin_status") === "company_admin" ||
    handle_session_get("admin_status") === "super_admin";
  const [domain_pref, set_domain_pref] = useState("user_preference");
  const fill_status = useContext(UserContext);

  const handle_delete_name = (val, pref) => {
    const filter_arr = company_dash_pref[pref].domains.filter(
      (item) => val !== item,
    );
    set_company_dash_pref((prev) => ({
      ...prev,
      [pref]: { ...prev[pref], domains: filter_arr },
    }));
  };
  const multiple_domain_name_user =
    company_dash_pref.user_preference.domains.map((item, index) => (
      <Chip
        label={item}
        onDelete={() => handle_delete_name(item, "user_preference")}
        color="success"
        key={index}
        sx={{ m: "2px" }}
      />
    ));
  const multiple_domain_name_company =
    fill_status === "create"
      ? company_dash_pref.company_preference.domains.map((item, index) => (
          <Chip
            label={item}
            onDelete={() => handle_delete_name(item, "company_preference")}
            color="secondary"
            key={index}
            sx={{ m: "2px" }}
          />
        ))
      : company_dash_pref.company_preference.domains.map((item, index) => {
          if (user_role) {
            return (
              <Chip
                label={item}
                onDelete={() => handle_delete_name(item, "company_preference")}
                color="secondary"
                key={index}
                sx={{ m: "2px" }}
              />
            );
          } else {
            return (
              <Chip
                label={item}
                color="secondary"
                key={index}
                sx={{ m: "2px" }}
              />
            );
          }
        });
  const input_ref = useRef();
  const handle_add_name = () => {
    const input_value = input_ref.current.value;
    if (!input_value) {
      handle_warning_message("Please enter a value in this field.");
      return;
    }
    if (domain_validator(input_value)) {
      handle_warning_message(domain_validator(input_value));
      return;
    }
    if (
      check_input_array(
        company_dash_pref.user_preference.domains,
        input_value,
      ) === true ||
      check_input_array(
        company_dash_pref.company_preference.domains,
        input_value,
      ) === true
    ) {
      handle_warning_message("You have already entered this value");
      return;
    }
    if (input_value.trim() !== "") {
      set_company_dash_pref((prev) => ({
        ...prev,
        [domain_pref]: {
          ...prev[domain_pref],
          domains: [...prev[domain_pref].domains, input_value.toLowerCase()],
        },
      }));
      input_ref.current.value = "";
    }
  };
  return (
    <div className="parameter_container">
      <div className="company_name_container">
        <Stack sx={{ width: "80%" }}>
          <label htmlFor="input_field_domains">Enter domain(s)</label>
          <input
            ref={input_ref}
            className="input_field_param input_field_domains"
            id="input_field_domains"
            name="input_field_domains"
            autoComplete="off"
          />
        </Stack>
        <div>
          <button onClick={handle_add_name} className="comp_dash_add_btn">
            <AddCircleOutlineIcon
              fontSize="large"
              sx={{ color: "whitesmoke", marginBottom: "18px" }}
            />
          </button>
        </div>
      </div>
      <p className="comp_dash_pref_info">
        <i>
          {domain_pref === "user_preference"
            ? "Sets chart for you only"
            : "Sets chart for your organization"}
        </i>
      </p>
      {company_status !== "None" ? (
        <div className="comp_dash_pref_radio">
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Preference Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              row
              name="controlled-radio-buttons-group"
              value={domain_pref}
              onChange={(e) => set_domain_pref(e.target.value)}
            >
              <FormControlLabel
                value="user_preference"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="User"
              />
              <FormControlLabel
                value="company_preference"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Company"
              />
            </RadioGroup>
          </FormControl>
        </div>
      ) : null}
      <div className="multiple_names_chip scroll-custom">
        {company_dash_pref.user_preference.domains.length > 0 ? (
          <p className="multiple_names_chip_pref_title">User Preference</p>
        ) : null}
        <div className="multiple_names_chip_pref">
          {multiple_domain_name_user}
        </div>
        {company_dash_pref.company_preference.domains.length > 0 ? (
          <p
            className="multiple_names_chip_pref_title"
            style={{ marginTop: "1rem" }}
          >
            Company Preference
          </p>
        ) : null}
        <div className="multiple_names_chip_pref">
          {multiple_domain_name_company}
        </div>
      </div>
    </div>
  );
}

Domains.propTypes = {
  company_dash_pref: PropTypes.func,
  set_company_dash_pref: PropTypes.func,
};
function Tags({ company_dash_pref, set_company_dash_pref }) {
  const company_status = handle_session_get("company_status");
  const user_role =
    handle_session_get("admin_status") === "company_admin" ||
    handle_session_get("admin_status") === "super_admin";
  const [tag_pref, set_tag_pref] = useState("user_preference");
  const fill_status = useContext(UserContext);

  const [card_filters, set_card_filters] = useState("bank_card");
  const input_ref = useRef();
  const handle_delete_name = (first, second, pref) => {
    const filter_arr = company_dash_pref[pref].tags.filter(
      (item) => item[first] !== second,
    );
    set_company_dash_pref((prev) => ({
      ...prev,
      [pref]: { ...prev[pref], tags: filter_arr },
    }));
  };
  const multiple_tags_name_user =
    company_dash_pref.user_preference.tags.length > 0 &&
    company_dash_pref.user_preference.tags.map((item, index) => {
      const [first, second] = Object.entries(item)[0];
      let first_name = "";
      try {
        first_name = first.replaceAll("_", " ");
      } catch {
        first_name = first;
      }

      return (
        <Chip
          label={`${first_name} : ${second}`}
          onDelete={() => handle_delete_name(first, second, "user_preference")}
          color="success"
          key={index}
          sx={{ m: "2px" }}
        />
      );
    });
  const multiple_tags_name_company =
    fill_status === "create"
      ? company_dash_pref.company_preference.tags.length > 0 &&
        company_dash_pref.company_preference.tags.map((item, index) => {
          const [first, second] = Object.entries(item)[0];
          let first_name = "";
          try {
            first_name = first.replaceAll("_", " ");
          } catch {
            first_name = first;
          }

          return (
            <Chip
              label={`${first_name} : ${second}`}
              onDelete={() =>
                handle_delete_name(first, second, "company_preference")
              }
              color="secondary"
              key={index}
              sx={{ m: "2px" }}
            />
          );
        })
      : company_dash_pref.company_preference.tags.length > 0 &&
        company_dash_pref.company_preference.tags.map((item, index) => {
          const [first, second] = Object.entries(item)[0];
          let first_name = "";
          try {
            first_name = first.replaceAll("_", " ");
          } catch {
            first_name = first;
          }
          return (
            <Chip
              label={`${first_name} : ${second}`}
              onDelete={() => {
                if (user_role) {
                  handle_delete_name(first, second, "company_preference");
                }
                return;
              }}
              color="secondary"
              key={index}
              sx={{ m: "2px" }}
            />
          );
        });

  const handle_add_name = () => {
    const input_value = input_ref.current.value;
    const added_tag_obj = { [card_filters]: input_value };
    if (!input_value) {
      handle_warning_message("Please enter a value in this field.");
      return;
    }
    if (card_filters === "bank_card" && bank_card_validator(input_value)) {
      handle_warning_message(bank_card_validator(input_value));
      return;
    }
    if (card_filters === "email" && email_validator(input_value)) {
      handle_warning_message(email_validator(input_value));
      return;
    }
    if (card_filters === "ip_address" && ip_address_validator(input_value)) {
      handle_warning_message(ip_address_validator(input_value));
      return;
    }
    if (card_filters === "crypto" && crypto_validator(input_value)) {
      handle_warning_message(crypto_validator(input_value));
      return;
    }
    if (card_filters === "phone" && phone_validator(input_value)) {
      handle_warning_message(phone_validator(input_value));
      return;
    }
    if (card_filters === "bin" && bin_validator(input_value)) {
      handle_warning_message(bin_validator(input_value));
      return;
    }
    if (
      company_dash_pref.user_preference.tags.some((item) =>
        Object.keys(added_tag_obj).every(
          (key) =>
            Object.prototype.hasOwnProperty.call(item, key) &&
            item[key].toLowerCase() === added_tag_obj[key].toLowerCase(),
        ),
      ) === true ||
      company_dash_pref.company_preference.tags.some((item) =>
        Object.keys(added_tag_obj).every(
          (key) =>
            Object.prototype.hasOwnProperty.call(item, key) &&
            item[key].toLowerCase() === added_tag_obj[key].toLowerCase(),
        ),
      ) === true
    ) {
      handle_warning_message("You have already entered this value");
      return;
    }
    if (input_value.trim() !== "") {
      set_company_dash_pref((prev) => ({
        ...prev,
        [tag_pref]: {
          ...prev[tag_pref],
          tags: [
            ...prev[tag_pref].tags,
            { [card_filters]: input_value.toLowerCase() },
          ],
        },
      }));
      input_ref.current.value = "";
    }
  };
  return (
    <>
      <GlobalStyles
        styles={{
          ".MuiDataGrid-menuList": {
            backgroundColor: "#394e5a",
          },
          ".MuiMenu-list ": {
            backgroundColor: "#394e5a",
          },
          ".MuiDataGrid-menuList li": {
            color: "#dcf3ff",
          },
          ".MuiMenu-list li": {
            color: "#dcf3ff",
          },
          ".MuiPaginationItem-root": {
            color: "#9bbcd1",
            backgroundColor: "#1e2a31",
          },
        }}
      />
      <div className="parameter_container">
        <div className="company_name_container">
          <Stack sx={{ width: "80%" }}>
            <label htmlFor="input_field_ip">Enter Tag(s)</label>
            <div className="comp_dash_input_dropdown_div">
              <div className="comp_dash_tag_dropdown">
                <FormControl
                  sx={{
                    minWidth: 120,
                    borderRight: "2px solid whitesmoke",
                    mt: 0.5,
                  }}
                  size="small"
                >
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={card_filters}
                    onChange={(e) => {
                      set_card_filters(e.target.value);
                    }}
                    sx={{ width: "100%", mt: 0.6, color: "#dcf3ff" }}
                    disableUnderline
                    variant="standard"
                  >
                    <MenuItem value="bank_card">Bank card</MenuItem>
                    <MenuItem value="email">Email</MenuItem>
                    <MenuItem value="ip_address">Ip address</MenuItem>
                    <MenuItem value="crypto">Crypto</MenuItem>
                    <MenuItem value="phone">Phone</MenuItem>
                    <MenuItem value="bin">BIN</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <input
                ref={input_ref}
                className="input_field_tag"
                id="input_field_tag"
                name="input_field_tag"
                autoComplete="off"
              />
            </div>
          </Stack>
          <div>
            <button onClick={handle_add_name} className="comp_dash_add_btn">
              <AddCircleOutlineIcon
                fontSize="large"
                sx={{ color: "whitesmoke", marginBottom: "18px" }}
              />
            </button>
          </div>
        </div>
        <p className="comp_dash_pref_info">
          <i>
            {tag_pref === "user_preference"
              ? "Sets chart for you only"
              : "Sets chart for your organization"}
          </i>
        </p>
        {company_status !== "None" ? (
          <div className="comp_dash_pref_radio">
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Preference Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                row
                name="controlled-radio-buttons-group"
                value={tag_pref}
                onChange={(e) => set_tag_pref(e.target.value)}
              >
                <FormControlLabel
                  value="user_preference"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="User"
                />
                <FormControlLabel
                  value="company_preference"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Company"
                />
              </RadioGroup>
            </FormControl>
          </div>
        ) : null}
        <div className="multiple_names_chip scroll-custom">
          {company_dash_pref.user_preference.tags.length > 0 ? (
            <p className="multiple_names_chip_pref_title">User Preference</p>
          ) : null}
          <div className="multiple_names_chip_pref">
            {multiple_tags_name_user}
          </div>
          {company_dash_pref.company_preference.tags.length > 0 ? (
            <p
              className="multiple_names_chip_pref_title"
              style={{ marginTop: "1rem" }}
            >
              Company Preference
            </p>
          ) : null}
          <div className="multiple_names_chip_pref">
            {multiple_tags_name_company}
          </div>
        </div>
      </div>
    </>
  );
}
Tags.propTypes = {
  company_dash_pref: PropTypes.object,
  set_company_dash_pref: PropTypes.func,
};

// Components for the Channels
function Channels({ company_dash_pref, set_company_dash_pref }) {
  const company_status = handle_session_get("company_status");
  const user_role =
    handle_session_get("admin_status") === "company_admin" ||
    handle_session_get("admin_status") === "super_admin";
  const token = handle_session_get("Token");
  const [input_search, set_input_search] = useState("");
  const [channel_pref, set_channel_pref] = useState("user_preference");
  const [channel_lists, set_channel_list] = useState({
    data: [],
    loading: false,
    error: null,
  });
  const fill_status = useContext(UserContext);

  const handle_delete_name = (val, pref) => {
    const filter_arr = company_dash_pref[pref].channel.filter(
      (item) => val !== item,
    );
    set_company_dash_pref((prev) => ({
      ...prev,
      [pref]: { ...prev[pref], channel: filter_arr },
    }));
  };
  const multiple_domain_name_user =
    company_dash_pref.user_preference.channel.map((item, index) => (
      <Chip
        label={item}
        onDelete={() => handle_delete_name(item, "user_preference")}
        color="success"
        key={index}
        sx={{ m: "2px" }}
      />
    ));
  const multiple_domain_name_company =
    fill_status === "create"
      ? company_dash_pref.company_preference.channel.map((item, index) => (
          <Chip
            label={item}
            onDelete={() => handle_delete_name(item, "company_preference")}
            color="secondary"
            key={index}
            sx={{ m: "2px" }}
          />
        ))
      : company_dash_pref.company_preference.channel.map((item, index) => {
          if (user_role) {
            return (
              <Chip
                label={item}
                onDelete={() => handle_delete_name(item, "company_preference")}
                color="secondary"
                key={index}
                sx={{ m: "2px" }}
              />
            );
          } else {
            return (
              <Chip
                label={item}
                color="secondary"
                key={index}
                sx={{ m: "2px" }}
              />
            );
          }
        });

  const handle_add_name = (channel_name) => {
    const input_value = input_search;
    if (!input_value) {
      handle_warning_message("Please enter a value in this field.");
      return;
    }
    set_company_dash_pref((prev) => ({
      ...prev,
      [channel_pref]: {
        ...prev[channel_pref],
        channel: [...prev[channel_pref].channel, channel_name],
      },
    }));
    set_input_search("");
  };
  const get_channel_lists = (e) => {
    set_input_search(e.target.value);
    if (e.target.value.length >= 2) {
      set_channel_list((prev) => ({ ...prev, loading: true }));
      const data = { qtext: e.target.value, search_type: "alert" };
      axios
        .post("https://dev.recordedtelegram.com/search_fields", data, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const filter_data = res.data.filter((item) => item.link !== "None");
          set_channel_list((prev) => ({
            ...prev,
            data: filter_data,
            loading: false,
            error: null,
          }));
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
            set_channel_list((prev) => ({
              ...prev,
              loading: false,
              error: err.response.data.errormsg,
            }));
          } catch {
            //Do nothing
          }
        });
    } else {
      return;
    }
  };
  return (
    <div className="parameter_container">
      <div className="company_name_container">
        <Stack sx={{ width: "80%" }}>
          <label htmlFor="input_field_domains">Enter Channels/Groups</label>
          <input
            className="input_field_param input_field_domains"
            id="input_field_domains"
            name="input_field_domains"
            autoComplete="off"
            onChange={(event) => get_channel_lists(event)}
            value={input_search}
          />
        </Stack>
      </div>
      {input_search.length === 0 ? (
        <p className="comp_dash_pref_info">
          <i>
            {channel_pref === "user_preference"
              ? "Sets chart for you only"
              : "Sets chart for your organization"}
          </i>
        </p>
      ) : null}

      {/* list of channel */}
      {input_search.length >= 2 ? (
        <div className="autocom-box w-80 mt-3">
          <h4
            style={{
              paddingLeft: "1rem",
              marginLeft: "15px",
              marginTop: "5px",
              fontSize: "18px",
              color: "whitesmoke",
            }}
          >
            List Of Channels
          </h4>
          {channel_lists.loading === true ? (
            <RollerAnimation />
          ) : channel_lists.error !== null ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "larger",
                color: "white",
                marginTop: "10%",
              }}
            >
              {channel_lists.error}
            </div>
          ) : channel_lists.data.length === 0 ? (
            <div
              style={{
                textAlign: "center",
                fontSize: "larger",
                color: "white",
                marginTop: "10%",
              }}
            >
              No Data to display
            </div>
          ) : (
            <>
              <ul>
                {input_search.length >= 2 &&
                  channel_lists.data.map((val, index) => (
                    <li key={index}>
                      <div className="row">
                        <div
                          className="col-md-8"
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {" "}
                          <img
                            style={{
                              width: "36px",
                              height: "36px",
                              padding: "2px",
                              border: "2px solid #f4f4f4",
                            }}
                            className="img-fluid rounded-circle"
                            src={
                              val.channel_id !== "None"
                                ? `https://telegramdb.org/data/chats/photo/big/${val.channel_id}.webp`
                                : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU"
                            }
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src =
                                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTlOW3LA5noaSuRaf6556w3Ab1vZxsXZpq9B7xBVxIO-LkKKuQOORffOjZ-77NS78MKFU&usqp=CAU";
                            }}
                            alt="User"
                          />
                          <span
                            className="channels_list_map"
                            style={{
                              marginLeft: "10px",
                              color: "#dcf3ff",
                            }}
                          >
                            {val.channel_name}
                          </span>
                        </div>
                        <div
                          className="col-md-4"
                          style={{
                            textAlign: "right",
                            marginTop: "5px",
                          }}
                        >
                          {[
                            ...company_dash_pref.user_preference.channel,
                            ...company_dash_pref.company_preference.channel,
                          ].includes(val.channel_name) === false ? (
                            <button
                              style={{
                                borderRadius: "40px",
                                background: "#d03940",
                                color: "white",
                                lineHeight: "1",
                              }}
                              className="btn btn-round mr-md-2 mb-md-0 mb-1"
                              onClick={() => handle_add_name(val.channel_name)}
                            >
                              Add
                            </button>
                          ) : (
                            <button
                              disabled
                              style={{
                                borderRadius: "40px",
                                background: "#4cabd9",
                                color: "white",
                                lineHeight: "1",
                              }}
                              className="btn btn-round mr-md-2 mb-md-0 mb-1"
                            >
                              Added
                            </button>
                          )}
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </>
          )}
        </div>
      ) : null}
      {company_status !== "None" ? (
        <div className="comp_dash_pref_radio">
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Preference Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              row
              name="controlled-radio-buttons-group"
              value={channel_pref}
              onChange={(e) => set_channel_pref(e.target.value)}
            >
              <FormControlLabel
                value="user_preference"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="User"
              />
              <FormControlLabel
                value="company_preference"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Company"
              />
            </RadioGroup>
          </FormControl>
        </div>
      ) : null}
      <div className="multiple_names_chip scroll-custom">
        {company_dash_pref.user_preference.channel.length > 0 ? (
          <p className="multiple_names_chip_pref_title">User Preference</p>
        ) : null}
        <div className="multiple_names_chip_pref">
          {multiple_domain_name_user}
        </div>
        {company_dash_pref.company_preference.channel.length > 0 ? (
          <p
            className="multiple_names_chip_pref_title"
            style={{ marginTop: "1rem" }}
          >
            Company Preference
          </p>
        ) : null}
        <div className="multiple_names_chip_pref">
          {multiple_domain_name_company}
        </div>
      </div>
    </div>
  );
}
Channels.propTypes = {
  company_dash_pref: PropTypes.func,
  set_company_dash_pref: PropTypes.func,
};

export default CompanyParametersFillModal;

import PropTypes from "prop-types";
import React, { useState } from "react";
import { IconContext } from "react-icons";
import Editor from "@monaco-editor/react";
import DownloadFileOptions from "../modalContainer/DownloadFileOptions";
import { FaCopy } from "react-icons/fa";
import "../../../css/Display_json/displayjson.css";

function DisplayJson({
  Data,
  teleSearch,
  getSearchedData,
  file_id,
  post_no,
  total_db_data,
  telegram_limit,
}) {
  const [copied_clipboard, set_copied_clipboard] = useState(false);
  return (
    <div className="row justify-content-center main__table__json">
      <div className="total_data_number">
        <h6>
          Results shown/found :
          <span>
            {Data.length}/{total_db_data}
          </span>
        </h6>
        <div className="total_data_number_child">
          {(teleSearch === "post" || teleSearch === "file") && (
            <DownloadFileOptions
              getSearchedData={getSearchedData}
              file_id={file_id}
              post_no={post_no}
              search_table_type={"telegram"}
            />
          )}
          <h6>
            Credits remaining :
            <span className="credit_limit_colors">{telegram_limit}</span>
          </h6>
        </div>
      </div>

      {Data !== null ? (
        <div className="col-lg-10 w-100">
          <p
            className="m-b-10 f-w-600"
            style={{
              position: "absolute",
              marginLeft: "37%",
              marginTop: "2%",
              zIndex: 995,
              display: "inline",
              flexShrink: "unset",
              cursor: "pointer",
            }}
            onClick={() => {
              navigator.clipboard.writeText(JSON.stringify(Data, null, 4));
              set_copied_clipboard(true);
            }}
            onMouseEnter={() => {
              const popup = document.getElementById("copy_id");
              popup.style.left = "-191%";
              popup.style.bottom = "134%";
              popup.style.visibility = "visible";
            }}
            onMouseLeave={() => {
              const popup = document.getElementById("copy_id");

              popup.style.visibility = "hidden";
            }}
          >
            <IconContext.Provider
              value={
                copied_clipboard
                  ? { size: 24, color: "#1FCECB" }
                  : { size: 24, color: "#8d8d8d" }
              }
            >
              <FaCopy />
            </IconContext.Provider>
            <span className="popuptext" id="copy_id">
              {copied_clipboard ? "Copied" : "Copy"}{" "}
            </span>
          </p>
          {Data.length > 0 ? (
            <Editor
              height="100vh"
              defaultLanguage="json"
              defaultValue={JSON.stringify(Data, null, 4)}
              options={{
                readOnly: true,
                selectionClipboard: true,
                emptySelectionClipboard: true,
              }}
              theme="vs-dark"
              language="json"
              selectionClipboard={true}
            />
          ) : null}
        </div>
      ) : null}
    </div>
  );
}

DisplayJson.propTypes = {
  Data: PropTypes.array,
  teleSearch: PropTypes.string,
  getSearchedData: PropTypes.string,
  file_id: PropTypes.string,
  post_no: PropTypes.number,
  telegram_limit: PropTypes.number,
  total_db_data: PropTypes.number,
};

export default DisplayJson;

/* eslint-disable no-useless-escape */
export const bank_card_validator = (data) => {
  const re = /[^0-9\|]/g;
  const hits = [];
  let match = null;
  try {
    do {
      match = re.exec(data);
      if (match) {
        hits.push(match[0]);
      }
    } while (match);
  } catch (err) {
    // Do nothing
  }

  if (data.match(/^\/.*\/$/) !== null) {
    return;
  }

  if (hits.length > 0) {
    return "Must contain only number";
  }

  const number_count = /[0-9]{17,}/g;
  if (data.match(number_count)) {
    return "Please enter valid bank card number";
  }
};
export const ip_address_validator = (data) => {
  const re = /[^0-9\.]/g;
  const hits = [];
  let match = null;
  try {
    do {
      match = re.exec(data);
      if (match) {
        hits.push(match[0]);
      }
    } while (match);
  } catch (err) {
    // Do nothing
  }
  if (data.match(/^\/.*\/$/) !== null) {
    return;
  }
  if (hits.length > 0) {
    return "Please enter valid ip address";
  }
};

export const phone_validator = (data) => {
  const re = /[^0-9\+\s]/g;
  const hits = [];
  let match = null;
  try {
    do {
      match = re.exec(data);
      if (match) {
        hits.push(match[0]);
      }
    } while (match);
  } catch (err) {
    // Do nothing
  }
  if (data.match(/^\/.*\/$/) !== null) {
    return;
  }
  if (hits.length > 0) {
    return "Phone number cannot be alphabet";
  }
  const phone_number_valid = /[0-9]{14,}/g;
  if (data.match(phone_number_valid)) {
    return "Phone number cannot exceed 13 digits";
  }
};

export const crypto_validator = (data) => {
  const re = /^(bc1|[1-9]|0x||T|[A-HJ-NP-Za-km-z0-9])[a-zA-HJ-NP-Z0-9]{0,95}$/g;
  if (data.match(/^\/.*\/$/) !== null) {
    return;
  }

  if (data.match(re) === null) {
    return "Please enter valid crypto";
  }
};

export const email_validator = (data) => {
  const re = /[a-zA-Z0-9-_.]+\.*[a-zA-Z0-9-_.]*\.*[a-zA-Z]*/g;

  if (data.match(/^\/.*\/$/) !== null) {
    return;
  }

  if (data.match(re) === null) {
    return "Please enter valid email";
  }
};

export const bin_validator = (data) => {
  const re = /[^0-9\|]/g;
  const hits = [];
  let match = null;
  try {
    do {
      match = re.exec(data);
      if (match) {
        hits.push(match[0]);
      }
    } while (match);
  } catch (err) {
    // Do nothing
  }

  if (data.match(/^\/.*\/$/) !== null) {
    return;
  }

  if (hits.length > 0) {
    return "Must contain only number";
  }
  const new_validator = data.toString();
  if (new_validator.length < 5 || new_validator.length > 7) {
    return "Characters should be numeric and should be at min 5 and at max 7 when using 'bin' filter.";
  }
};

export const domain_validator = (data) => {
  const re =
    /((http.{0,2}?):\/{0,2}\/{0,2})?(www\.)?([a-zA-Z0-9.\-]+)\.([a-zA-Z0-9.\-]){2,3}/g;

  if (data.match(/^\/.*\/$/) !== null) {
    return;
  }

  if (data.match(re) === null) {
    return "Please enter valid domain";
  }
};

export const isValidCountrycode = (input) => {
  const regex = /^[A-Za-z]{2}$/;
  return regex.test(input);
};

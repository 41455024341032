import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import "../../../css/Modal/modal.css";

function LimitReached({
  open_modal_limit,
  set_open_modal_limit,
  delete_bulk_users,
}) {
  return (
    <div>
      <Modal
        open={open_modal_limit}
        onClose={() => set_open_modal_limit(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="container__modal_limit_reached">
          <div className="modal_limit-close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_modal_limit(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>

          <div className="modal_limit_desc">
            <h4>Are you sure?</h4>
            <p>
              Do you really want to delete these records? This process cannot be
              undone.
            </p>
          </div>
          <div className="modal-footer justify-content-center">
            <button
              className="btn btn-secondary"
              onClick={() => set_open_modal_limit(false)}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={() => {
                delete_bulk_users();
                set_open_modal_limit(false);
              }}
            >
              Delete
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
LimitReached.propTypes = {
  open_modal_limit: PropTypes.bool,
  set_open_modal_limit: PropTypes.func,
  delete_bulk_users: PropTypes.func,
};

export default LimitReached;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import {
  FaChartLine,
  FaBell,
  FaTelegramPlane,
  FaSearch,
  FaCloudDownloadAlt,
} from "react-icons/fa";
import "../styles2.css";
import { connect } from "react-redux";
import { user_preference } from "../actions/userAction";

function Usersidenav({ users, user_preference }) {
  useEffect(() => {
    user_preference();
  }, []);

  return (
    <section className={users.dark_theme ? "dk-mode" : ""}>
      <nav id="sidebar" className={users.sidebar_activ ? "active" : null}>
        <div className="sidebar-header">
          <h3 />
          <strong />
        </div>
        <ul className="list-unstyled components">
          <li className="active">
            <Link to="/dashboard">
              <a href="#!">
                <div
                  style={{
                    marginRight: "4px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaTelegramPlane />
                  </IconContext.Provider>
                </div>

                <span style={{ marginLeft: "-3px" }}>Telegram</span>
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/forum">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaSearch />
                  </IconContext.Provider>
                </div>
                Others
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/custom">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaChartLine />
                  </IconContext.Provider>
                </div>
                Pages
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/notification">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaBell />
                  </IconContext.Provider>
                </div>
                Notify
              </a>
            </Link>
          </li>
          <li className="active">
            <Link to="/report">
              <a href="#!">
                <div
                  style={{
                    marginRight: "2px",
                    display: "inline",

                    marginBottom: "5px",
                  }}
                >
                  <IconContext.Provider value={{}}>
                    <FaCloudDownloadAlt />
                  </IconContext.Provider>
                </div>
                Reports
              </a>
            </Link>
          </li>
        </ul>
      </nav>
    </section>
  );
}
Usersidenav.propTypes = {
  users: PropTypes.object,
  user_preference: PropTypes.func,
};

const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps, { user_preference })(Usersidenav);

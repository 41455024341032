import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box } from "@mui/material";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { handle_close_div } from "../company_dashboard/companyglobalfunc";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_error_message } from "../../../utils/ResponseMessage/response_message";
import "../../../css/Modal/modal.css";
import { handle_delete_stats_localstorage } from "../company_dashboard/companyglobalfunc";

function DialogAlert({ open_dialog, set_open_dialog, show_filter_dialog }) {
  const handle_close_dialog = () => {
    set_open_dialog(false);
  };
  return (
    <div>
      <Dialog
        open={open_dialog}
        onClose={handle_close_dialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: "rgba(29, 40, 53, .95)",
            color: "white",
            paddingBottom: "55px",
          }}
        >
          <Stack alignItems="flex-end">
            <CancelIcon
              onClick={handle_close_dialog}
              sx={{ color: "#eb0000 ", cursor: "pointer", m: 0.5 }}
            />
          </Stack>

          <DialogTitle id="alert-dialog-title">
            {show_filter_dialog === true
              ? "To enable this category please contact service provider"
              : "To inquire for this service please contact service provider"}
          </DialogTitle>
        </Box>
      </Dialog>
    </div>
  );
}

function DialogAlertPostNo({
  open_modal_postNo_limit,
  set_open_modal_postNo_limit,
}) {
  const handle_close_dialog = () => {
    set_open_modal_postNo_limit(false);
  };
  return (
    <div>
      <Dialog
        open={open_modal_postNo_limit}
        onClose={handle_close_dialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: "rgba(29, 40, 53, .95)",
            color: "white",
            paddingBottom: "55px",
          }}
        >
          <Stack alignItems="flex-end">
            <CancelIcon
              onClick={handle_close_dialog}
              sx={{ color: "#ddd ", cursor: "pointer", m: 0.5 }}
            />
          </Stack>

          <DialogTitle id="alert-dialog-title">
            Maximum Limit of the Post No Filter is 2000 while searching on Table
            and Graph Display Filters
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "Gray" }}
            >
              Note : You can search upto 10,000 Data on JSON Display Filter
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}

function DialogAlertBreach({ open_modal_limit, set_open_modal_limit }) {
  const handle_close_dialog = () => {
    set_open_modal_limit(false);
  };
  return (
    <div>
      <Dialog
        open={open_modal_limit}
        onClose={handle_close_dialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: "rgba(29, 40, 53, .95)",
            color: "white",
            paddingBottom: "55px",
          }}
        >
          <Stack alignItems="flex-end">
            <CancelIcon
              onClick={handle_close_dialog}
              sx={{ color: "#ddd ", cursor: "pointer", m: 0.5 }}
            />
          </Stack>

          <DialogTitle id="alert-dialog-title">
            Maximum search limit is 5000 while searching on View type Table.
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              sx={{ color: "Gray" }}
            >
              Note : You can search upto 40,000 Data on View type JSON
            </DialogContentText>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}

function LanguageLimit({
  open_modal_lang_limit,
  set_open_modal_lang_limit,
  translation_error_msg,
}) {
  const handle_close_dialog = () => {
    set_open_modal_lang_limit(false);
  };
  const error_message_obj = {
    paid_logical_trans:
      "You can select up to 10 languages when enabling translation on logical search.",
    trial_logical_trans:
      "As a trial customer, You can select up to 2 languages when enabling translation on logical search.",
    trail_trans: "As a trial customer, You can select up to 5 languages.",
  };
  return (
    <div>
      <Dialog
        open={open_modal_lang_limit}
        onClose={handle_close_dialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: "rgba(29, 40, 53, .95)",
            color: "white",
            paddingBottom: "55px",
          }}
        >
          <Stack alignItems="flex-end">
            <CancelIcon
              onClick={handle_close_dialog}
              sx={{ color: "#ddd ", cursor: "pointer", m: 0.5 }}
            />
          </Stack>

          <DialogTitle id="alert-dialog-title">
            {error_message_obj[translation_error_msg]}
          </DialogTitle>
        </Box>
      </Dialog>
    </div>
  );
}

function DeleteConfirmBox({
  open_modal,
  set_open_modal,
  company_pref_id,
  chart_type,
  stats_name,
  tag_name_obj,
  alert_delete_id,
}) {
  const token = handle_session_get("Token");
  const user_name_session = handle_session_get("admin_status");
  const handle_close_dialog = () => {
    set_open_modal(false);
  };
  const handle_delete_user_chart = () => {
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
    const data = {
      pref_type: chart_type,
      qtext:
        chart_type === "product"
          ? stats_name
          : chart_type === "domain" || chart_type === "channel"
          ? stats_name
          : { [tag_name_obj[stats_name]]: stats_name },
    };
    const route = "https://dev.recordedtelegram.com/delete_user_preference";
    axios
      .post(route, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
        handle_close_div(`${chart_type}_${stats_name}_company_stats_chart`);
        handle_delete_stats_localstorage(chart_type, stats_name);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
      });
  };

  const handle_delete_alert_stats = () => {
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
    axios
      .delete(
        `https://dev.recordedtelegram.com/user_dashboard/${alert_delete_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then(() => {
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
        handle_close_div(`${chart_type}_${stats_name}_company_stats_chart`);
        handle_delete_stats_localstorage(chart_type, stats_name);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
      });
  };
  const handle_delete_company_pref = () => {
    document.getElementById("downloadmodal").className = "modal fade show";
    document.getElementById("downloadmodal").style.display = "block";
    const data = { pref_id: company_pref_id };
    axios
      .post("https://dev.recordedtelegram.com/company_preference", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
        handle_delete_stats_localstorage(chart_type, stats_name);
        handle_close_div(`${chart_type}_${stats_name}_company_stats_chart`);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        handle_error_message(err.response.data.errormsg);
        document.getElementById("downloadmodal").className = "modal fade";
        document.getElementById("downloadmodal").style.display = "none";
      });
  };
  return (
    <div>
      <Dialog
        open={open_modal}
        onClose={handle_close_dialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: "rgba(29, 40, 53, .95)",
            color: "white",
            paddingBottom: "55px",
          }}
        >
          <DialogContent>
            <Stack justifyContent="start" sx={{ width: "fit-content" }}>
              {company_pref_id === null ? (
                <span className="delete_warning_chip delete_warning_chip_user">
                  Warning
                </span>
              ) : (
                <span className="delete_warning_chip delete_warning_chip_company">
                  Warning
                </span>
              )}
            </Stack>
            <DialogContentText>
              {company_pref_id === null ? (
                <p className="delete_warning_message">
                  Deleting your personal chart preference
                </p>
              ) : (
                <p className="delete_warning_message">
                  Deleting chart for your entire organization
                </p>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack
              direction="row"
              justifyContent="center"
              sx={{ width: "100%" }}
              gap={2}
            >
              <button
                className="delete_warning_btn delete_warning_btn_cancel"
                onClick={() => set_open_modal(false)}
              >
                Cancel
              </button>
              <button
                className="delete_warning_btn delete_warning_btn_delete"
                onClick={() => {
                  (user_name_session === "company_admin" ||
                    user_name_session === "super_admin") &&
                  company_pref_id !== null
                    ? handle_delete_company_pref()
                    : chart_type !== "alert"
                    ? handle_delete_user_chart()
                    : handle_delete_alert_stats();
                  handle_close_dialog();
                }}
              >
                Delete
              </button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
DialogAlert.propTypes = {
  open_dialog: PropTypes.bool,
  set_open_dialog: PropTypes.func,
  show_filter_dialog: PropTypes.bool,
};
DialogAlertPostNo.propTypes = {
  open_modal_postNo_limit: PropTypes.bool,
  set_open_modal_postNo_limit: PropTypes.func,
};
DialogAlertBreach.propTypes = {
  open_modal_limit: PropTypes.bool,
  set_open_modal_limit: PropTypes.func,
};
LanguageLimit.propTypes = {
  open_modal_lang_limit: PropTypes.bool,
  set_open_modal_lang_limit: PropTypes.func,
  translation_error_msg: PropTypes.string,
};
DeleteConfirmBox.propTypes = {
  open_modal: PropTypes.bool,
  set_open_modal: PropTypes.func,
  company_pref_id: PropTypes.number,
  chart_type: PropTypes.string,
  stats_name: PropTypes.string,
  tag_name_obj: PropTypes.object,
  alert_delete_id: PropTypes.number,
};
export default DialogAlert;
export {
  DialogAlertPostNo,
  DialogAlertBreach,
  LanguageLimit,
  DeleteConfirmBox,
};

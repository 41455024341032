import React, { memo } from "react";
import PropTypes from "prop-types";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Formik } from "formik";
import { handle_determine_graph_or_table_data } from "../../company_dashboard/companyglobalfunc";

function ChartSearchBox({
  set_open_modal,
  chart_stats,
  set_chart_stats,
  stats_name,
  alert_type,
  // alert_id,
  // graph_type,
}) {
  // const [search_query, set_search_query] = useState("")
  const handle_chart_search_data = (all_data, query, chart_filter_type) => {
    const display_mode = chart_stats[stats_name]["display_mode"];
    if (["file", "channel"].includes(chart_filter_type)) {
      const file_filter = all_data.filter(
        (item) =>
          item?.filename.toLowerCase().includes(query.toLowerCase()) === true,
      );
      const channel_filter = all_data.filter(
        (item) =>
          item?.message.toLowerCase().includes(query.toLowerCase()) === true,
      );
      const filter_data =
        chart_filter_type === "file" ? file_filter : channel_filter;
      set_chart_stats((prev) => ({
        ...prev,
        [alert_type]: {
          ...prev[alert_type],
          [stats_name]: {
            ...prev[alert_type][stats_name],
            loading_chart: false,
            error: "",
            data: [...filter_data],
          },
        },
      }));
      return;
    }
    const sanitize_overall_category = {};
    const sanitize_tag_channel = {};
    let filter_table_data = [];
    if (display_mode === "graph") {
      if (chart_filter_type === "keyword") {
        for (const category in all_data) {
          const sanitize_category = all_data[category].map((item) => {
            const sanitize_item = {
              ...item,
              value: item.channel.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  (
                    currentValue.messages.filter((chan_msg) =>
                      new RegExp(query, "i").test(chan_msg.message),
                    ) ?? []
                  ).length,
                0,
              ),
              channel: item.channel.map((chan_item) => {
                const sanitize_channel = {
                  ...chan_item,
                  messages: chan_item.messages.filter((chan_msg) =>
                    new RegExp(query, "i").test(chan_msg.message),
                  ),
                  total_message: (
                    chan_item.messages.filter((chan_msg) =>
                      new RegExp(query, "i").test(chan_msg.message),
                    ) ?? []
                  ).length,
                };
                return sanitize_channel;
              }),
            };
            return sanitize_item;
          });
          sanitize_overall_category[category] = sanitize_category;
        }
      }
      if (chart_filter_type === "tag") {
        for (const category in all_data) {
          const sanitize_category = all_data[category].map((item) => {
            const sanitize_item = {
              ...item,
              value: (
                item.messages.filter((chan_msg) =>
                  new RegExp(query, "i").test(chan_msg.message),
                ) ?? []
              ).length,
              messages: item.messages.filter((chan_msg) =>
                new RegExp(query, "i").test(chan_msg.message),
              ),
            };
            return sanitize_item;
          });
          sanitize_tag_channel[category] = sanitize_category;
        }
      }
    } else {
      if (chart_filter_type === "keyword") {
        filter_table_data = all_data.filter(
          (item) =>
            new RegExp(query, "i").test(item.conv_name) ||
            new RegExp(query, "i").test(item.message),
        );
      }
      if (chart_filter_type === "tag") {
        const handle_row_data = (row_item, row_sub_type) => {
          try {
            if (Array.isArray(row_item)) {
              return row_item[0][row_sub_type];
            }
          } catch {
            return "None";
          }
        };
        filter_table_data = all_data.filter((item) => {
          return [
            handle_row_data(item?.bank_card, "bank_card_number"),
            handle_row_data(item?.email, "email"),
            handle_row_data(item?.ip_address, "ip_address"),
            handle_row_data(item?.crypto, "crypto_adress"),
            handle_row_data(item?.domain, "domain_name"),
            handle_row_data(item?.phone, "phone_number"),
          ].some((val) => val.includes(query));
        });
      }
    }

    const categories_obj =
      display_mode === "table"
        ? filter_table_data
        : chart_filter_type === "keyword"
        ? sanitize_overall_category
        : sanitize_tag_channel;
    if (chart_filter_type === "keyword") {
      const restructuredData = handle_determine_graph_or_table_data(
        categories_obj,
        display_mode,
        "keyword",
      )["sanitize_data"];
      const categories_channel_obj = handle_determine_graph_or_table_data(
        categories_obj,
        display_mode,
        "keyword",
      )["sanitize_channel"];
      set_chart_stats((prev) => ({
        ...prev,
        [alert_type]: {
          ...prev[alert_type],
          [stats_name]: {
            ...prev[alert_type][stats_name],
            loading_chart: false,
            error: "",
            data: restructuredData,
            categories_channel: categories_channel_obj,
          },
        },
      }));
    } else if (chart_filter_type === "tag") {
      const restructuredData = handle_determine_graph_or_table_data(
        categories_obj,
        display_mode,
        "tag",
      )["sanitize_data"];
      const categories_channel_arr = handle_determine_graph_or_table_data(
        categories_obj,
        display_mode,
        "tag",
      )["sanitize_channel"];

      set_chart_stats((prev) => ({
        ...prev,
        [alert_type]: {
          ...prev[alert_type],
          [stats_name]: {
            ...prev[alert_type][stats_name],
            loading_chart: false,
            error: "",
            data: restructuredData,
            categories_channel: categories_channel_arr,
          },
        },
      }));
    }
    if (chart_filter_type === "stealer_logs") {
      const filter_data_stealer = handle_filter_stealer_logs_data(
        all_data,
        query,
      );
      set_chart_stats((prev) => ({
        ...prev,
        [alert_type]: {
          ...prev[alert_type],
          [stats_name]: {
            ...prev[alert_type][stats_name],
            loading_chart: false,
            error: "",
            data: [...filter_data_stealer],
          },
        },
      }));
    }
  };

  const handle_filter_stealer_logs_data = (all_data, query) => {
    try {
      const clone_stealer_logs_data = [...all_data];
      const filter_stealer_data = clone_stealer_logs_data.filter((item) => {
        if (
          item.passwords.some(
            (user_item) =>
              new RegExp(query, "i").test(user_item.url) ||
              new RegExp(query, "i").test(user_item.username) ||
              new RegExp(query, "i").test(user_item.password),
          ) ||
          new RegExp(query, "i").test(item.ip_address) ||
          new RegExp(query, "i").test(item.location) ||
          new RegExp(query, "i").test(item.guid) ||
          new RegExp(query, "i").test(item.hwid) ||
          item.autofill_value.some(
            (user_item) =>
              new RegExp(query, "i").test(user_item.name) ||
              new RegExp(query, "i").test(user_item.value),
          )
        ) {
          return item;
        }
      });
      return filter_stealer_data;
    } catch {
      return all_data;
    }
  };

  return (
    <ClickAwayListener onClickAway={() => set_open_modal(false)}>
      <div className="dashboard_chart_search_input_box">
        <Formik
          initialValues={{ search_value: "" }}
          validate={(values) => {
            const errors = {};
            const { search_value } = values;
            if (search_value.length < 2) {
              errors.search_value =
                "search keyword must be longer than 1 character";
            }

            return errors;
          }}
          onSubmit={(values) => {
            if (
              ["file", "channel", "keyword", "tag", "stealer_logs"].includes(
                alert_type,
              )
            ) {
              handle_chart_search_data(
                chart_stats[stats_name]["all_response_data"],
                values.search_value,
                alert_type,
              );
            }
            return;
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            // errors,
            // touched,
          }) => (
            <form onSubmit={handleSubmit} autoComplete="off">
              <div>
                <div>
                  <input
                    id="srch_tab"
                    type="search"
                    placeholder={
                      alert_type === "file" ? "Enter filename" : "Enter a query"
                    }
                    name="search_value"
                    value={values.search_value}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    className="dashboard_chart_search_input_box_field"
                  />
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </ClickAwayListener>
  );
}
ChartSearchBox.propTypes = {
  set_open_modal: PropTypes.func,
  chart_type: PropTypes.string,
  chart_stats: PropTypes.object,
  set_chart_stats: PropTypes.func,
  stats_name: PropTypes.string,
  alert_type: PropTypes.string,
  alert_id: PropTypes.number,
  graph_type: PropTypes.string,
};

export default memo(ChartSearchBox);

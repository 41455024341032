/* eslint-disable no-useless-escape */
import axios from "axios";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import {
  handle_error_message,
  handle_success_message,
  response_message_custom,
} from "../../../utils/ResponseMessage/response_message";
import fileDownload from "js-file-download";
import { handle_check_category_length } from "../../../Assets/globalfunctions";

export const handle_maximize_div = (id_parent, id_chart) => {
  const get_div = document.getElementById(id_parent);
  get_div.className = "company_stats_chart_child";
  const get_child_div = document.getElementById(id_chart);
  get_child_div.className = "comp_stats_chart_maximize";
  const get_btn_min = document.getElementById(`${id_parent}_min_btn`);
  get_btn_min.style.display = "block";
  const get_btn_max = document.getElementById(`${id_parent}_max_btn`);
  get_btn_max.style.display = "none";
};

export const handle_minimize_div = (id_parent, id_chart) => {
  const get_div = document.getElementById(id_parent);
  get_div.className = "company_stats_chart_child_div";
  const get_child_div = document.getElementById(id_chart);
  get_child_div.className = "comp_stats_chart";
  const get_btn_min = document.getElementById(`${id_parent}_min_btn`);
  get_btn_min.style.display = "none";
  const get_btn_max = document.getElementById(`${id_parent}_max_btn`);
  get_btn_max.style.display = "block";
};

export const handle_close_div = (id) => {
  const get_div = document.getElementById(id);
  let opacity = 1;
  const fadeOut = () => {
    opacity -= 0.05;
    get_div.style.opacity = opacity.toFixed(2);

    if (opacity > 0) {
      requestAnimationFrame(fadeOut);
    } else {
      get_div.style.display = "none";
      get_div.style.opacity = 1;
    }
  };
  requestAnimationFrame(fadeOut);
};

export const handle_show_div = (id) => {
  const get_div = document.getElementById(id);
  let opacity = 0;

  // Ensure the div is displayed and prepare for animation
  get_div.style.display = "block";
  get_div.style.opacity = opacity;

  const fadeIn = () => {
    opacity += 0.05;
    get_div.style.opacity = opacity.toFixed(2);

    if (opacity < 1) {
      requestAnimationFrame(fadeIn);
    }
  };

  requestAnimationFrame(fadeIn);
};
export const handle_determine_maximize_minimize_chart = (
  categories,
  container_type,
  chart_type,
  chart_item,
) => {
  try {
    const user_name = localStorage.getItem("User");
    const get_localstorage_product_obj =
      localStorage.getItem("store_dashboard_stats") &&
      JSON.parse(localStorage.getItem("store_dashboard_stats"));
    if (get_localstorage_product_obj) {
      if (
        get_localstorage_product_obj?.["dashboard_stats"]?.[user_name]?.[
          chart_type
        ]?.[chart_item] !== undefined
      ) {
        const get_stats =
          get_localstorage_product_obj["dashboard_stats"][user_name][
            chart_type
          ][chart_item];
        if (container_type === "parent") {
          return get_stats === "max"
            ? "company_stats_chart_child"
            : "company_stats_chart_child_div";
        }
        return get_stats === "max"
          ? "comp_stats_chart_maximize"
          : "comp_stats_chart";
      }
    }
    // const categories_keys = Object.keys(categories[0]);
    // const x_axis_len = categories.length;
    // if (categories_keys.length >= 20 || x_axis_len >= 20) {
    //   if (container_type === "parent") {
    //     return "company_stats_chart_child";
    //   }
    //   return "comp_stats_chart_maximize";
    // }
    if (container_type === "parent") {
      return "company_stats_chart_child_div";
    }
    return "comp_stats_chart";
  } catch {
    if (container_type === "parent") {
      return "company_stats_chart_child_div";
    }
    return "comp_stats_chart";
  }
};

export const handle_show_maximize_minimize_btn = (
  categories,
  chart_type,
  chart_item,
) => {
  try {
    const user_name = localStorage.getItem("User");
    const get_localstorage_product_obj =
      localStorage.getItem("store_dashboard_stats") &&
      JSON.parse(localStorage.getItem("store_dashboard_stats"));
    if (get_localstorage_product_obj) {
      if (
        get_localstorage_product_obj?.["dashboard_stats"]?.[user_name]?.[
          chart_type
        ]?.[chart_item] !== undefined
      ) {
        const get_stats =
          get_localstorage_product_obj["dashboard_stats"][user_name][
            chart_type
          ][chart_item];
        return get_stats === "max" ? "minimize" : "maximize";
      }
    }
    // const categories_keys = Object.keys(categories[0]);
    // const x_axis_len = categories.length;
    // if (x_axis_len >= 20) return "minimize";
    // if (categories_keys.length >= 12) {
    //   return "minimize";
    // }
    return "maximize";
  } catch {
    return "maximize";
  }
};

export const handle_detect_company_pref_id = (
  chart_name,
  chart,
  company_pref_data,
  set_company_pref_id,
) => {
  try {
    const filter_company_pref = company_pref_data.filter((item) => {
      if (chart === "product" && item.preference_type === "product") {
        return chart_name === item.preference_value;
      }
      if (chart === "domain" && item.preference_type === "domain") {
        return chart_name === item.preference_value;
      }
      if (chart === "channel" && item.preference_type === "channel") {
        return chart_name === item.preference_value;
      }
      return chart_name === item.preference_value;
    });
    set_company_pref_id(filter_company_pref[0].id);
    return true;
  } catch {
    return true;
  }
};

export function compareDates(date1, date2) {
  const dateString = date1;
  // Split the date string into its components

  try {
    const [year, month, day] = dateString.split("-");
    if (year && month && day) {
      const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
      const oneWeek = 7 * oneDay; // One week in milliseconds
      const date1Obj = new Date(date1);
      const date2Obj = new Date(date2);
      const difference = Math.abs(date2Obj - date1Obj);
      if (difference === oneDay) {
        return "Daily";
      }
      if (difference === oneWeek) {
        return "Weekly";
      }
      return "Daily";
    }
    if (year && month) {
      return "Monthly";
    }
    return "Hourly";
  } catch {
    return "Daily";
  }
}

export const handle_time_format = (date) => {
  if (date.includes("T")) {
    const dateTime = new Date(date);
    const hours = dateTime.getHours();
    const minutes = dateTime.getMinutes();
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }
  return date;
};

export const store_stats_localstorage = (
  chart_type,
  chart_item,
  chart_div_status,
) => {
  const user_name = localStorage.getItem("User");
  try {
    const get_localstorage =
      localStorage.getItem("store_dashboard_stats") &&
      JSON.parse(localStorage.getItem("store_dashboard_stats"));
    if (get_localstorage) {
      const get_localstorage_dashboard_stats = get_localstorage.dashboard_stats;
      if (
        Object.prototype.hasOwnProperty.call(
          get_localstorage_dashboard_stats,
          user_name,
        )
      ) {
        const clone_obj = get_localstorage_dashboard_stats[user_name];
        if (Object.prototype.hasOwnProperty.call(clone_obj, chart_type)) {
          const filter_clone_obj = clone_obj[chart_type];
          get_localstorage_dashboard_stats[user_name][chart_type] = {
            ...filter_clone_obj,
            [chart_item]: chart_div_status,
          };
        } else {
          get_localstorage_dashboard_stats[user_name] = {
            ...clone_obj,
            [chart_type]: { [chart_item]: chart_div_status },
          };
        }
      } else {
        get_localstorage_dashboard_stats[user_name] = {
          [chart_type]: { [chart_item]: chart_div_status },
        };
      }
      const store_obj = {
        dashboard_stats: { ...get_localstorage_dashboard_stats },
      };
      localStorage.setItem(`store_dashboard_stats`, JSON.stringify(store_obj));
    } else {
      const store_obj = {
        dashboard_stats: {
          [user_name]: {
            [chart_type]: {
              [chart_item]: chart_div_status,
            },
          },
        },
      };
      localStorage.setItem(`store_dashboard_stats`, JSON.stringify(store_obj));
    }
  } catch {
    const store_obj = {
      dashboard_stats: {
        [user_name]: {
          [chart_type]: {
            [chart_item]: chart_div_status,
          },
        },
      },
    };
    localStorage.setItem(`store_dashboard_stats`, JSON.stringify(store_obj));
  }
};

export const handle_delete_stats_localstorage = (chart_type, chart_item) => {
  const user_name = localStorage.getItem("User");
  try {
    const get_localstorage_product_obj =
      localStorage.getItem("store_dashboard_stats") &&
      JSON.parse(localStorage.getItem("store_dashboard_stats"));
    if (get_localstorage_product_obj) {
      if (
        get_localstorage_product_obj?.["dashboard_stats"]?.[user_name]?.[
          chart_type
        ]?.[chart_item] !== undefined
      ) {
        delete get_localstorage_product_obj["dashboard_stats"][user_name][
          chart_type
        ][chart_item];
        setTimeout(() => {
          localStorage.setItem(
            `store_dashboard_stats`,
            JSON.stringify(get_localstorage_product_obj),
          );
        });
      }
    }
  } catch {
    // Do nothing
  }
};

export const file_download = (id, file_type, getSearchedData) => {
  let token = localStorage.getItem("Token");
  const data = { file_hash: id, file_type: file_type };
  try {
    axios
      .post("https://dev.recordedtelegram.com/stealer_logs_download", data, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const new_file_name = `${getSearchedData}_${new Date()}.${file_type}`;
        fileDownload(res.data, new_file_name);
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(
          err.response.data,
          err.response.status,
        );
        if (token_expire_status === true) return;
        let errorMessage = "";
        const reader = new FileReader();
        reader.onload = () => {
          errorMessage = reader.result;
          handle_error_message(JSON.parse(errorMessage).errormsg);
        };
        reader.readAsText(err.response.data);
      });
  } catch (err) {
    handle_error_message("Sorry could not download file");
  }
};

export const handle_determine_graph_or_table_data = (
  chart_data,
  display_type,
  chart_filter_type,
) => {
  if (display_type === "graph") {
    const categories_obj = chart_data;
    if (chart_filter_type === "keyword") {
      const restructuredData = (
        Object.keys(categories_obj).length > 0
          ? categories_obj[Object.keys(categories_obj)[0]]
          : []
      ).map((item, index) => {
        const newObj = {
          name: handle_time_format(item.date),
        };

        for (const val in categories_obj) {
          newObj[val] = categories_obj[val][index].value;
        }
        return newObj;
      });

      const categories_channel_obj = {};
      for (const val in categories_obj) {
        categories_channel_obj[val] = categories_obj[val].map(
          (item) => item.channel,
        );
      }
      return {
        sanitize_data: restructuredData,
        sanitize_channel: categories_channel_obj,
      };
      // set_chart_stats((prev) => ({
      //   ...prev,
      //   [alert_type]: {
      //     ...prev[alert_type],
      //     [stats_name]: {
      //       ...prev[alert_type][stats_name],
      //       loading_chart: false,
      //       error: "",
      //       data: restructuredData,
      //       categories_channel: categories_channel_obj,
      //     },
      //   },
      // }));
    } else if (chart_filter_type === "tag") {
      const filter_array =
        Object.keys(categories_obj).length > 0
          ? categories_obj[Object.keys(categories_obj)[0]]
          : [];
      const restructuredData = filter_array.map((item, index) => {
        const newObj = {
          name: handle_time_format(item.date),
        };
        for (const val in categories_obj) {
          newObj[val] = categories_obj[val][index].value;
        }
        return newObj;
      });

      const categories_channel_arr = filter_array.map((item, index) => {
        const newObj = {};
        for (const val in categories_obj) {
          newObj[val] = {
            channel_name: val,
            total_messages: categories_obj[val][index].messages.length,
            messages: categories_obj[val][index].messages,
            date: item.date,
          };
        }
        return newObj;
      });
      return {
        sanitize_data: restructuredData,
        sanitize_channel: categories_channel_arr,
      };
      // set_chart_stats((prev) => ({
      //   ...prev,
      //   [alert_type]: {
      //     ...prev[alert_type],
      //     [stats_name]: {
      //       ...prev[alert_type][stats_name],
      //       loading_chart: false,
      //       error: "",
      //       data: restructuredData,
      //       categories_channel: categories_channel_arr,
      //     },
      //   },
      // }));
    }
  } else {
    return {
      sanitize_data: chart_data,
      sanitize_channel: [],
    };
  }
};

export const handle_static_chart_data_tag = () => {
  try {
    const daysArray = [];
    const today = new Date();

    for (let i = 0; i < 7; i++) {
      const date = new Date();
      date.setDate(today.getDate() - i); // Subtract i days from today
      const formattedDate = date.toISOString().split("T")[0]; // Format the date as YYYY-MM-DD

      daysArray.push({ name: formattedDate, Base: 0 });
    }

    return daysArray;
  } catch {
    //Do nothing
  }
};

export const get_telegram_search_data = (
  searchedData,
  set_data,
  search_mode,
  filters_obj,
) => {
  let token = localStorage.getItem("Token");
  let isSubmitBlocked = false;
  set_data((prev) => ({ ...prev, loading: true }));
  const data_keyword = {
    api_mode: 1,
    qtext: searchedData,
    max: 10,
    multimedia_option: "disabled",
    search_filter:
      search_mode === "filters" ? filters_obj["keyword"] : "contains",
    search_type:
      search_mode === "filters"
        ? filters_obj["post_search_sub"]
        : "Channel/Group",
    selectCategory:
      search_mode === "filters"
        ? handle_check_category_length(filters_obj["postCategory"]) === true
          ? "all"
          : filters_obj["postCategory"]
        : "all",
    start_date: "None",
    end_date: "None",
    sort_order: "desc",
    select_field: "None",
    search_after_id: null,
    fuzzing: 0,
    spam_filter: null,
    proximity: null,
  };
  if (!isSubmitBlocked) {
    isSubmitBlocked = true;
    axios
      .post("https://dev.recordedtelegram.com/v2/posts", data_keyword, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        set_data((prev) => ({
          ...prev,
          loading: false,
          data: res.data.data,
          file_id: res.data.file_id,
          total_data: res.data.total_db_data,
          rate_limit: res.data.ratelimit,
          error: "",
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_data((prev) => ({
          ...prev,
          loading: false,
          data: [],
          error: err.response.data.errormsg,
        }));
      });
  }
};
export const get_tag_search_data = (
  searchedData,
  set_data,
  card_filters = "email",
  search_mode,
  filters_obj,
) => {
  let token = localStorage.getItem("Token");
  let isSubmitBlocked = false;
  set_data((prev) => ({ ...prev, loading: true }));
  const data = {
    search_mode: card_filters,
    qtext: searchedData,
    expiry_year:
      card_filters === "bank_card" || card_filters === "bin"
        ? search_mode === "filters"
          ? filters_obj["exp_year"]
          : "all"
        : null,
    expiry_month:
      card_filters === "bank_card" || card_filters === "bin"
        ? search_mode === "filters"
          ? filters_obj["exp_month"]
          : "all"
        : null,
    bank_card_type:
      card_filters === "bank_card" || card_filters === "bin"
        ? search_mode === "filters"
          ? filters_obj["card_type"]
          : "all"
        : null,
    email_provider:
      card_filters === "email"
        ? search_mode === "filters"
          ? filters_obj["email_provider"]
          : "all"
        : null,
    crypto_type:
      card_filters === "crypto"
        ? search_mode === "filters"
          ? filters_obj["crypto_provider"]
          : "all"
        : null,
    start_date: "None",
    end_date: "None",
    max_result: 10,
    search_after_id: null,
    source: "telegram",
    search_type: "default",
    selectCategory:
      search_mode === "filters"
        ? handle_check_category_length(filters_obj["postCategory"]) === true
          ? "all"
          : filters_obj["postCategory"]
        : "all",
  };
  if (!isSubmitBlocked) {
    isSubmitBlocked = true;
    axios
      .post("https://dev.recordedtelegram.com/v2/tag_search", data, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        set_data((prev) => ({
          ...prev,
          loading: false,
          data: res.data.data,
          rate_limit: res.data.ratelimit,
          file_id: res.data.file_id,
          total_data: res.data.total_data,
          error: "",
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_data((prev) => ({
          ...prev,
          loading: false,
          data: [],
          error: err.response.data.errormsg,
        }));
      });
  }
};
export const get_stealerlogs_search_data = (
  searchedData,
  set_data,
  stealer_filters = "email",
  search_mode,
  filters_obj,
) => {
  let token = localStorage.getItem("Token");
  let isSubmitBlocked = false;
  set_data((prev) => ({ ...prev, loading: true }));

  const data = {
    qtext: searchedData,
    search_filter: stealer_filters,
    search_type: "contains",
    max_result: 10,
    search_after_id: null,
    start_date: "None",
    end_date: "None",
    log_date_filter: false,
    compress: true,
    category: null,
    country_code: "all",
  };
  const filter_stealer_multiple_obj = {};
  if (search_mode === "filters") {
    const stealer_multiple_obj = filters_obj["stealer_multiple_obj"];
    for (const key in stealer_multiple_obj) {
      if (Object.prototype.hasOwnProperty.call(stealer_multiple_obj, key)) {
        filter_stealer_multiple_obj[key] =
          stealer_multiple_obj[key].length === 0
            ? null
            : stealer_multiple_obj[key];
      }
    }
  }

  const data_multiple_search = {
    ...filter_stealer_multiple_obj,
    api_mode: 2,
    search_type: "contains",
    max_result: 10,
    search_after_id: null,
    start_date: "None",
    end_date: "None",
    log_date_filter: false,
    compress: true,
    category:
      search_mode === "filters"
        ? filters_obj["category_stealer"] !== "off"
          ? filters_obj["category_stealer"]
          : null
        : null,
  };
  if (!isSubmitBlocked) {
    isSubmitBlocked = true;
    axios
      .post(
        "https://dev.recordedtelegram.com/stealer_logs_search",
        search_mode === "search" ? data : data_multiple_search,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        set_data((prev) => ({
          ...prev,
          loading: false,
          data: res.data.data,
          total_data: res.data.total_db_data,
          rate_limit: res.data.ratelimit,
          error: "",
        }));
      })
      .catch((err) => {
        const token_expire_status = handle_token_expire(err.response.data);
        if (token_expire_status === true) return;
        set_data((prev) => ({
          ...prev,
          loading: false,
          data: [],
          error: err.response.data.errormsg,
        }));
      });
  }
};

export const handle_determine_dashboard_tag_search_subtype = (data) => {
  // Bank card validation
  if (/^[0-9]{2,16}$/.test(data)) {
    return "bank_card";
  }

  // IP address validation
  if (/^[0-9\.]/.test(data)) {
    return "ip_address";
  }

  // Phone number validation
  if (/^[0-9\+\s]{1,13}$/.test(data)) {
    return "phone";
  }

  // Crypto address validation
  if (
    /^(bc1|[1-9]|0x|T|[A-HJ-NP-Za-km-z0-9])[a-zA-HJ-NP-Z0-9]{0,95}$/.test(data)
  ) {
    return "crypto";
  }

  // Email validation
  if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data)) {
    return "email";
  }

  // BIN validation
  if (/^[0-9]{5,7}$/.test(data)) {
    return "bin";
  }

  // Domain validation
  if (
    /^((http.{0,2}?:\/{0,2})?(www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}))$/.test(
      data,
    )
  ) {
    return "domain";
  }

  return "email";
};

export const handle_add_query_to_dashboard = (
  val,
  notification_type,
  set_loading,
  handle_dashboard_access_version_2,
  notification_filters_tag,
) => {
  let isSubmitBlocked = false;
  const clone_loading =
    notification_type === "keyword"
      ? "keyword_loading"
      : notification_type === "tag"
      ? "tag_loading"
      : "stealer_logs_loading";
  set_loading((prev) => ({ ...prev, [clone_loading]: true }));
  let token = localStorage.getItem("Token");
  const data = {
    selectCategory: "all",
    value: val,
    dashboard_type: "keyword",
    dashboard_filter: "default",
    dashboard_preference: "user",
    is_enabled: true,
    telegram_obj: {
      field_filter: null,
      spam: false,
      search_type: "contains",
      search_filter: "Channel/Group",
    },
  };
  const data_stealer = {
    value: val,
    dashboard_type: "stealer log",
    dashboard_filter: "default",
    dashboard_preference: "user",
    is_enabled: true,
    search_type: "contains",
    search_mode: "email",
    stealer_obj: {
      search_type: "contains",
      stealer_category: null,
    },
  };
  const data_tag = {
    selectCategory: "all",
    value: val,
    dashboard_type: "tag",
    dashboard_filter: "default",
    dashboard_preference: "user",
    search_mode:notification_filters_tag,
    is_enabled: true,
    tag_obj: {
      exp_year:
        notification_filters_tag === "bank_card" ||
        notification_filters_tag === "bin"
          ? "all"
          : null,
      exp_month:
        notification_filters_tag === "bank_card" ||
        notification_filters_tag === "bin"
          ? "all"
          : null,
      bank_card_type:
        notification_filters_tag === "bank_card" ||
        notification_filters_tag === "bin"
          ? "all"
          : null,
      email_type: notification_filters_tag === "email" ? "all" : null,
      crypto_type: notification_filters_tag === "crypto" ? "all" : null,
    },
  };
  let sanitize_data = {};
  if (notification_type === "tag") {
    sanitize_data = { ...data_tag };
  } else if (notification_type === "stealer_logs") {
    sanitize_data = { ...data_stealer };
  } else {
    sanitize_data = { ...data };
  }
  if (!isSubmitBlocked) {
    isSubmitBlocked = true;
    setTimeout(() => {
      axios
        .post(
          "https://dev.recordedtelegram.com/user_dashboard",
          sanitize_data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        )
        .then((res) => {
          handle_success_message(res.data.message ?? "Dashboard added successfully");
          set_loading({
            keyword_loading: false,
            stealer_logs_loading: false,
            tag_loading: false,
          });
          handle_dashboard_access_version_2(res.data.data);
          isSubmitBlocked = false;
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(err.response.data);
          if (token_expire_status === true) return;
          set_loading({
            keyword_loading: false,
            stealer_logs_loading: false,
            tag_loading: false,
          });
          response_message_custom(err.response.data.errormsg, "error");
          isSubmitBlocked = false;
        });
    }, 4000);
  }
};

export  const handle_set_edit_value_stealer_log= (query_name,chart_type,all_details)=>{
if(chart_type==="stealer log"){
  if(all_details.is_empty ===true){
    return ""
  }else if(all_details.is_moded===true){
    if(query_name.includes(":")){
      const split_name = query_name.split(":")
      const get_query = split_name[1]
      return get_query ?? query_name
    }
  }
  return query_name
}else{
  return query_name
}
}
import React, { useState } from "react";
import PropTypes from "prop-types";
import { date_fm } from "../../../../Assets/globalfunctions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { participants } from "../../../../Assets/globalfunctions";
import ModalContainer from "../../modalContainer/ModalContainer";
import TagSearchMultiLine from "../../modalContainer/TagSearchMultiLine";
import Highlighter from "react-highlight-words";
import { filter_logical_keyword } from "../../../../utils/Globals/global_functions";
import { check_filename } from "../../../../Assets/globalfunctions";
import { rename_all_categories_name } from "../../../../utils/Globals/global_functions";
import { Stack } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

function TableDashboard({ table_data, stats_name, graph_type }) {
  const [open_modal, set_open_modal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const channel_type = (typ) => {
    if (typ !== undefined) {
      const channel_type = typ === "True" ? "Group" : "Channel";
      return channel_type;
    }
    return null;
  };
  // Highlight Name
  const highlight_message = (words) => {
    if (graph_type === "logical") {
      try {
        let required_string = "";
        const filter_array = filter_logical_keyword(stats_name);
        filter_array.map((item) => {
          const result = words.toUpperCase().indexOf(item.toUpperCase());
          if (result >= 0) {
            required_string += `${words.slice(
              result >= 20 ? result - 15 : 0,
              result + 50,
            )}  ...`;
          }
          return null;
        });

        return required_string.length === 0 ? words : required_string;
      } catch {
        return words;
      }
    }

    if (words) {
      try {
        const result = words.toUpperCase().indexOf(stats_name.toUpperCase());

        if (result <= 85) {
          return words;
        }
        return words.slice(result - 10, words.length - 1);
      } catch {
        return words;
      }
    } else {
      return "";
    }
  };
  const file_link_adder = (links, msgid) => {
    if (links !== null && links !== "None") {
      if (links.includes("t.me")) {
        const new_link = links.replace("s/", "");
        const open_link = `${new_link}/${msgid}`;

        window.open(
          open_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      } else {
        const new_link = `https://t.me/${links}/${msgid}`;
        window.open(
          new_link,
          "_blank",
          "location=yes,height=570,width=520,scrollbars=yes,status=yes",
        );
      }
    }
  };
  return (
    <>
      <div className="alert_chart_file_box scroll-custom">
        <table className="alert_chart_table_mode alert_chart_keyword_table">
          <thead>
            <tr>
              <th style={{ width: "20%" }}>Name</th>
              <th style={{ width: "59%" }}>Messages</th>
              <th style={{ width: "16%" }}>Date</th>
              <th style={{ width: "5%" }}></th>
            </tr>
          </thead>
          <tbody>
            {table_data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.conv_name}</td>
                  <td className="chart_keyword_table_message_col">
                    {!item.message ? (
                      <button
                        className="filter_file_link"
                        disabled={!(item.link !== null && item.link !== "None")}
                        onClick={() => file_link_adder(item.link, item.msgid)}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          columnGap={0.5}
                        >
                          <InsertDriveFileIcon fontSize="small" />
                          <span className="link_filename_modal">
                            {check_filename(item.filename, "filename") ===
                            true ? (
                              <Highlighter
                                highlightClassName="highlightclass"
                                searchWords={
                                  graph_type === "logical"
                                    ? filter_logical_keyword(stats_name)
                                    : [stats_name]
                                }
                                autoEscape={graph_type !== "regex"}
                                textToHighlight={highlight_message(
                                  item.filename,
                                )}
                              />
                            ) : (
                              "No File Name"
                            )}
                            {check_filename(item.filename, "fileext") === true
                              ? `(${item.fileext})`
                              : null}
                          </span>
                        </Stack>
                      </button>
                    ) : (
                      <Highlighter
                        highlightClassName="highlightclass"
                        searchWords={
                          graph_type === "logical"
                            ? filter_logical_keyword(stats_name)
                            : [stats_name]
                        }
                        autoEscape={graph_type !== "regex"}
                        textToHighlight={highlight_message(item.message)}
                      />
                    )}
                  </td>
                  <td>{date_fm(item.date)}</td>
                  <td>
                    {" "}
                    <VisibilityIcon
                      onClick={() => {
                        set_open_modal(true);
                        setSelectedRow({
                          ...item,
                          id: index,
                          to_id: item.to_id,
                          name: item.conv_name,
                          link: item.link,
                          date: date_fm(item.date),
                          subscribers: participants(item.participants_count)
                            .subs,
                          user_id: item.id,
                          message: item.message,
                          views: item.views ? item.views : 0,
                          type: channel_type(item.is_group),
                          fileextension: item.fileext,
                          filename: item.filename
                            ? item.filename.replace(
                                "SIZE_EXCEEDED_NOT_DOWNLOADED_",
                                "",
                              )
                            : null,
                        });
                      }}
                      fontSize="small"
                      style={{ color: "#42afb1", cursor: "pointer" }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {open_modal === true && (
        <ModalContainer
          openModal={open_modal}
          setOpenModal={set_open_modal}
          selectedRow={selectedRow}
          set_telegram_limit="disable_limit_func"
        />
      )}
    </>
  );
}
TableDashboard.propTypes = {
  table_data: PropTypes.array,
  stats_name: PropTypes.string,
  graph_type: PropTypes.string,
};

export default TableDashboard;

function TableTagDashboard({ table_data, tag_sub_type, stats_name }) {
  const [open_modal_tag, set_open_modal_tag] = useState(false);
  const [selected_row, set_selected_row] = useState();
  const handle_row_data = (row_item, row_sub_type) => {
    try {
      if (Array.isArray(row_item)) {
        return row_item[0][row_sub_type];
      }
    } catch {
      return "None";
    }
  };
  const regex_validator_tag = (txt) => {
    if (txt.match(/^\/.*\/$/) !== null) {
      return true;
    }
    return false;
  };
  const regex_verifier = (txt) => {
    try {
      if (txt.match(/^\/.*\/$/) !== null) {
        const first_filter = txt.replace(/^\//, "");
        const last_filter = first_filter.replace(/\/$/, "");
        return last_filter;
      }
      return txt;
    } catch {
      return txt;
    }
  };
  return (
    <>
      <div className="alert_chart_file_box scroll-custom">
        <table className="alert_chart_table_mode alert_chart_tag_table">
          <thead>
            <tr>
              {tag_sub_type === "bank_card" || tag_sub_type === "bin" ? (
                <th>Card No</th>
              ) : null}
              {tag_sub_type === "bin" ? <th>Bin</th> : null}
              {tag_sub_type === "bank_card" || tag_sub_type === "bin" ? (
                <th>Type</th>
              ) : null}
              {tag_sub_type === "email" ? <th>email</th> : null}
              {tag_sub_type === "ip_address" ? <th>IP</th> : null}
              {tag_sub_type === "crypto" ? <th>Crypto address</th> : null}
              {tag_sub_type === "crypto" ? <th>Type</th> : null}
              {tag_sub_type === "domain" ? <th>Domain</th> : null}
              {tag_sub_type === "phone" ? <th>Phone</th> : null}
              <th>Channel</th>
              <th>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {table_data.map((item, index) => {
              return (
                <tr key={index}>
                  {tag_sub_type === "bank_card" || tag_sub_type === "bin" ? (
                    <td>
                      {tag_sub_type === "bank_card" ? (
                        <Highlighter
                          highlightClassName="highlightclass"
                          searchWords={[regex_verifier(stats_name)]}
                          autoEscape={regex_validator_tag(stats_name) !== true}
                          textToHighlight={handle_row_data(
                            item?.bank_card,
                            "bank_card_number",
                          )}
                        />
                      ) : (
                        handle_row_data(item?.bank_card, "bank_card_number")
                      )}
                    </td>
                  ) : null}
                  {tag_sub_type === "bin" ? (
                    <td>
                      <Highlighter
                        highlightClassName="highlightclass"
                        searchWords={[regex_verifier(stats_name)]}
                        autoEscape={regex_validator_tag(stats_name) !== true}
                        textToHighlight={handle_row_data(
                          item?.bank_card,
                          "bin",
                        )}
                      />
                    </td>
                  ) : null}
                  {tag_sub_type === "bank_card" || tag_sub_type === "bin" ? (
                    <td>
                      {handle_row_data(item?.bank_card, "bank_card_type")}
                    </td>
                  ) : null}
                  {tag_sub_type === "email" ? (
                    <td>
                      <Highlighter
                        highlightClassName="highlightclass"
                        searchWords={[regex_verifier(stats_name)]}
                        autoEscape={regex_validator_tag(stats_name) !== true}
                        textToHighlight={handle_row_data(item?.email, "email")}
                      />
                    </td>
                  ) : null}
                  {tag_sub_type === "ip_address" ? (
                    <td>
                      <Highlighter
                        highlightClassName="highlightclass"
                        searchWords={[regex_verifier(stats_name)]}
                        autoEscape={regex_validator_tag(stats_name) !== true}
                        textToHighlight={handle_row_data(
                          item?.ip_address,
                          "ip_address",
                        )}
                      />
                    </td>
                  ) : null}
                  {tag_sub_type === "crypto" ? (
                    <td>
                      <Highlighter
                        highlightClassName="highlightclass"
                        searchWords={[regex_verifier(stats_name)]}
                        autoEscape={regex_validator_tag(stats_name) !== true}
                        textToHighlight={handle_row_data(
                          item?.crypto,
                          "crypto_adress",
                        )}
                      />
                    </td>
                  ) : null}
                  {tag_sub_type === "crypto" ? (
                    <td>{handle_row_data(item?.crypto, "crypto_type")}</td>
                  ) : null}
                  {tag_sub_type === "domain" ? (
                    <td>
                      <Highlighter
                        highlightClassName="highlightclass"
                        searchWords={[regex_verifier(stats_name)]}
                        autoEscape={regex_validator_tag(stats_name) !== true}
                        textToHighlight={handle_row_data(
                          item?.domain,
                          "domain_name",
                        )}
                      />
                    </td>
                  ) : null}
                  {tag_sub_type === "phone" ? (
                    <td>
                      <Highlighter
                        highlightClassName="highlightclass"
                        searchWords={[regex_verifier(stats_name)]}
                        autoEscape={regex_validator_tag(stats_name) !== true}
                        textToHighlight={handle_row_data(
                          item?.phone,
                          "phone_number",
                        )}
                      />
                    </td>
                  ) : null}
                  <td>{item.post_channel}</td>
                  <td>{date_fm(item.date)}</td>
                  <td>
                    {" "}
                    <VisibilityIcon
                      onClick={() => {
                        set_open_modal_tag(true);
                        set_selected_row({
                          id: index,
                          bank_card_no:
                            item.bank_card.length !== 0 ? item.bank_card : null,
                          bin:
                            item.bank_card.length !== 0 ? item.bank_card : null,
                          exp_year:
                            item.bank_card.length !== 0 ? item.bank_card : null,
                          exp_month:
                            item.bank_card.length !== 0 ? item.bank_card : null,
                          type:
                            item.bank_card.length !== 0 ? item.bank_card : null,
                          cvv_no:
                            item.bank_card.length !== 0 ? item.bank_card : null,
                          ip_address:
                            item.ip_address.length !== 0
                              ? item.ip_address
                              : null,
                          domain: item.domain.length !== 0 ? item.domain : null,
                          country_code:
                            item.phone.length !== 0 ? item.phone : null,
                          phone_no: item.phone.length !== 0 ? item.phone : null,
                          email: item.email.length !== 0 ? item.email : null,
                          crypto: item.crypto.length !== 0 ? item.crypto : null,
                          crypto_type:
                            item.crypto.length !== 0 ? item.crypto : null,

                          source: item.data_source,
                          user: item.post_user ? item.post_user : "None",
                          channel_name: item.post_channel
                            ? item.post_channel
                            : "None",
                          date: item.date,
                          tag_category: item.category
                            ? item.category.length > 0
                              ? rename_all_categories_name(item.category)
                              : "None"
                            : "None",
                          all_data: item,
                        });
                      }}
                      fontSize="small"
                      style={{ color: "#42afb1", cursor: "pointer" }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {open_modal_tag === true ? (
        <TagSearchMultiLine
          open_modal={open_modal_tag}
          set_open_modal={set_open_modal_tag}
          selected_row={selected_row}
          template_card_filters={tag_sub_type}
        />
      ) : null}
    </>
  );
}
TableTagDashboard.propTypes = {
  table_data: PropTypes.array,
  tag_sub_type: PropTypes.string,
  stats_name: PropTypes.string,
};

export { TableTagDashboard };

import React, { useRef, memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Tooltip from "@mui/material/Tooltip";

import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { RoundAnimationLoader } from "../loader/Loader";
import fileDownload from "js-file-download";
import { ToastContainer } from "react-toastify";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { handle_error_message } from "../../../utils/ResponseMessage/response_message";
import { date_fm } from "../../../Assets/globalfunctions";
import "../../../css/Stealerlogs/stealerlogs.css";

function StealerLogsModal({
  getSearchedData,
  open_stealer_modal,
  set_open_stealer_modal,
  selected_row,
  user_table_ref,
}) {
  const containerRef = useRef(null);
  const [autofill_user_data, set_autofill_user_data] = useState({
    autofill: [],
    password: [],
  });
  const [response_status, set_response_status] = useState({
    loading: false,
    error: "",
  });
  useEffect(() => {
    if (
      selected_row.autofill_value.length > 6 ||
      selected_row.passwords.length > 6
    ) {
      get_autofill_user_data();
      if (user_table_ref.current !== "details") {
        setTimeout(() => {
          const get_container = document.getElementById(
            "container_modal_stealer_main_id",
          );
          const multiplier =
            user_table_ref.current === "user_accounts" ? 0.95 : 1;
          if (get_container) {
            get_container.scrollTop =
              (get_container.scrollHeight - get_container.clientHeight) *
              multiplier;
          }
        }, 100);
      }
    } else return true;
  }, []);
  const get_autofill_user_data = () => {
    const token = localStorage.getItem("Token");
    set_response_status((prev) => ({ ...prev, loading: true }));
    const data = {
      hash_id: selected_row.all_data.hash_id,
      sort_hash: selected_row.all_data?.sort_hash,
    };
    axios
      .post(
        "https://dev.recordedtelegram.com/stealer_logs_search/hash_id",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        set_autofill_user_data(res.data);
        set_response_status({ loading: false, error: "" });
      })
      .catch((err) => {
        set_response_status({
          loading: false,
          error: err.response.data.errormsg,
        });
      });
  };
  const file_download = (id, file_type) => {
    let token = localStorage.getItem("Token");
    const data = { file_hash: id, file_type: file_type };
    try {
      axios
        .post("https://dev.recordedtelegram.com/stealer_logs_download", data, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          const new_file_name = `${getSearchedData}_${new Date()}.${file_type}`;
          fileDownload(res.data, new_file_name);
        })
        .catch((err) => {
          const token_expire_status = handle_token_expire(
            err.response.data,
            err.response.status,
          );
          if (token_expire_status === true) return;
          let errorMessage = "";
          const reader = new FileReader();
          reader.onload = () => {
            errorMessage = reader.result;
            handle_error_message(JSON.parse(errorMessage).errormsg);
          };
          reader.readAsText(err.response.data);
        });
    } catch (err) {
      handle_error_message("Sorry could not download file");
    }
  };

  const format_string = (str) => {
    try {
      const formattedString = str.split("\n");
      formattedString.pop();
      return formattedString;
    } catch {
      return [];
    }
  };

  // const downloadAsHtml = () => {
  //     const container = containerRef.current;
  //     if (!container) return;
  //     const htmlContent = `<body style="background-color:rgb(2, 31, 36)">${container.outerHTML}</body>`
  //     const blob = new Blob([htmlContent], { type: 'text/html' });
  //     const url = URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = `${getSearchedData}_${new Date()}.html`
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //     URL.revokeObjectURL(url);
  // };
  return (
    <div>
      <Modal
        open={open_stealer_modal}
        onClose={() => set_open_stealer_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className="container_modal_stealer_main scroll-custom"
          id="container_modal_stealer_main_id"
        >
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_stealer_modal(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          <div className="container_modal_stealer_download_div">
            <Tooltip title="Download as HTML">
              <button
                className="container_modal_stealer_download_btn"
                onClick={() =>
                  file_download(selected_row.all_data.file_id, "html")
                }
              >
                <CloudDownloadIcon sx={{ marginRight: "5px" }} />
                Download
              </button>
            </Tooltip>
          </div>
          <section ref={containerRef} style={section_style}>
            {selected_row ? (
              <Box className="container_modal_stealer_desc">
                <h6 style={title_style}>
                  Country Code :{" "}
                  <span style={{ color: "var(--headingColor)" }}>
                    {selected_row.country_code}
                  </span>
                </h6>
                {selected_row.all_data?.stealer_info && (
                  <h6 style={title_style}>
                    Source :{" "}
                    <span
                      style={{
                        color: "var(--headingColor)",
                        textTransform: "capitalize",
                      }}
                    >
                      {selected_row.all_data.stealer_info}
                    </span>
                  </h6>
                )}
                {selected_row.all_data?.dateofindexing && (
                  <h6 style={title_style}>
                    Date of Indexing:{" "}
                    <span
                      style={{
                        color: "var(--headingColor)",
                        textTransform: "capitalize",
                      }}
                    >
                      {date_fm(selected_row.all_data.dateofindexing)}
                    </span>
                  </h6>
                )}
                <div className="stealer_modal_system_info">
                  <h6 style={title_style}>User System Info</h6>
                  {format_string(selected_row.system_information).length > 0 ? (
                    <ul>
                      {format_string(selected_row.system_information)
                        .slice(0, 35)
                        .map((item, id) => {
                          return (
                            <li
                              key={id}
                              style={{
                                marginBottom: "0",
                                marginBlockStart: "0",
                                marginBlockEnd: "0",
                                textAlign: "justify",
                              }}
                            >
                              {item}
                            </li>
                          );
                        })}
                    </ul>
                  ) : (
                    <p style={text_none_style}>None</p>
                  )}
                </div>
                <div className="stealer_modal_auto_filled_source">
                  <h6 style={title_style}>Installed Softwaress</h6>

                  {format_string(selected_row.all_data.installed_softwares)
                    .length > 0 ? (
                    <ul>
                      {format_string(selected_row.all_data.installed_softwares)
                        .slice(0, 35)
                        .map((item, id) => {
                          return (
                            <li
                              style={{
                                marginBottom: "0",
                                marginBlockStart: "0",
                                marginBlockEnd: "0",
                                textAlign: "justify",
                              }}
                              key={id}
                            >
                              {item}
                            </li>
                          );
                        })}
                    </ul>
                  ) : (
                    <p style={text_none_style}>None</p>
                  )}
                </div>
                <div className="stealer_modal_auto_filled_source">
                  <h6 style={title_style}> Important Auto Filled</h6>

                  {format_string(
                    selected_row.all_data.important_auto_filled_source,
                  ).length > 0 ? (
                    <ul>
                      {format_string(
                        selected_row.all_data.important_auto_filled_source,
                      )
                        .slice(0, 35)
                        .map((item, id) => {
                          return (
                            <li
                              key={id}
                              style={{
                                marginBottom: "0",
                                marginBlockStart: "0",
                                marginBlockEnd: "0",
                                textAlign: "justify",
                              }}
                            >
                              {item}
                            </li>
                          );
                        })}
                    </ul>
                  ) : (
                    <p style={text_none_style}>None</p>
                  )}
                </div>
                <div className="stealer_modal_domain_list">
                  <h6 style={title_style}>Domains</h6>
                  {selected_row.domains.length > 0 ? (
                    <ul>
                      {[...new Set(selected_row.domains)].map((item, id) => {
                        return <li key={id}>{item}</li>;
                      })}
                    </ul>
                  ) : (
                    <p style={text_none_style}>None</p>
                  )}
                </div>
                {selected_row.passwords.length > 0 ? (
                  <>
                    <h6 style={title_style}>User Accounts</h6>
                    <div
                      style={{ width: "95%" }}
                      className="stealer_modal_user_accounts_table 
                      "
                    >
                      {response_status.loading === true ? (
                        <RoundAnimationLoader />
                      ) : (
                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr style={table_row_style}>
                              <th style={table_head_style} width="4%">
                                SN
                              </th>
                              <th style={table_head_style} width="38%">
                                URL
                              </th>
                              <th style={table_head_style} width="25%">
                                Username
                              </th>
                              <th style={table_head_style} width="25%">
                                Password
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {(selected_row.passwords.length <= 6
                              ? selected_row.passwords
                              : autofill_user_data.password
                            ).map((item, index) => {
                              return (
                                <tr key={index} style={table_row_style}>
                                  <td style={table_cell_style}>{index + 1}</td>
                                  <td style={table_cell_style}>{item.url}</td>
                                  <td style={table_cell_style}>
                                    {item.username}
                                  </td>
                                  <td style={table_cell_style}>
                                    {item.password}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </>
                ) : null}
                {selected_row.autofill_value.length > 0 ? (
                  <>
                    <h6 style={title_style}>Auto Fill</h6>
                    <div
                      style={{ width: "95%" }}
                      className="stealer_modal_user_accounts_table scroll-custom"
                    >
                      {response_status.loading === true ? (
                        <RoundAnimationLoader />
                      ) : (
                        <table style={{ width: "100%" }}>
                          <thead>
                            <tr style={table_row_style}>
                              <th style={table_head_style} width="4%">
                                SN
                              </th>
                              <th style={table_head_style} width="40%">
                                Name
                              </th>
                              <th style={table_head_style} width="52%">
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {(selected_row.autofill_value.length <= 6
                              ? selected_row.autofill_value
                              : autofill_user_data.autofill
                            ).map((item, index) => {
                              return (
                                <tr key={index} style={table_row_style}>
                                  <td style={table_cell_style}>{index + 1}</td>
                                  <td style={table_cell_style}>{item.name}</td>
                                  <td style={table_cell_style}>{item.value}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </>
                ) : null}
              </Box>
            ) : null}
          </section>
        </Box>
      </Modal>
      <ToastContainer />
    </div>
  );
}
const title_style = {
  fontWeight: "bold",
  textAlign: "justify",
  color: "#34c311",
  fontSize: "1rem",
};
const section_style = {
  color: "white",
  backgroundColor: "rgba(29, 40, 53, .95)",
  padding: "10px",
  overflowX: "hidden",
};
const table_head_style = {
  padding: "10px",
  textAlign: "start",
  border: "1px solid #56545485",
  color: "white",
};
const table_row_style = {
  width: "100%",
};
const table_cell_style = {
  overflowWrap: "break-word",
  wordWrap: "break-word",
  wordBreak: "break-all",
  textAlign: "justify",
  padding: "10px",
  border: "1px solid #56545485",
  color: "white",
};
const text_none_style = {
  color: "white",
  textAlign: "start",
};
StealerLogsModal.propTypes = {
  getSearchedData: PropTypes.string,
  open_stealer_modal: PropTypes.bool,
  set_open_stealer_modal: PropTypes.func,
  selected_row: PropTypes.object,
  user_table_ref: PropTypes.object,
};

export default memo(StealerLogsModal);

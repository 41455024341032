import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const handle_success_message = (message) => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "success",
    autoClose: 3000,
    theme: "dark",
    style: {
      background: "rgba(29, 40, 53, .95)",
      color: "white",
    },
  });
};
export const handle_warning_message = (warning) => {
  toast.warn(warning, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "warning",
    autoClose: 3000,
    theme: "dark",
    style: {
      background: "rgba(29, 40, 53, .95)",
      color: "white",
    },
  });
};
export const handle_error_message = (err__message) => {
  toast.error(err__message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: "error",
    autoClose: 3000,
    theme: "dark",
    style: {
      background: "rgba(29, 40, 53, .95)",
      color: "white",
    },
  });
};
export const response_message_custom = (message, type) => {
  toast[type](message, {
    position: toast.POSITION.TOP_CENTER,
    toastId: type,
    autoClose: 10000,
    theme: "dark",
    style: {
      background: "rgba(29, 40, 53, .95)",
      color: "white",
      fontWeight: "bold",
      width: "fit-content",
    },
  });
};

export const handle_internal_server_error = (status_code) => {
  if (status_code === 500) {
    toast.error(
      "Internal Server Error: We're experiencing technical difficulties. Please try again later",
      {
        position: toast.POSITION.TOP_CENTER,
        toastId: "error",
        autoClose: 3000,
        theme: "dark",
        style: {
          background: "rgba(29, 40, 53, .95)",
          color: "white",
        },
      },
    );
    return true;
  } else {
    return false;
  }
};

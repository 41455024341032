import React from "react";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import ShowMoreText from "react-show-more-text";
import { Typography } from "@mui/material";
import { link_website, crypto_link_tag } from "../../../Assets/globalfunctions";
import "../../../css/Modal/modal.css";

function TagSearchMultiLine({
  open_modal,
  set_open_modal,
  selected_row,
  template_card_filters,
}) {
  // function for the Show More text
  function executeOnClick() {}
  return (
    <div>
      <Modal
        open={open_modal}
        onClose={() => set_open_modal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="container__modal container_modal_tag container_modal_nested_channel scroll-custom">
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_modal(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          {selected_row ? (
            <div className="tag_modal_desc">
              <div className="tag_modal_desc_child">
                <h5>Telegram Channel</h5>
                {selected_row.channel_name}
              </div>
              <div className="tag_modal_desc_child">
                <h5>source</h5>
                <div className="flex_first_child_body">
                  <ShowMoreText
                    /* Default options */
                    lines={3}
                    more="Show more"
                    less="Show less"
                    className="content-css multi_line_showmore"
                    anchorClass="my-anchor-css-class"
                    onClick={executeOnClick}
                    expanded={false}
                  >
                    <Typography>{selected_row.all_data.post_source}</Typography>
                  </ShowMoreText>
                </div>
              </div>
              <div className="tag_modal_desc_child">
                <h5>source Link</h5>
                {selected_row.all_data.post_link !== "None" ? (
                  <button
                    className="filter_file_link link_website_user"
                    onClick={() =>
                      link_website(selected_row.all_data.post_link)
                    }
                  >
                    {selected_row.all_data.post_link}
                  </button>
                ) : (
                  <span>None</span>
                )}
              </div>

              <div className="tag_modal_desc_child">
                <h5>Tag</h5>

                {template_card_filters === "ip_address" ? (
                  <div className="filter_tag_div">
                    <div>
                      <h6>Ip Address</h6>
                      <ul>
                        {selected_row.ip_address !== null ? (
                          selected_row.ip_address.map((item, id) => (
                            <li key={id}>{item.ip_address}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Bank Card No</h6>
                      <ul>
                        {selected_row.bank_card_no !== null ? (
                          selected_row.bank_card_no.map((item, id) => (
                            <li key={id}>{item.bank_card_number}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>

                    <div>
                      <h6>Phone No</h6>
                      <ul>
                        {selected_row.phone_no !== null ? (
                          selected_row.phone_no.map((item, id) => (
                            <li key={id}>{item.phone_number}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Domain Name</h6>
                      <ul>
                        {selected_row.domain !== null ? (
                          selected_row.domain.map((item, id) => (
                            <li key={id}>{item.domain_name}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Email Address</h6>
                      <ul>
                        {selected_row.email !== null ? (
                          selected_row.email.map((item, id) => (
                            <li key={id}>{item.email}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Crypto Address </h6>
                      <ul>
                        {selected_row.crypto !== null ? (
                          selected_row.crypto.map((item, id) => (
                            <li key={id}>{item.crypto_adress}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : template_card_filters === "bank_card" ? (
                  <div className="filter_tag_div">
                    <div className="d-flex" style={{ columnGap: "20px" }}>
                      <div>
                        <h6>Bank Card No</h6>
                        <ul>
                          {selected_row.bank_card_no !== null &&
                            selected_row.bank_card_no.map((item, id) => (
                              <li key={id}>{item.bank_card_number}</li>
                            ))}
                        </ul>
                      </div>
                      <div>
                        <h6>Bin No</h6>
                        <ul>
                          {selected_row.bin !== null &&
                            selected_row.bin.map((item, id) => (
                              <li key={id}>{item.bin}</li>
                            ))}
                        </ul>
                      </div>
                      <div>
                        <h6>Type</h6>
                        <ul>
                          {selected_row.type !== null &&
                            selected_row.type.map((item, id) => (
                              <li key={id}>{item.bank_card_type}</li>
                            ))}
                        </ul>
                      </div>
                      <div>
                        <h6>CVV No</h6>
                        <ul>
                          {selected_row.cvv_no !== null &&
                            selected_row.cvv_no.map((item, id) => (
                              <li key={id}>{item.cvv_number}</li>
                            ))}
                        </ul>
                      </div>
                      <div>
                        <h6>Exp Year</h6>
                        <ul>
                          {selected_row.exp_year !== null &&
                            selected_row.exp_year.map((item, id) => (
                              <li key={id}>
                                {item.expiry_year !== null
                                  ? item.expiry_year
                                  : "None"}
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div>
                        <h6>Exp month</h6>
                        <ul>
                          {selected_row.exp_month !== null &&
                            selected_row.exp_month.map((item, id) => (
                              <li key={id}>
                                {item.expiry_month !== null
                                  ? item.expiry_month
                                  : "None"}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div>
                      <h6>Ip Address</h6>
                      <ul>
                        {selected_row.ip_address !== null ? (
                          selected_row.ip_address.map((item, id) => (
                            <li key={id}>{item.ip_address}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>

                    <div>
                      <h6>Domain Name</h6>
                      <ul>
                        {selected_row.domain !== null ? (
                          selected_row.domain.map((item, id) => (
                            <li key={id}>{item.domain_name}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Email Address</h6>
                      <ul>
                        {selected_row.email !== null ? (
                          selected_row.email.map((item, id) => (
                            <li key={id}>{item.email}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Crypto Address </h6>
                      <ul>
                        {selected_row.crypto !== null ? (
                          selected_row.crypto.map((item, id) => (
                            <li key={id}>{item.crypto_adress}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : template_card_filters === "phone" ? (
                  <div className="filter_tag_div">
                    <div className="d-flex">
                      <div>
                        <h6>Phone No</h6>
                        <ul>
                          {selected_row.phone_no !== null &&
                            selected_row.phone_no.map((item, id) => (
                              <li key={id}>{item.phone_number}</li>
                            ))}
                        </ul>
                      </div>
                      <div>
                        <h6>Country Code</h6>
                        <ul>
                          {selected_row.country_code !== null &&
                            selected_row.country_code.map((item, id) => (
                              <li key={id}>{item.country_code}</li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div>
                      <h6>Ip Address</h6>
                      <ul>
                        {selected_row.ip_address !== null ? (
                          selected_row.ip_address.map((item, id) => (
                            <li key={id}>{item.ip_address}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Bank Card No</h6>
                      <ul>
                        {selected_row.bank_card_no !== null ? (
                          selected_row.bank_card_no.map((item, id) => (
                            <li key={id}>{item.bank_card_number}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Domain Name</h6>
                      <ul>
                        {selected_row.domain !== null ? (
                          selected_row.domain.map((item, id) => (
                            <li key={id}>{item.domain_name}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Email Address</h6>
                      <ul>
                        {selected_row.email !== null ? (
                          selected_row.email.map((item, id) => (
                            <li key={id}>{item.email}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Crypto Address </h6>
                      <ul>
                        {selected_row.crypto !== null ? (
                          selected_row.crypto.map((item, id) => (
                            <li key={id}>{item.crypto_adress}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : template_card_filters === "bin" ? (
                  <div className="filter_tag_div">
                    <div className="d-flex" style={{ columnGap: "20px" }}>
                      <div>
                        <h6>Bank Card No</h6>
                        <ul>
                          {selected_row.bank_card_no !== null ? (
                            selected_row.bank_card_no.map((item, id) => (
                              <li key={id}>{item.bank_card_number}</li>
                            ))
                          ) : (
                            <li>None</li>
                          )}
                        </ul>
                      </div>
                      <div>
                        <h6>Bin No</h6>
                        <ul>
                          {selected_row.bin !== null &&
                            selected_row.bin.map((item, id) => (
                              <li key={id}>{item.bin}</li>
                            ))}
                        </ul>
                      </div>

                      <div>
                        <h6>Type</h6>
                        <ul>
                          {selected_row.type !== null &&
                            selected_row.type.map((item, id) => (
                              <li key={id}>{item.bank_card_type}</li>
                            ))}
                        </ul>
                      </div>
                      <div>
                        <h6>CVV No</h6>
                        <ul>
                          {selected_row.cvv_no !== null &&
                            selected_row.cvv_no.map((item, id) => (
                              <li key={id}>{item.cvv_number}</li>
                            ))}
                        </ul>
                      </div>
                      <div>
                        <h6>Exp Year</h6>
                        <ul>
                          {selected_row.exp_year !== null &&
                            selected_row.exp_year.map((item, id) => (
                              <li key={id}>
                                {item.expiry_year !== null
                                  ? item.expiry_year
                                  : "None"}
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div>
                        <h6>Exp month</h6>
                        <ul>
                          {selected_row.exp_month !== null &&
                            selected_row.exp_month.map((item, id) => (
                              <li key={id}>
                                {item.expiry_month !== null
                                  ? item.expiry_month
                                  : "None"}
                              </li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div>
                      <h6>Ip Address</h6>
                      <ul>
                        {selected_row.ip_address !== null ? (
                          selected_row.ip_address.map((item, id) => (
                            <li key={id}>{item.ip_address}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>

                    <div>
                      <h6>Domain Name</h6>
                      <ul>
                        {selected_row.domain !== null ? (
                          selected_row.domain.map((item, id) => (
                            <li key={id}>{item.domain_name}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Email Address</h6>
                      <ul>
                        {selected_row.email !== null ? (
                          selected_row.email.map((item, id) => (
                            <li key={id}>{item.email}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Crypto Address </h6>
                      <ul>
                        {selected_row.crypto !== null ? (
                          selected_row.crypto.map((item, id) => (
                            <li key={id}>{item.crypto_adress}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : template_card_filters === "domain" ? (
                  <div className="filter_tag_div">
                    <div>
                      <h6>Domain Name</h6>
                      <ul>
                        {selected_row.domain !== null &&
                          selected_row.domain.map((item, id) => (
                            <li key={id}>{item.domain_name}</li>
                          ))}
                      </ul>
                    </div>
                    <div>
                      <h6>Ip Address</h6>
                      <ul>
                        {selected_row.ip_address !== null ? (
                          selected_row.ip_address.map((item, id) => (
                            <li key={id}>{item.ip_address}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Bank Card No</h6>
                      <ul>
                        {selected_row.bank_card_no !== null ? (
                          selected_row.bank_card_no.map((item, id) => (
                            <li key={id}>{item.bank_card_number}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>

                    <div>
                      <h6>Phone No</h6>
                      <ul>
                        {selected_row.phone_no !== null ? (
                          selected_row.phone_no.map((item, id) => (
                            <li key={id}>{item.phone_number}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Email Address</h6>
                      <ul>
                        {selected_row.email !== null ? (
                          selected_row.email.map((item, id) => (
                            <li key={id}>{item.email}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Crypto Address </h6>
                      <ul>
                        {selected_row.crypto !== null ? (
                          selected_row.crypto.map((item, id) => (
                            <li key={id}>{item.crypto_adress}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : template_card_filters === "email" ? (
                  <div className="filter_tag_div">
                    <div>
                      <h6>Email Address</h6>
                      <ul>
                        {selected_row.email !== null &&
                          selected_row.email.map((item, id) => (
                            <li key={id}>{item.email}</li>
                          ))}
                      </ul>
                    </div>
                    <div>
                      <h6>Ip Address</h6>
                      <ul>
                        {selected_row.ip_address !== null ? (
                          selected_row.ip_address.map((item, id) => (
                            <li key={id}>{item.ip_address}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Bank Card No</h6>
                      <ul>
                        {selected_row.bank_card_no !== null ? (
                          selected_row.bank_card_no.map((item, id) => (
                            <li key={id}>{item.bank_card_number}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>

                    <div>
                      <h6>Phone No</h6>
                      <ul>
                        {selected_row.phone_no !== null ? (
                          selected_row.phone_no.map((item, id) => (
                            <li key={id}>{item.phone_number}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Domain Name</h6>
                      <ul>
                        {selected_row.domain !== null ? (
                          selected_row.domain.map((item, id) => (
                            <li key={id}>{item.domain_name}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Crypto Address </h6>
                      <ul>
                        {selected_row.crypto !== null ? (
                          selected_row.crypto.map((item, id) => (
                            <li key={id}>{item.crypto_adress}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : template_card_filters === "crypto" ? (
                  <div className="filter_tag_div">
                    <div className="d-flex">
                      <div className="tag_modal_crypto_address">
                        <h6>Crypto Address </h6>
                        <ul>
                          {selected_row.crypto !== null &&
                            selected_row.crypto.map((item, id) => (
                              <li
                                onClick={() => {
                                  if (
                                    [item.crypto_type].some(
                                      (cryptoType) =>
                                        cryptoType === "bitcoin" ||
                                        cryptoType === "ethereum" ||
                                        cryptoType === "tron" ||
                                        cryptoType === "xrp",
                                    )
                                  ) {
                                    link_website(
                                      crypto_link_tag(
                                        item.crypto_adress,
                                        item.crypto_type,
                                      ),
                                    );
                                  }
                                  return;
                                }}
                                className={
                                  [item.crypto_type].some(
                                    (cryptoType) =>
                                      cryptoType === "bitcoin" ||
                                      cryptoType === "ethereum" ||
                                      cryptoType === "tron" ||
                                      cryptoType === "xrp",
                                  )
                                    ? "tag_crypto_link_btn_modal"
                                    : "tag_crypto_link_btn_no_pointer_modal"
                                }
                                key={id}
                              >
                                {item.crypto_adress}
                              </li>
                            ))}
                        </ul>
                      </div>
                      <div className="tag_modal_crypto_type">
                        <h6>Crypto Type</h6>
                        <ul>
                          {selected_row.crypto_type !== null &&
                            selected_row.crypto_type.map((item, id) => (
                              <li key={id}>{item.crypto_type}</li>
                            ))}
                        </ul>
                      </div>
                    </div>
                    <div>
                      <h6>Ip Address</h6>
                      <ul>
                        {selected_row.ip_address !== null ? (
                          selected_row.ip_address.map((item, id) => (
                            <li key={id}>{item.ip_address}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Bank Card No</h6>
                      <ul>
                        {selected_row.bank_card_no !== null ? (
                          selected_row.bank_card_no.map((item, id) => (
                            <li key={id}>{item.bank_card_number}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>

                    <div>
                      <h6>Phone No</h6>
                      <ul>
                        {selected_row.phone_no !== null ? (
                          selected_row.phone_no.map((item, id) => (
                            <li key={id}>{item.phone_number}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Domain Name</h6>
                      <ul>
                        {selected_row.domain !== null ? (
                          selected_row.domain.map((item, id) => (
                            <li key={id}>{item.domain_name}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                    <div>
                      <h6>Email Address</h6>
                      <ul>
                        {selected_row.email !== null ? (
                          selected_row.email.map((item, id) => (
                            <li key={id}>{item.email}</li>
                          ))
                        ) : (
                          <li>None</li>
                        )}
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
}
TagSearchMultiLine.propTypes = {
  open_modal: PropTypes.bool,
  set_open_modal: PropTypes.func,
  selected_row: PropTypes.object,
  template_card_filters: PropTypes.string,
};

export default TagSearchMultiLine;

export const handle_session_get = (key) => {
  const get_session = localStorage.getItem(key);
  return get_session;
};
export const handle_session_set = (key, value) => {
  localStorage.setItem(key, value);
};
export const handle_disable_radio_btn = () => {
  const acct_type = localStorage.getItem("Acc_typ");
  const User = localStorage.getItem("User");
  if (acct_type === "PAID_CUSTOMER" || User === "administrator") {
    return false;
  } else {
    return true;
  }
};

export const handle_check_paid_trial_customer = () => {
  const acct_type = localStorage.getItem("Acc_typ");
  const User = localStorage.getItem("User");
  const user_type = localStorage.getItem("admin_status");
  if (
    acct_type === "PAID_CUSTOMER" ||
    User === "administrator" ||
    user_type === "super_admin" ||
    user_type === "admin"
  ) {
    return "paid_customer";
  } else {
    return "trial_customer";
  }
};

export const handle_clear_localStorage = () => {
  localStorage.removeItem("Acc_typ");
  localStorage.removeItem("Expdate");
  localStorage.removeItem("User");
  localStorage.removeItem("Type");
  localStorage.removeItem("enabled_categories");
  localStorage.removeItem("company_status");
  localStorage.removeItem("Token");
  localStorage.removeItem("admin_status");
  localStorage.removeItem("filters_array");
  localStorage.removeItem("Data");
  localStorage.removeItem("stealer_logs_access");
  localStorage.removeItem("Ratelimits");
  localStorage.removeItem("filters_array_tag");
  localStorage.removeItem("filters_array_stealer");
};

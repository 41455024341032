import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { Grid } from "@mui/material";
import Sidenav from "../../../TableUi/components/Navbar/Sidenav";
import UserMainNav from "../../../TableUi/components/Navbar/UserMainNav";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { styles_background_img } from "../../../utils/Globals/global_functions";
import MarkedChannels from "./MarkedChannels";
import { useDispatch } from "react-redux";
import { handle_store_url_location } from "../../../actions/filterAction";

function MarkedChannelsFunc() {
  const token = handle_session_get("Token");
  const dispatch = useDispatch();
  const [show_sidenav, set_show_sidenav] = useState(false);
  const url = window.location.pathname;
  const user_name_session = handle_session_get("admin_status");
  if (token === null) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <div className="company_stats_main_container" style={styles_background_img}>
      <Grid container>
        {user_name_session === "super_admin" ||
        user_name_session === "admin" ? (
          <Grid
            item
            xs={show_sidenav === true ? 0.5 : 0}
            sm={show_sidenav === true ? 0.5 : 0}
          >
            {show_sidenav ? <Sidenav /> : null}
          </Grid>
        ) : null}
        <Grid
          item
          xs={show_sidenav === true ? 11.5 : 12}
          sm={show_sidenav === true ? 11.5 : 12}
        >
          <UserMainNav
            show_sidenav={show_sidenav}
            set_show_sidenav={set_show_sidenav}
          />
          {url.includes("channel-preference") === true ? (
            <MarkedChannels />
          ) : null}
        </Grid>
      </Grid>
    </div>
  );
}

export default MarkedChannelsFunc;

/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Stack } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import { LanguageLimit } from "./DialogAlert";
import InputSwitch from "../sections/Switch/InputSwitch";
import { handle_check_paid_trial_customer } from "../../../utils/SessionStorage/handle_session";
import { response_message_custom } from "../../../utils/ResponseMessage/response_message";
import "../../../css/Modal/modal.css";

function TranslateApi({
  open_translate,
  set_open_translate,
  language_array,
  set_language_array,
  checked_exclude,
  set_checked_exclude,
  teleSearch,
  postSearch,
}) {
  const Europe = [
    ["Albanian", "sq"],
    ["Basque", "eu"],
    ["Belarusian", "be"],
    ["Bosnian", "bs"],
    ["Bulgarian", "bg"],
    ["Catalan", "ca"],
    ["Corsican", "co"],
    ["Croatian", "hr"],
    ["Czech", "cs"],
    ["Danish", "da"],
    ["Dutch", "nl"],
    ["English", "en"],
    ["Esperanto", "eo"],
    ["Estonian", "et"],
    ["Irish", "ga"],
    ["Finnish", "fi"],
    ["French", "fr"],
    ["Frisian", "fy"],
    ["Galician", "gl"],
    ["Georgian", "ka"],
    ["German", "de"],
    ["Greek", "el"],
    ["Hungarian", "hu"],
    ["Icelandic", "is"],
    ["Italian", "it"],
    ["Latvian", "lv"],
    ["Lithuanian", "lt"],
    ["Luxembourgish", "lb"],
    ["Macedonian", "mk"],
    ["Norwegian", "no"],
    ["Polish", "pl"],
    ["Portuguese", "pt"],
    ["Romanian", "ro"],
    ["Russian", "ru"],
    ["Scots Gaelic", "gd"],
    ["Serbian", "sr"],
    ["Slovak", "sk"],
    ["Slovenian", "sl"],
    ["Spanish", "es"],
    ["Maltese", "mt"],
    ["Swedish", "sv"],
    ["Tatar", "tt"],
    ["Turkish", "tr"],
    ["Ukrainian", "uk"],
    ["Uyghur", "ug"],
    ["Welsh", "cy"],
    ["Yiddish", "yi"],
    ["Samoan", "sm"],
  ];
  const Asia = [
    ["Arabic", "ar"],
    ["Armenian", "hy"],
    ["Azerbaijani", "az"],
    ["Bengali", "bn"],
    ["Cebuano", "ceb"],
    ["Chinese (Simplified)", "zh-CN"],
    ["Chinese (Traditional)", "zh-TW"],
    ["Gujarati", "gu"],
    ["Hebrew", "he"],
    ["Hindi", "hi"],
    ["Hmong", "hmn"],
    ["Indonesian", "id"],
    ["Japanese", "ja"],
    ["Javanese", "jv"],
    ["Kannada", "kn"],
    ["Kazakh", "kk"],
    ["Khmer", "km"],
    ["Korean", "ko"],
    ["Lao", "lo"],
    ["Kyrgyz", "ky"],
    ["Kurdish", "ku"],
    ["Malayalam", "ml"],
    ["Malay", "ms"],
    ["Marathi", "mr"],
    ["Mongolian", "mn"],
    ["Myanmar", "my"],
    ["Nepali", "ne"],
    ["Odia", "or"],
    ["Pashto", "ps"],
    ["Persian", "fa"],
    ["Punjabi", "pa"],
    ["Sindhi", "sd"],
    ["Sinhala", "si"],
    ["Sundanese", "su"],
    ["Tagalog", "tl"],
    ["Tajik", "tg"],
    ["Tamil", "ta"],
    ["Telugu", "te"],
    ["Thai", "th"],
    ["Turkmen", "tk"],
    ["Urdu", "ur"],
    ["Uzbek", "uz"],
    ["Vietnamese", "vi"],
  ];
  const Africa = [
    ["Amharic", "am"],
    ["Hausa", "ha"],
    ["Igbo", "ig"],
    ["Kinyarwanda", "rw"],
    ["Malagasy", "mg"],
    ["Nyanja", "ny"],
    ["Sesotho", "st"],
    ["Shona", "sn"],
    ["Somali", "so"],
    ["Swahili", "sw"],
    ["Xhosa", "xh"],
    ["Yoruba", "yo"],
    ["Zulu", "zu"],
  ];
  const North_america = [
    ["Haitian", "ht"],
    ["Hawaiian", "haw"],
    ["Maori", "mi"],
  ];

  // Arrays for the shortlisted countries
  const Europe_short = [
    ["Bulgarian", "bg"],
    ["Czech", "cs"],
    ["Danish", "da"],
    ["Dutch", "nl"],
    ["English", "en"],
    ["Estonian", "et"],
    ["Finnish", "fi"],
    ["French", "fr"],
    ["German", "de"],
    ["Greek", "el"],
    ["Hungarian", "hu"],
    ["Italian", "it"],
    ["Latvian", "lv"],
    ["Lithuanian", "lt"],
    ["Polish", "pl"],
    ["Portuguese", "pt"],
    ["Romanian", "ro"],
    ["Russian", "ru"],
    ["Slovak", "sk"],
    ["Spanish", "es"],
    ["Swedish", "sv"],
    ["Turkish", "tr"],
  ];
  const Asia_short = [
    ["Chinese (Simplified)", "zh-CN"],
    ["Indonesian", "id"],
    ["Japanese", "ja"],
  ];

  const top_lang_array = [
    "bg",
    "cs",
    "da",
    "nl",
    "en",
    "et",
    "fi",
    "fr",
    "de",
    "el",
    "hu",
    "it",
    "lv",
    "lt",
    "pl",
    "pt",
    "ro",
    "ru",
    "sk",
    "es",
    "sv",
    "tr",
    "zh-CN",
    "id",
    "ja",
  ];

  const [select_top_lang, set_select_top_lang] = useState(false);
  const [open_modal_lang_limit, set_open_modal_lang_limit] = useState(false);
  const [translation_error_msg, set_translation_error_msg] =
    useState("trail_trans");

  const handle_language_selection = (event) => {
    const { value } = event.target;
    const list_lang = [...language_array];
    const index_lang = list_lang.indexOf(value);
    index_lang === -1 ? list_lang.push(value) : list_lang.splice(index_lang, 1);
    if (handle_check_paid_trial_customer() === "paid_customer") {
      if (
        (teleSearch === "post" || teleSearch === "file") &&
        postSearch === "logical"
      ) {
        if (list_lang.length >= 11) {
          set_open_modal_lang_limit(true);
          set_translation_error_msg("paid_logical_trans");
        } else {
          set_language_array(list_lang);
        }
      } else {
        set_language_array(list_lang);
      }
    } else {
      if (
        (teleSearch === "post" || teleSearch === "file") &&
        postSearch === "logical"
      ) {
        if (list_lang.length >= 3) {
          set_open_modal_lang_limit(true);
          set_translation_error_msg("trial_logical_trans");
        } else {
          set_language_array(list_lang);
        }
      } else {
        if (list_lang.length >= 6) {
          set_open_modal_lang_limit(true);
          set_translation_error_msg("trail_trans");
        } else {
          set_language_array(list_lang);
        }
      }
    }
  };

  const handleChange = (event) => {
    set_checked_exclude(event.target.checked);
  };
  const handle_select_top_lang = (event) => {
    set_select_top_lang(event.target.checked);
  };
  useEffect(() => {
    if (select_top_lang === true) {
      if (
        handle_check_paid_trial_customer() === "paid_customer" &&
        (teleSearch === "post" || teleSearch === "file") &&
        postSearch === "logical"
      ) {
        response_message_custom(
          "You can select up to 10 languages when enabling translation on logical search.",
          "warn",
        );
        return;
      }
      set_language_array(top_lang_array);
    } else {
      set_language_array([]);
    }
  }, [select_top_lang]);

  const handle_show_less_lang = () => {
    const new_lang = language_array.filter((item) => {
      return top_lang_array.includes(item) === true;
    });
    set_language_array(new_lang);
    document.getElementById("modal_translate_desc_more_flag").style.display =
      "none";
    document.getElementById("modal_translate_desc_less_flag").style.display =
      "block";
    document.getElementById("translation_modal_id").className =
      "container_modal_translate scroll-custom";
  };

  return (
    <div>
      <Modal
        open={open_translate}
        onClose={() => set_open_translate(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          id="translation_modal_id"
          className="container_modal_translate scroll-custom"
        >
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_translate(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          <div className="toggle_btns_div">
            <Stack direction="row" alignItems="baseline">
              <div className="exclude_source">
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Exclude results from source language
                </FormLabel>
                <InputSwitch
                  checked_status={checked_exclude}
                  handle_change={handleChange}
                  disabled_status={false}
                />
              </div>

              <div className="exclude_source">
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Select Top languages{" "}
                  <Chip
                    label="Premium"
                    color="secondary"
                    size="small"
                    sx={{ mb: 1 }}
                  />
                </FormLabel>
                <InputSwitch
                  checked_status={select_top_lang}
                  handle_change={handle_select_top_lang}
                  disabled_status={
                    !(handle_check_paid_trial_customer() === "paid_customer")
                  }
                />
              </div>
            </Stack>
          </div>

          <div className="divider_translation" />
          <div
            className="modal_translate_desc"
            id="modal_translate_desc_less_flag"
          >
            <Grid container>
              <Grid item xs={7}>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Europe
                  </FormLabel>
                  <Box className="checkbox_translate_short_europe">
                    {Europe_short.map((item, index) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={item[1]}
                            onChange={handle_language_selection}
                            checked={language_array.includes(item[1])}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label={
                          <Stack
                            direction="row"
                            columnGap={0.5}
                            alignItems="center"
                          >
                            <img
                              width="20.5411"
                              height="20"
                              src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                              alt="flag"
                            />
                            {item[0]}
                          </Stack>
                        }
                        key={index}
                      />
                    ))}

                    {/* <FormControlLabel control={<Checkbox value="all" onChange={handleChangePostCategoryModal} checked={isAllselected} size="small" sx={{
                                    color: "white", '&.Mui-checked': {
                                        color: '#28cab3',
                                    }
                                }} />} label="All" />  */}
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Asia
                  </FormLabel>
                  <Box className="checkbox_translate_short">
                    {Asia_short.map((item, id) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={item[1]}
                            onChange={handle_language_selection}
                            checked={language_array.includes(item[1])}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label={
                          <Stack
                            direction="row"
                            columnGap={0.5}
                            alignItems="center"
                          >
                            <img
                              width="20.5411"
                              height="20"
                              src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                              alt="flag"
                            />
                            {item[0]}
                          </Stack>
                        }
                        key={id}
                      />
                    ))}
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
            <button
              disabled={
                !(handle_check_paid_trial_customer() === "paid_customer")
              }
              className="filter_category_arrow_lang"
              onClick={() => {
                document.getElementById(
                  "modal_translate_desc_less_flag",
                ).style.display = "none";
                document.getElementById(
                  "modal_translate_desc_more_flag",
                ).style.display = "block";
                document.getElementById("translation_modal_id").className =
                  "container_modal_translate_expand scroll-custom";
              }}
            >
              {" "}
              <Badge badgeContent="premium" color="secondary">
                <KeyboardDoubleArrowDownIcon
                  fontSize="large"
                  sx={{ color: "#78ffd3" }}
                />
              </Badge>{" "}
            </button>
          </div>
          <div
            className="modal_translate_desc"
            id="modal_translate_desc_more_flag"
            style={{ display: "none" }}
          >
            <Grid container>
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Europe
                  </FormLabel>
                  <Box className="checkbox_translate">
                    {Europe.map((item, id) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={item[1]}
                            onChange={handle_language_selection}
                            checked={language_array.includes(item[1])}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label={
                          <Stack
                            direction="row"
                            columnGap={0.5}
                            alignItems="center"
                          >
                            <img
                              width="20.5411"
                              height="20"
                              src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                              alt="flag"
                            />
                            {item[0]}
                          </Stack>
                        }
                        key={id}
                      />
                    ))}
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Asia
                  </FormLabel>
                  <Box className="checkbox_translate">
                    {Asia.map((item, id) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={item[1]}
                            onChange={handle_language_selection}
                            checked={language_array.includes(item[1])}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label={
                          <Stack
                            direction="row"
                            columnGap={0.5}
                            alignItems="center"
                          >
                            <img
                              width="20.5411"
                              height="20"
                              src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                              alt="flag"
                            />
                            {item[0]}
                          </Stack>
                        }
                        key={id}
                      />
                    ))}
                  </Box>
                </FormControl>
              </Grid>

              <Grid item xs={2}>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Africa
                  </FormLabel>
                  <Box className="filter_category_modal">
                    {Africa.map((item, id) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={item[1]}
                            onChange={handle_language_selection}
                            checked={language_array.includes(item[1])}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label={
                          <Stack
                            direction="row"
                            columnGap={0.5}
                            alignItems="center"
                          >
                            <img
                              width="20.5411"
                              height="20"
                              src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                              alt="flag"
                            />
                            {item[0]}
                          </Stack>
                        }
                        key={id}
                      />
                    ))}
                  </Box>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title"
                  >
                    Americas
                  </FormLabel>
                  <Box className="filter_category_modal">
                    {North_america.map((item, id) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={item[1]}
                            onChange={handle_language_selection}
                            checked={language_array.includes(item[1])}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label={
                          <Stack
                            direction="row"
                            columnGap={0.5}
                            alignItems="center"
                          >
                            <img
                              width="20.5411"
                              height="20"
                              src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                              alt="flag"
                            />
                            {item[0]}
                          </Stack>
                        }
                        key={id}
                      />
                    ))}
                  </Box>
                </FormControl>
              </Grid>
            </Grid>
            <button
              className="filter_category_arrow_lang"
              onClick={handle_show_less_lang}
            >
              {" "}
              <KeyboardDoubleArrowUpIcon
                fontSize="large"
                sx={{ color: "#78ffd3" }}
              />
            </button>
          </div>
        </Box>
      </Modal>
      <ToastContainer />
      {open_modal_lang_limit === true ? (
        <LanguageLimit
          open_modal_lang_limit={open_modal_lang_limit}
          set_open_modal_lang_limit={set_open_modal_lang_limit}
          translation_error_msg={translation_error_msg}
        />
      ) : null}
    </div>
  );
}
TranslateApi.propTypes = {
  open_translate: PropTypes.bool,
  set_open_translate: PropTypes.func,
  language_array: PropTypes.array,
  set_language_array: PropTypes.array,
  checked_exclude: PropTypes.bool,
  set_checked_exclude: PropTypes.func,
  teleSearch: PropTypes.string,
  postSearch: PropTypes.string,
};

//NOTE : Translation modal for the update of languages on notification
function TranslateApiUpdate({
  open_translate,
  set_open_translate,
  language_array,
  set_language_array,
  select_top_lang,
  set_select_top_lang,
  checked_exclude,
  set_checked_exclude,
}) {
  const Europe = [
    ["Albanian", "sq"],
    ["Basque", "eu"],
    ["Belarusian", "be"],
    ["Bosnian", "bs"],
    ["Bulgarian", "bg"],
    ["Catalan", "ca"],
    ["Corsican", "co"],
    ["Croatian", "hr"],
    ["Czech", "cs"],
    ["Danish", "da"],
    ["Dutch", "nl"],
    ["English", "en"],
    ["Esperanto", "eo"],
    ["Estonian", "et"],
    ["Irish", "ga"],
    ["Finnish", "fi"],
    ["French", "fr"],
    ["Frisian", "fy"],
    ["Galician", "gl"],
    ["Georgian", "ka"],
    ["German", "de"],
    ["Greek", "el"],
    ["Hungarian", "hu"],
    ["Icelandic", "is"],
    ["Italian", "it"],
    ["Latvian", "lv"],
    ["Lithuanian", "lt"],
    ["Luxembourgish", "lb"],
    ["Macedonian", "mk"],
    ["Norwegian", "no"],
    ["Polish", "pl"],
    ["Portuguese", "pt"],
    ["Romanian", "ro"],
    ["Russian", "ru"],
    ["Scots Gaelic", "gd"],
    ["Serbian", "sr"],
    ["Slovak", "sk"],
    ["Slovenian", "sl"],
    ["Spanish", "es"],
    ["Maltese", "mt"],
    ["Swedish", "sv"],
    ["Tatar", "tt"],
    ["Turkish", "tr"],
    ["Ukrainian", "uk"],
    ["Uyghur", "ug"],
    ["Welsh", "cy"],
    ["Yiddish", "yi"],
    ["Samoan", "sm"],
  ];
  const Asia = [
    ["Arabic", "ar"],
    ["Armenian", "hy"],
    ["Azerbaijani", "az"],
    ["Bengali", "bn"],
    ["Cebuano", "ceb"],
    ["Chinese (Simplified)", "zh-CN"],
    ["Chinese (Traditional)", "zh-TW"],
    ["Gujarati", "gu"],
    ["Hebrew", "he"],
    ["Hindi", "hi"],
    ["Hmong", "hmn"],
    ["Indonesian", "id"],
    ["Japanese", "ja"],
    ["Javanese", "jv"],
    ["Kannada", "kn"],
    ["Kazakh", "kk"],
    ["Khmer", "km"],
    ["Korean", "ko"],
    ["Lao", "lo"],
    ["Kyrgyz", "ky"],
    ["Kurdish", "ku"],
    ["Malayalam", "ml"],
    ["Malay", "ms"],
    ["Marathi", "mr"],
    ["Mongolian", "mn"],
    ["Myanmar", "my"],
    ["Nepali", "ne"],
    ["Odia", "or"],
    ["Pashto", "ps"],
    ["Persian", "fa"],
    ["Punjabi", "pa"],
    ["Sindhi", "sd"],
    ["Sinhala", "si"],
    ["Sundanese", "su"],
    ["Tagalog", "tl"],
    ["Tajik", "tg"],
    ["Tamil", "ta"],
    ["Telugu", "te"],
    ["Thai", "th"],
    ["Turkmen", "tk"],
    ["Urdu", "ur"],
    ["Uzbek", "uz"],
    ["Vietnamese", "vi"],
  ];
  const Africa = [
    ["Amharic", "am"],
    ["Hausa", "ha"],
    ["Igbo", "ig"],
    ["Kinyarwanda", "rw"],
    ["Malagasy", "mg"],
    ["Nyanja", "ny"],
    ["Sesotho", "st"],
    ["Shona", "sn"],
    ["Somali", "so"],
    ["Swahili", "sw"],
    ["Xhosa", "xh"],
    ["Yoruba", "yo"],
    ["Zulu", "zu"],
  ];
  const North_america = [
    ["Haitian", "ht"],
    ["Hawaiian", "haw"],
    ["Maori", "mi"],
  ];

  // Arrays for the shortlisted countries
  const Europe_short = [
    ["Bulgarian", "bg"],
    ["Czech", "cs"],
    ["Danish", "da"],
    ["Dutch", "nl"],
    ["English", "en"],
    ["Estonian", "et"],
    ["Finnish", "fi"],
    ["French", "fr"],
    ["German", "de"],
    ["Greek", "el"],
    ["Hungarian", "hu"],
    ["Italian", "it"],
    ["Latvian", "lv"],
    ["Lithuanian", "lt"],
    ["Polish", "pl"],
    ["Portuguese", "pt"],
    ["Romanian", "ro"],
    ["Russian", "ru"],
    ["Slovak", "sk"],
    ["Spanish", "es"],
    ["Swedish", "sv"],
    ["Turkish", "tr"],
  ];
  const Asia_short = [
    ["Chinese (Simplified)", "zh-CN"],
    ["Indonesian", "id"],
    ["Japanese", "ja"],
  ];

  const top_lang_array = [
    "bg",
    "cs",
    "da",
    "nl",
    "en",
    "et",
    "fi",
    "fr",
    "de",
    "el",
    "hu",
    "it",
    "lv",
    "lt",
    "pl",
    "pt",
    "ro",
    "ru",
    "sk",
    "es",
    "sv",
    "tr",
    "zh-CN",
    "id",
    "ja",
  ];

  const [see_all_lang, set_see_all_lang] = useState(false);
  const [open_modal_lang_limit, set_open_modal_lang_limit] = useState(false);

  const handle_language_selection = (event) => {
    const { value } = event.target;
    const list_lang = [...language_array];
    const index_lang = list_lang.indexOf(value);
    index_lang === -1 ? list_lang.push(value) : list_lang.splice(index_lang, 1);
    if (handle_check_paid_trial_customer() === "paid_customer") {
      set_language_array(list_lang);
    } else if (list_lang.length >= 6) {
      set_open_modal_lang_limit(true);
    } else {
      set_language_array(list_lang);
    }
  };

  const handleChange = (event) => {
    set_checked_exclude(event.target.checked);
  };
  const handle_select_top_lang = (event) => {
    set_select_top_lang(event.target.checked);
    if (event.target.checked === true) {
      set_language_array(top_lang_array);
    } else {
      set_language_array([]);
    }
  };
  const handle_show_less_lang = () => {
    const new_lang = language_array.filter((item) => {
      if (top_lang_array.includes(item) === true) {
        return item;
      }
      return null;
    });
    set_language_array(new_lang);
    set_see_all_lang(false);
  };

  return (
    <div>
      <Modal
        open={open_translate}
        onClose={() => set_open_translate(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          className={
            see_all_lang === false
              ? "container_modal_translate scroll-custom"
              : "container_modal_translate_expand scroll-custom"
          }
        >
          <div className="modal_email_alert_close">
            <button
              className="modal-heading-closeBtn"
              onClick={() => set_open_translate(false)}
            >
              <CancelIcon sx={{ color: "#eb0000 ", cursor: "pointer" }} />
            </button>
          </div>
          <div className="toggle_btns_div">
            <Stack direction="row" alignItems="baseline">
              <div className="exclude_source">
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Exclude results from source language
                </FormLabel>
                <InputSwitch
                  checked_status={checked_exclude}
                  handle_change={handleChange}
                  disabled_status={false}
                />
              </div>

              <div className="exclude_source">
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Select Top languages{" "}
                  <Chip
                    label="Premium"
                    color="secondary"
                    size="small"
                    sx={{ mb: 1 }}
                  />
                </FormLabel>
                <InputSwitch
                  checked_status={select_top_lang}
                  handle_change={handle_select_top_lang}
                  disabled_status={
                    !(handle_check_paid_trial_customer() === "paid_customer")
                  }
                />
              </div>
            </Stack>
          </div>

          <div className="divider_translation" />

          {see_all_lang === false ? (
            <div className="modal_translate_desc">
              <Grid container>
                <Grid item xs={7}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Europe
                    </FormLabel>
                    <Box className="checkbox_translate_short_europe">
                      {Europe_short.map((item, id) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item[1]}
                              onChange={handle_language_selection}
                              checked={language_array.includes(item[1])}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={
                            <Stack
                              direction="row"
                              columnGap={0.5}
                              alignItems="center"
                            >
                              <img
                                width="20.5411"
                                height="20"
                                src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                                alt="flag"
                              />
                              {item[0]}
                            </Stack>
                          }
                          key={id}
                        />
                      ))}

                      {/* <FormControlLabel control={<Checkbox value="all" onChange={handleChangePostCategoryModal} checked={isAllselected} size="small" sx={{
                                    color: "white", '&.Mui-checked': {
                                        color: '#28cab3',
                                    }
                                }} />} label="All" />  */}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Asia
                    </FormLabel>
                    <Box className="checkbox_translate_short">
                      {Asia_short.map((item, id) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item[1]}
                              onChange={handle_language_selection}
                              checked={language_array.includes(item[1])}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={
                            <Stack
                              direction="row"
                              columnGap={0.5}
                              alignItems="center"
                            >
                              <img
                                width="20.5411"
                                height="20"
                                src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                                alt="flag"
                              />
                              {item[0]}
                            </Stack>
                          }
                          key={id}
                        />
                      ))}
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
              <button
                disabled={
                  !(handle_check_paid_trial_customer() === "paid_customer")
                }
                className="filter_category_arrow_lang"
                onClick={() => set_see_all_lang(true)}
              >
                {" "}
                <Badge badgeContent="premium" color="secondary">
                  <Tooltip title="see more">
                    <KeyboardDoubleArrowDownIcon
                      fontSize="large"
                      sx={{ color: "#78ffd3" }}
                    />
                  </Tooltip>
                </Badge>{" "}
              </button>
            </div>
          ) : (
            <div className="modal_translate_desc">
              <Grid container>
                <Grid item xs={4}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Europe
                    </FormLabel>
                    <Box className="checkbox_translate">
                      {Europe.map((item, id) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item[1]}
                              onChange={handle_language_selection}
                              checked={language_array.includes(item[1])}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={
                            <Stack
                              direction="row"
                              columnGap={0.5}
                              alignItems="center"
                            >
                              <img
                                width="20.5411"
                                height="20"
                                src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                                alt="flag"
                              />
                              {item[0]}
                            </Stack>
                          }
                          key={id}
                        />
                      ))}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Asia
                    </FormLabel>
                    <Box className="checkbox_translate">
                      {Asia.map((item, id) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item[1]}
                              onChange={handle_language_selection}
                              checked={language_array.includes(item[1])}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={
                            <Stack
                              direction="row"
                              columnGap={0.5}
                              alignItems="center"
                            >
                              <img
                                width="20.5411"
                                height="20"
                                src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                                alt="flag"
                              />
                              {item[0]}
                            </Stack>
                          }
                          key={id}
                        />
                      ))}
                    </Box>
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Africa
                    </FormLabel>
                    <Box className="filter_category_modal">
                      {Africa.map((item, id) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item[1]}
                              onChange={handle_language_selection}
                              checked={language_array.includes(item[1])}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={
                            <Stack
                              direction="row"
                              columnGap={0.5}
                              alignItems="center"
                            >
                              <img
                                width="20.5411"
                                height="20"
                                src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                                alt="flag"
                              />
                              {item[0]}
                            </Stack>
                          }
                          key={id}
                        />
                      ))}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl>
                    <FormLabel
                      id="demo-controlled-radio-buttons-group"
                      className="label__title"
                    >
                      Americas
                    </FormLabel>
                    <Box className="filter_category_modal">
                      {North_america.map((item, id) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item[1]}
                              onChange={handle_language_selection}
                              checked={language_array.includes(item[1])}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={
                            <Stack
                              direction="row"
                              columnGap={0.5}
                              alignItems="center"
                            >
                              <img
                                width="20.5411"
                                height="20"
                                src={`${process.env.PUBLIC_URL}/static/images/flags/${item[1]}.png`}
                                alt="flag"
                              />
                              {item[0]}
                            </Stack>
                          }
                          key={id}
                        />
                      ))}
                    </Box>
                  </FormControl>
                </Grid>
              </Grid>
              <button
                className="filter_category_arrow_lang"
                onClick={handle_show_less_lang}
              >
                {" "}
                <Tooltip title="see less">
                  <KeyboardDoubleArrowUpIcon
                    fontSize="large"
                    sx={{ color: "#78ffd3" }}
                  />
                </Tooltip>
              </button>
            </div>
          )}
        </Box>
      </Modal>
      <ToastContainer />
      {open_modal_lang_limit === true ? (
        <LanguageLimit
          open_modal_lang_limit={open_modal_lang_limit}
          set_open_modal_lang_limit={set_open_modal_lang_limit}
          translation_error_msg={"trail_trans"}
        />
      ) : null}
    </div>
  );
}
TranslateApiUpdate.propTypes = {
  open_translate: PropTypes.bool,
  set_open_translate: PropTypes.func,
  language_array: PropTypes.array,
  set_language_array: PropTypes.func,
  select_top_lang: PropTypes.bool,
  set_select_top_lang: PropTypes.func,
  checked_exclude: PropTypes.bool,
  set_checked_exclude: PropTypes.func,
};
export default TranslateApi;
export { TranslateApiUpdate };

import {
  SEARCH_FILTER_RADIO,
  USER_SEARCHED_DATA,
  PAGINATION_VALUE,
  ARRAY_LOGICAL,
  STORE_ARRAY,
  STORE_URL_LOCATION,
  OPEN_CHANNEL_SCRAPE_MODAL,
} from "../actions/filterTypes";

const initialValues = {
  telesearch: "post",
  userFilter: "all",
  postSearch: "None",
  postSearchSub: "Channel/Group",
  keyword: "contains",
  postCategory: [],
  multimedia: "disabled",
  dataDisplay: "desc",
  postNo: 100,
  dateInput: ["None", "None"],
  telegramOption_post: "None",
  telegramOption_user: "username",
  user_group: "group_channel_name",
  search_value: "",
  spamFilter: "False",
  Proximity: 0,
  filters_array: {},
  user_searched_data: [],
  pagination_value: 1,
  array_logical: [],
  bulk_search_array: [],
  trans_lang: [],
  user_url_location: "",
  channel_scrape_modal: false,
};

const filter_reducer = (state = initialValues, action) => {
  switch (action.type) {
    case SEARCH_FILTER_RADIO:
      return {
        ...state,
        [action.name]: action.value,
      };
    case USER_SEARCHED_DATA:
      return {
        ...state,
        user_searched_data: action.data,
      };
    case PAGINATION_VALUE:
      return {
        ...state,
        pagination_value: action.value,
      };
    case ARRAY_LOGICAL:
      return {
        ...state,
        array_logical: action.array,
      };
    case STORE_ARRAY:
      return {
        ...state,
        [action.name]: action.array,
      };
    case STORE_URL_LOCATION:
      return {
        ...state,
        user_url_location: action.url,
      };
    case OPEN_CHANNEL_SCRAPE_MODAL:
      return {
        ...state,
        channel_scrape_modal: action.modal_status,
      };
    default:
      return state;
  }
};

export default filter_reducer;

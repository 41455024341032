import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_token_expire } from "../../../utils/Validation/Token/token_checker";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
const CustomDropdown = ({
  all_clients,
  set_all_clients,
  selected_user,
  set_selected_user,
}) => {
  const [show_dropdown, set_show_dropdown] = useState(false);
  const token = handle_session_get("Token");

  const handleChange = (e) => {
    const value = e.target.value;
    set_selected_user(value);
    handle_get_all_clients(value);
  };
  const handle_options = (clients) => {
    if (clients.length > 0) {
      const filter_client = clients.map((item) => item.username);
      return filter_client;
    }
    return [];
  };
  const handle_get_all_clients = (user) => {
    if (user.length > 0) {
      axios
        .get(`https://dev.recordedtelegram.com/api/clients/?username=${user}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          set_all_clients(res.data.data);
        })
        .catch((err) => {
          try {
            const token_expire_status = handle_token_expire(err.response.data);
            if (token_expire_status === true) return;
          } catch {
            //Do nothing
          }
        });
    }
  };
  return (
    <div className="custom_dropdown">
      <input
        className="global_input_box"
        type="text"
        required
        value={selected_user}
        onChange={handleChange}
        onClick={() => set_show_dropdown(true)}
        placeholder="Enter username"
        style={{ borderRadius: "20px", marginBlockEnd: "5px" }}
      />
      {show_dropdown === true && selected_user ? (
        <ClickAwayListener onClickAway={() => set_show_dropdown(false)}>
          <ul className="custom_dropdown_options scroll-custom">
            {handle_options(all_clients).map((option, index) => (
              <li
                key={index}
                onClick={() => {
                  set_selected_user(option);
                  set_show_dropdown(false);
                }}
                className="dropdown-option"
              >
                {option}
              </li>
            ))}
          </ul>
        </ClickAwayListener>
      ) : null}
    </div>
  );
};
CustomDropdown.propTypes = {
  all_clients: PropTypes.array,
  set_all_clients: PropTypes.func,
  selected_user: PropTypes.string,
  set_selected_user: PropTypes.func,
};

export default CustomDropdown;

import React, { useState } from "react";
import { PropTypes } from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import { date_time_tooltip } from "../../Assets/globalfunctions";
import { sort_cloumn_datewise } from "../../utils/Globals/global_functions";

function GridTable({ scraper_data, table_type }) {
  const [pageSize_scrapper, setPageSize_scrapper] = useState(50);
  const [pagination_value, set_pagination_value] = useState(1);

  const handlePaginationChange = (event, value) => {
    set_pagination_value(value);
  };

  // Select the required page size
  const page_size_fixer = (newPageSize) => {
    setPageSize_scrapper(newPageSize);
    set_pagination_value(Math.ceil(scraper_data.length / newPageSize));
  };

  const filter_scrapper_data = scraper_data.map((item, index) => {
    try {
      if (table_type === "scraper_info_table") {
        return {
          id: index,
          alt_phone: item.alt_phone.includes("+")
            ? item.alt_phone
            : `+${item.alt_phone}`,
          api_id: item.api_id,
          api_hash: item.api_hash,
          ip_address: item.ip_adress.split(",")[0],
          alt_category: item.alt_category,
          alt_name: item.alt_name,
          status: item.status === "True" ? "Scrapping" : "Finished",
        };
      } else if (table_type === "vpn_tracker_table") {
        return {
          ...item,
          id: index + 1,
          last_modified: date_time_tooltip(item.last_modified),
          vpn_type: "Wire Guard",
        };
      }
      return { ...item, id: index, date: date_time_tooltip(item.date) };
    } catch {
      return null;
    }
  });
  const clone_data = [...filter_scrapper_data];
  const new_state_scrapper = clone_data.slice(
    (parseInt(pagination_value) - 1) * pageSize_scrapper,
    parseInt(pagination_value) * pageSize_scrapper,
  );
  const new_total_page = Math.ceil(
    filter_scrapper_data.length / pageSize_scrapper,
  );

  const columns_scraper =
    table_type === "scraper_info_table"
      ? [
          {
            field: "id",
            headerName: "ID",
            width: 70,
            disableColumnMenu: true,
          },
          {
            field: "alt_phone",
            headerName: "Phone",
            width: 200,
            disableColumnMenu: true,
          },
          {
            field: "api_id",
            headerName: "Api Id",
            width: 120,
            disableColumnMenu: true,
          },
          {
            field: "api_hash",
            headerName: "Api Hash",
            flex: 1,
            disableColumnMenu: true,
          },
          {
            field: "ip_address",
            headerName: "Ip Adress",
            width: 150,
            disableColumnMenu: true,
          },
          {
            field: "alt_category",
            headerName: "Scrapping Category",
            width: 200,
            disableColumnMenu: true,
          },
          {
            field: "alt_name",
            headerName: "Scrapper Name",
            flex: 1,
            disableColumnMenu: true,
          },
          {
            field: "status",
            headerName: "Current Status",
            width: 120,
            disableColumnMenu: true,
          },
        ]
      : table_type === "vpn_tracker_table"
      ? [
          { field: "id", headerName: "ID", width: 70, disableColumnMenu: true },
          {
            field: "host",
            headerName: "Docker",
            flex: 1,
            disableColumnMenu: true,
          },
          {
            field: "vpn_host",
            headerName: "VPN Host",
            flex: 1,
            disableColumnMenu: true,
          },
          {
            field: "mullvad_user",
            headerName: "Mullvad User",
            width: 200,
            sortingOrder: ["desc", "asc"],
            disableColumnMenu: true,
          },
          {
            field: "current_ip",
            headerName: "Current IP",
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
          },
          {
            field: "last_modified",
            headerName: "Last Modified",
            width: 200,
            type: "date",
            sortComparator: sort_cloumn_datewise,
            disableColumnMenu: true,
          },
          {
            field: "vpn_country",
            headerName: "VPN Country",
            width: 130,
            disableColumnMenu: true,
          },
          {
            field: "vpn_type",
            headerName: "VPN Type",
            width: 130,
            filterable: false,
            disableColumnMenu: true,
          },
          {
            field: "is_connected",
            headerName: "Connection",
            width: 120,
            sortComparator: (v1, v2) => v2 - v1,
            disableColumnMenu: true,
            renderCell: (cellValues) => (
              <Tooltip
                title={
                  cellValues.row.is_connected === true ? "Active" : "Inactive"
                }
                disableInteractive
              >
                <span
                  className={
                    cellValues.row.is_connected === true
                      ? "deployed_scrapper_status_sign sign_color_active"
                      : "deployed_scrapper_status_sign sign_color_inactive"
                  }
                />
              </Tooltip>
            ),
          },
        ]
      : [
          { field: "id", headerName: "ID", width: 50 },
          {
            field: "channel_name",
            headerName: "Channel Name",
            flex: 1,
            disableColumnMenu: true,
          },
          {
            field: "channel_link",
            headerName: "Channel Link",
            width: 240,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "channel_type",
            headerName: "Type",
            width: 70,
            disableColumnMenu: true,
          },
          {
            field: "date",
            headerName: "Date",
            width: 200,
            disableColumnMenu: true,
          },
          {
            field: "category",
            headerName: "Category",
            width: 130,
            sortable: false,
            disableColumnMenu: true,
          },
          {
            field: "number_of_posts",
            headerName: "Posts No",
            width: 130,
            disableColumnMenu: true,
          },
          {
            field: "phone",
            headerName: "Phone",
            width: 110,
            disableColumnMenu: true,
          },
          {
            field: "scraper_name",
            headerName: "Scraper Name",
            width: 160,
            disableColumnMenu: true,
          },
          {
            field: "server_ip",
            headerName: "Server IP",
            width: 120,
            disableColumnMenu: true,
          },
        ];
  return (
    <div
      className="row_scrapper"
      style={{
        margin: table_type === "vpn_tracker_table" ? "15px 0" : "0 0 15px 0",
        justifyContent: "center",
      }}
    >
      <div className="row_scrapper_grid">
        {scraper_data.length > 0 ? (
          <>
            <DataGrid
              rows={new_state_scrapper}
              columns={columns_scraper}
              pageSize={pageSize_scrapper}
              onPageSizeChange={(newPageSize) => page_size_fixer(newPageSize)}
              rowsPerPageOptions={[50, 100]}
              componentsProps={{
                panel: {
                  sx: {
                    "& .MuiMenu-list": {
                      bgcolor: "white",
                    },
                    "& .MuiDataGrid-menuList": {
                      bgcolor: "white",
                    },
                  },
                },
              }}
              autoHeight
              columnVisibilityModel={{
                id: table_type === "vpn_tracker_table",
              }}
              disableColumnSelector
              density="comfortable"
            />
            <Pagination
              count={new_total_page}
              color="primary"
              page={pagination_value}
              onChange={handlePaginationChange}
              size="small"
            />
          </>
        ) : null}
      </div>
    </div>
  );
}
GridTable.propTypes = {
  scraper_data: PropTypes.array,
  table_type: PropTypes.string,
};
export default GridTable;

import PropTypes from "prop-types";
import React, { useEffect } from "react";
import "../../styles2.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Dashboard from "../Dashboard";
import Adminsidenav from "./Adminsidenav";
import Adminmainnav from "./Adminmainnav";
import { useDispatch } from "react-redux";
import { handle_store_url_location } from "../../actions/filterAction";

function AdminDashboard({ admin_reducer }) {
  useEffect(() => {}, []);
  const dispatch = useDispatch();
  if (localStorage.getItem("Token") == null) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <div className="wrapper">
      <Adminsidenav />
      {/* Page Content  */}
      <div id="content" className={admin_reducer.boot_active ? "active" : null}>
        <section>
          <Adminmainnav />
          <Dashboard />
        </section>
      </div>
    </div>
  );
}

AdminDashboard.propTypes = {
  admin_reducer: PropTypes.object,
};
const mapStateToProps = (state) => ({
  admin_reducer: state.admin_reducer,
});

export default connect(mapStateToProps)(AdminDashboard);

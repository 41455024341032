import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { login_func, change_inv_func } from "../actions/userAction";
import { useSelector } from "react-redux";
import { styles_background_img } from "../utils/Globals/global_functions";
import "../App.css";

function Login({ users, login_func, change_inv_func }) {
  useEffect(() => {
    change_inv_func("signup_redirect", false);
  }, []);
  const all_filters_state = useSelector((state) => state.filter_reducer);
  const user_url_location =
    all_filters_state.user_url_location !== null
      ? all_filters_state.user_url_location
      : "";
  const current_url_location = window.location.href;
  const split_url = user_url_location.split(current_url_location);
  const filter_location = split_url[split_url.length - 1].trim();
  if (users.login_redirect) {
    if (
      users.admin_status === "super_admin" ||
      users.admin_status === "admin"
    ) {
      if (user_url_location.trim().length > 0) {
        return <Redirect to={filter_location} />;
      } else {
        return <Redirect to="/company-dashboard" />;
      }
    }
    if (user_url_location.trim().length > 0) {
      return <Redirect to={filter_location} />;
    } else {
      return <Redirect to="/company-dashboard" />;
    }
  }
  return (
    <div className="company_stats_main_container" style={styles_background_img}>
      <div className="login_form_main_container">
        <div className="login_form_container">
          <ToastContainer />
          {/* <div className="row no-gutters"> */}
          {/* <div className="col-md-5">
                                    <img src="assets/images/login.jpg" alt="login" className="login-card-img" />
                                </div> */}

          <div className="brand-wrapper">
            {/* <img src={process.env.PUBLIC_URL + '/static/images/tesseract.jpg'} style={{ width: '44%', height: '80%' }} alt="logo" className="logo" /> */}
          </div>
          <h1 className="login_form_heading">Sign In</h1>
          <Formik
            initialValues={{ username: "", password: "" }}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .min(3, "Username must be longer than 2 character")
                .required("Username is required for Login")
                .matches(
                  /^(\S+$)/g,
                  "This field should not contain whitespaces",
                )
                .matches(/[^@]+$/g, "This field should not contain email")
                .matches(
                  /^[.A-Za-z0-9_-]*$/g,
                  "Username should only contain alphabetical characters, numbers and symbol as . - _",
                ),

              password: Yup.string()
                .min(6, "Password must be longer than 5 character")
                .max(30, "Password must be less than 30 character")
                .matches(
                  /[^'"]+$/g,
                  "Character must not contain single quote or double quote",
                )
                .required("Please enter a password"),
            })}
            onSubmit={(values) => {
              const { username } = values;
              const { password } = values;
              const data = {
                username,
                password,
              };

              login_func(data);
            }}
          >
            {({ values, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <div className="login-form-group">
                  <label htmlFor="username" className="login_form_label">
                    Username
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="login-form-control"
                    placeholder="Username Here"
                    required
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    name="username"
                    className="login_form_error_msg"
                  />
                </div>
                <div className="login-form-group mb-4">
                  <label htmlFor="password" className="login_form_label">
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="login-form-control"
                    placeholder="***********"
                    required
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    component="div"
                    name="password"
                    className="login_form_error_msg"
                  />
                </div>
                <input
                  name="login"
                  id="login"
                  className="login-form-control-btn"
                  type="submit"
                  value="Login"
                  defaultValue="Login"
                />
              </form>
            )}
          </Formik>
          <p
            className="forgot-password-link"
            onClick={() => {
              toast.error(
                "Please reach out to the service provider, who has setup your account",
                {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 7000,
                },
              );
            }}
          >
            Forgot password?
          </p>
          {/* <p className="login-card-footer-text">Don't have an account?<Link to='/signup'> <a href="#!" className="text-reset">Register Here</a></Link></p> */}
          <p className="login_form_register">
            {`Don't have an account?`}
            <span
              style={{ cursor: "pointer" }}
              className="login_form_register_btn"
              onClick={() => {
                toast.warn(
                  "Please contact us at tesseract@tesseractintelligence.com to create an account.",
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 7000,
                    style: {
                      color: "white !important",
                      fontWeight: "bold",
                      width: "fit-content",
                    },
                  },
                );
              }}
            >
              Register Here
            </span>
          </p>
          <div className="login_form_terms_div">
            <p>Terms of use.</p>
            <p>Privacy policy.</p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
}
Login.propTypes = {
  users: PropTypes.object,
  login_func: PropTypes.func,
  change_inv_func: PropTypes.func,
};
const mapStateToProps = (state) => ({
  users: state.users,
});
export default connect(mapStateToProps, { login_func, change_inv_func })(Login);

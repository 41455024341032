import PropTypes from "prop-types";
import React, { useState, memo } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import Avatar from "@mui/material/Avatar";
import { GlobalStyles } from "@mui/material";
import Highlighter from "react-highlight-words";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from "@mui/material/Tooltip";
import ModalForum from "../modalContainer/ModalForum";
import "../../../css/Forum/forumdata.css";
function ForumData({
  forum_data,
  paginationValue,
  setPaginationValue,
  get_searched_data_forum,
  forum_rate_limit,
  total_db_data,
}) {
  const [pageSize, setPageSize] = useState(25);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRow_forum, setSelectedRow_forum] = useState(null);
  const handleOpenModal = (required_row) => {
    setOpenModal(true);
    setSelectedRow_forum(required_row);
  };

  const text_index = (text) => {
    try {
      if (text && text.length > 0) {
        if (
          text.toUpperCase().indexOf(get_searched_data_forum.toUpperCase()) > 0
        ) {
          return true;
        }
        return false;
      }
      return false;
    } catch {
      //Do nothing
    }
  };

  // Highlight message for the post and file
  const highlight_message = (words) => {
    if (words) {
      try {
        const result = words
          .toUpperCase()
          .indexOf(get_searched_data_forum.toUpperCase());
        if (result <= 180) {
          return words;
        }
        return words.slice(result - 20, result + 200);
      } catch {
        return words;
      }
    } else {
      return null;
    }
  };

  const columns_forum = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "image",
      headerName: "Image",
      width: 61,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => (
        <Avatar
          alt="Remy Sharp"
          src={
            cellValues.row.image !== "None"
              ? cellValues.row.image
              : // eslint-disable-next-line no-undef
                `${process.env.PUBLIC_URL}/static/images/forum_icon.svg`
          }
          sx={{ background: "white" }}
        />
      ),
    },
    {
      field: "title",
      headerName: "Title",
      width: 300,
      renderCell: (cellValues) => (
        <Highlighter
          highlightClassName="highlightclass"
          searchWords={
            text_index(cellValues.row.title) === true
              ? [get_searched_data_forum]
              : get_searched_data_forum.split(" ")
          }
          autoEscape
          textToHighlight={cellValues.row.title}
          className="text_hidden"
        />
      ),
    },

    {
      field: "post",
      headerName: "Post",
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => (
        <>
          <Highlighter
            highlightClassName="highlightclass"
            searchWords={
              text_index(cellValues.row.post) === true
                ? [get_searched_data_forum]
                : get_searched_data_forum.split(" ")
            }
            autoEscape
            textToHighlight={highlight_message(cellValues.row.post)}
            className="text_hidden"
          />
        </>
      ),
    },

    {
      field: "link",
      headerName: "Link",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: "action",
      headerName: "Action",
      width: 75,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (cellValues) => (
        <>
          <button
            className="alert_bell_icon_btn"
            onClick={() => handleOpenModal(cellValues.row.all_data)}
          >
            <Tooltip title="see more">
              <VisibilityIcon sx={{ color: "#9bbcd1" }} />
            </Tooltip>
          </button>
        </>
      ),
    },
  ];

  const filter_data_forum = forum_data.map((item, index) => ({
    id: index,
    image: item.image_link,
    link: item.urllink,
    post: item.snippet,
    title: item.post_title,
    all_data: item,
  }));

  const clone_data_forum = [...filter_data_forum];
  const new_state_forum = clone_data_forum.slice(
    (parseInt(paginationValue) - 1) * pageSize,
    parseInt(paginationValue) * pageSize,
  );
  const new_total_page = Math.ceil(filter_data_forum.length / pageSize);

  const handlePaginationChange = (event, value) => {
    setPaginationValue(value);
  };

  // Select the required page size
  const page_size_fixer = (newPageSize) => {
    setPageSize(newPageSize);
    setPaginationValue(Math.ceil(filter_data_forum.length / newPageSize));
  };

  return (
    <>
      <div className="main__table forum_main_table">
        <div className="total_data_number_forum">
          <h6>
            Results shown/found :{" "}
            <span>
              {forum_data.length}/{total_db_data}
            </span>
          </h6>
          <div className="total_data_number_child">
            <h6>
              Credits remaining :{" "}
              <span className="credit_limit_colors">{forum_rate_limit}</span>
            </h6>
          </div>
        </div>
        <GlobalStyles
          styles={{
            ".MuiDataGrid-menuList": {
              backgroundColor: "#394e5a",
            },
            ".MuiMenu-list ": {
              backgroundColor: "#394e5a",
            },
            ".MuiDataGrid-menuList li": {
              color: "#dcf3ff",
            },
            ".MuiMenu-list li": {
              color: "#dcf3ff",
            },
            ".MuiPaginationItem-root": {
              color: "#9bbcd1",
              backgroundColor: "#1e2a31",
            },
            ".MuiDataGrid-filterForm": {
              backgroundColor: "#394e5a",
              border: "1px solid #9bbcd1",
            },
          }}
        />
        <DataGrid
          rows={new_state_forum}
          columns={columns_forum}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => page_size_fixer(newPageSize)}
          rowsPerPageOptions={[25, 50, 75, 100]}
          autoHeight
          columnVisibilityModel={{
            id: false,
          }}
          disableColumnSelector
          rowHeight={65}
        />
        <Pagination
          count={new_total_page}
          color="primary"
          page={paginationValue}
          onChange={handlePaginationChange}
          size="small"
        />
      </div>
      <ModalForum
        openModal={openModal}
        setOpenModal={setOpenModal}
        selectedRow={selectedRow_forum}
      />
    </>
  );
}

ForumData.propTypes = {
  forum_data: PropTypes.array,
  forum_rate_limit: PropTypes.number,
  total_db_data: PropTypes.number,
  get_searched_data_forum: PropTypes.string,
  paginationValue: PropTypes.any,
  setPaginationValue: PropTypes.func,
};

export default memo(ForumData);

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import Navbar from "../Navbar/Navbar";
import { useDispatch } from "react-redux";
import Searchbar from "../searchbar/Searchbar";
import Sidenav from "../Navbar/Sidenav";
import { user_nav_func } from "../../../actions/userAction";
import { handle_session_get } from "../../../utils/SessionStorage/handle_session";
import { handle_store_url_location } from "../../../actions/filterAction";
import { styles_background_img } from "../../../utils/Globals/global_functions";

function HomeTable({ user_nav_func }) {
  const token = handle_session_get("Token");
  const [searchTypeTab, setSearchTypeTab] = useState("telegram");
  // for tab selection
  const [selectedTab, setSelectedTab] = React.useState();
  const [show_sidenav, set_show_sidenav] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (token === null) {
      return;
    }
    try {
      if (location.state.selectedTab) {
        if (localStorage.getItem("company_url_status")) {
          setSelectedTab(location.state.selectedTab);
          setSearchTypeTab(location.state.selectedTab);
          localStorage.removeItem("company_url_status");
        }
      }
    } catch {
      //Do nothing
    }
  }, []);

  const user_name_session = handle_session_get("admin_status");
  if (token === null) {
    dispatch(handle_store_url_location(window.location.href));
    return <Redirect to="/" />;
  }
  return (
    <div className="company_stats_main_container" style={styles_background_img}>
      <Grid container>
        {user_name_session === "super_admin" ||
        user_name_session === "admin" ? (
          <Grid
            item
            xs={show_sidenav === true ? 0.5 : 0}
            sm={show_sidenav === true ? 0.5 : 0}
          >
            {show_sidenav ? <Sidenav /> : null}
          </Grid>
        ) : null}
        <Grid
          item
          xs={show_sidenav === true ? 11.5 : 12}
          sm={show_sidenav === true ? 11.5 : 12}
        >
          <Navbar
            stateChanger={setSearchTypeTab}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            session={user_nav_func}
            show_sidenav={show_sidenav}
            set_show_sidenav={set_show_sidenav}
          />
          <Searchbar
            searchTypeTab={searchTypeTab}
            stateChanger={setSearchTypeTab}
            setSelectedTab={setSelectedTab}
            session={user_nav_func}
          />
        </Grid>
      </Grid>
    </div>
  );
}
HomeTable.propTypes = {
  user_nav_func: PropTypes.func,
};
const mapStateToProps = (state) => ({
  admin_reducer: state.admin_reducer,
  user: state.users,
});
export default connect(mapStateToProps, { user_nav_func })(HomeTable);

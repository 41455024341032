import PropTypes from "prop-types";
import React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import Categories from "../sections/Checkbox/Categories";
import "../../../css/Filters/filters.css";
function FiltersAlertAdmin({
  set_show_filters,
  postCategory,
  setPostCategory,
  keyword,
  setKeyword,
  interval_type_filter,
  set_interval_type_filter,
  interval_number_filter,
  set_interval_number_filter,
  option_dropdown,
  notification_filters,
  set_notification_filters,
  notification_filters_breach,
  set_notification_filters_breach,
  notification_filters_tag,
  set_notification_filters_tag,
  alert_type,
  filter_type,
  set_filter_type,
  exp_year,
  set_exp_year,
  exp_year_options,
  exp_month_options,
  exp_month,
  set_exp_month,
  card_type,
  set_card_type,
  card_type_options,
  crypto_provider,
  set_crypto_provider,
  crypto_options,
  email_provider,
  set_email_provider,
  email_options,
  alert_file_type,
  set_alert_file_type,
  alert_post_no,
  set_alert_post_no,
  multiple_email_type,
  set_multiple_email_type,
  notification_filters_stealer,
  set_notificaton_filters_stealer,
  keyword_stealer,
  setKeyword_stealer,
  post_category_stealer,
  set_post_category_stealer,
  query_type,
  set_query_type,
  set_open_bulk_modal,
}) {
  const isAllselectedExpYear =
    exp_year_options.length > 0 && exp_year.length === exp_year_options.length;
  const isAllselectedExpMonth =
    exp_month_options.length > 0 &&
    exp_month.length === exp_month_options.length;
  const isAllselectedCardType =
    card_type_options.length > 0 &&
    card_type.length === card_type_options.length;
  const isAllselectedCryptoProvider =
    crypto_options.length > 0 &&
    crypto_provider.length === crypto_options.length;
  const isAllselectedEmailProvider =
    email_options.length > 0 && email_provider.length === email_options.length;

  // Click outside function
  const handleClickAway = () => {
    set_show_filters(false);
  };
  const handleChangeIntervalType = (event) => {
    set_interval_type_filter(event.target.value);
    set_interval_number_filter(event.target.value === "minutes" ? 5 : 1);
  };

  const valuetext = (value) => value;
  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  };

  const handle_change_notification_filters = (event) => {
    set_notification_filters(event.target.value);
    if (event.target.value !== "Default") {
      setKeyword("exact");
    }
  };
  const handle_change_notification_filters_breach = (event) => {
    set_notification_filters_breach(event.target.value);
  };
  const handle_change_notification_filters_tag = (event) => {
    set_notification_filters_tag(event.target.value);
  };
  const handleChangeMultipleEmailType = (event) => {
    set_multiple_email_type(event.target.value);
  };

  const handleChangeFilterType = (event) => {
    set_filter_type(event.target.value);
  };

  // Function for the tag alert filters
  const handle_change_exp_year = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_year(
        exp_year.length === exp_year_options.length ? [] : exp_year_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_year = [...exp_year];
      const index_exp_year = list_exp_year.indexOf(num_value);
      index_exp_year === -1
        ? list_exp_year.push(num_value)
        : list_exp_year.splice(index_exp_year, 1);
      set_exp_year(list_exp_year);
    }
  };
  const handle_change_exp_month = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_exp_month(
        exp_month.length === exp_month_options.length ? [] : exp_month_options,
      );
    } else {
      const num_value = Number(value);
      const list_exp_month = [...exp_month];
      const index_exp_month = list_exp_month.indexOf(num_value);
      index_exp_month === -1
        ? list_exp_month.push(num_value)
        : list_exp_month.splice(index_exp_month, 1);
      set_exp_month(list_exp_month);
    }
  };
  const handle_change_card_type = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_card_type(
        card_type.length === card_type_options.length ? [] : card_type_options,
      );
    } else {
      const list_card_type = [...card_type];
      const index_card_type = list_card_type.indexOf(value);
      index_card_type === -1
        ? list_card_type.push(value)
        : list_card_type.splice(index_card_type, 1);
      set_card_type(list_card_type);
    }
  };
  const handle_change_crypto_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_crypto_provider(
        crypto_provider.length === crypto_options.length ? [] : crypto_options,
      );
    } else {
      const list_crypto_provider = [...crypto_provider];
      const index_crypto_provider = list_crypto_provider.indexOf(value);
      index_crypto_provider === -1
        ? list_crypto_provider.push(value)
        : list_crypto_provider.splice(index_crypto_provider, 1);
      set_crypto_provider(list_crypto_provider);
    }
  };
  const handle_change_email_provider = (event) => {
    const { value } = event.target;
    if (value === "all") {
      set_email_provider(
        email_provider.length === email_options.length ? [] : email_options,
      );
    } else {
      const list_email_provider = [...email_provider];
      const index_email_provider = list_email_provider.indexOf(value);
      index_email_provider === -1
        ? list_email_provider.push(value)
        : list_email_provider.splice(index_email_provider, 1);
      set_email_provider(list_email_provider);
    }
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="filters__main notification_filters_div">
          {/* Category Filters */}
          {option_dropdown !== "breach" &&
            option_dropdown !== "channel" &&
            option_dropdown !== "stealer_logs" &&
            option_dropdown !== "brand_protection" && (
              <Categories
                postCategory={postCategory}
                setPostCategory={setPostCategory}
                container_type={"column"}
              />
            )}

          {/* Notificaton filters for the breach */}

          {option_dropdown === "breach" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Filters
              </FormLabel>
              <Box className="filter__category">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={notification_filters_breach}
                  onChange={handle_change_notification_filters_breach}
                >
                  <FormControlLabel
                    value="domain"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Domain"
                  />
                  <FormControlLabel
                    value="phone"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Phone"
                  />
                  <FormControlLabel
                    value="name"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Name"
                  />
                  <FormControlLabel
                    value="email"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Email"
                  />
                  <FormControlLabel
                    value="username"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Username"
                  />
                  <FormControlLabel
                    value="ip"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="IP"
                  />
                  <FormControlLabel
                    value="password"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Password"
                  />
                </RadioGroup>
              </Box>
            </FormControl>
          )}

          {/* Notificaton filters for the Stealer logs */}
          {option_dropdown === "stealer_logs" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Filters
              </FormLabel>
              <Box className="filter__category " id="scroll_filter">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={notification_filters_stealer}
                  onChange={(e) =>
                    set_notificaton_filters_stealer(e.target.value)
                  }
                >
                  <FormControlLabel
                    value="email"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Username/Email"
                  />
                  <FormControlLabel
                    value="domain"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Domain"
                  />
                  <FormControlLabel
                    value="password"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Password"
                  />
                  <FormControlLabel
                    value="country_code"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Country Code"
                  />
                  <FormControlLabel
                    value="ip_address"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="IP"
                  />
                  <FormControlLabel
                    value="zip"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Zip"
                  />
                  <FormControlLabel
                    value="location"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Location"
                  />
                  <FormControlLabel
                    value="device_id"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="MID/HWID/GUID"
                  />
                </RadioGroup>
              </Box>
            </FormControl>
          )}

          {/* Notificaton filters for the tag */}
          {option_dropdown === "tag" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Filters
              </FormLabel>
              <Box className="filter__category " id="scroll_filter">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={notification_filters_tag}
                  onChange={handle_change_notification_filters_tag}
                >
                  <FormControlLabel
                    value="bank_card"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Bank Card"
                  />
                  <FormControlLabel
                    value="email"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Email"
                  />
                  <FormControlLabel
                    value="ip_address"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Ip Address"
                  />
                  <FormControlLabel
                    value="crypto"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Crypto"
                  />
                  <FormControlLabel
                    value="domain"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Domain"
                  />
                  <FormControlLabel
                    value="phone"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Phone"
                  />
                  <FormControlLabel
                    value="bin"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Bin"
                  />
                </RadioGroup>
              </Box>
            </FormControl>
          )}

          {option_dropdown === "tag"
            ? (notification_filters_tag === "bank_card" ||
                notification_filters_tag === "bin") && (
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title filter_category_title"
                  >
                    Exp Year
                  </FormLabel>
                  <Box className="filter__category " id="scroll_filter">
                    <FormGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                    >
                      {exp_year_options.map((item, id) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item}
                              onChange={handle_change_exp_year}
                              checked={exp_year.includes(item)}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={item}
                          key={id}
                        />
                      ))}

                      <FormControlLabel
                        control={
                          <Checkbox
                            value="all"
                            onChange={handle_change_exp_year}
                            checked={isAllselectedExpYear}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="All"
                      />
                    </FormGroup>
                  </Box>
                </FormControl>
              )
            : null}

          {option_dropdown === "tag"
            ? (notification_filters_tag === "bank_card" ||
                notification_filters_tag === "bin") && (
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title filter_category_title"
                  >
                    Exp Month
                  </FormLabel>
                  <Box className="filter__category " id="scroll_filter">
                    <FormGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                    >
                      {exp_month_options.map((item, id) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item}
                              onChange={handle_change_exp_month}
                              checked={exp_month.includes(item)}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={item}
                          key={id}
                        />
                      ))}

                      <FormControlLabel
                        control={
                          <Checkbox
                            value="all"
                            onChange={handle_change_exp_month}
                            checked={isAllselectedExpMonth}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="All"
                      />
                    </FormGroup>
                  </Box>
                </FormControl>
              )
            : null}

          {option_dropdown === "tag"
            ? (notification_filters_tag === "bank_card" ||
                notification_filters_tag === "bin") && (
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title filter_category_title"
                  >
                    Card Type
                  </FormLabel>
                  <Box className="filter__category " id="scroll_filter">
                    <FormGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                    >
                      {card_type_options.map((item, id) => {
                        let split_card_name = "";
                        if (item === "universal_air_travel_plan_card") {
                          split_card_name = "UATP";
                        } else if (item === "jcb_card") {
                          split_card_name = "JCB";
                        } else if (
                          ["amex_card", "mastercard", "visa_card"].includes(
                            item,
                          ) === false
                        ) {
                          split_card_name = item.replaceAll("card", "");
                        } else {
                          split_card_name = item;
                        }
                        split_card_name = split_card_name.replaceAll("_", " ");
                        return (
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item}
                                onChange={handle_change_card_type}
                                checked={card_type.includes(item)}
                                size="small"
                                sx={{
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "#28cab3",
                                  },
                                }}
                              />
                            }
                            label={
                              <span className="card_name">
                                {split_card_name}
                              </span>
                            }
                            key={id}
                          />
                        );
                      })}

                      <FormControlLabel
                        control={
                          <Checkbox
                            value="all"
                            onChange={handle_change_card_type}
                            checked={isAllselectedCardType}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="All"
                      />
                    </FormGroup>
                  </Box>
                </FormControl>
              )
            : null}

          {option_dropdown === "tag"
            ? notification_filters_tag === "crypto" && (
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title filter_category_title"
                  >
                    Crypto Provider
                  </FormLabel>
                  <Box className="filter__category " id="scroll_filter">
                    <FormGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                    >
                      {crypto_options.map((item, id) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item}
                              onChange={handle_change_crypto_provider}
                              checked={crypto_provider.includes(item)}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={<span className="card_name">{item}</span>}
                          key={id}
                        />
                      ))}

                      <FormControlLabel
                        control={
                          <Checkbox
                            value="all"
                            onChange={handle_change_crypto_provider}
                            checked={isAllselectedCryptoProvider}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="All"
                      />
                    </FormGroup>
                  </Box>
                </FormControl>
              )
            : null}
          {option_dropdown === "tag"
            ? notification_filters_tag === "email" && (
                <FormControl>
                  <FormLabel
                    id="demo-controlled-radio-buttons-group"
                    className="label__title filter_category_title"
                  >
                    Email Provider
                  </FormLabel>
                  <Box className="filter__category " id="scroll_filter">
                    <FormGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                    >
                      {email_options.map((item, id) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={item}
                              onChange={handle_change_email_provider}
                              checked={email_provider.includes(item)}
                              size="small"
                              sx={{
                                color: "white",
                                "&.Mui-checked": {
                                  color: "#28cab3",
                                },
                              }}
                            />
                          }
                          label={<span className="card_name">{item}</span>}
                          key={id}
                        />
                      ))}

                      <FormControlLabel
                        control={
                          <Checkbox
                            value="all"
                            onChange={handle_change_email_provider}
                            checked={isAllselectedEmailProvider}
                            size="small"
                            sx={{
                              color: "white",
                              "&.Mui-checked": {
                                color: "#28cab3",
                              },
                            }}
                          />
                        }
                        label="All"
                      />
                    </FormGroup>
                  </Box>
                </FormControl>
              )
            : null}

          {/* Keyword  */}
          {option_dropdown !== "channel" &&
            option_dropdown !== "breach" &&
            option_dropdown !== "tag" &&
            option_dropdown !== "stealer_logs" &&
            option_dropdown !== "brand_protection" &&
            notification_filters === "Default" && (
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Keyword
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={keyword}
                  onChange={handleChangeKeyword}
                >
                  <FormControlLabel
                    value="contains"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Contains"
                  />
                  <FormControlLabel
                    value="exact"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Exact"
                  />
                </RadioGroup>
              </FormControl>
            )}

          {/* KEYWORD for the stealer logs  */}
          {option_dropdown === "stealer_logs" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Keyword
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={keyword_stealer}
                onChange={(e) => setKeyword_stealer(e.target.value)}
              >
                <FormControlLabel
                  value="contains"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Contains"
                />
                <FormControlLabel
                  value="exact"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Exact"
                />
                <FormControlLabel
                  value="regex"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Regex"
                />
              </RadioGroup>
            </FormControl>
          )}
          {option_dropdown === "stealer_logs" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Category
              </FormLabel>
              <Box className="filter__category " id="scroll_filter">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={post_category_stealer}
                  onChange={(e) => set_post_category_stealer(e.target.value)}
                  sx={{ columnGap: 2 }}
                >
                  <FormControlLabel
                    value={"hacking"}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Hacking"
                  />
                  <FormControlLabel
                    value={"pedophiles"}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Pedophiles"
                  />
                  <FormControlLabel
                    value={"russian defense"}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Russian Defense"
                  />
                  <FormControlLabel
                    value={"terrorism"}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Terrorism"
                  />
                  <FormControlLabel
                    value={"off"}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="OFF"
                  />
                </RadioGroup>
              </Box>
            </FormControl>
          )}
          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Interval Type
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={interval_type_filter}
              onChange={handleChangeIntervalType}
            >
              {option_dropdown !== "breach" && (
                <FormControlLabel
                  value="day"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Day"
                />
              )}

              <FormControlLabel
                value="week"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: "white",
                      "&.Mui-checked": {
                        color: "#28cab3",
                      },
                    }}
                  />
                }
                label="Week"
              />
              {option_dropdown !== "breach" &&
                option_dropdown !== "stealer_logs" &&
                option_dropdown !== "brand_protection" && (
                  <FormControlLabel
                    value="minutes"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label={"Minutes"}
                  />
                )}
            </RadioGroup>
          </FormControl>

          <FormControl>
            <FormLabel
              id="demo-controlled-radio-buttons-group"
              className="label__title"
            >
              Interval Duration
            </FormLabel>
            <Box sx={{ width: "100%" }}>
              <Slider
                aria-label="Temperature"
                value={interval_number_filter}
                getAriaValueText={valuetext}
                onChange={(e, newValue) => {
                  set_interval_number_filter(newValue);
                }}
                valueLabelDisplay="auto"
                min={interval_type_filter === "minutes" ? 5 : 1}
                max={
                  interval_type_filter === "minutes"
                    ? 1439
                    : interval_type_filter === "week"
                    ? 4
                    : 7
                }
                marks
                sx={{ color: "#28cab3" }}
              />
              <p
                style={{
                  color: "whitesmoke",
                  fontWeight: 500,
                  textAlign: "start",
                }}
              >
                Interval:{" "}
                {interval_type_filter === "minutes"
                  ? interval_number_filter >= 60
                    ? `${Math.floor(Number(interval_number_filter) / 60)} hr ${
                        Number(interval_number_filter) % 60
                      } mins`
                    : `${interval_number_filter} mins`
                  : interval_number_filter}
              </p>
            </Box>
          </FormControl>
          {option_dropdown === "stealer_logs" && (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Query Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={query_type}
                onChange={(e) => {
                  set_query_type(e.target.value);
                  if (e.target.value === "bulk") {
                    set_open_bulk_modal(true);
                  }
                }}
              >
                <FormControlLabel
                  value="Default"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Default"
                />
                <FormControlLabel
                  value="bulk"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Bulk"
                />
              </RadioGroup>
            </FormControl>
          )}

          {option_dropdown !== "breach" &&
            option_dropdown !== "channel" &&
            option_dropdown !== "tag" &&
            option_dropdown !== "stealer_logs" &&
            option_dropdown !== "brand_protection" && (
              <FormControl>
                <FormLabel
                  id="demo-controlled-radio-buttons-group"
                  className="label__title"
                >
                  Filters
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={notification_filters}
                  onChange={handle_change_notification_filters}
                >
                  <FormControlLabel
                    value="Default"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Default"
                  />
                  <FormControlLabel
                    value="regex"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Regex"
                  />
                  <FormControlLabel
                    value="logical"
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label="Logical"
                  />
                </RadioGroup>
              </FormControl>
            )}

          {alert_type === "multiple_email" ? (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Emails
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={multiple_email_type}
                onChange={handleChangeMultipleEmailType}
              >
                <FormControlLabel
                  value="cc"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="CC"
                />
                <FormControlLabel
                  value="bcc"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="BCC"
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {option_dropdown === "channel" ? (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Filter Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={filter_type}
                onChange={handleChangeFilterType}
              >
                <FormControlLabel
                  value="default"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Default"
                />
                <FormControlLabel
                  value="individual"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="Individual"
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {/* Alert file type */}
          {option_dropdown !== "breach" &&
          option_dropdown !== "tag" &&
          option_dropdown !== "stealer_logs" &&
          (alert_type === "email" || alert_type === "multiple_email") ? (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                File Type
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={alert_file_type}
                onChange={(e) => {
                  set_alert_file_type(e.target.value);
                }}
              >
                <FormControlLabel
                  value="csv"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="CSV"
                />
                <FormControlLabel
                  value="html"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "#28cab3",
                        },
                      }}
                    />
                  }
                  label="HTML"
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {/* Alert Post no */}
          {option_dropdown !== "breach" &&
          option_dropdown !== "tag" &&
          option_dropdown !== "brand_protection" &&
          alert_file_type === "html" ? (
            <FormControl>
              <FormLabel
                id="demo-controlled-radio-buttons-group"
                className="label__title"
              >
                Post
              </FormLabel>
              <Box className="filter__category">
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={alert_post_no}
                  onChange={(e) => {
                    set_alert_post_no(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value={0}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label={0}
                  />
                  <FormControlLabel
                    value={1}
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label={1}
                  />
                  <FormControlLabel
                    value={
                      option_dropdown === "keyword" ||
                      option_dropdown === "file" ||
                      option_dropdown === "file_extension"
                        ? 2
                        : 5
                    }
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label={
                      option_dropdown === "keyword" ||
                      option_dropdown === "file" ||
                      option_dropdown === "file_extension"
                        ? 2
                        : 5
                    }
                  />
                  <FormControlLabel
                    value={
                      option_dropdown === "keyword" ||
                      option_dropdown === "file" ||
                      option_dropdown === "file_extension"
                        ? 3
                        : 10
                    }
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label={
                      option_dropdown === "keyword" ||
                      option_dropdown === "file" ||
                      option_dropdown === "file_extension"
                        ? 3
                        : 10
                    }
                  />
                  <FormControlLabel
                    value={
                      option_dropdown === "keyword" ||
                      option_dropdown === "file" ||
                      option_dropdown === "file_extension"
                        ? 4
                        : 15
                    }
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label={
                      option_dropdown === "keyword" ||
                      option_dropdown === "file" ||
                      option_dropdown === "file_extension"
                        ? 4
                        : 15
                    }
                  />
                  <FormControlLabel
                    value={
                      option_dropdown === "keyword" ||
                      option_dropdown === "file" ||
                      option_dropdown === "file_extension"
                        ? 5
                        : 20
                    }
                    control={
                      <Radio
                        size="small"
                        sx={{
                          color: "white",
                          "&.Mui-checked": {
                            color: "#28cab3",
                          },
                        }}
                      />
                    }
                    label={
                      option_dropdown === "keyword" ||
                      option_dropdown === "file" ||
                      option_dropdown === "file_extension"
                        ? 5
                        : 20
                    }
                  />
                </RadioGroup>
              </Box>
            </FormControl>
          ) : null}

          {/* Translate activate or deactivate */}
          {/* {((option_dropdown === 'keyword' || option_dropdown === "channel") && interval_type_filter !== "minutes") &&
                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group" className="label__title">
                                Translate Report
                            </FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={translate_report}
                                onChange={handle_change_translate_report}
                            >
                                <FormControlLabel value="activate" control={<Radio size="small" sx={{
                                    color: "white", '&.Mui-checked': {
                                        color: '#28cab3',
                                    }
                                }} />} label={<Stack direction='row' columnGap={0.5} alignItems='center'>
                                    Activate
                                    <HtmlTooltip title='Translate report on english language'>
                                        <img width='23.5411' height='23' src={process.env.PUBLIC_URL + `/static/images/en.png`} />
                                    </HtmlTooltip>

                                </Stack>} />
                                <FormControlLabel
                                    value="deactivate"
                                    control={<Radio size="small" sx={{
                                        color: "white", '&.Mui-checked': {
                                            color: '#28cab3',
                                        }
                                    }} />}
                                    label="Deactivate"
                                />
                            </RadioGroup>
                        </FormControl>
                    } */}
        </div>
      </ClickAwayListener>
    </>
  );
}

FiltersAlertAdmin.propTypes = {
  alert_file_type: PropTypes.string,
  alert_post_no: PropTypes.number,
  alert_type: PropTypes.string,
  card_type: PropTypes.array,
  card_type_options: PropTypes.array,
  crypto_options: PropTypes.array,
  crypto_provider: PropTypes.array,
  email_options: PropTypes.array,
  email_provider: PropTypes.array,
  end_point: PropTypes.any,
  exp_month: PropTypes.array,
  exp_month_options: PropTypes.array,
  exp_year: PropTypes.array,
  exp_year_options: PropTypes.array,
  filter_type: PropTypes.string,
  interval_number_filter: PropTypes.number,
  interval_type_filter: PropTypes.string,
  keyword: PropTypes.string,
  keyword_stealer: PropTypes.string,
  multiple_email_type: PropTypes.string,
  multiple_emails_list: PropTypes.array,
  notification_filters: PropTypes.string,
  notification_filters_breach: PropTypes.string,
  notification_filters_stealer: PropTypes.string,
  notification_filters_tag: PropTypes.string,
  option_dropdown: PropTypes.string,
  postCategory: PropTypes.array,
  setKeyword: PropTypes.func,
  setKeyword_stealer: PropTypes.func,
  setPostCategory: PropTypes.func,
  set_alert_file_type: PropTypes.func,
  set_alert_post_no: PropTypes.func,
  set_alert_type: PropTypes.func,
  set_card_type: PropTypes.func,
  set_crypto_provider: PropTypes.func,
  set_email_provider: PropTypes.func,
  set_exp_month: PropTypes.func,
  set_exp_year: PropTypes.func,
  set_filter_type: PropTypes.func,
  set_interval_number_filter: PropTypes.func,
  set_interval_type_filter: PropTypes.func,
  set_multiple_email_type: PropTypes.func,
  set_notification_filters: PropTypes.func,
  set_notification_filters_breach: PropTypes.func,
  set_notification_filters_tag: PropTypes.func,
  set_notificaton_filters_stealer: PropTypes.func,
  set_show_filters: PropTypes.func,
  post_category_stealer: PropTypes.string,
  set_post_category_stealer: PropTypes.func,
  query_type: PropTypes.bool,
  set_query_type: PropTypes.func,
  set_open_bulk_modal: PropTypes.func,
};

export default FiltersAlertAdmin;

import React, { memo } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Stack } from "@mui/material";
import { Formik } from "formik";
import { handle_error_message } from "../../../utils/ResponseMessage/response_message";
import { handle_validation_brand_protection_query } from "../../../utils/Globals/global_functions";

function BulkModal({
  open_bulk_modal,
  set_open_bulk_modal,
  post_data,
  option_dropdown,
  alert_status,
  brand_protection_data,
}) {
  return (
    <Modal
      open={open_bulk_modal}
      onClose={() => set_open_bulk_modal(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="bulk_search_modal">
        <Stack alignItems="flex-end">
          <CancelIcon
            onClick={() => set_open_bulk_modal(false)}
            fontSize="small"
            sx={{ color: "red ", cursor: "pointer", m: 0.5 }}
          />
        </Stack>
        <h6>Insert your Queries</h6>
        <Formik
          initialValues={{ bulk_search: "" }}
          validate={(values) => {
            const errors = {};

            if (values.bulk_search.length === 0) {
              errors.bulk_search = "";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            if (alert_status === "user_alert") {
              const validation_text = handle_validation_brand_protection_query(
                brand_protection_data,
                values.bulk_search,
                "new line",
              );
              setTimeout(() => {
                setSubmitting(false);
                if (validation_text !== "submit form") {
                  handle_error_message(validation_text);
                  return;
                } else {
                  post_data(values.bulk_search, "brand_protection");
                }
                set_open_bulk_modal(false);
              }, 400);
            } else if (alert_status === "admin_alert") {
              setTimeout(() => {
                setSubmitting(false);
                post_data(values.bulk_search, option_dropdown);
                set_open_bulk_modal(false);
              }, 400);
            }
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <textarea
                name="bulk_search"
                className="bulk_search_textarea"
                rows="15"
                cols="20"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.bulk_search}
                placeholder="example:&#10;query1&#10;query2&#10;query3&#10;query4&#10;query5"
              />
              <button
                type="submit"
                disabled={isSubmitting}
                className="button_global_style"
              >
                Submit
              </button>
            </form>
          )}
        </Formik>
      </Box>
    </Modal>
  );
}
BulkModal.propTypes = {
  open_bulk_modal: PropTypes.bool,
  set_open_bulk_modal: PropTypes.func,
  post_data: PropTypes.func,
  brand_protection_data: PropTypes.array,
  option_dropdown: PropTypes.string,
  alert_status: PropTypes.string,
};
export default memo(BulkModal);

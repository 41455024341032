export const CHANGE_STATE = "CHANGE_STATE";
export const INV_DATA = "INV_DATA";
export const GET_USER_DATA = "GET_USER_DATA";
export const UPDATE_USER = "UPDATE_USER";
export const NAV_USER = "NAV_USER";
export const LOGOUT = "LOGOUT";
export const SIGNUP = "SIGNUP";
export const LOGIN = "LOGIN";
export const LOADING = "LOADING";
export const SEARCH_DATA = "SEARCH_DATA";
export const SEARCH_DATA_ERROR = "SEARCH_DATA_ERROR";
export const CHART_INFO_DATA = "CHART_INFO_DATA";
export const CHART_DATA = "CHART_DATA";
export const HOME_INDV_FILTER = "HOME_INDV_FILTER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_ADMIN_PAGE = "UPDATE_ADMIN_PAGE";
export const GET_ALL_USER = "GET_ALL_USER";
export const ADMIN_NOTIFICATION = "ADMIN_NOTIFICATION";
export const ADMIN_CHART_DATA = "ADMIN_CHART_DATA";
export const TABLE_SORT = "TABLE_SORT";
export const NAV_CHANGER = "NAV_CHANGER";
export const REG_USER = "REG_USER";
export const UNAUTH_USER = "UNAUTH_USER";
export const FILT_DATA = "FILT_DATA";
export const POST_FILT_DATA = "POST_FILT_DATA";
export const AUD_INfO = "AUD_INfO";
export const CALENDAR_STATS = "CALENDAR_STATS";
export const CALENDAR_DATA = "CALENDAR_DATA";
export const FORWARDED_DAT = "FORWARDED_DAT";
export const FORWARDED_CHANNEL = "FORWARDED_CHANNEL";
export const STATS_LOADING = "STATS_LOADING";
export const FILE_DATA = "FILE_DATA";
export const FILE_EXTENSION = "FILE_EXTENSION";
export const MENTIONS_DATA = "MENTIONS_DATA";
export const HASH_DATA = "HASH_DATA";
export const FORUM_DATA = "FORUM_DATA";
export const THEME = "THEME";
export const EMAIL_SENT = "EMAIL_SENT";
export const CALENDAR_LOADING = "CALENDAR_LOADING";
export const SHOW_MAIN_DASHBOARD = "SHOW_MAIN_DASHBOARD";
